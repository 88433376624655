<app-page>
  <v2-two-columns-page [mobile]="mobile"
                       class="v2-margin-10-b"
                       rightClasses="v2-padding-8px-h"
                       mobileLayout="flex-column-start-center v2-row-gap-2">
    <div left
         *ngIf="faq != null"
         class="">
      <a [href]="faqCategoryLink($any(faq.category))"
         class="h5 color-green-grey-3 flex-row-start-center"> <img src="/assets/v2-back-grey.svg"
             alt="back"
             class="v2-margin-4px-r back-icon" />
        <div class="back-text">Back</div>
      </a>
      <div class="display-1 color-green-grey-1 v2-margin-16px-t">{{$any(faq.article).title}}</div>
      <div class="label-2 color-green-grey-3 v2-margin-8px-t">{{faqUpdateTime}} &middot; Updated</div>
      <div class="article-content v2-margin-32px-t color-green-grey-2"
           [innerHTML]="faqContent"></div>
    </div>
    <div right>
      <div *ngIf="relatedFaqs != null"
           class="v2-border-1px border-green-grey-4 v2-radius-8px v2-padding-2">
        <h3 class="color-green-grey-1 v2-margin-16px-b">Related Topics</h3>
        <a *ngFor="let faq of relatedFaqs;"
           class="body-2 color-green-grey-3 v2-margin-8px-t display-block"
           [href]="faqLink(faq._id.toString())">
          {{$any(faq.article).title}}
        </a>
      </div>

      <app-webinar-card />
    </div>
  </v2-two-columns-page>
</app-page>
