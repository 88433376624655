import { A7ButtonModule } from '@ark7/button';
import { A7LayoutModule } from '@ark7/layout';
import { A7LoadingModule } from '@ark7/loading';
import { A7MapsModule } from '@ark7/maps';
import { A7MovingNumberModule } from '@ark7/moving-number';
import { A7PanelModule } from '@ark7/panel';
import { A7ResourcesCommonModule } from '@ark7/resources-common';
import { A7TooltipsModule } from '@ark7/tooltips';
import { A7UtilsModule } from '@ark7/utils';
import { CommonModule } from '@angular/common';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faFacebookMessenger,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import {
  A7DialogBaseActionsDirective,
  A7DialogBaseContentsDirective,
  DialogBaseComponent,
} from './dialog-base/dialog-base.component';
import { A7SpjTemplateDirective } from './a7-switch-panel-junior/a7-spj-template.directive';
import { A7SwitchPanelJuniorComponent } from './a7-switch-panel-junior/a7-switch-panel-junior.component';
import { AbBlockComponent } from './ab-block/ab-block.component';
import { AbcdBlockComponent } from './abcd-block/abcd-block.component';
import { CardComponent } from './card/card.component';
import { CarouselComponent } from './carousel/carousel.component';
import {
  CarouselItemDirective,
  CarouselItemElement,
} from './carousel/carousel-item.directive';
import { CarrierAboutComponent } from './carrier-about/carrier-about.component';
import { CarrierAbstractComponent } from './carrier-abstract/carrier-abstract.component';
import { CarrierBaseComponent } from './carrier-base/carrier-base.component';
import { CarrierCaptionsComponent } from './carrier-captions/carrier-captions.component';
import { CarrierDistributionComponent } from './carrier-distribution/carrier-distribution.component';
import { CarrierHeaderComponent } from './carrier-header/carrier-header.component';
import { CarrierMarketComponent } from './carrier-market/carrier-market.component';
import { CarrierTileComponent } from './carrier-tile/carrier-tile.component';
import { CarrierTileSmallComponent } from './carrier-tile-small/carrier-tile-small.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { CloseButtonComponent } from './close-button/close-button.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DetailsBackgroundComponent } from './details-background/details-background.component';
import { FootDropComponent } from './footer/foot-drop/foot-drop.component';
import { FooterComponent } from './footer/footer.component';
import { GaugeComponent } from './gauge/gauge.component';
import { HeaderComponent } from './header/header.component';
import { HoverClassDirective } from './hover-class.directive';
import { InplaceDropdownSelectComponent } from './inplace-dropdown-select/inplace-dropdown-select.component';
import { InvestmentLocationPoisComponent } from './investment-location-pois/investment-location-pois.component';
import { InvestmentLocationSchoolsComponent } from './investment-location-schools/investment-location-schools.component';
import { LogoComponent } from './logo/logo.component';
import { MatMenuComponent } from './mat-menu/mat-menu.component';
import { MatMenuTemplateDirective } from './mat-menu/mat-menu-template.directive';
import { MoneyInputComponent } from './money-input/money-input.component';
import { NumInputWithButtonComponent } from './num-input-with-button/num-input-with-button.component';
import { OfferingLogoComponent } from './offering-logo/offering-logo.component';
import { OnOffComponent } from './on-off/on-off.component';
import { PageIndexComponent } from './page-index/page-index.component';
import { PauseButtonComponent } from './pause-button/pause-button.component';
import { PresentingGlassComponent } from './presenting-glass/presenting-glass.component';
import { PropertySectionComponent } from './property-section/property-section.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { ReturnSimulatorComponent } from './return-simulator/return-simulator.component';
import { RoundSliderComponent } from './round-slider/round-slider.component';
import { ShareFooterComponent } from './share-footer/share-footer.component';
import { SliderComponent } from './slider/slider.component';
import { SocialShareComponent } from './social-share/social-share.component';
import { TwoColumnsPageComponent } from './two-columns-page/two-columns-page.component';
import { V2DialogBarComponent } from './v2-dialog-bar/v2-dialog-bar.component';
import { VisualSlideComponent } from './visual-slide/visual-slide.component';

@NgModule({
  declarations: [
    A7DialogBaseActionsDirective,
    A7DialogBaseContentsDirective,
    A7SpjTemplateDirective,
    A7SwitchPanelJuniorComponent,
    AbBlockComponent,
    AbcdBlockComponent,
    CardComponent,
    CarouselComponent,
    CarouselItemDirective,
    CarouselItemElement,
    CarrierAboutComponent,
    CarrierAbstractComponent,
    CarrierBaseComponent,
    CarrierCaptionsComponent,
    CarrierDistributionComponent,
    CarrierHeaderComponent,
    CarrierMarketComponent,
    CarrierTileComponent,
    CarrierTileSmallComponent,
    ChatbotComponent,
    CloseButtonComponent,
    DatePickerComponent,
    DetailsBackgroundComponent,
    DialogBaseComponent,
    FootDropComponent,
    FooterComponent,
    GaugeComponent,
    HeaderComponent,
    HoverClassDirective,
    InplaceDropdownSelectComponent,
    InvestmentLocationPoisComponent,
    InvestmentLocationSchoolsComponent,
    LogoComponent,
    MatMenuComponent,
    MatMenuTemplateDirective,
    MoneyInputComponent,
    NumInputWithButtonComponent,
    OfferingLogoComponent,
    OnOffComponent,
    PageIndexComponent,
    PauseButtonComponent,
    PresentingGlassComponent,
    PropertySectionComponent,
    ReturnSimulatorComponent,
    RoundSliderComponent,
    ShareFooterComponent,
    SliderComponent,
    SocialShareComponent,
    TwoColumnsPageComponent,
    V2DialogBarComponent,
    VisualSlideComponent,
    RadioGroupComponent,
  ],
  imports: [
    A7ButtonModule,
    A7LayoutModule,
    A7LoadingModule,
    A7MapsModule,
    A7MovingNumberModule,
    A7PanelModule,
    A7ResourcesCommonModule,
    A7TooltipsModule,
    A7UtilsModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    GoogleMapsModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    ReactiveFormsModule,
    RouterModule,
    ShareButtonsModule,
  ],
  exports: [
    A7DialogBaseActionsDirective,
    A7DialogBaseContentsDirective,
    A7SpjTemplateDirective,
    A7SwitchPanelJuniorComponent,
    AbBlockComponent,
    AbcdBlockComponent,
    CardComponent,
    CarouselComponent,
    CarouselItemDirective,
    CarouselItemElement,
    CarrierAboutComponent,
    CarrierAbstractComponent,
    CarrierBaseComponent,
    CarrierCaptionsComponent,
    CarrierDistributionComponent,
    CarrierHeaderComponent,
    CarrierMarketComponent,
    CarrierTileComponent,
    CarrierTileSmallComponent,
    ChatbotComponent,
    CloseButtonComponent,
    DatePickerComponent,
    DetailsBackgroundComponent,
    DialogBaseComponent,
    FootDropComponent,
    FooterComponent,
    HeaderComponent,
    HoverClassDirective,
    InplaceDropdownSelectComponent,
    InvestmentLocationPoisComponent,
    InvestmentLocationSchoolsComponent,
    LogoComponent,
    MatMenuComponent,
    MatMenuTemplateDirective,
    NumInputWithButtonComponent,
    OfferingLogoComponent,
    OnOffComponent,
    PageIndexComponent,
    PauseButtonComponent,
    PresentingGlassComponent,
    PropertySectionComponent,
    RadioGroupComponent,
    ReturnSimulatorComponent,
    RoundSliderComponent,
    SliderComponent,
    ShareFooterComponent,
    SocialShareComponent,
    TwoColumnsPageComponent,
    V2DialogBarComponent,
    VisualSlideComponent,
  ],
})
export class A7SharedComponentsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faLink,
      faQuestionCircle,
      faEnvelope,
      faWhatsapp,
      faFacebookF,
      faFacebookMessenger,
      faTwitter,
    );
  }
}
