<app-page>
  <div class="v2-max-w-120 margin-auto v2-min-h-50 body-2 color-green-grey-1 v2-padding-5-b"
       [ngClass]="sm ? 'v2-padding-2-h' : 'v2-padding-5-h'">
    <div class="h1 text-center">Dalmore Reg A Website Disclosure</div>

    <br />
    <br />

    <p>THESE OFFERING MATERIALS MAY CONTAIN FORWARD-LOOKING STATEMENTS AND INFORMATION RELATING TO, AMONG OTHERTHINGS,
      THE
      COMPANY, ITS BUSINESS PLAN AND STRATEGY, AND ITS INDUSTRY. THESE FORWARD-LOOKING STATEMENTS AREBASED ON THE
      BELIEFS
      OF, ASSUMPTIONS MADE BY, AND INFORMATION CURRENTLY AVAILABLE TO THE COMPANY’SMANAGEMENT. WHEN USED IN THE OFFERING
      MATERIALS, THE WORDS “ESTIMATE,” “PROJECT,” “BELIEVE,” “ANTICIPATE,”“INTEND,” “EXPECT” AND SIMILAR EXPRESSIONS ARE
      INTENDED TO IDENTIFY FORWARD-LOOKING STATEMENTS. THESESTATEMENTS REFLECT MANAGEMENT’S CURRENT VIEWS WITH RESPECT
      TO
      FUTURE EVENTS AND ARE SUBJECT TO RISKS ANDUNCERTAINTIES THAT COULD CAUSE THE COMPANY’S ACTUAL RESULTS TO DIFFER
      MATERIALLY FROM THOSE CONTAINED IN THEFORWARD-LOOKING STATEMENTS. INVESTORS ARE CAUTIONED NOT TO PLACE UNDUE
      RELIANCE ON THESE FORWARD-LOOKINGSTATEMENTS, WHICH SPEAK ONLY AS OF THE DATE ON WHICH THEY ARE MADE. THE COMPANY
      DOES NOT UNDERTAKE ANYOBLIGATION TO REVISE OR UPDATE THESE FORWARD-LOOKING STATEMENTS TO REFLECT EVENTS OR
      CIRCUMSTANCES AFTERSUCH DATE OR TO REFLECT THE OCCURRENCE OF UNANTICIPATED EVENTS.</p>

    <p>PLEASE NOTE INVESTORS IN THIS
      OFFERING WILL BE CLIENTS OF THE ISSUER AND NOT DALMORE. DALMORE’S ROLE IN THETRANSACTION IS TO FACILITATE BACK
      OFFICE AND REGULATORY FUNCTIONS RELATED TO THE REGULATION A TRANSACTION.DALMORE IS NOT PROVIDING INVESTMENT ADVICE
      OR RECOMMENDATIONS, OR LEGAL OR TAX ADVICE.</p>

    <p>
      NO MONEY OR OTHER CONSIDERATION IS BEING SOLICITED, AND IF SENT IN
      RESPONSE, WILL NOT BE ACCEPTED. NO OFFER TOBUY THE SECURITIES CAN BE ACCEPTED AND NO PART OF THE PURCHASE PRICE
      CAN BE RECEIVED UNTIL THE OFFERINGSTATEMENT FILED BY THE COMPANY WITH THE SEC HAS BEEN QUALIFIED BY THE SEC. ANY
      SUCH OFFER MAY BE WITHDRAWN ORREVOKED, WITHOUT OBLIGATION OR COMMITMENT OF ANY KIND, AT ANY TIME BEFORE NOTICE OF
      ACCEPTANCE GIVEN AFTERTHE DATE OF QUALIFICATION. AN INDICATION OF INTEREST INVOLVES NO OBLIGATION OR COMMITMENT OF
      ANY KIND.</p>

    <p>
      NO MONEY OR OTHER CONSIDERATION IS BEING SOLICITED, AND IF SENT IN RESPONSE, WILL NOT BE ACCEPTED. NO OFFER
      TOBUY THE SECURITIES CAN BE ACCEPTED AND NO PART OF THE PURCHASE PRICE CAN BE RECEIVED UNTIL THE OFFERINGSTATEMENT
      FILED BY THE COMPANY WITH THE SEC HAS BEEN QUALIFIED BY THE SEC. ANY SUCH OFFER MAY BE WITHDRAWN ORREVOKED,
      WITHOUT
      OBLIGATION OR COMMITMENT OF ANY KIND, AT ANY TIME BEFORE NOTICE OF ACCEPTANCE GIVEN AFTERTHE DATE OF
      QUALIFICATION.AN INDICATION OF INTEREST INVOLVES NO OBLIGATION OR COMMITMENT OF ANY KIND. ANOFFERING STATEMENT
      REGARDING THIS OFFERING HAS BEEN FILED WITH THE SEC. YOU MAY OBTAIN A COPY OF THEPRELIMINARY OFFERING CIRCULAR
      THAT IS PART OF THAT OFFERING STATEMENT AT: <a
         href="https://ark7-corp-allosaurus.us-west1.ark7.com/api/v2/conversions/signal?code=DCICE"
         target="_blank">LINK</a>.</p>

    <p>
      AN OFFERING STATEMENT REGARDING THIS OFFERING HAS BEEN FILED WITH THE
      SEC. THE SEC HAS QUALIFIED THAT OFFERINGSTATEMENT, WHICH ONLY MEANS THAT THE COMPANY MAY MAKE SALES OF THE
      SECURITIES DESCRIBED BY THE OFFERINGSTATEMENT. IT DOES NOT MEAN THAT THE SEC HAS APPROVED, PASSED UPON THE MERITS
      OR PASSED UPON THE ACCURACY ORCOMPLETENESS OF THE INFORMATION IN THE OFFERING STATEMENT. THE OFFERING CIRCULAR
      THAT IS PART OF THAT OFFERINGSTATEMENT IS AT: <a
         href="https://ark7-corp-allosaurus.us-west1.ark7.com/api/v2/conversions/signal?code=DCICE"
         target="_blank">LINK</a>.</p>
  </div>
</app-page>
