<div class="padding-sm-h children-margin-sm-v">

  <h2 class="a7-ui-auth-form-title a7-ui-auth-form_section">
    TERMS AND CONDITIONS
  </h2>

  <section class="block margin-lg-t_xs margin-md-t a7-ui-auth-form_section">
    <ng-content select="[a7-accept-tos-form-tos]">
    </ng-content>
  </section>

  <div class="action-section a7-ui-auth-form_section">
    <button mat-flat-button
            color="primary"
            (click)="acceptTos()">
      Agree
    </button>

    <a class="a7-form-button"
       color="primary"
       [routerLink]="'/a/sign-out'">
      Logout
    </a>
  </div>
</div>
