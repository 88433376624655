import { Component, OnInit } from '@angular/core';
import { A7LayoutService, V2BaseComponent } from '@ark7/layout';

import { FeaturedUser } from './featured-user/featured-user.component';

@Component({
  selector: 'v2-featured-users',
  templateUrl: './featured-users.component.html',
  styleUrls: ['./featured-users.component.scss'],
})
export class FeaturedUsersComponent extends V2BaseComponent implements OnInit {
  constructor(layoutService: A7LayoutService) {
    super(layoutService);
  }

  fus: FeaturedUser[] = [
    {
      name: 'Cassie Han',
      profiles: [
        'Senior Software Engineer @Google',
        'New to Real Estate Investment',
        'Part-owner of 2 properties in 2 states',
      ],
      profileImgUrl: '/assets/v2-home-cassie.webp',
      comments: [
        {
          text:
            '“The Berkeley apartment has a <span class="h4 color-green-grey-1">very stable cash flow</span>, while the Austin SFH provides really <span class="h4 color-green-grey-1">good appreciation potential</span>. Ark7 makes real estate investing <span class="h4 color-green-grey-1">super convenient</span>.',
        },
        {
          text:
            'I would highly recommend Ark7 to anyone who’s interested in <span class="h4 color-green-grey-1">stable passive incomes</span>.”',
        },
      ],
    },
    {
      name: 'Emma Chen',
      profileImgUrl: '/assets/v2-home-emma.webp',
      profiles: [
        'Ex-tech, mother of two, small business owner',
        'Veteran real estate investor',
        'Part-owner of 4 properties in 3 states',
      ],
      comments: [
        {
          text:
            '“Really liked the concept of Ark7’s <span class="h4 color-green-grey-1">fractional real estate investment</span> and its <span class="h4 color-green-grey-1">intuitive mobile app</span>.',
        },
        {
          text:
            'I use it to <span class="h4 color-green-grey-1">diversify my current real estate portfolio without adding more to the workload</span>. Plan to hold shares long-term as I always do with my properties.',
        },
        { text: ' A great find!”' },
      ],
    },
    {
      name: 'Pat H.',
      profiles: [
        'Strategic Planner @ Federal law Enforcement Agency',
        'New to real estate investment',
        'Part-owner of 4 properties in 4 states',
      ],
      profileImgUrl: '/assets/v2-home-pat.webp',
      comments: [
        {
          text:
            '“<span class="h4 color-green-grey-1">Diversification</span> led me to Ark7.',
        },
        {
          text:
            'I want to add real estate to my portfolio but was always hesitant about the headache part.  Fractional shares in <span class="h4 color-green-grey-1">professionally managed properties</span> is a perfect answer to my dilemma. ',
        },
        {
          text: 'Thank you Ark7!”',
        },
      ],
    },
    {
      name: 'Andrew C.',
      profiles: [
        'Managing Partner @House of Chwalik',
        'Experienced real estate investor',
        'Part-owner of 4 properties in 4 states',
      ],
      profileImgUrl: '/assets/v2-home-andrew.webp',
      comments: [
        {
          text:
            '“I have been looking for a solution to <span class="h4 color-green-grey-1">long-distance real estate investments</span> for quite a while. Ark7 gives me the <span class="h4 color-green-grey-1">right level of control</span> needed managing properties remotely.  ',
        },
        {
          text:
            'It is exactly what I was looking for and I wouldn’t have it any other way!”',
        },
      ],
    },
  ];

  ngOnInit(): void {}
}
