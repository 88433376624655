import _ from 'underscore';
import { Injectable } from '@angular/core';

import { ClientResource } from '../resources/client-resource';

// According to https://angular.io/guide/singleton-services, providedIn root
// tells angular to initialize a singleton service.  In such way, no need to
// register it under module providers.
@Injectable({
  providedIn: 'root',
})
export class Logger {
  constructor(private clientResource: ClientResource) {}

  async log(
    level: 'debug' | 'info' | 'warn' | 'error',
    message: string,
    payload?: any,
  ) {
    const payloadStr = JSON.stringify(standardErrorConverter(payload));
    return await this.clientResource.emitClientLog({
      level,
      message,
      payload: payloadStr,
    });
  }

  async debug(message: string, payload?: any) {
    return this.log('debug', message, payload);
  }

  async info(message: string, payload?: any) {
    return this.log('info', message, payload);
  }

  async warn(message: string, payload?: any) {
    return this.log('warn', message, payload);
  }

  async error(message: string, payload?: any) {
    return this.log('error', message, payload);
  }
}

export function standardErrorConverter(err: any): ErrorObject {
  if (err instanceof Error) {
    return {
      type: 'Error',
      name: err.constructor.name,
      message: err.message,
      stack: err.stack,
    };
  }

  return {
    type: 'unknown',
    raw: JSON.stringify(err),
  };
}

export interface ErrorObject {
  type: string;
  name?: string;
  message?: string;
  stack?: string;
  raw?: string;
}
