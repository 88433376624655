import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'v2-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent extends V2BaseComponent {
  tiles = [
    [
      '/assets/v2-tile1.svg',
      'Community',
      'We create Ark7 for people like us, and we lean on our investors community to always push modern fractional real estate investing to the next level by asking for a higher standard, harvesting insights from, and building a mutually beneficial business model.',
    ],
    [
      '/assets/v2-tile2.svg',
      'Efficiency',
      'We believe in the marriage of Artificial Intelligence and human calibalization. The “heaviness” of real estate investment decision making and operation can be modernized by technology and data insights. We take responsibility for getting better every day.',
    ],
    [
      '/assets/v2-tile3.svg',
      'Transparency',
      'The history of a black box is gone. Confident financial decisions stem from clarity and information democracy. We aim to share all product details with clarity and take all feedback with courage.',
    ],
  ];

  constructor(l: A7LayoutService) {
    super(l);
  }
}
