import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd } from '@angular/router';
import { A7Router, Debug } from '@ark7/utils';
import debug from 'debug';
import { filter } from 'rxjs/operators';
import _ from 'underscore';

import {
  EventParams,
  GtagConfig,
  GtagEventName,
  GTAG_CONFIG,
} from '../declarations';

declare let gtag: Function;

const d = debug('a7-layout:GtagService');

@Injectable({
  providedIn: 'root',
})
export class GtagService {
  initialized: boolean = false;

  userId: string;
  pendingPageViewEvent: any;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(GTAG_CONFIG) private config: GtagConfig,
    router: A7Router,
    title: Title,
  ) {
    d('Initializing Gtag service : %O', this.config);
    if (isPlatformBrowser(platformId) && this.config) {
      if (typeof gtag !== 'undefined') {
        gtag('set', {
          custom_map: _.extend({}, this.config.dimensions, this.config.metrics),
        });

        gtag('config', this.config.trackingId, {
          send_page_view: false,
        });

        if (this.config.ga4TrackingId) {
          gtag('config', this.config.ga4TrackingId);
        }

        if (this.config.adsTrackingId) {
          gtag('config', this.config.adsTrackingId);
        }

        this.initialized = true;
      }

      router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.pageView({
            page_path: event.urlAfterRedirects,
            page_title: title.getTitle(),
            page_location: window?.location.href,
          });
        });
    }
  }

  @Debug({ d })
  private pageView(event: any) {
    if (this.initialized) {
      if (this.config.requireUserId && this.userId == null) {
        this.pendingPageViewEvent = event;
        return false;
      } else {
        gtag('config', this.config.trackingId, event);
        return true;
      }
    }
  }

  @Debug({ d })
  sendEvent(eventName: GtagEventName | string, eventParams: EventParams) {
    if (this.initialized) {
      gtag('event', eventName, eventParams);
    }
  }

  @Debug({ d })
  setUserId(userId: string) {
    this.userId = userId;
    if (this.initialized) {
      gtag('set', { user_id: this.userId });
      if (this.pendingPageViewEvent != null) {
        this.pageView(this.pendingPageViewEvent);
        this.pendingPageViewEvent = null;
      }
    }
  }

  @Debug({ d })
  setPersistentValues(values: object) {
    if (this.initialized) {
      gtag('set', values);
    }
  }

  @Debug({ d })
  exception(description: string) {
    if (this.initialized) {
      gtag('event', 'exception', {
        description,
        fatal: false, // set to true if the error is fatal
      });
    }
  }

  public get isAdsTrackingEnabled(): boolean {
    return this.config.adsTrackingId != null;
  }

  public get adsConversionInvestment(): string {
    return this.config.adsConversionInvestment;
  }

  public get adsConversionSignUp(): string {
    return this.config.adsConversionSignUp;
  }
}
