import { AbstractControl, ValidatorFn } from '@angular/forms';

export function a7EmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    // email regex taken from http://emailregex.com/
    // const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // make sure use the same email validation as model: model-mongoose/src/schemas/email.ts
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/;
    const valid = emailRegex.test(value);
    return valid ? null : { invalidEmail: { value: control.value } };
  };
}
