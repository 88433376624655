import _ from 'underscore';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  animate,
  style,
} from '@angular/animations';

import {
  CarouselItemDirective,
  CarouselItemElement,
} from './carousel-item.directive';

@Component({
  selector: 'v2-carousel',

  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  @Input() timing = '250ms ease-in';
  @Input() controls: 'square' | 'circle' | 'none' = 'circle';
  @Input() focus = true;
  @Input() defaultPos: 'begin' | 'center' = 'center';
  @Input() allowCircleBack = true;

  carouselWrapperStyle = {};

  @ContentChildren(CarouselItemDirective)
  items: QueryList<CarouselItemDirective>;

  @ViewChildren(CarouselItemElement, { read: ElementRef })
  private itemsElements: QueryList<ElementRef>;

  @ViewChild('carousel')
  private carousel: ElementRef;

  private player: AnimationPlayer;
  private itemWidth: number;

  private _currentSlide = 0;

  get currentSlide() {
    return this._currentSlide;
  }

  set currentSlide(s) {
    this._currentSlide = s;
    this.animate();
  }

  buttonTransY = 200;

  @HostListener('window:resize', ['$event'])
  adjust() {
    if (this.itemsElements?.first && typeof window !== 'undefined') {
      const bound = this.itemsElements?.first?.nativeElement?.getBoundingClientRect();
      this.itemWidth = bound.width;

      if (this.itemWidth === 0) {
        setTimeout(() => this.adjust(), 200);
        return;
      }
      this.buttonTransY = bound.height / 2 + 24;

      this.carouselWrapperStyle = {
        width: `${this.itemWidth}px`,
      };

      this.gotoDefaultPos();
    }
  }

  constructor(private builder: AnimationBuilder) {}

  ngAfterViewInit() {
    _.defer(() => this.adjust());
  }

  gotoDefaultPos() {
    this.currentSlide =
      this.defaultPos === 'center'
        ? Math.floor(Math.max(0, this.items.length - 1) / 2)
        : 0;
    this.animate();
  }

  private animate() {
    if (!this.carousel) {
      return;
    }
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.builder.build([
      animate(this.timing, style({ transform: `translateX(-${offset}px)` })),
    ]);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }
}
