import _ from 'underscore';
import { Component, Input } from '@angular/core';
import { SubscribeComponent } from '@ark7/utils';

import { A7LayoutService, PageSize } from './services/a7-layout.service';

@Component({
  selector: 'layout-subscribe-component',
  template: '',
})
export class LayoutSubscribeComponent extends SubscribeComponent {
  _pageSize: PageSize;
  _pretend: PageSize;

  get pageSize() {
    return this._pretend ?? this._pageSize;
  }

  @Input()
  set pretend(s: PageSize) {
    this._pretend = s;
  }

  constructor(layout: A7LayoutService) {
    super();
    this.safeSubscribe(
      layout.pageSize,
      (pageSize) => (this._pageSize = pageSize),
    );
  }
}
