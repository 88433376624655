<mat-tab-group [selectedIndex]="stage"
               class="a7-ui-auth-form_tab-group ">
  <!-- selectedIndex 0: Forgot password form -->
  <mat-tab>
    <a7-auth-form-tab [formGroup]="form">
      <div class="center-inner">
        <div class="color-green-grey-1 h1 nowrap">
          Reset Your Password
        </div>
      </div>
      <div class="body-2 color-green-grey-3 v2-margin-40px-t">
        Please enter your email address and we'll send you instructions to reset
        your password.
      </div>
      <mat-form-field class="full-width a7-form-field v2-margin-40px-t"
                      [hideRequiredMarker]="true">
        <div matPrefix
             class="v2-h-5 v2-w-4 center-inner">
          <img src="/assets/v2-email.svg"
               alt="email" />
        </div>
        <input matInput
               formControlName="email"
               name="email"
               type="email"
               autocomplete="email"
               autocorrect="off"
               autocapitalize="none"
               placeholder="Email" />
        <!-- <mat-placeholder class="color-green-grey-3 body-1">Email</mat-placeholder> -->

        <mat-error *ngIf="
          form.controls['email'].hasError('email') ||
          form.controls['email'].hasError('required')
        ">
          Please enter your email.
        </mat-error>
      </mat-form-field>

      <button a7-auth-form-actions
              mat-flat-button
              color="primary"
              [disabled]="!form.valid||sending"
              (click)="sendResetPasswordEmail($event)"
              class="h4 v2-margin-40px-t">
        Send Instructions
      </button>
    </a7-auth-form-tab>
  </mat-tab>

  <!-- selectedIndex 1: Instruction sent -->
  <mat-tab>
    <a7-auth-form-tab>
      <div class="center-inner">
        <div (click)="stage = 0"
             class="v2-margin-1-r pointer">
          <img src="/assets/v2-back-grey.svg"
               alt="back" />
        </div>
        <div class="color-green-grey-1 h1 nowrap text-center">
          Instruction Email Sent
        </div>
        <div></div>
      </div>

      <div class="body-2 color-green-grey-3 v2-margin-40px-t">
        An email has been sent to your email address:
        <span class="text-primary">{{email}}</span>.
        Please check the email for instructions to reset your password.
      </div>
    </a7-auth-form-tab>
  </mat-tab>

  <!-- selectedIndex 2: internal server error -->
  <mat-tab>
    <a7-auth-form-tab>
      <div class="center-inner">
        <div class="color-green-grey-1 h1">
          Oops, something wrong on our side
        </div>
      </div>
      <div class="body-2 color-green-grey-3 v2-margin-40px-t">
        We are facing an unexpected error, please try again later!
      </div>
      <div class="center-inner v2-margin-40px-t">
        <img src="/assets/v2-fail.svg"
             alt="back" />
      </div>
      <button a7-auth-form-actions
              mat-flat-button
              class="h5 v2-margin-40px-t border border-blue-grey-4 text-green-grey-1"
              (click)="stage = 0">
        Go back
      </button>
    </a7-auth-form-tab>
  </mat-tab>
</mat-tab-group>
