<div class="a7-debug-panel-container"
     cdkDrag>
  <div class="flex-row-between-start">
    <h3>Debug Panel</h3>
    <span class="actionable"
          a7DebugClose>x</span>
  </div>

  <div *ngFor="let item of debugService.stack; let i = index">
    <div class="flex-row-between actionable a7-debug-item-title"
         [class.on-hover]="item.hovering && debugService.snapMode"
         (click)="item.toggle()"
         (mouseover)="item.flash()">
      <span>{{i}}. {{item.name}} ({{item.keys.length}} keys)</span>
      <span *ngIf="!item.expanded">&#x2193;</span>
      <span *ngIf="item.expanded">&#x2191;</span>
    </div>

    <div *ngIf="item.expanded">
      <div *ngFor="let key of item.keys">
        <div class="flex-row-between">
          <span class="a7-debug-item-key">{{key}}</span>
          <span>
            <span (click)="item.logInConsole(key)"
                  class="actionable">Log in Console</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
