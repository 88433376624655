import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';

import { CampaignTooltipDirective } from './v2/c-tip.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { TooltipHostComponent } from './tooltip-host/tooltip-host.component';

@NgModule({
  declarations: [
    TooltipHostComponent,
    TooltipDirective,
    CampaignTooltipDirective,
  ],
  imports: [CommonModule, FontAwesomeModule],
  exports: [TooltipHostComponent, TooltipDirective, CampaignTooltipDirective],
})
export class A7TooltipsModule {}
