import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const FADE_IN = trigger('fadeIn', [
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  state(
    '*',
    style({
      opacity: 1,
    }),
  ),
  transition(':enter', [animate('200ms 0ms ease-in-out')]),
  transition(':leave', [animate('200ms 0ms ease-in-out')]),
]);

export const FADE_IN_HIDE = trigger('fadeInHide', [
  state(
    'void',
    style({
      display: 'none',
      opacity: 0,
    }),
  ),
  state(
    '*',
    style({
      opacity: 1,
    }),
  ),
  transition('void <=> in', [animate('200ms 0ms ease-in')]),
]);
