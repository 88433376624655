import {
  A7ResourceCRUD,
  A7ResourceParams,
  ResourceHandler,
} from '@ark7/resource2';
import { Document } from '@ark7/common-models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/user/documents',
  withCredentials: true,
  model: Document,
  linkModel: true,
})
export class DocumentResource extends A7ResourceCRUD<
  Document,
  {
    account?: string;
    type?: string;
  }
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }
}
