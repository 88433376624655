import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-webinar-card',
  templateUrl: './webinar-card.component.html',
  styleUrls: ['./webinar-card.component.scss'],
})
export class WebinarCardComponent {
  @Input()
  size: 'small' | 'large' = 'small';
}
