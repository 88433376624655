import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IpInfoService {
  private readonly apiURL = 'https://ipinfo.io/json';

  constructor(private http: HttpClient) {}

  getIpInfo(): Observable<IpInfo | null> {
    return this.http.get<IpInfo>(this.apiURL).pipe(
      catchError((error) => {
        console.error('An error occurred:', error);
        return of(null, asyncScheduler);
      }),
    );
  }

  isSensitiveCountry(
    sensitiveCountryCodes = ['ZA', 'NG', 'UG'],

    trustTimezonePrefix = 'America/',
  ): Observable<boolean> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // If the time zone starts with 'America/', consider it to be in the US or Canada
    if (timeZone.startsWith(trustTimezonePrefix)) {
      return of(false, asyncScheduler);
    }

    // check if indeed sensitive countries
    return this.getIpInfo().pipe(
      map((ipInfo: IpInfo | null) => {
        // suspect active scam on going
        if (sensitiveCountryCodes.includes(ipInfo?.country)) {
          return true;
        }
        return false;
      }),
    );
  }
}

export interface IpInfo {
  ip: string;
  hostname: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  org: string;
  postal: string;
  timezone: string;
}
