<ng-container *ngIf="mode == 'mini'; else normalView">
  <div class="mini flex-row-start-center v2-col-gap-16px">
    <span class="label-2 color-green-grey-3 prefix-label">&middot;&nbsp;&nbsp;Sign in with:</span>
    <fa-icon class="logo actionable google"
             (click)="signInGoogle()"
             [icon]="['fab', 'google']"></fa-icon>
    <fa-icon class="logo actionable apple"
             (click)="signInApple()"
             [icon]="['fab', 'apple']"></fa-icon>
    <fa-icon class="logo actionable facebook"
             (click)="signInFacebook()"
             [icon]="['fab', 'facebook-square']"></fa-icon>
  </div>
</ng-container>

<ng-template #normalView>
  <div class="social-separator"></div>

  <div class="flex-column-center-center flex-gap-12">
    <button class="sign-in-button"
            (click)="signInGoogle()">
      <div class="flex-row-center-center full-width">
        <img src="/assets/g-logo.webp"
             alt="google"
             class="logo">
        <div [ngClass]="['word', wordClasses]">{{action}} with Google</div>
      </div>
    </button>
    <button class="sign-in-button sign-in-button-apple"
            (click)="signInApple()">
      <div class="flex-row-center-center full-width">
        <fa-icon class="logo"
                 [icon]="['fab', 'apple']"></fa-icon>
        <div [ngClass]="['word', wordClasses]">{{action}} with Apple</div>
      </div>
    </button>
    <button class="sign-in-button sign-in-button-facebook"
            (click)="signInFacebook()">
      <div class="flex-row-center-center full-width">
        <fa-icon class="logo"
                 [icon]="['fab', 'facebook-square']"></fa-icon>
        <div [ngClass]="['word', wordClasses]">{{action}} with Facebook</div>
      </div>
    </button>
  </div>
</ng-template>
