/**
 * This is a component that provides a simple abstraction:
 * Giving an area of specified width:
 * - fit text in this area as one line.
 * - smoothly animate & transit if text changes.
 * - TODO(rui): css transition may show inconsistent state when *dev console* is Open.
 */

import {
  animate,
  sequence,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input } from '@angular/core';

const IN = 'in';
const OUT = 'out';
const TRANS_TIME = 150;
const FADE = trigger('fadeTrigger', [
  // defines the states that the text can be in.
  state(
    IN,
    style({
      opacity: 1,
      'font-size': '{{pixels}}px',
    }),
    { params: { pixels: '10' } }, // default value that should not apply
  ),
  state(
    OUT,
    style({
      opacity: 0,
      'font-size': 0,
    }),
  ),
  transition(IN + ' => ' + OUT, [
    sequence([
      // fade first
      animate(
        TRANS_TIME,
        style({
          opacity: 0,
        }),
      ),
      // shrink text to prepare for resize
      style({
        'font-size': '0',
      }),
    ]),
  ]),
  transition(
    OUT + ' => ' + IN,
    [
      sequence([
        // immediately resize the text before fade in
        style({ 'font-size': '{{pixels}}px' }),
        // fade in
        animate(
          TRANS_TIME,
          style({
            opacity: 1,
          }),
        ),
      ]),
    ],
    { params: { pixels: '10' } }, // default value that should not apply
  ),
]);

@Component({
  selector: 'a7-fade-on-change-text',
  templateUrl: './fade-on-change-text.component.html',
  styleUrls: ['./fade-on-change-text.component.scss'],
  animations: [FADE],
})
export class FadeOnChangeTextComponent {
  public state: string = IN;
  public fontSize = 0;

  private showing: string = '';
  private lastValue = this.showing;
  private timeout: any;

  @Input() widthPx: number;

  @Input() maxFontSizePx?: number;

  @Input() set text(value: string) {
    // debounce
    if (value === this.lastValue) {
      return;
    }
    this.lastValue = value;

    // Stop pending fade in if any.
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    // start to fade out
    this.state = OUT;

    // delayed fade in
    this.timeout = setTimeout(() => {
      this.fontSize =
        value.length === 0
          ? 5
          : Math.ceil(
              (this.widthPx / value.length) *
                1.7 /* magical number to make the font big but still fit in one line*/,
            );
      if (this.maxFontSizePx > 0) {
        this.fontSize = Math.min(this.fontSize, this.maxFontSizePx);
      }
      this.showing = value;
      this.state = IN;
      this.timeout = null;
    }, TRANS_TIME + 100 /* buffer time to allow param -> CSS sync */);
  }

  get showingTxt(): string {
    return this.showing;
  }
}
