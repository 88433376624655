import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'a7-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss'],
})
export class CodeInputComponent implements OnInit {
  @Input() digitCount = 4; // default to 4, can be overridden
  public form: FormGroup;

  @Output() codeEntered = new EventEmitter<string>();
  code = '';

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    let group = {};
    for (let i = 1; i <= this.digitCount; i++) {
      group[`c${i}`] = ['', [Validators.required, this.singleDigitValidator]];
    }
    this.form = this.fb.group(group);
  }

  singleDigitValidator(control: FormControl) {
    const value = control.value;
    const isValid = /^\d$/.test(value);
    return isValid ? null : { notSingleDigit: true };
  }

  onVerifycodeEntered(value: string[]) {
    if (value?.length === this.digitCount) {
      let patchValue = {};
      for (let i = 0; i < this.digitCount; i++) {
        patchValue[`c${i + 1}`] = value[i];
      }
      this.form.patchValue(patchValue);
      this.code = value.join('');
      this.codeEntered.emit(this.code);
    }
  }
}
