import moment from 'moment';

export function urlToLocation(url: string) {
  const u = new URL(url, location.href);

  const searchParams = {};
  new URLSearchParams(u.search).forEach(
    (value: string, key: string) => (searchParams[key] = value),
  );

  return {
    path: u.pathname,
    query: u.search?.startsWith('?') ? u.search.substring(1) : u.search,
    queryMap: searchParams,
    hash: u.hash,
  };
}

export function utcDate(dateString: string): Date {
  return moment.utc(dateString).toDate();
}
