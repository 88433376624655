import { A7Resource2Module } from '@ark7/resource2';
import { A7UtilsModule } from '@ark7/utils';
import { CommonModule } from '@angular/common';
import {
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { RouterModule } from '@angular/router';

import { A7BaseAppComponent } from './a7-base-app-component';
import { A7ResourcesCommonErrorHandler } from './services/error.handler';
import {
  A7ResourcesCommonModuleConfig,
  A7_EXECUTE_SIGN_OUT_TIME,
  A7_RESOURCES_CONFIG,
  A7_RESOURCES_USER,
  A7_SIGN_OUT_DIALOG_TIME,
} from './declarations';
import { AccountRoutingService } from './services/account-routing.service';
import { AdminPreview } from './directives/admin-preview.directive';
import { AsCarrierRefPipe } from './pipes/as-carrier-ref.pipe';
import { AsCarrierStatsPipe } from './pipes/as-carrier-stats.pipe';
import { AsRenterRefPipe } from './pipes/as-renter-ref.pipe';
import { AsUserRefPipe } from './pipes/as-user-ref.pipe';
import { AutoSignOutDialogComponent } from './dialogs/auto-sign-out-dialog/auto-sign-out-dialog.component';
import { BasicUserResource } from './resources/user-resource';
import { DurationPipe } from './pipes/duration.pipe';
import { FadeOnChangeTextComponent } from './components/fade-on-change-text/fade-on-change-text.component';
import { GlobalNotificationComponent } from './components/global-notification/global-notification.component';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { Logger } from './services/logger.service';
import { MomentPipe } from './pipes/moment.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { PrivilegeBadgeComponent } from './components/privilege-badge/privilege-badge.component';
import { RequestInterceptor } from './auth/request.interceptor';
import { SafePipe } from './pipes/safe-pipe.pipe';
import { TrackingService } from './services/tracking.service';
import { UserService } from './services/user.service';
import { UserSubscribeComponent } from './components/user.subscriber.component';
import { VideoPlayComponent } from './components/video-play/video-play.component';
import { YoutubeApiLoaderService } from './services/youtube-api-loader.service';

@NgModule({
  declarations: [
    A7BaseAppComponent,
    AdminPreview,
    AsCarrierRefPipe,
    AsCarrierStatsPipe,
    AsRenterRefPipe,
    AsUserRefPipe,
    AutoSignOutDialogComponent,
    DurationPipe,
    FadeOnChangeTextComponent,
    MomentPipe,
    MoneyPipe,
    OrdinalPipe,
    PrivilegeBadgeComponent,
    SafePipe,
    SafePipe,
    UserSubscribeComponent,
    VideoPlayComponent,
    GlobalNotificationComponent,
  ],
  imports: [
    MatSnackBarModule,
    A7Resource2Module,
    A7UtilsModule,
    NgxWebstorageModule,
    MatButtonModule,
    MatDialogModule,
    RouterModule,
    FontAwesomeModule,
    CommonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: A7_EXECUTE_SIGN_OUT_TIME,
      useValue: 1 * 60000,
    },
    {
      provide: A7_SIGN_OUT_DIALOG_TIME,
      useValue: 15 * 60000,
    },
    MoneyPipe,
    AsCarrierRefPipe,
    AsCarrierStatsPipe,
    AsRenterRefPipe,
    AsUserRefPipe,
  ],
  exports: [
    AdminPreview,
    AsCarrierRefPipe,
    AsCarrierStatsPipe,
    AsRenterRefPipe,
    AsUserRefPipe,
    AutoSignOutDialogComponent,
    DurationPipe,
    FadeOnChangeTextComponent,
    MomentPipe,
    MoneyPipe,
    OrdinalPipe,
    PrivilegeBadgeComponent,
    SafePipe,
    VideoPlayComponent,
    GlobalNotificationComponent,
  ],
  // entryComponents: [AutoSignOutDialogComponent, VideoPlayComponent],
})
export class A7ResourcesCommonModule {
  constructor() {}

  static forRoot(
    config: A7ResourcesCommonModuleConfig,
  ): ModuleWithProviders<A7ResourcesCommonModule> {
    return {
      ngModule: A7ResourcesCommonModule,
      providers: [
        UserService,
        YoutubeApiLoaderService,
        TrackingService,
        AccountRoutingService,
        {
          provide: A7_RESOURCES_CONFIG,
          useValue: config,
        },
        {
          provide: A7_RESOURCES_USER,
          useClass: BasicUserResource,
        },
        {
          provide: ErrorHandler,
          useClass: A7ResourcesCommonErrorHandler,
          deps: [Logger, PLATFORM_ID],
        },
      ],
    };
  }
}
