import _ from 'underscore';
import { Injectable } from '@angular/core';
import {
  LayoutSectionDirective,
  PageSection,
} from '../directives/layout-section.directive';

@Injectable({
  providedIn: 'root',
})
export class LayoutSectionService {
  sections: LayoutSectionDirective[] = [];

  constructor() {}

  addSection(section: LayoutSectionDirective) {
    this.sections.push(section);
  }

  removeSection(section: LayoutSectionDirective) {
    const index = this.sections.indexOf(section);
    if (index >= 0) {
      this.sections.splice(index, 1);
    }
  }

  get pageSections(): PageSection[] {
    return _.map(this.sections, s => s.pageSection);
  }
}
