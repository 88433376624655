import {
  A7ResourceAction,
  A7ResourceCRUDObservable,
  A7ResourceParams,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';
import { Device } from '@ark7/core-business-models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  withCredentials: true,
  model: Device,
  pathPrefix: '/api/v2/user/devices',
})
export class DeviceResource extends A7ResourceCRUDObservable<
  Device,
  {
    _id?: string | string[];
    deviceId?: string | string[];
    user?: {
      _id?: string | string[];
    };
    status?: string | string[];
  }
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    model: Device,
    method: ResourceRequestMethod.Post,
    path: '/',
  })
  updateOrCreate: IResourceMethodPromise<any, void>;
}
