import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'v2-offering-logo',
  templateUrl: './offering-logo.component.html',
  styleUrls: ['./offering-logo.component.scss'],
})
export class OfferingLogoComponent implements OnInit {
  @Input()
  status = 'OFFERING';

  constructor() {}

  ngOnInit(): void {}
}
