<div class="popup v2-padding-24px-t">
  <span class="color-green-grey-4 v2-margin-1-r pointer absolute v2-right-1 close-icon z-index-highest"
        (click)="closeDialog()">&times;</span>

  <div class="background absolute v2-bottom-0"></div>
  <div
       class="z-index-higher relative v2-padding-2-h v2-padding-32px-t v2-padding-32px-b flex-column-between-start full-height border-box">
    <div>
      <img class="v2-obj-contain pointer smooth"
           style="height:30px"
           routerLink="/"
           src="/assets/v2-logo.svg"
           alt="Ark7" />

      <h4 class="color-green-grey-2 v2-margin-1-t">Get the app for a faster and smoother experience!</h4>
    </div>
    <img src="/assets/app-download-preview.webp"
         alt="Ark7 App"
         class='full-width display-block margin-auto v2-max-w-25 preview' />

    <a [href]="downloadUrl"
       class="display-block full-width">
      <div
           class="bg-white v2-padding-2-h v2-padding-1-v v2-h-6 v2-radius-16px flex-row-center-center v2-col-gap-20px pointer">
        <span class="bg-500 v2-radius-8px v2-h-40px v2-w-40px center-inner">
          <span class="icon-square-logo-weight-2 h4 white"></span>
        </span>

        <span class="h4 text-primary">
          Download Now</span>
      </div>
    </a>
  </div>
</div>
