import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  style,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { formatNumber, formatPercent } from '@ark7/common-models';
import { Carrier } from '@ark7/core-business-models';
import {
  A7LayoutService,
  A7PageSize,
  PageSize,
  V2BaseComponent,
} from '@ark7/layout';
import { $$ } from '@ark7/model';
import { A7Router } from '@ark7/utils';
import _ from 'underscore';

@Component({
  selector: 'v2-featured-properties',
  templateUrl: './featured-properties.component.html',
  styleUrls: ['./featured-properties.component.scss'],
})
export class FeaturedPropertiesComponent
  extends V2BaseComponent
  implements AfterViewInit, OnChanges {
  @A7PageSize() pageSize: PageSize;

  @Input() carriers: Carrier[];

  @ViewChild('container') container: ElementRef;
  translateX = 0;
  fps: FeaturedProperty[] = [];
  selected: FeaturedProperty;

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    this.select(0);
  }

  constructor(
    private builder: AnimationBuilder,
    l: A7LayoutService,
    private router: A7Router,
  ) {
    super(l);
    this.selected = this.fps[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.carriers && this.carriers?.length > 0) {
      this.fps = _.map(this.carriers, (c) => {
        return {
          carrierId: c._id.toString(),
          name: c.nickname,
          location: `${c.address.city} · ${c.address.state}`,
          type: c.info.displayType,
          price: c.sharePrice.amount,
          distribution: `${
            c.isRegDOffering || c.avgDividendRate > 0
              ? formatPercent(c.dividendRate)
              : ''
          }`,
          appreciation: `${formatPercent(c.appreciation)}`,
          totalInvestors: `${
            $$(c.stats).totalInvestors >= 30
              ? formatNumber($$(c.stats).totalInvestors)
              : ''
          }`,
          highlights: _.first(c.introduction?.whyWeInvested ?? [], 3),
          picUrl: c.imgUrl + '?d=960x604',
          requireAccredited: c.isRegDOffering,
          marketCap: c.marketCap.format({ precision: 0 }),
          monthlyRent: c.monthlyRent.format({ precision: 0 }),
        };
      });
      this.select(0);
    }
  }

  ngAfterViewInit(): void {
    this.select(0);
  }

  isSelected(p: FeaturedProperty) {
    return p === this.selected;
  }

  select(i: number) {
    this.selected = this.fps[i];
    if (this.width >= 1440) {
      return;
    }
    if (this.width >= 700 && this.sm) {
      return;
    }
    if (!this.sm) {
      this.translateX = Math.max(i - 1, 0) * -270;
    } else if (this.width < 700) {
      this.translateX = Math.max(i - 1, 0) * -132 + 156;
    }
    this.animate();
  }
  prev() {
    let target = this.fps.indexOf(this.selected);
    target = (target - 1 + this.fps.length) % this.fps.length;
    this.select(target);
  }

  next() {
    let target = this.fps.indexOf(this.selected);
    target = (target + 1) % this.fps.length;
    this.select(target);
  }

  jumpToSharePage() {
    this.router.navigate(['/share-investments'], {
      queryParams: { carrierId: this.selected.carrierId },
    });
  }

  private animate() {
    const myAnimation: AnimationFactory = this.builder.build([
      animate(
        '250ms ease-in-out',
        style({ transform: `translateX(${this.translateX}px)` }),
      ),
    ]);
    const player = myAnimation.create(this.container.nativeElement);
    player.play();
  }
}

interface FeaturedProperty {
  carrierId: string;
  name: string;
  location: string;
  type: string;
  price: number;
  distribution: string;
  appreciation: string;
  totalInvestors: string;
  highlights: string[];
  picUrl: string;
  requireAccredited: boolean;
  marketCap: string;
  monthlyRent: string;
}
