<a7-page-index [(index)]="currentSlide"
               [count]="items.length"
               [showBottomDots]="controls === 'circle'"
               [buttonShape]="controls | asAny"
               [buttonSize]="controls === 'square' ? 32 : 60"
               [buttonStyle]="
    controls === 'none'
      ? 'none'
      : controls === 'circle'
      ? 'between_card'
      : 'within_container'
  "
               [allowCircleBack]="allowCircleBack">
  <section [ngClass]="['carousel-wrapper']"
           [ngStyle]="carouselWrapperStyle">
    <ul #carousel
        class="carousel-inner">
      <li *ngFor="let item of items; let i = index"
          class="carousel-item">
        <div [ngClass]="[
            'smooth',
            focus && i !== currentSlide ? 'v2-opacity-30 v2-scale-90' : ''
          ]">
          <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
        </div>
      </li>
    </ul>
  </section>
</a7-page-index>