import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { LoadingProgressor } from '@ark7/loading';

@Component({
  selector: ' a7-button',
  templateUrl: './a7-button.component.html',
  styleUrls: ['./a7-button.component.scss'],
})
export class A7ButtonComponent implements OnInit {
  @Input()
  @HostBinding('class')
  widthClass = '';

  _size: A7ButtonSize = 'medium';
  @Input() set size(v: A7ButtonSize) {
    if (this._size === v) {
      return;
    }
    this._size = v;
    this.refreshClasses();
  }
  _theme: A7ButtonStyle = 'primary';
  @Input() set theme(v: A7ButtonStyle) {
    if (this._theme === v) {
      return;
    }
    this._theme = v;
    this.refreshClasses();
  }

  @Input() progressor: LoadingProgressor = new LoadingProgressor();

  get theme() {
    return this._theme;
  }

  // disabled state is stored in separated boolean.
  // you can hover/click on a disabled button, etc, without any effects.
  _interactiveState: Omit<A7ButtonState, 'disabled'> = 'standard';
  set state(v: A7ButtonState) {
    if (this._interactiveState === v) {
      return;
    }
    this._interactiveState = v;
    this.refreshClasses();
  }

  get state(): A7ButtonState {
    return this.disabled
      ? 'disabled'
      : (this._interactiveState as A7ButtonState);
  }

  _disabled = false;
  @Input()
  set disabled(v: boolean) {
    if (v === this._disabled) {
      return;
    }
    this._disabled = v;
    this.refreshClasses();
  }
  get disabled() {
    return this._disabled;
  }

  classes = [];

  RippleColor = RippleColor;
  ngOnInit(): void {
    this.refreshClasses();
  }

  refreshClasses() {
    const ret = ['inline-block', 'flex-row-center-center'].concat(
      this.widthClass ? ['full-width'] : [],
    );

    [
      Radius[this._size],
      Padding[this._size],
      FontSize[this._size],
      RowGap[this._size],
      Cursor[this.state],
      fontColor(this._theme, this.state),
      bgColor(this._theme, this.state),
      borderStyle(this._theme, this.state),
    ].forEach((t) => {
      ret.push(...(t?.split(' ') ?? []));
    });

    this.classes = ret;
  }
  /**
   * stop event propagation if disabled
   * @param e
   * @returns
   */
  onClick(e) {
    if (this.disabled || this.progressor.isLoading) {
      e.stopPropagation();
      return;
    }
  }
}
export type A7ButtonSize = 'mini' | 'small' | 'medium' | 'large' | 'xl';
export type A7ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'line'
  | 'elevated'
  | 'white'
  | 'black'
  | 'text';
export type A7ButtonState = 'standard' | 'hovered' | 'pressed' | 'disabled';

const Radius = {
  mini: 'v2-radius-8px',
  small: 'v2-radius-8px',
  medium: 'v2-radius-10px',
  large: 'v2-radius-12px',
  xl: 'v2-radius-16px',
};

const RowGap = {
  mini: 'v2-row-gap-4px',
  small: 'v2-row-gap-4px',
  medium: 'v2-row-gap-8px',
  large: 'v2-row-gap-8px',
  xl: 'v2-row-gap-8px',
};

const Padding = {
  mini: 'v2-padding-1-h v2-padding-8px-v',
  small: 'v2-padding-16px-h v2-padding-10px-v',
  medium: 'v2-padding-20px-h v2-padding-1-v',
  large: 'v2-padding-2-h v2-padding-16px-v',
  xl: 'v2-padding-32px-h v2-padding-20px-v',
};

const FontSize = {
  mini: 'footer-1',
  small: 'body-2',
  medium: 'h5',
  large: 'h4',
  xl: 'h3',
};

const Cursor = {
  standard: 'pointer',
  hovered: 'pointer',
  pressed: 'wait',
  disabled: 'disabled',
};

const RippleColor = {
  primary: '#FFFFFF80',
  secondary: '#FFFFFF80',
  line: '#FFFFFF80',
  elevated: '#FFFFFF80',
  white: '#FFFFFF80',
  black: '#FFFFFF80',
  text: '#FFFFFF80',
};

export const LogoSize = {
  mini: 'v2-h-16px',
  small: 'v2-h-20px',
  medium: 'v2-h-2',
  large: 'v2-h-28px',
  xl: 'v2-h-32px',
};

function fontColor(style: string, state: A7ButtonState): string {
  if (['primary', 'black'].includes(style)) {
    return 'text-white-high-emphasis';
  }

  if (['line'].includes(style)) {
    return state === 'disabled' ? 'color-green-grey-4' : 'color-green-grey-1';
  }

  if (['secondary', 'elevate', 'text'].includes(style)) {
    return state === 'disabled' ? 'color-green-grey-4' : 'text-primary';
  }

  if (['white'].includes(style)) {
    return state === 'disabled' ? 'text-white-high-emphasis' : 'text-primary';
  }
}

function bgColor(style: A7ButtonStyle, state: A7ButtonState): string {
  switch (style) {
    case 'primary': {
      switch (state) {
        case 'standard':
          return 'bg-500';
        case 'hovered':
        case 'pressed':
          return 'bg-600';
        case 'disabled':
          return 'bg-blue-grey-2';
      }
      break;
    }
    case 'secondary': {
      switch (state) {
        case 'standard':
          return 'bg-white';
        case 'hovered':
        case 'pressed':
          return 'bg-200';
        case 'disabled':
          return 'bg-blue-grey-1';
      }
      break;
    }
    case 'line': {
      switch (state) {
        case 'standard':
          return 'bg-white';
        case 'hovered':
        case 'pressed':
          return 'bg-blue-grey-1';
        case 'disabled':
          return 'bg-blue-grey-5';
      }
      break;
    }
    case 'elevated': {
      switch (state) {
        case 'standard':
        case 'hovered':
        case 'pressed':
          return 'bg-white';
        case 'disabled':
          return 'bg-blue-grey-150';
      }
      break;
    }

    case 'white': {
      switch (state) {
        case 'standard':
        case 'hovered':
        case 'pressed':
          return 'bg-200';
        case 'disabled':
          return 'bg-blue-grey-2';
      }
      break;
    }

    case 'black': {
      switch (state) {
        case 'standard':
        case 'hovered':
        case 'pressed':
          return 'bg-green-grey-1';
        case 'disabled':
          return 'bg-blue-grey-2';
      }
      break;
    }

    case 'text': {
      switch (state) {
        case 'standard':
        case 'disabled':
          return 'bg-transparent';
        case 'hovered':
        case 'pressed':
          return 'bg-200';
      }
    }
  }
}

function borderStyle(style: A7ButtonStyle, state: A7ButtonState): string {
  switch (style) {
    case 'primary': {
      switch (state) {
        case 'standard':
        case 'hovered':
        case 'pressed':
        case 'disabled':
          return 'v2-border-0px';
      }
      break;
    }
    case 'secondary': {
      switch (state) {
        case 'standard':
        case 'hovered':
        case 'pressed':
          return 'v2-border-1px border-300';
        case 'disabled':
          return 'border-green-grey-5';
      }
      break;
    }
    case 'line': {
      switch (state) {
        case 'standard':
        case 'hovered':
        case 'pressed':
          return 'v2-border-1px border-green-grey-4';
        case 'disabled':
          return 'border-green-grey-5';
      }
      break;
    }
    case 'elevated': {
      switch (state) {
        case 'standard':
        case 'hovered':
        case 'pressed':
          return 'elevation-1dp';
        case 'disabled':
          return 'v2-border-0px';
      }
    }
  }
}
