import { Component } from '@angular/core';
import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { TrackingService } from '@ark7/resources-common';

@Component({
  selector: 'v2-build-wealth',
  templateUrl: './build-wealth.component.html',
  styleUrls: ['./build-wealth.component.scss'],
})
export class BuildWealthComponent extends V2BaseComponent {
  constructor(
    layoutService: A7LayoutService,
    private trackingService: TrackingService,
  ) {
    super(layoutService);
  }
  items = [
    {
      title: 'Start with Cash Deposits?',
      icon: '/assets/v2-icons/build-wealth-create-account.svg',
      button: 'Create Free Cash Account',
      link: '/a/sign-up',
      params: () => {
        return {};
      },
    },
    {
      title: 'Maximize Retirement Plan Tax Benefit?',
      icon: '/assets/v2-icons/build-wealth-ira.svg',
      button: 'Learn More About IRA',
      link: '/ira',
      params: () => {
        return {};
      },
    },
    {
      title: 'Prefer Everything on the Go?',
      icon: '/assets/v2-icons/build-wealth-download.svg',
      button: 'Download Ark7 Mobile App',
      link: '/download-link',
      params: () => {
        return {
          r: this.trackingService.referrer,
          tc: this.trackingService.trackingCode,
        };
      },
    },
  ];
}
