import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MouseCaptureService {
  mouseX: number;
  mouseY: number;

  constructor(
    @Inject(DOCUMENT) document: any,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    if (isPlatformBrowser(platformId)) {
      fromEvent(document.body, 'mousemove').subscribe((e: MouseEvent) => {
        this.mouseX = e.pageX;
        this.mouseY = e.pageY;
      });
    }
  }
}
