import { $$ } from '@ark7/model';
import { A7PageSizeMatcher } from '@ark7/layout';
import { Component, HostBinding } from '@angular/core';
import { FAQCategory } from '@ark7/core-business-models';
import { SubscribeComponent } from '@ark7/utils';

@Component({
  selector: 'app-help-base',
  template: '',
})
export class HelpBaseComponent extends SubscribeComponent {
  @HostBinding('class.mobile')
  @A7PageSizeMatcher('SM-')
  mobile: boolean;

  faqLink(faqId: string) {
    return `/faq?faqId=${faqId}`;
  }

  faqCategoryLinkById(categoryId: string) {
    return `/faq-category?categoryId=${categoryId}`;
  }

  faqCategoryLink(category: FAQCategory) {
    return `/faq-category?categoryId=${
      category.parentCategory ? $$(category.parentCategory)._id : category._id
    }`;
  }
}
