import {
  A7ResourceAction,
  A7ResourceCRUDObservable,
  A7ResourceParams,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';
import { Injectable } from '@angular/core';
import { MarketingConversionType } from '@ark7/core-business-models';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/conversions',
  withCredentials: true,
})
export class MarketingConversionResource extends A7ResourceCRUDObservable<
  any,
  {}
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/signal',
  })
  signal: IResourceMethodPromise<
    {
      code: string;
      leadCode?: string;
      conversionType: MarketingConversionType;
      impactClickId?: string;
    },
    any
  >;
}
