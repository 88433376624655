import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SubscribeDirective } from '@ark7/utils';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { A7LayoutService } from '../services/a7-layout.service';

@Directive({
  selector: '[a7PageSize]',
})
export class PageSizeDirective
  extends SubscribeDirective
  implements OnDestroy, OnInit {
  @Input() a7PageSize: string = 'XL,LG,MD,SM,XS';

  private viewRef: EmbeddedViewRef<{}>;

  constructor(
    private layoutService: A7LayoutService,
    private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutService.pageSize
      .pipe(this.takeUntilDestroyed())
      .pipe(map((pageSize) => this.a7PageSize.indexOf(pageSize) >= 0))
      .pipe(distinctUntilChanged())
      .subscribe((show) => {
        if (show) {
          this.viewRef = this.template.createEmbeddedView({});
          this.viewContainer.insert(this.viewRef);
        } else if (this.viewRef) {
          this.viewContainer.clear();
          this.viewRef.destroy();
          this.viewRef = null;
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.viewRef) {
      this.viewContainer.clear();
      this.viewRef.destroy();
    }
  }
}
