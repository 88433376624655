// tslint:disable:max-line-length

export const TERMS = `
The Ark7 Referral Program (the “Program”) is offered by Ark7 Inc. (“Ark7”, “we” or “us”) as a reward for our loyal users
(“Referrer(s)” or “You”) and your friends, family, and connections (“Referee(s)” or “You”). We reserve the right to
amend or terminate the Program at any time, for any reason. The Program is administered by Ark7.

These terms and conditions ("Terms") apply to your participation in the Program. By participating in the Program, you
agree to use the Program as outlined herein, and consistent with any other terms we may apply to the Program. You
further agree with Ark7 contacting you about the Program via email and app notifications. You may not participate in the
Program where doing so would be prohibited by any applicable law or regulations.

The Program consists of limited-time promotional offerings. Please review the offerings below to see which promotions
are available to you under the Program at the time you make referrals.

1. Promotion Period
<ol type="a">
  <li>Subject to the availability of referral rewards (the “New Investor Bonus”), the Promotion begins on or around
    12:00 am PT on January 1, 2023, and ends at 11:59 pm PT on December 31, 2024 (the “Promotion Period”).</li>
  <li>We reserve the right to end the Promotion Period early if it has given away all of the New Investor Bonus.</li>
</ol>
<br />
2. Eligibility
<ol type="a">
  <li>You must be aged 18 or above to participate in the Program.</li>
  <li>You must be a legal resident of the United States of America to participate in the Program.</li>
  <li>You must have an existing and valid Ark7 account to participate in the Program.</li>
</ol>
<br />
3. New Investor Bonus
<ol type="a">
  <li>A “New Investor Bonus” consists of <ol type="i">
      <li>Referrers: each time someone becomes a new Ark7 user by setting up an Ark7 account using your Referral Link
        (as defined below) that you shared with them during the Promotional Period in accordance with the eligibility
        criteria, and makes a minimum of $20 investment in Ark7 offerings, you will
        receive a New Investor Bonus by Ark7 credits in your Ark7 account; and</li>
      <li>Referees: each Referee who completes the setup of your Ark7 account and makes a minimum of $3,000 investment in Ark7 offerings (the “Qualified Investment”), will
        receive a New Investor Bonus in the amount of $150 by Ark7 credits in your Ark7 account.</li>
    </ol>
  </li>
  <li>New Investor Bonus is strictly subject to availability. Section 4 outlines how you can receive a New Investor
    Bonus.</li>
  <li>New Investor Bonus can only be used to invest in Ark7 offerings on your Ark7 account and may not be withdrawn from
    your Ark7 account.</li>
  <li>New Investor Bonus is non-transferable and non-negotiable.</li>
</ol>
<br />
4. The Program
<ol type="a">
  <li>Referrers are subject to these Terms:<ol type="i">
      <li>Referrers will be provided with a unique referral link or code (a “Referral Link”) from their Ark7 accounts,
        which they can share with Referees. Referrers must share their Referral Link with Referees during the Promotion
        Period, after which the Referral Link will no longer work.</li>
      <li>Subject to subsection iii below, if a Referee correctly follows the steps set out in section 4.b, both the
        Referees and their Referrer will receive a New Investor Bonus.</li>
      <li>New Investor Bonus is strictly subject to availability, in accordance with section 3.b.</li>
    </ol>
  </li>
  <li>Referees are subject to these Terms:<ol type="i">
      <li>Upon receiving a Referral Link from the Referrer, the Referee must register and open an Ark7 account and apply
        the Referral Link during the Program Period.</li>
      <li>If a Referral Link is incorrectly applied or is applied outside the Program Period, neither the Referee nor
        their Referrer will receive a New Investor Bonus.</li>
      <li>After a Referee has correctly applied a Referral Link, if the Referee makes a Qualifying Investment, the
        Referee will receive a New Investor Bonus.</li>
      <li>New Investor Bonus is strictly subject to availability, in accordance with section 3. b.
      </li>
    </ol>
  </li>
</ol>
<br />
5. Delivery of New Investor Bonus
<ol type="a">
  <li>New Investor Bonus is determined solely at Ark7’s sole discretion.
  </li>
  <li>Referrers will receive their New Investor Bonus once Ark7 has performed the necessary risk and abuse checks. Ark7
    reserves the right to cancel any New Investor Bonus without notice if it determines Referrer engaged in fraud or
    abuse of the Program.</li>
  <li>You will receive your applicable New Investor Bonus for each successful Referral within five (5) business days
    (which includes, for the avoidance of doubt, your satisfaction with the Qualified Investment).</li>
  <li>You must have an active Ark7 account in order to receive your New Investor Bonus in your Ark7 account.</li>
  <li>Your Ark7 account must not be canceled or past due at the time of fulfillment of any New Investor Bonus.</li>
</ol>
<br />
6. Other important information
<ol type="a">
  <li>We may (a) stop you from participating in the Program or claiming a New Investor Bonus; (b) revoke any New
    Investor Bonus; (c) block you from participating in future Programal activities; and/or (d) suspend your Ark7
    account, in each case if it considers, in its reasonable opinion, that you are acting contrary to these Terms, the
    spirit of these Terms and/or that you are unfairly taking advantage of or are abusing the Program in any way. To the
    fullest extent permitted by law, we will not be liable for any loss or damage arising from your participation in the
    Program or from your enjoyment of a New Investor Bonus.</li>
  <li>You will be solely responsible for all federal, state, and local taxes, and for any other fees or costs associated
    with all bonuses they receive, regardless of whether the New Investor Bonus, in whole or in part, is used. We do not
    take responsibility for any tax related to any New Investor Bonus you receive. Consult with your tax advisor about
    the appropriate tax treatment and any tax implications associated with the receipt of a New Investor Bonus before
    participating.</li>
  <li>We reserve the right at any time to cancel, change or replace the Program (including the alteration of a New
    Investor Bonus) due to:<ol type="i">
      <li>any reason outside of its reasonable control; and/or</li>
      <li>the technical operation of software for any reason operating in a way that is materially different to the
        purpose of the Program, including by distributing more New Investor Bonus than the number of available New
        Investor Bonus.</li>
    </ol>
  </li>
</ol>
<br />
7. Limitation of Liability & Disclaimer of Warranties
<ol type="a">
  <li>IN NO EVENT WILL ARK7 OR ITS AFFILIATES, SUBSIDIARIES AND RELATED COMPANIES, OR THEIR RESPECTIVE OFFICERS,
    DIRECTORS, EMPLOYEES, REPRESENTATIVES AND AGENTS, BE RESPONSIBLE OR LIABLE FOR ANY DAMAGES OR LOSSES OF ANY KIND,
    INCLUDING DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF YOUR PARTICIPATION IN THE
    PROGRAM OR FOR ANY ACTION OR OMISSION MADE IN CONNECTION WITH THE PROGRAM. WITHOUT LIMITING THE FOREGOING,
    EVERYTHING IN THESE RULES AND IN THIS PROGRAM, INCLUDING THE NEW INVESTOR BONUS AWARDED, IS PROVIDED “AS IS” WITHOUT
    WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. SOME JURISDICTIONS MAY NOT ALLOW THE
    LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES OR EXCLUSION OF IMPLIED WARRANTIES SO
    SOME OF THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR ANY RESTRICTIONS OR
    LIMITATIONS REGARDING THESE LIMITATIONS OR EXCLUSIONS.</li>
</ol>
<br />
8. Governing Law
<ol type="a">
  <li>Except where prohibited, disputes, claims, and causes of action arising out of or related to this Program shall be
    resolved under the laws of the United States, and except where prohibited, the laws of California (without reference
    to its conflicts of laws principles), and you agree to submit any dispute to the exclusive jurisdiction of the state
    and federal courts located in San Francisco County, California.</li>
</ol>
<br />
9. Miscellaneous
<ol type="a">
  <li>No waiver of any of the provisions of these Terms shall be deemed or shall constitute a waiver of any other
    provisions hereof, nor shall waiver constitute a continuing waiver unless otherwise expressly provided. If any
    provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, such
    provision shall be severed from the remainder of these Terms, which will otherwise remain in full force and effect.
  </li>
</ol>

If you have any questions about these Terms, please contact us at:`;
