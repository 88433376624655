import * as _ from 'underscore';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'childrenCount',
})
export class ChildrenCountPipe implements PipeTransform {
  transform(
    value: HTMLElement | NodeListOf<Node & ChildNode>,
    args?: any,
  ): number {
    const nodes = value instanceof NodeList ? value : value.childNodes;
    return _.filter(nodes, node => node.nodeType !== Node.COMMENT_NODE).length;
  }
}
