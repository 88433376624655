import * as _ from 'underscore';
import {
  animation,
  style,
  animate,
  keyframes,
} from '@angular/animations';

import { DEFAULT_TIMING } from './defaults';
import { translateTiming } from './utils';

export interface FadeParams {
  timing?: number | string;
  delay?: number | string;
  tx1?: number | string;
  tx2?: number | string;
  ty1?: number | string;
  ty2?: number | string;
  opacity1?: number;
  opacity2?: number;
}

export function fade(options: FadeParams = {}) {
  const params: FadeParams = _.defaults({}, options, {
    timing: DEFAULT_TIMING,
    delay: '0s',
    tx1: '0',
    tx2: '0',
    ty1: '0',
    ty2: '0',
    opacity1: 1,
    opacity2: 1,
  });
  params.timing = translateTiming(params.timing);
  params.delay = translateTiming(params.delay);

  return animation(
    animate(
      '{{ timing }} {{ delay }}',
      keyframes([
        style({
          opacity: '{{ opacity1 }}',
          transform: 'translate3d({{ tx1 }}, {{ ty1 }}, 0)',
          offset: 0,
        }),
        style({
          opacity: '{{ opacity2 }}',
          transform: 'translate3d({{ tx2 }}, {{ ty2 }}, 0)',
          offset: 1,
        }),
      ]),
    ),
    { params },
  );
}

export const fadeIn = fade({opacity1: 0});
export const fadeInLeft = fade({opacity1: 0, tx1: '-100%'});
export const fadeInRight = fade({opacity1: 0, tx1: '100%'});
export const fadeInDown = fade({opacity1: 0, ty1: '-100%'});
export const fadeInUp = fade({opacity1: 0, ty1: '100%'});

export const fadeOut = fade({opacity2: 0});
export const fadeOutLeft = fade({opacity2: 0, tx2: '-100%'});
export const fadeOutRight = fade({opacity2: 0, tx2: '100%'});
export const fadeOutDown = fade({opacity2: 0, ty2: '100%'});
export const fadeOutUp = fade({opacity2: 0, ty2: '-100%'});
