<app-page>
  <div class="v2-max-w-120 margin-auto v2-min-h-50 body-2 color-green-grey-1 v2-padding-5-b"
       [ngClass]="sm ? 'v2-padding-2-h' : 'v2-padding-5-h'">
    <div class="h1 text-center">Secondary Market Trading Agreement</div>

    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:3.35pt;margin-right:0cm;margin-left:157.2pt;'>
      <br>
    </p>
    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:0cm;margin-right:5.25pt;margin-left:6.0pt;text-align:justify;text-indent:36.15pt;'>
      <strong><span style="color:#030303;">THIS SECONDARY MARKET TRADING
          AGREEMENT&nbsp;</span></strong><span style="color:#030303;">(this
        <strong>&quot;Agreement&quot;)&nbsp;</strong>governs all transactions and is a binding contract among the
        <strong>Owner/Seller</strong></span><strong><span style="color:#2D2D2D;">,&nbsp;</span></strong><span
            style="color:#030303;">the true and beneficial owner of certain units or
        shares
        <strong>(</strong></span><strong><span style="color:#2D2D2D;">&quot;</span></strong><strong><span
              style="color:#030303;">Shares</span></strong><strong><span
              style="color:#3F3F3F;">&quot;</span></strong><strong><span
              style="color:#030303;">)&nbsp;</span></strong><span style="color:#030303;">of a security
        (the</span><strong><span style="color:#3F3F3F;">&quot;</span></strong><strong><span
              style="color:#030303;">Security</span></strong><strong><span
              style="color:#2D2D2D;">&quot;</span></strong><strong><span
              style="color:#030303;">),&nbsp;</span></strong><span style="color:#030303;">or the
        <strong>Buyer/Bidder&nbsp;</strong>(each
        individually,&nbsp;as the case may be, the</span><strong><span
              style="color:#3F3F3F;">&quot;</span></strong><strong><span
              style="color:#030303;">User</span></strong><strong><span
              style="color:#2D2D2D;">&quot;</span></strong><strong><span style="color:#030303;">), Dalmore Group,
          LLC,&nbsp;</span></strong><span style="color:#030303;">a registered broker-dealer and member of the
        Financial
        Industry Regulatory Authority, Inc</span><span style="color:#3F3F3F;">.&nbsp;</span><strong><span
              style="color:#030303;">(</span></strong><strong><span
              style="color:#2D2D2D;">&quot;</span></strong><strong><span
              style="color:#030303;">FINRA</span></strong><strong><span
              style="color:#3F3F3F;">&quot;</span></strong><strong><span
              style="color:#030303;">)&nbsp;</span></strong><span style="color:#030303;">and the Securities Industry
        Protection Corporation
        <strong>(</strong></span><strong><span style="color:#3F3F3F;">&quot;</span></strong><strong><span
              style="color:#030303;">SIPC</span></strong><strong><span
              style="color:#2D2D2D;">&quot;</span></strong><strong><span
              style="color:#030303;">)&nbsp;(</span></strong><strong><span
              style="color:#3F3F3F;">&quot;</span></strong><strong><span
              style="color:#030303;">Dalmore&nbsp;Group</span></strong><strong><span
              style="color:#3F3F3F;">&quot;&nbsp;</span></strong><strong><span
              style="color:#030303;">or&nbsp;</span></strong><strong><span
              style="color:#2D2D2D;">&quot;</span></strong><strong><span
              style="color:#030303;">Company</span></strong><strong><span
              style="color:#2D2D2D;">&quot;</span></strong><strong><span
              style="color:#030303;">)</span></strong><strong><span style="color:#2D2D2D;">.&nbsp;</span></strong><span
            style="color:#030303;">This Agreement governs the terms under which the User
        is
        granted the right to bid for, purchase, offer for sale and/or sell Shares of the Security through the
        <strong>North
          Capital Private Securities Corporation Alternative Trading System&nbsp;</strong>(the
        <strong>&quot;Platform&quot;).&nbsp;</strong>All&nbsp;User activity on&nbsp;the Platform is governed
        by&nbsp;this
        Agreement and other documents included by&nbsp;reference</span><span style="color:#3F3F3F;">.</span>
    </p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.2pt;'><span
            style="">&nbsp;</span></p>
    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:0cm;margin-right:5.65pt;margin-left:6.2pt;text-align:justify;text-indent:-.05pt;'>
      <strong><span style="color:#030303;">NOTICE: Please read all referenced
          Agreements,
          Disclosures, Policies, and&nbsp;Terms of Service.&nbsp;These terms contain important information, including a
          binding arbitration agreement that impacts your rights when disputes are&nbsp;resolved.&nbsp;If&nbsp;any of
          these
          terms are unacceptable to&nbsp;the User, then please do&nbsp;not&nbsp;visit, access or use the
          Platform.</span></strong>
    </p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'>
      <strong><span style="">&nbsp;</span></strong>
    </p>
    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:0cm;margin-right:5.55pt;margin-left:5.95pt;text-align:justify;text-indent:.1pt;'>
      <span style="color:#030303;">The User, when acting as a seller, is the owner of Shares of the Security and upon
        acceptance of this Agreement, shall grant Company the exclusive right to offer for sale on the Platform the
        User&apos;s Shares in the Security for the period of time chosen by Seller, or good&nbsp;</span><span
            style="color:#2D2D2D;">&apos;</span><span style="color:#030303;">til canceled if no period of time is chosen
        by
        Seller <strong>(&apos;Term</strong></span><strong><span style="color:#2D2D2D;">&quot;</span><span
              style="color:#030303;">),&nbsp;</span></strong><span style="color:#030303;">at&nbsp;the Ask&nbsp;Price for
        the
        number of shares specified by the User</span><span style="color:#3F3F3F;">.</span>
    </p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.5pt;'><span
            style="">&nbsp;</span></p>
    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:0cm;margin-right:5.45pt;margin-left:6.0pt;text-align:justify;text-indent:.15pt;'>
      <span style="color:#030303;">By accepting the terms of this Agreement, the User offering the Security shall be
        obligated to sell Shares of the Security if a transaction is executed&nbsp;at the&nbsp;Ask Price set by the User
        during&nbsp;the term&nbsp;of&nbsp;the&nbsp;order, and&nbsp;the User bidding on the&nbsp;Security&nbsp;shall be
        obligated&nbsp;to buy Shares&nbsp;of the&nbsp;Security&nbsp;if a transaction&nbsp;is executed&nbsp;at the Bid
        Price
        set by the User during the term of the order.</span>
    </p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.1pt;'><span
            style="">&nbsp;</span></p>
    <div style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'>
      <ol style="margin-bottom:0cm;list-style-type: decimal;margin-left:32.2px;">
        <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                  style="color:#030303;">Eligibility&nbsp;of&nbsp;User&nbsp;to&nbsp;Trade&nbsp;on&nbsp;Platform</span></strong>
        </li>
      </ol>
    </div>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.45pt;'>
      <strong>&nbsp;</strong>
    </p>
    <ol style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                style="color:#030303;">Account Approval&nbsp;</span></strong><span style="color:#030303;">- Each
          prospective&nbsp;User is required&nbsp;to
          register on the Platform by&nbsp;completing the investor registration process, and be approved,
          in&nbsp;order to
          have trading access to the&nbsp;Platform. Each User must complete the investor registration process, which
          will
          require the User to provide certain personal&nbsp;information,&nbsp;including&nbsp;but in
          no&nbsp;way&nbsp;limited&nbsp;to, such as: legal name, address</span><span
              style="color:#2D2D2D;">,&nbsp;</span><span style="color:#030303;color:#030303;">phone number, date of
          birth, social
          security number, residency information, valid ID, accreditation information (as required),&nbsp;financial
          status, proof of&nbsp;funds</span><span style="color:#2D2D2D;">,&nbsp;</span><span
              style="color:#030303;">proof of banking relationship, employment information and
          financial
          experience</span><span style="color:#2D2D2D;">.&nbsp;</span><span style="color:#030303;">The User
          acknowledges&nbsp;that the Company is
          the
          final decision maker of&nbsp;when or if&nbsp;a User is approved&nbsp;and owes&nbsp;no
          explanation&nbsp;to&nbsp;the applying&nbsp;User&nbsp;if the User</span><span
              style="color:#2D2D2D;">&apos;</span><span style="color:#030303;">s
          application to the Platform is denied.</span>
      </li>
      <p></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                style="color:#030303;">Identity
            Verification&nbsp;</span></strong><span style="color:#030303;">- All Users
          are subject to Anti-Money Laundering
          (&quot;AML</span><span style="color:#2D2D2D;">&quot;</span><span style="color:#030303;">) and Know Your
          Client (&quot;KYC&quot;) rules
          and
          regulations of FINRA and other applicable regulatory agencies</span><span
              style="color:#2D2D2D;">.&nbsp;</span><span style="color:#030303;">The
          User authorizes the Company to make any
          inquiries necessary to verify the identity of the User, protect against fraud or other financial crimes
          and/or
          comply with the legal obligations regarding AML and KYC.&nbsp;Failure to provide proper information or
          offering
          misrepresentations when completing the Investor&nbsp;Passport&nbsp;will delay or prevent&nbsp;the
          Company&nbsp;from approving&nbsp;the User and could result in the revocation of a prior approval.</span>
      </li>
    </ol>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'>&nbsp;
    </p>
    <div style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'>
      <ol start="2"
          style="margin-bottom:0cm;list-style-type: undefined;margin-left:32.2px;">
        <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                  style="color:#030303;">Trading&nbsp;on&nbsp;The&nbsp;Platform&nbsp;by
              Approved&nbsp;User</span></strong></li>
      </ol>
    </div>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.4pt;'>
      <strong>&nbsp;</strong>
    </p>
    <ol style="list-style-type: lower-alpha;">
      <li><strong><span style="color:#030303;">Secondary Trading Process&nbsp;</span></strong><span
              style="color:#030303;color:#030303;">-</span>
        <ul>
          <li><u><span style="color:#030303;">Selling</span></u><span
                  style="color:#030303;color:#030303;">&nbsp;</span><span style="color:#030303;color:#030303;">- The
              User</span><span style="color:#2D2D2D;">,&nbsp;</span><span style="color:#030303;color:#030303;">when
              acting as a
              <strong>Seller/Offerer</strong></span><strong><span style="color:#2D2D2D;">,&nbsp;</span></strong><span
                  style="color:#030303;">may create an <strong>Offer/Sell
                order,&nbsp;</strong>which shall indicate the Security, number of Shares, duration of order Term and an
              Ask
              Price that will cause the Security to be offered on the Platform. By entering an Offer</span><span
                  style="color:#2D2D2D;">,&nbsp;</span><span style="color:#030303;">the User is generating
              instructions&nbsp;to
              transfer the security to a buyer in the event of a&nbsp;match</span><span
                  style="color:#2D2D2D;">.&nbsp;</span><span style="color:#030303;color:#030303;">The Term of the Offer
              is
              good&nbsp;</span><span style="color:#1A1A1A;">&apos;til&nbsp;</span><span
                  style="color:#030303;">canceled&nbsp;if no period of time is chosen by
              Seller</span><span style="color:#2D2D2D;">.&nbsp;</span><span style="color:#030303;">The User can cancel
              the Offer at any time
              prior to
              a match</span><span style="color:#3F3F3F;">.&nbsp;</span><span style="color:#030303;">If a match does not
              occur during the term, the
              Seller may re-Offer the Security for another Term</span><span
                  style="color:#3F3F3F;color:#3F3F3F;">.</span></li>

          <li><u><span style="color:#030303;">Buying</span></u><span style="color:#030303;color:#030303;">&nbsp;- The
              User,
              when acting as a
              <strong>Buyer/Bidder,&nbsp;</strong>may place a <strong>Bid/Buy order,&nbsp;</strong>which shall indicate
              the
              Security, number of shares and duration of the Bid. The Term of the Bid is good&nbsp;</span><span
                  style="color:#2D2D2D;">&apos;</span><span style="color:#030303;">til&nbsp;canceled if no period of
              time is
              chosen
              by
              Buyer.</span></li>
        </ul>
      </li>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                style="color:#030303;color:#030303;">Match
            Process&nbsp;</span></strong><span style="color:#030303;">- During the
          Term,
          Buyers may place Bids on the Platform&nbsp;to purchase&nbsp;the Security.&nbsp;The
          Seller&nbsp;is&nbsp;obligated&nbsp;to&nbsp;sell&nbsp;and the Buyer is&nbsp;obligated to buy&nbsp;the
          Security&nbsp;per&nbsp;the terms of&nbsp;this&nbsp;Agreement&nbsp;if the Buyer&apos;s&nbsp;Bid price is at
          or
          above&nbsp;the Seller&apos;s&nbsp;Offer Price during&nbsp;the Term</span><span
              style="color:#424242;color:#424242;">.&nbsp;</span><span style="color:#030303;">The Platform allows
          for&nbsp;partial fills of
          any
          Bid&nbsp;or&nbsp;Offer entered by the User</span><span style="color:#424242;">.&nbsp;</span><span
              style="color:#030303;">The
          Company reserves the sole right to cancel
          any
          open Bid or Offer of Shares of the Security at any time and all parties agree to hold the Company harmless
          if
          such is the case.</span></li>

      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.55pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                style="color:#030303;">Match Price&nbsp;</span></strong><span
              style="color:#030303;color:#030303;">-&nbsp;At&nbsp;Platform market
          open,
          the Match Price is the average of the Bid/Offer Price of a Bid/Offer order resting on the Platform&apos;s
          order
          book and an Offer/Bid with an Offer/Bid Price at or below/at or above the former order resting on the
          Platform&apos;s order book</span><span style="color:#2F2F2F;">.&nbsp;</span><span
              style="color:#030303;">During Platform market hours immediately
          following
          market open</span><span style="color:#2F2F2F;">,&nbsp;</span><span style="color:#030303;">the Match
          Price&nbsp;is&nbsp;the Bid/Offer Price
          of
          a&nbsp;Bid/Offer order resting on the Platform&apos;s order book, when a subsequent Offer/Bid with an
          Offer/Bid
          Price at or below/at&nbsp;or&nbsp;above&nbsp;the&nbsp;resting&nbsp;order&nbsp;is entered&nbsp;onto&nbsp;the
          Platform&apos;s&nbsp;order book</span><span style="color:#2F2F2F;color:#2F2F2F;">.&nbsp;</span><span
              style="color:#030303;">The total amount due from the
          Buyer&nbsp;</span><span style="color:#1A1A1A;">is&nbsp;</span><span style="color:#030303;">equal to
          the&nbsp;Match Price, plus any
          commission&nbsp;and fees</span><span style="color:#525252;">.&nbsp;</span><span
              style="color:#030303;">The&nbsp;amount due the Seller&nbsp;is equal to
          the
          Match Price,&nbsp;less any&nbsp;commission and fees.&nbsp;The Company may</span><span
              style="color:#2F2F2F;">,&nbsp;</span><span style="color:#030303;">at&nbsp;its&nbsp;sole discretion,
          reduce the
          commission paid by the Buyer or Seller.</span></li>

      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="">&nbsp;</span></p>

      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                style="color:#030303;color:#030303;">Offer
            Price&nbsp;</span></strong><span style="color:#030303;">-&nbsp;The Seller
          must indicate an Offer Price</span><span style="color:#424242;color:#424242;">.&nbsp;</span><span
              style="color:#030303;">If, at any time during the Seller&apos;s Offer order Term, the
          Offer
          is the highest-priced Offer on the order book and a User places a Bid order at or above the Offer Price, the
          Offer order will be considered&nbsp;matched and the Security will be transferred from the Offeror to the
          matching Bidder.</span></li>

      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.5pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                style="color:#030303;">Bid Price&nbsp;</span></strong><span
              style="color:#030303;color:#030303;">-&nbsp;The User&nbsp;must indicate
          a
          Bid&nbsp;Price&nbsp;when placing a&nbsp;Bid order.&nbsp;If</span><span
              style="color:#2F2F2F;">,&nbsp;</span><span style="color:#030303;">at
          any time during the Bid order&nbsp;Term, the
          Bid
          is the highest-priced Bid on the order book and&nbsp;a User places&nbsp;an Offer order at or&nbsp;below the
          Bid
          Price, the Bid order will be considered matched and the Security will be transferred from the Offeror to the
          matching Bidder</span><span style="color:#2F2F2F;">.</span></li>

      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.1pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                style="color:#030303;">Trading Time Zone&nbsp;</span></strong><span
              style="">-&nbsp;Eastern&nbsp;Standard&nbsp;or&nbsp;Daylight&nbsp;Time.&nbsp;24/7/365</span><span
              style="color:#2F2F2F;">.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.45pt;'>&nbsp;
      </p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                style="color:#030303;color:#030303;">Document
            Delivery&nbsp;</span></strong><span style="color:#030303;">- User agrees
          to
          execute and deliver promptly to the Company any and all agreements, documents or instruments necessary or
          appropriate, for the avoidance of doubt, to effect an assignment or substitution on the books and records,
          or
          substantial equivalent</span><span style="color:#2F2F2F;">,&nbsp;</span><span style="color:#030303;">by the
          Issuer of the Shares, as required by
          Company
          to fulfill the obligations of the User as described&nbsp;in this Agreement. Documents and
          data&nbsp;may&nbsp;be&nbsp;delivered electronically.&nbsp;Agreements are&nbsp;considered binding on the User
          if
          certified with electronic signatures or memorialized via an affirmative check box.</span></li>
    </ol>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.25pt;'><span
            style="">&nbsp;</span></p>
    <div style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'>
      <ol start="3"
          style="margin-bottom:0cm;list-style-type: undefined;margin-left:32.3px;">
        <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                  style="color:#030303;">Representation and Warranties of the
              User&nbsp;</span></strong><span
                style="color:#030303;">-&nbsp;The&nbsp;User&nbsp;hereby&nbsp;represents,&nbsp;warrants,&nbsp;agrees&nbsp;and
            acknowledges the following:</span></li>
      </ol>
    </div>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'><span
            style="">&nbsp;</span></p>
    <ol style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">The
          User has the full right, power and authority
          to
          execute this Agreement along with any other documents or agreements&nbsp;as required by the Company to
          fulfil
          any obligations or grant powers in this Agreement.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.45pt;'>&nbsp;
      </p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;">Securities are offered through Dalmore Group,
          LLC, a
          registered broker-dealer and member of FlNRA/SIPC</span><span style="color:#2F2F2F;">.&nbsp;</span><span
              style="color:#030303;">Neither
          Dalmore Group, nor any of its affiliates, shall be deemed to be providing any investment advice or making
          any
          investment recommendations to any person.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.25pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">Assets
          listed on the Platform are securities
          that
          are not&nbsp;publicly traded, may be subject to holding period requirements and are intended for qualified
          investors with no need for liquidity in such investment.</span></li>

      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="">&nbsp;</span></p>

      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;">Investments in&nbsp;the Securities listed
          on&nbsp;the&nbsp;Platform are NOT&nbsp;bank deposits (and thus NOT insured by the FDIC or by any other
          federal
          governmental&nbsp;agency), are NOT guaranteed by the Company and/or its affiliates and may lose some or all
          of
          their value.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.1pt;'>&nbsp;
      </p>
      <li><span style="color:#030303;">Neither the U</span><span style="color:#2F2F2F;color:#2F2F2F;">.</span><span
              style="color:#030303;">S</span><span style="color:#424242;">.&nbsp;</span><span
              style="color:#030303;">Securities and Exchange Commission nor any federal
          or
          state securities commission or&nbsp;regulatory authority has recommended&nbsp;or approved any&nbsp;investment
          or&nbsp;the accuracy or completeness of any of the information or materials provided by or through the
          website</span><span style="color:#2F2F2F;">.</span></li>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">The
          User has access to all information regarding
          the
          Security deemed necessary by the User to make an informed investment decision.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.1pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">The
          User is sophisticated,&nbsp;has knowledge
          and
          experience in&nbsp;financial and business matters and is capable of evaluating&nbsp;the merits and risks of
          an&nbsp;investment in&nbsp;the Security.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.1pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">The
          User understands&nbsp;that the purchase of
          the
          Security involves&nbsp;substantial risk; and, the&nbsp;User must be able to afford the loss of the
          User&apos;s
          entire investment.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">The
          User has consulted with or has had the
          opportunity to consult with a qualified attorney, tax advisor, or accountant, and understands the income tax
          aspects of the User&apos;s activity on the Platform.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.35pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">The
          User has reviewed and accepts any
          restrictions
          contained in&nbsp;the shareholders&apos; agreement or similar agreement, if any, upon&nbsp;taking ownership
          of
          the Security.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.2pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;">The&nbsp;User&nbsp;has
          not&nbsp;relied&nbsp;on&nbsp;Company&nbsp;nor&nbsp;any&nbsp;of
          its&nbsp;affiliates&nbsp;for&nbsp;investment,&nbsp;legal&nbsp;or&nbsp;tax advice</span><span
              style="color:#3B3B3B;color:#3B3B3B;">.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.15pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">The User
          has not relied upon any representation or warranty made by the
          Company
          or its affiliates with respect to the value or marketability of the Security. The User accepts sole
          responsibility
          for the decision to buy or sell Security at the determined price and that the Match Price of the Security may
          be
          above or below the net asset value or book value of the Security.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.5pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;color:#030303;">No commission,
          fee&nbsp;or&nbsp;other
          compensation is&nbsp;due&nbsp;to any broker, finder or&nbsp;other&nbsp;agent of the User in connection
          with&nbsp;this transaction except as otherwise stated in&nbsp;the confirmation, and, in&nbsp;the event of
          any
          claim for any commission, finder&apos;s fee&nbsp;or other&nbsp;such&nbsp;compensation,&nbsp;User agrees that
          it
          shall be exclusively responsible therefor.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.3pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;color:#030303;">The User
          is&nbsp;purchasing&nbsp;the
          Security exclusively&nbsp;for the User&apos;s&nbsp;own&nbsp;account and not&nbsp;with a&nbsp;view to, or
          sale in
          connection with, a distribution.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;color:#030303;">All historical pricing provided to
          the
          User by the Company is for informational purposes only and is not indicative of the User&apos;s ability to
          obtain similar results.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.15pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;color:#030303;">The Company did not solicit any
          transaction, bid, ask or other such activity of the User on the Platform.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.1pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;color:#030303;">Any and all of the information and
          documents provided&nbsp;by the User to the Company are true, correct and complete. Providing
          false&nbsp;documents and/or information to the&nbsp;Company is&nbsp;grounds&nbsp;for rejecting the Users
          access
          to the Platform or revoking access if previously granted</span><span
              style="color:#4F4F4F;color:#4F4F4F;">.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.5pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;color:#030303;">The Platform is operated on
          a&nbsp;</span><span style="color:#2A2A2A;">&quot;best&nbsp;</span><span style="color:#030303;">efforts&quot;
          basis and Bids&nbsp;and/or
          Offers&nbsp;may not be present&nbsp;for any Security listed on the Platform</span><span
              style="color:#3B3B3B;">.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.5pt;'>&nbsp;
      </p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;color:#030303;">The User is solely responsible for
          any
          and all activity that occurs on the Platform when the User account is used.&nbsp;If
          a&nbsp;User&nbsp;believes
          that the User did&nbsp;not authorize a particular transaction or&nbsp;that a transaction was incorrectly
          executed, the User must contact Dalmore Group at once to report the same</span><span
              style="color:#4F4F4F;color:#4F4F4F;">.&nbsp;</span><span style="color:#030303;">All confirmations are
          considered accurate and complete unless
          the
          User contacts the Company within three (3) days.</span></li>
    </ol>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.3pt;'><span
            style="">&nbsp;</span></p>
    <div style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'>
      <ol start="4"
          style="margin-bottom:0cm;list-style-type: undefined;margin-left:32.3px;">
        <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                  style="color:#030303;">Scope of Services Provided&nbsp;</span></strong><strong><span
                  style='font-family:"Times New Roman",serif;color:#030303;'>by&nbsp;</span></strong><strong><span
                  style="color:#030303;">Dalmore Group, LLC.&nbsp;</span></strong><span
                style="color:#030303;">-&nbsp;The User acknowledges&nbsp;and&nbsp;understands&nbsp;the
            following</span><span style="color:#3B3B3B;color:#3B3B3B;">:</span></li>
      </ol>
    </div>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.1pt;'><span
            style="">&nbsp;</span></p>
    <ol style="margin-bottom:0cm;list-style-type: lower-alpha;">
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">All
          securities are offered through Dalmore
          Group,
          LLC</span><span style="color:#3B3B3B;">.</span><span style="color:#181818;">,&nbsp;</span><span
              style="color:#030303;">a&nbsp;registered broker-dealer and member of
          FlNRA/SIPC</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'><span
              style="">&nbsp;</span></p>
      <li><span style="color:#030303;">Dalmore Group is&nbsp;a broker-dealer acting
          as&nbsp;an&nbsp;agent, not as a&nbsp;principal, in&nbsp;connection with&nbsp;the sale of the Security.</span>
      </li>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">Dalmore
          Group is requesting certain information
          from
          the User is to satisfy FINRA and SEC rules and regulations&nbsp;governing&nbsp;&quot;Know Your
          Customer&quot;
          and Anti-Money Laundering&nbsp;standards</span><span style="color:#1A1F2A;">,&nbsp;</span><span
              style="color:#030303;">along with provisions of the Bank Secrecy Act,
          the
          USA PATRIOT Act and other regulations</span><span style="color:#3D3D41;">.&nbsp;</span><span
              style="color:#030303;">The
          User may be required to provide more information to allow
          Dalmore Group to fulfill its legal obligations.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">The
          User was not&nbsp;solicited by&nbsp;Dalmore
          Group or any Company entity&nbsp;in connection with&nbsp;the sale of any&nbsp;Security</span><span
              style="color:#3D3D41;color:#3D3D41;">.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.45pt;'>&nbsp;
      </p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">Dalmore
          Group and its representatives may
          receive
          fees,&nbsp;commissions and/or other compensation as a result of the User&apos;s trading activity</span><span
              style="color:#4B4D54;">.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.55pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">Neither
          Dalmore Group nor any of&nbsp;its
          representatives has offered a&nbsp;value of the Security to be sold through Dalmore Group.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.2pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">Neither
          Dalmore Group nor&nbsp;any&nbsp;of
          its&nbsp;representatives&nbsp;has suggested that&nbsp;User&nbsp;may obtain&nbsp;a&nbsp;certain price for the
          Security</span><span style="color:#3D3D41;">.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">Any
          order book services offered by Dalmore Group
          are
          limited to the&nbsp;</span><span style="color:#1A1F2A;color:#1A1F2A;">&quot;</span><span
              style="color:#030303;">best-effort&apos;</span><span style="color:#3D3D41;">&apos;&nbsp;</span><span
              style="color:#030303;">functions associated with matching User-entered
          Bids
          and Offers</span><span style="color:#1A1F2A;color:#1A1F2A;">.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.2pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span
              style="color:#030303;color:#030303;">While Dalmore Group and its
          affiliates
          have performed certain due diligence on the Issuers of the Securities being offered
          on&nbsp;the&nbsp;Platform,
          Dalmore Group and&nbsp;its affiliates are in&nbsp;no way responsible for&nbsp;the&nbsp;actions of the
          Issuer&nbsp;of the Security.&nbsp;Any due diligence performed by the&nbsp;Dalmore Group or its affiliates is
          not
          intended to&nbsp;be viewed as&nbsp;a&nbsp;recommendation&nbsp;or basis for any activity by the User on the
          Platform.</span></li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">Dalmore
          Group is not a fiduciary for the User
          and
          has not offered and is not offering investment advice to the User.&nbsp;The User has engaged
          in&nbsp;a&nbsp;relationship with&nbsp;Dalmore Group for the exclusive purpose of trading on the
          Platform.</span>
      </li>
      <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'><span
              style="">&nbsp;</span></p>
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="color:#030303;">Dalmore
          Group does&nbsp;not&nbsp;guarantee
          or&nbsp;otherwise have responsibility&nbsp;for&nbsp;settling the trades&nbsp;and does not</span><span
              style="color:#1A1F2A;">,&nbsp;</span><span style="color:#030303;">at
          any time</span><span style="color:#1A1F2A;">,&nbsp;</span><span style="color:#030303;">exercise any level
          of&nbsp;control over&nbsp;the
          digital asset securities being sold or&nbsp;the&nbsp;cash being used to make the purchase</span></li>
    </ol>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.5pt;'><span
            style="">&nbsp;</span></p>
    <div style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'>
      <ol start="5"
          style="margin-bottom:0cm;list-style-type: undefined;margin-left:32.25px;">
        <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                  style="color:#030303;">Indemnification&nbsp;</span></strong><span style="color:#030303;">-&nbsp;The
            User will defend</span><span style="color:#1A1F2A;">,&nbsp;</span><span
                style="color:#030303;">indemnify&nbsp;and&nbsp;harmless Company&nbsp;and
            its
            affiliates&nbsp;from and against&nbsp;any and all claims</span><span
                style="color:#1A1F2A;">,&nbsp;</span><span style="color:#030303;">losses</span><span
                style="color:#1A1F2A;">,&nbsp;</span><span style="color:#030303;">damages, and liabilities, joint or
            several</span><span style="color:#1A1F2A;">,&nbsp;</span><span style="color:#030303;">or actions</span><span
                style="color:#1A1F2A;color:#1A1F2A;">,&nbsp;</span><span
                style="color:#030303;">including&nbsp;shareholder actions</span><span
                style="color:#1A1F2A;">,&nbsp;</span><span style="color:#030303;">in
            respect thereof related to or arising directly
            or
            indirectly out of its: (i) services contemplated hereunder; and (ii)&nbsp;breaches of
            representations</span><span style="color:#1A1F2A;">,&nbsp;</span><span style="color:#030303;">warranties and
            covenants and agreements set
            forth in
            this Agreement.</span></li>
      </ol>
    </div>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.25pt;'><span
            style="">&nbsp;</span></p>
    <div style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'>
      <ol start="6"
          style="margin-bottom:0cm;list-style-type: undefined;margin-left:32.25px;">
        <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                  style="color:#030303;">Rescission&nbsp;</span></strong><span style="color:#030303;">-&nbsp;Company
            shall have the right</span><span style="color:#1A1F2A;">,&nbsp;</span><span
                style="color:#030303;">in&nbsp;its absolute discretion and without
            prior
            notice to the User</span><span style="color:#1A1F2A;">,&nbsp;</span><span style="color:#030303;">to rescind,
            reject,&nbsp;cancel&nbsp;or refuse to&nbsp;display
            any
            order or transaction</span><span style="color:#1A1F2A;">.&nbsp;</span><span style="color:#030303;">In
            such&nbsp;event</span><span style="color:#1A1F2A;">,&nbsp;</span><span style="color:#030303;">the
            Company shall have no liability to the User</span><span style="color:#1A1F2A;">.</span>
        </li>
      </ol>
    </div>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.5pt;'><span
            style="">&nbsp;</span></p>
    <div style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'>
      <ol start="7"
          style="margin-bottom:0cm;list-style-type: undefined;margin-left:32.25px;">
        <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                  style="color:#030303;">Account Suspension, or
              Termination&nbsp;</span></strong><span style="color:#030303;">- The
            Company,
            at its sole discretion and without notice</span><span style="color:#1A1F2A;">,&nbsp;</span><span
                style="color:#030303;color:#030303;">may terminate or suspend
            an&nbsp;approved User&apos;s access to the Platform</span><span style="color:#1A1F2A;">.&nbsp;</span><span
                style="color:#030303;">In&nbsp;that case, the User will&nbsp;no longer
            have
            access to content on the Platform such as trade history, bids, offers and User Information</span><span
                style="color:#1A1F2A;">.</span></li>
      </ol>
    </div>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><span style="">&nbsp;</span></p>
    <div style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'>
      <ol start="8"
          style="margin-bottom:0cm;list-style-type: undefined;margin-left:32.3px;">
        <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                  style="color:#030303;">Claw back&nbsp;</span></strong><span style="color:#030303;">-&nbsp;</span><span
                style="color:#1A1F2A;">Company shall have the right to initiate
            a&nbsp;</span><span style="color:#3D3D41;">&quot;</span><span style="color:#1A1F2A;">claw
            back&apos;</span><span style="color:#4B4D54;color:#4B4D54;">&apos;&nbsp;</span><span
                style="color:#1A1F2A;">of any Security after the&nbsp;trade&nbsp;has
            settled, to correct for any technological or market irregularity resulting in an erroneous
            transaction</span><span style="color:#3D3D41;">.&nbsp;</span><span style="color:#1A1F2A;">User acknowledges
            that this may result in funds
            and
            Securities being reconciled</span><span style="color:#3D3D41;">,&nbsp;</span><span
                style="color:#1A1F2A;">removed and credited, as required, to unwind the
            trade</span><span style="color:#4B4D54;">.</span></li>
      </ol>
    </div>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.45pt;'>&nbsp;
    </p>
    <ol start="9"
        style="list-style-type: undefined;margin-left:32.25px;">
      <li><strong><span style="color:#030303;">Conflicts of
            Interest&nbsp;</span></strong><span style="color:#030303;">-&nbsp;Company
          may&nbsp;participate&nbsp;and receive compensation and fees&nbsp;from&nbsp;trades in&nbsp;which&nbsp;it is
          an&nbsp;agent for both a Buyer and a Seller</span><span
              style="color:#3D3D41;color:#3D3D41;">.&nbsp;</span><span style="color:#030303;">The User acknowledges and
          accepts this conflict of
          interest and waives any and all claims otherwise resulting from it.</span></li>
    </ol>
    <ol start="10"
        style="margin-bottom:0cm;list-style-type: undefined;margin-left:32.3px;">
      <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                style="color:#010101;">Entire Agreement&nbsp;</span></strong><span style="color:#010101;">-&nbsp;This
          Agreement constitutes the entire agreement between
          the
          User and the Company regarding the subject matter hereof.&nbsp;This Agreement shall supersede all prior
          or&nbsp;contemporaneous written and oral agreements, communications and other understanding relating to the
          subject matter hereof this Agreement.</span></li>
    </ol>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.35pt;'><span
            style="">&nbsp;</span></p>
    <div style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'>
      <ol start="11"
          style="margin-bottom:0cm;list-style-type: undefined;margin-left:32.25px;">
        <li style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
                  style="color:#010101;">Dispute Resolution&nbsp;</span></strong><span style="color:#010101;">- Any
            dispute, claim or controversy&nbsp;which
            may
            arise out of this&nbsp;Agreement&nbsp;which cannot be amicably settled between the parties shall be finally
            settled by binding FINRA arbitration</span><span style="color:#3B3B3B;">.&nbsp;</span><span
                style="color:#010101;">By accepting this Agreement, the User agrees as
            follows</span><span style="color:#3B3B3B;">:</span></li>
      </ol>
    </div>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.5pt;'><span
            style="">&nbsp;</span></p>
    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:0cm;margin-right:5.3pt;margin-left:78.3pt;text-align:justify;text-indent:-42.45pt;'>
      <strong><span style="color:#010101;">(A)&nbsp;ALL&nbsp;PARTIES&nbsp;TO&nbsp;THIS
          AGREEMENT&nbsp;ARE&nbsp;GIVING UP&nbsp;THE&nbsp;RIGHT TO&nbsp;SUE&nbsp;EACH&nbsp;OTHER&nbsp;IN&nbsp;COURT,
          INCLUDING&nbsp;THE RIGHT&nbsp;TO A&nbsp;TRIAL&nbsp;BY&nbsp;JURY, EXCEPT&nbsp;AS PROVIDED&nbsp;BY THE RULES
          OF</span></strong>
    </p>
    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:0cm;margin-right:5.35pt;margin-left:78.25pt;text-align:justify;'>
      <strong><span style="color:#010101;">THE&nbsp;ARBITRATION&nbsp;FORUM&nbsp;IN&nbsp;WHICH&nbsp;A&nbsp;CLAIM
          IS&nbsp;FILED;&nbsp;(B)&nbsp;Arbitration&nbsp;awards&nbsp;are generally final and binding; a party&apos;s
          ability
          to have a court reverse or modify an arbitration award is very limited;&nbsp;(C) The ability of the
          parties&nbsp;to obtain&nbsp;documents,&nbsp;witness&nbsp;statements&nbsp;and other discovery is generally more
          limited in arbitration than in court proceedings; (D)&nbsp;The arbitrators do&nbsp;not&nbsp;have to explain
          the
          reason(s) for their award unless, in&nbsp;an&nbsp;eligible case, a joint&nbsp;request for an&nbsp;explained
          decision has&nbsp;been&nbsp;submitted by&nbsp;all&nbsp;parties to the&nbsp;panel at&nbsp;least 20 days prior
          to
          the first scheduled hearing date; (E) The panel of arbitrators&nbsp;will typically include a minority of
          arbitrators who were or are affiliated with the securities industry; (F) The rules of some arbitration forums
          may
          impose time limits for bringing a claim in arbitration. In some&nbsp;cases, a claim that is
          ineligible&nbsp;for
          arbitration&nbsp;may be brought in court;</span></strong>
    </p>
    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:0cm;margin-right:5.05pt;margin-left:78.3pt;text-align:justify;text-indent:-.1pt;'>
      <strong><span style="color:#010101;">(G) The rules of the arbitration forum in
          which
          the claim is filed, and any amendments thereto, shall be incorporated into this agreement; (H) the Parties
          agree
          that all&nbsp;controversies that&nbsp;may arise&nbsp;among the User and Dalmore Group, LLC, a&nbsp;registered
          broker&shy; dealer and member of FINRA/SIPC, their respective affiliates, and their respective officers,
          directors, employees, agents or representatives concerning any transaction or the construction, performance,
          or
          breach of this or any other agreement between the parties pertaining to securities and other property, whether
          entered into prior, on&nbsp;or subsequent to the date hereof, shall be determined by arbitration.&nbsp;The
          award
          of the arbitrators, or the majority of them, shall be final, and judgment upon the award rendered may be
          entered
          in any court, state or federal, having jurisdiction.</span></strong>
    </p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.35pt;'>
      <strong><span style="">&nbsp;</span></strong>
    </p>
    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;margin-right:5.85pt;margin-left:42.7pt;text-align:justify;text-indent:-18.15pt;'>
      <span style="color:#010101;">14. <strong>Validity&nbsp;</strong>-&nbsp;In&nbsp;case any term of this
        Agreement&nbsp;will be held invalid, illegal or&nbsp;unenforceable</span><span
            style="color:#2B2B2B;">,&nbsp;</span><span style="color:#010101;">in whole or&nbsp;in&nbsp;part, the
        validity of
        any&nbsp;of the other terms of this Agreement will&nbsp;not&nbsp;in&nbsp;any way be affected thereby.</span>
    </p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:.05pt;'><span
            style="">&nbsp;</span></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-left:6.2pt;text-indent:-.05pt;'>
      <strong><span style="color:#010101;">This Agreement&nbsp;and all other
          referenced&nbsp;terms and agreements&nbsp;shall inure to the benefit of and be binding upon the respective
          heirs,
          legal representatives,&nbsp;successors or assigns of the parties.</span></strong>
    </p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
              style="">&nbsp;</span></strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;'><strong><span
              style="">&nbsp;</span></strong></p>
    <p
       style='margin:0cm;margin-bottom:.0001pt;font-family:"Arial",sans-serif;margin-top:6.35pt;margin-right:0cm;margin-left:6.2pt;text-indent:-.05pt;'>
      <strong><span style="color:#010101;">By pressing the button
          entitled&nbsp;</span></strong><u><span
              style='font-family:"Times New Roman",serif;color:#010101;'>I&nbsp;</span></u><span
            style='font-family:"Times New Roman",serif;color:#010101;'>&nbsp;</span><strong><u><span
                style="color:#010101;">Understand</span></u></strong><strong><span style="color:#010101;">, you
          acknowledge and agree to the terms of
          referenced
          and contained herein.</span></strong>
    </p>

  </div>
</app-page>
