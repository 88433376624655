import { isPlatformBrowser } from '@angular/common';
import {
  ElementRef,
  Inject,
  Injectable,
  NgZone,
  PLATFORM_ID,
} from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ElementSizeService {
  constructor(@Inject(PLATFORM_ID) platformId: Object, private ngZone: NgZone) {
    if (!isPlatformBrowser(platformId)) {
      return;
    }
  }
  watchWidth(elementRef: ElementRef): Subject<number> {
    const subject = new Subject<number>();
    let lastWidth: number;

    this.ngZone.runOutsideAngular(() => {
      const checkWidth = () => {
        const width = elementRef.nativeElement.offsetWidth;
        if (lastWidth !== width) {
          this.ngZone.run(() => {
            lastWidth = width;
            subject.next(width);
          });
        }
      };

      // Initial check
      checkWidth();

      // Listen to resize event
      const resizeObserver = new ResizeObserver(checkWidth);
      resizeObserver.observe(elementRef.nativeElement);

      // Cleanup
      return () => {
        resizeObserver.disconnect();
      };
    });

    return subject;
  }
}
