import _ from 'underscore';
import {
  BehaviorSubject,
  OperatorFunction,
  Subject,
  interval,
  throwError,
} from 'rxjs';
import {
  HttpDownloadProgressEvent,
  HttpEventType,
  HttpUploadProgressEvent,
} from '@angular/common/http';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';

export enum LoadingState {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export interface LoadingProgressState {
  state: LoadingState;
  // Loading progress, range from 0.0 to 100.0
  progress: number;
}

export interface LoadingProgressorOptions {
  estimateLoadingTime?: number;
  successBackDelay?: number;
  multithread?: boolean;
}

const DEFAULT_LOADING_PROGRESSOR_OPTIONS: LoadingProgressorOptions = {
  estimateLoadingTime: 200,
  successBackDelay: null,
  multithread: false,
};

// TODO(ARK7-1064): concurrency support.
export class LoadingProgressor {
  progress: BehaviorSubject<LoadingProgressState> = new BehaviorSubject({
    state: LoadingState.IDLE,
    progress: 0,
  });

  private threadCount = 0;

  private startThreads() {
    if (this.options.multithread) {
      this.threadCount++;
    }
  }

  private stopThreads(): boolean {
    return !this.options.multithread || --this.threadCount <= 0;
  }

  constructor(
    private options: LoadingProgressorOptions = {
      estimateLoadingTime: 200,
      successBackDelay: 200,
      multithread: false,
    },
  ) {
    _.defaults(this.options, DEFAULT_LOADING_PROGRESSOR_OPTIONS);
  }

  get isLoading(): boolean {
    return (
      this.progress.value && this.progress.value.state === LoadingState.LOADING
    );
  }

  get isSuccess(): boolean {
    return (
      this.progress.value && this.progress.value.state === LoadingState.SUCCESS
    );
  }

  get isFailed(): boolean {
    return (
      this.progress.value && this.progress.value.state === LoadingState.FAILURE
    );
  }

  get isIdle(): boolean {
    return (
      this.progress.value && this.progress.value.state === LoadingState.IDLE
    );
  }

  reset() {
    this.progress.next({
      state: LoadingState.IDLE,
      progress: 0,
    });
  }

  private emit(state: LoadingProgressState) {
    this.progress.next(_.defaults(state, this.progress.value));

    if (
      this.options.successBackDelay != null &&
      state.state === LoadingState.SUCCESS
    ) {
      _.delay(() => {
        if (this.progress.value.state === LoadingState.SUCCESS) {
          this.emit({ state: LoadingState.IDLE, progress: 0 });
        }
      }, this.options.successBackDelay);
    }
  }

  setLoadingProgress(progress: number) {
    this.emit({
      state: LoadingState.LOADING,
      progress,
    });
  }

  markSuccess() {
    this.emit({ state: LoadingState.SUCCESS, progress: 100 });
  }

  async watchAsyncCall<T extends () => any>(func: T): Promise<ReturnType<T>> {
    this.startThreads();
    this.emit({ state: LoadingState.LOADING, progress: 0 });

    let hasError = false;

    const stopper = new Subject<void>();
    interval(100)
      .pipe(takeUntil(stopper))
      .subscribe((x) => {
        const current = x * 100;
        const progress = Math.min(
          0.85,
          current / this.options.estimateLoadingTime,
        );

        this.emit({ state: LoadingState.LOADING, progress: progress * 100 });
      });

    try {
      const ret = await func();
      return ret;
    } catch (error) {
      hasError = true;
      throw error;
    } finally {
      const allDone = this.stopThreads();

      if (allDone) {
        stopper.next();
        stopper.complete();

        this.emit({
          state: hasError ? LoadingState.FAILURE : LoadingState.SUCCESS,
          progress: 100,
        });
      } else {
        this.emit({
          state: hasError ? LoadingState.FAILURE : LoadingState.LOADING,
          progress: 90,
        });
      }
    }
  }

  watchHttpRequestObservable<T>(): [
    OperatorFunction<T, T>,
    OperatorFunction<T, T>,
    OperatorFunction<T, T>,
  ] {
    this.startThreads();
    this.emit({ state: LoadingState.LOADING, progress: 0 });

    return [
      map((x: any) => {
        switch (x.type) {
          case HttpEventType.UploadProgress:
            const e = x as HttpUploadProgressEvent;
            this.emit({
              state: LoadingState.LOADING,
              progress: (e.loaded / e.total) * 40,
            });
            return null;

          case HttpEventType.DownloadProgress:
            const e2 = x as HttpDownloadProgressEvent;
            this.emit({
              state: LoadingState.LOADING,
              progress: 60 + (e2.loaded / e2.total) * 40,
            });
            return null;

          case HttpEventType.Response:
            if (this.stopThreads()) {
              this.emit({
                state: LoadingState.SUCCESS,
                progress: 100,
              });
            }
            return x;
        }
      }),
      filter((x) => x != null),
      catchError((x) => {
        this.stopThreads();
        this.emit({
          state: LoadingState.FAILURE,
          progress: 100,
        });
        return throwError(x);
      }),
    ];
  }

  watchObserver<T>(): [OperatorFunction<T, T>, OperatorFunction<T, T>] {
    this.startThreads();
    this.emit({ state: LoadingState.LOADING, progress: 0 });

    return [
      tap(() => {
        if (this.stopThreads()) {
          this.emit({
            state: LoadingState.SUCCESS,
            progress: 100,
          });
        }
      }),

      catchError((x) => {
        this.stopThreads();
        this.emit({
          state: LoadingState.FAILURE,
          progress: 100,
        });
        return throwError(x);
      }),
    ];
  }
}
