<div [ngClass]="{
    'homepage-typography': !md,
    'mobile-typography': sm
  }">

  <div *ngIf="showDownloadAppBanner"
       class="app-download-banner v2-h-7 flex-row-between-center v2-top-0 fixed v2-padding-1-h z-index-highest full-width">
    <div class="flex-row-start-center">
      <span class="color-blue-grey-4 v2-margin-1-r pointer"
            (click)="closeDownloadAppBanner()">&times;</span>
      <div
           class="logo-square-outer v2-h-5 v2-w-5 v2-radius-16px center-inner text-white-high-emphasis bg-500 v2-margin-1-r">
        <span class="icon-square-logo-weight-2 h2"></span>
      </div>
      <span class="description label-1 color-green-grey-1">Ark7 - Your rental portfolio in<br /> your pocket</span>
    </div>
    <a color="primary"
       class="h5"
       [routerLink]="['/download-link']"
       [queryParams]="trackingService.downloadQueryParams">OPEN</a>
  </div>

  <div *ngIf="showPromoBanner"
       class="v2-h-64px v2-top-0 fixed z-index-highest full-width text-center flex-row-center-center label-1 bg-blue-grey-9 text-white-high-emphasis">
    <img src="/assets/v2-icons/time.svg"
         class="v2-margin-8px-r" /> Limited time offer:&nbsp;<a routerLink="/a/sign-up"
       class="text-primary">{{ promoBannerMessage }}</a>

    <span class="color-green-grey-2 pointer v2-right-0 body-1 absolute"
          [class.hide]="md"
          [ngClass]="v2PaddingH"
          (click)="closePromoBanner()">&times;</span>
  </div>

  <v2-header [topClass]="showDownloadAppBanner ? 'v2-top-7' : showPromoBanner  ? 'v2-top-64px' : 'v2-top-0'"></v2-header>
  <div [ngClass]="[
      'bg-white flex-column-center-center',
      showDownloadAppBanner ? 'v2-padding-12-t' : 'v2-padding-5-t' 
    ]">


    <app-ark7-intro></app-ark7-intro>
    <v2-home-stats class="v2-max-w-120 full-width"
                   [ngClass]="[ sm ? 'v2-margin-64px-t' : 'v2-margin-8-t']"></v2-home-stats>
  </div>

  <v2-featured-properties [carriers]="carriers"></v2-featured-properties>

  <div [ngClass]="[
      'v2-padding-6-v bg-blue-grey-1 flex-column-center-center',
      md ? 'v2-row-gap-64px' : 'v2-row-gap-9'
    ]">
    <v2-home-feature-block class="full-width"
                           title="Earn passive income"
                           [reverse]="true"
                           [features]="[
        'Receive monthly distribution straight to your account.',
        'Your data and transaction are safeguarded.'
      ]">
      <img src="/assets/v2-passive-income.svg"
           alt="Earn passive income"
           class="v2-w-96pct" />
    </v2-home-feature-block>
    <v2-home-feature-block class="full-width"
                           title="Sell or Hold for Appreciation"
                           learnMoreLink="https://ark7.com/faq?faqId=66573fe5c98b64d91bc9b34f"
                           [features]="[
        'The properties are typically held long-term; ride along for real estate assets appreciation.',
        'Investors have full flexibility to sell the shares after a minimum holding period.',
      ]">
      <img src="/assets/v2-save.svg"
           alt="Sell or Hold for Appreciation"
           class="v2-w-96pct" />
    </v2-home-feature-block>
    <v2-home-feature-block class="full-width"
                           [reverse]="true"
                           title="Access Operation Transparency"
                           learnMoreLink="https://ark7.com/faq?faqId=66573f8bc98b64d91bc9b269"
                           [features]="[
        'Complete legal and financial disclosure accessible 24/7 and readily searchable.',
        'No hidden fees, no surprise ever.
        Ark7 holds a small percentage of funds to cover property sourcing and management expenses.'
      ]">
      <img src="/assets/v2-easy.svg"
           alt="Access Operation Transparency"
           class="v2-w-96pct" />
    </v2-home-feature-block>
    <create-account class="full-width center-inner"
                    [ngClass]="[v2PaddingH]"></create-account>
    <v2-home-feature-block class="full-width"
                           title="We’ve Got You Covered"
                           learnMoreLink="https://ark7.com/faq?faqId=665751ecc98b64d91bca70b1"
                           [features]="[
        'Our hybrid approach of Artificial Intelligence plus local expertise are constantly
         hand-picking best investment opportunities, automate sourcing, leasing and property management.',
        'Extra savings and tax benefits are passed on to investors.'
      ]">
      <img src="/assets/v2-low-risk.svg"
           class="v2-w-96pct"
           alt="We’ve Got You Covered" />
    </v2-home-feature-block>
  </div>
  <v2-featured-users></v2-featured-users>
  <v2-why-ark7 class="flex-column-center-center v2-col-gap-16px"></v2-why-ark7>
  <v2-home-featured-in class="center-inner full-width bg-blue-grey-1"
                       [ngClass]="[ sm ? 'v2-padding-64px-v' : 'v2-padding-12-v' ]"></v2-home-featured-in>
  <v2-build-wealth class="flex-column-center-center v2-col-gap-16px"></v2-build-wealth>

  <div [ngClass]="['full-width bg-white',
                  xs? 'v2-h-16 v2-margin-2-t-n background-vector-mobile' : 'v2-h-30 background-vector']"></div>
  <!-- <div class="v2-padding-8-v bg-white flex-column-center-center">
    <v2-home-feature-poster class="v2-margin-6-t" headline="Your rental portfolio in your pocket"
                            [points]="[
        'Introducing the all-new Ark7 App. Hands-down the simplest way to invest in real estate, period.'
      ]"
                            headlineClass="headline"
                            [paddingLeft]="md ? '' : 'v2-padding-8-l'"
                            imgSrc="/assets/v2-phones.webp"
                            imgAlt="Ark7 Mobile App"
                            [colGap]="sm? 'v2-col-gap-7':'v2-col-gap-4'">
      <button [ngClass]="[
          'flex-row-center-center v2-padding-24px-h  v2-radius-10px border-300 v2-border-0px text-white-high-emphasis h4 bg-500 pointer',
          sm ? 'v2-w-25 v2-padding-12px-v' : 'v2-margin-2-r v2-padding-16px-v'
        ]"
              routerLink="/download-link"
              [queryParams]="downloadQueryParams">
        <img src="/assets/v2-icons/Download.svg"
             alt="download"
             class="svg-white v2-margin-8px-r" />
        Get the App
      </button>
      <button [ngClass]="[
          'flex-row-center-center v2-padding-24px-h  v2-radius-10px border-300 v2-border-1px text-primary h4 bg-white pointer',
          sm ? 'v2-w-25 v2-margin-2-b v2-padding-12px-v' : 'v2-padding-16px-v'
        ]"
              routerLink="/app">
        <img src="/assets/v2-icons/Play.svg"
             alt="play video"
             [ngClass]="[
            'svg-primary-green-1 v2-margin-8px-r',
          ]" />
        Learn More
      </button>
    </v2-home-feature-poster>
  </div> -->
  <!-- <div [ngClass]="[
      'full-width bg-blue-grey-1 flex-column-between-center',
      'v2-padding-8-t v2-padding-12-b'
    ]">
    <div [ngClass]="[
        'flex-column-between-center v2-max-w-90 text-center color-green-grey-1 full-width',
        v2PaddingH
      ]">
      <div class="h1 v2-margin-3-t">Our mission is to simplify the complicated</div>
      <div class="h1 v2-margin-3-b">when it comes to real estate investment.</div>
      <div class="h4 v2-margin-3-b text-justify text-last-center color-green-grey-2">
        We look for high-yield properties in growing markets that are most
        likely to have stable returns. We strategically add value to the
        properties in order to optimize for returns. Finally, tenant
        satisfaction is a top priority, and we have strict guidelines to ensure
        top-notch property management.
      </div>
      <a routerLink="/a/sign-up">
        <button [ngClass]="[
            'flex-row-center-center v2-padding-24px-h v2-padding-16px-v v2-radius-10px v2-border-0px text-white-high-emphasis h5 bg-500 pointer v2-margin-2-t',
            sm ? 'v2-w-25' : ' ',
          ]">
          Get Started
        </button>
      </a>
    </div>
  </div> -->
  <div [ngClass]="[
      'flex-column-center-center full-width',
      xs ? 'v2-margin-8-t-n v2-margin-15-b-n' : 'v2-margin-13-v-n',
      v2PaddingH
    ]">
    <div [ngClass]="[
        'flex-column-around-center full-width v2-max-w-110 v2-padding-2-h bg-white text-center z-index-high',
        xs ? 'v2-h-30 v2-radius-14px v2-padding-32px-v' : sm? 'v2-h-26 v2-radius-14px v2-padding-40px-v' : 'v2-h-26 v2-radius-28px v2-padding-5-v'
      ]">
      <div class="h3 color-green-grey-1">Subscribe to Our Fractional Newsletter</div>
      <div [ngClass]="['body-1 color-green-grey-2',
                      sm? 'v2-margin-1-t' : '']">Receive rental properties updates, news and market trends.</div>
      <v2-subscription [ngClass]="[sm? 'v2-margin-32px-t' : 'v2-margin-40px-t']"></v2-subscription>
    </div>
  </div>
  <v2-page-footer [showTermsApply]="showPromoMessage"
                  [showDisclaimer]="showDisclaimer"
                  maxWidth="v2-max-w-110"></v2-page-footer>
</div>
