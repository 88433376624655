import { A7Router } from '@ark7/utils';
import { Component, OnInit } from '@angular/core';

import { UserService } from './services/user.service';

/**
 * Extend app.component from this base class.
 */
@Component({
  selector: 'a7-base-app-component',
  template: '<div></div>',
})
export class A7BaseAppComponent<T> implements OnInit {
  user: T;

  constructor(protected userService: UserService, protected router: A7Router) {
    // It's safe to subscribe directly since app.component always exists.
    userService.userChange$.subscribe((user) => (this.user = user));
  }

  /**
   * A indicator whether it's safe to display the components which may reply on
   * the user object.
   */
  get display(): boolean {
    return this.user != null || this.router.url.startsWith('/a/');
  }

  async ngOnInit() {
    try {
      await this.userService.fetch();
    } catch (err) {
      if (err.status === 401 && err.body.meta.tos === true) {
        if (this.router.url !== '/a/sign-out') {
          this.router.navigate(['/a/accept-tos']);
        } else {
          throw err;
        }
      }
    }
  }
}
