<div class="full-width flex-row-between-center v2-col-gap-2">
  <v2-offering-logo [status]="carrier.isSecondaryMarket ? 'TRADING' : 'OFFERING'"></v2-offering-logo>
  <span class="flex-scale"></span>
  <a7-social-share headingSize='h5'
                   [url]="shareUrl"
                   [carrier]="carrier">
  </a7-social-share>
  <ng-content></ng-content>
</div>
<div [ngClass]="[
    sm || forceSm ? 'h2' : 'display-1',
    'flex-row-start-center full-width v2-margin-8px-t'
  ]">
  <span>{{ carrier.nickname }}</span>
  <img *ngIf="showPlus"
       [ngClass]="['v2-w-4 v2-margin-1-l']"
       src="/assets/v2-a7p-tile.svg"
       cTip="Accredited investors only" />
</div>
<div [ngClass]="[
    sm || forceSm ? 'label-2' : 'body-2',
    'color-green-grey-2 flex-row-between-center full-width flex-wrap v2-margin-4px-t'
  ]">
  <div class="flex-row-start-center v2-margin-4px-l-n">
    <img src="/assets/v2-map-loc.svg" />
    {{ streetAddressOrCity }}· {{ carrier.address?.state }} · {{ carrier.address?.zipCode }}
  </div>
  <div>
    {{ carrier.info.displayTypeShort }} · {{ carrier.info.displayUnit }} ·
    {{ carrier.info.floorSizeInSF | number }} sqft ·
    {{ carrier.info.builtInYear }} built
  </div>
</div>

<div [ngClass]="[
    'flex-row-between-center full-width flex-wrap v2-col-gap-1',
    sm || forceSm ? 'v2-margin-2-t' : 'v2-margin-32px-t'
  ]">
  <v2-ab-block *ngIf="carrier.isSecondaryMarket"
               [aText]="carrier.marketCap.format({ precision: 0 })"
               [aClass]="sm || forceSm ? 'h4' : 'h1'"
               bText="Market Cap">
  </v2-ab-block>
  <v2-ab-block *ngIf="!carrier.isSecondaryMarket"
               [aText]="
      carrier.purchasePrice.format({
        precision: 0
      })
    "
               [aClass]="sm || forceSm ? 'h4' : 'h1'"
               bText="Purchase Price">
  </v2-ab-block>

  <v2-ab-block [aText]="carrier.monthlyRent | money: '.0-2'"
               [aClass]="sm || forceSm ? 'h4' : 'h1'"
               [decimalPlaceClass]="sm || forceSm ? 'h4' : 'h1'"
               bText="Monthly Rent">
  </v2-ab-block>


  <a7-carrier-distribution *ngIf="showDistribution"
                           [carrier]="carrier"
                           [mobile]="sm || forceSm"></a7-carrier-distribution>

  <ng-container *ngIf="
      $any(carrier.stats).totalInvestors >= 0;
      then investors;
      else appreciation
    ">
  </ng-container>
</div>

<div *ngIf="hasDividendBoost"
     class="dividend-boost-detail full-width text-center v2-padding-1 v2-margin-1-t">
  <div class="footer-2 color-green-grey-3">
    <span class="label-1 color-green-grey-1">{{carrier.dividendRate | percent: '.0-2'}}</span> Base Distribution
    + <span class="label-1 color-green-grey-1">{{dividendBoostRate | percent: '.0-2'}}</span> Boost</div>
  <div class="footer-2 color-green-grey-2">Earn an exclusive dividend boost on your new orders until
    {{dividendBoostEndDate}}.</div>
</div>

<div [ngClass]="[
    'flex-column-start-start v2-row-gap-1',
    sm || forceSm ? 'v2-margin-2-t' : 'v2-margin-4-t'
  ]">
  <div *ngFor="
      let fi of [
        ['propertyMarket', 'v2-Market'],
        ['whyWeInvested', 'v2-Whyme'],
        ['returnStrategy', 'v2-Return']
      ]
    ">
    <ng-container *ngTemplateOutlet="
        introMarket;
        context: {
          field: fi[0],
          icon: fi[1]
        }
      ">
    </ng-container>
  </div>
</div>

<ng-template #investors>
  <v2-abcd-block [cText]="$any(carrier.stats).totalInvestors > 9 ? ($any(carrier.stats).totalInvestors | number) : '-'"
                 [cClass]="sm || forceSm ? 'h4' : 'h1'"
                 [decimalPlaceClass]="sm || forceSm ? 'h4' : 'h1'"
                 dText="Investors"
                 dClass="label-2 color-green-grey-3">
  </v2-abcd-block>
</ng-template>

<ng-template #appreciation>
  <v2-abcd-block *ngIf="carrier.isRegDOffering"
                 [cText]="carrier.appreciation | percent: '.0-2'"
                 [cClass]="sm || forceSm ? 'h4' : 'h1'"
                 [decimalPlaceClass]="sm || forceSm ? 'h4' : 'h1'"
                 dText="Appreciation"
                 dClass="label-2 color-green-grey-3"
                 toolTip="Estimated property value gain shareholders receive when the property or shares of the property is sold.">
  </v2-abcd-block>
</ng-template>

<ng-template #introMarket
             let-field="field"
             let-icon="icon"
             let-tValue="tValue"
             let-index="index">
  <div [ngClass]="['flex-row-start-start full-width']">
    <img [src]="'/assets/' + icon + '.svg'"
         class="v2-w-32px v2-h-32px v2-margin-16px-r" />
    <div class="flex-column-start-start">
      <div [ngClass]="[sm || forceSm ? 'body-2' : 'body-1', 'color-green-grey-3']">
        {{ field | case: 'title' }}
      </div>

      <div [ngClass]="[sm || forceSm ? 'body-2' : 'h4', 'v2-margin-4px-t']">
        {{ introductionContent(field) }}
      </div>
    </div>
  </div>
</ng-template>
