<div [ngClass]="[
    'full-width',
    v2PaddingH,
  ]">
  <div [ngClass]="[
    'bg-blue-grey-1 full-width v2-radius-24px',
    sm ? 'flex-column-between-center' : 'flex-row-around-center v2-h-13'
  ]">
    <div *ngFor="let stat of stats"
         class="flex-factor-1 flex-column-center-center relative"
         [ngClass]="[sm ? 'v2-min-h-9 v2-min-w-12 border-bottom' : 'border-right']">
      <ng-lottie *ngIf="stat.videoId != null"
                 class="full-size absolute v2-top-0 v2-left-0 pointer"
                 (click)="onPlayVideo(stat.videoId)"
                 [options]="bgOptions">
      </ng-lottie>
      <span class="color-green-grey-1 h1">{{ stat.value }}</span>
      <div class="body-2 color-green-grey-2">{{ stat.label }}</div>
      <div *ngIf="stat.videoId != null"
           class="pointer"
           (click)="onPlayVideo(stat.videoId)">
        <img src="/assets/play.svg"
             alt="play video"
             class="vertical-align-middle v2-margin-4px-r" />
        <span class="footer-1 text-primary">Watch our celebration video</span>
      </div>
    </div>
  </div>
  <div class="v2-margin-1-t center-inner color-green-grey-4 label-2">— Figures as of Jun 2024 —</div>
</div>
