import { Pipe, PipeTransform } from '@angular/core';
import { UserRef } from '@ark7/common-models';

@Pipe({
  name: 'asUserRef',
})
export class AsUserRefPipe implements PipeTransform {
  transform(value: any): UserRef {
    return value;
  }
}
