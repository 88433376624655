<div *ngIf="useSectionLayout"
     [a7LayoutSection]="sectionName"
     [a7LayoutSectionDelta]="-300"
     [ngClass]="[topMargin]">
  <ng-container [ngTemplateOutlet]="content"> </ng-container>
</div>

<div *ngIf="!useSectionLayout"
     [ngClass]="[topMargin]">
  <ng-container [ngTemplateOutlet]="content"> </ng-container>
</div>

<ng-template #content>
  <div [ngClass]="[
      'v2-padding-2-l v2-padding-20px-v bg-blue-grey-1 v2-radius-8px flex-row-start-center v2-col-gap-1',
      xs ? 'v2-margin-2-h-n' : ''
    ]">
    <img [src]="'/assets/tplus/' + sectionName + '.svg'"
         class="v2-w-32px v2-h-32px" />
    <div [ngClass]="xs ? 'h2' : 'h1'">{{ sectionName }}</div>
  </div>

  <div [ngClass]="['full-width ', xs ? '' : 'v2-padding-2-l']">
    <ng-content></ng-content>
  </div>
</ng-template>
