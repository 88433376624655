import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@ark7/core-business-models';
import { GtagEventName, GtagService } from '@ark7/layout';
import { LoadingProgressor } from '@ark7/loading';
import {
  IpInfoService,
  Logger,
  SignUpErrorCode,
  StatsResource,
  TrackingService,
  UserService,
  VerifiedResource,
} from '@ark7/resources-common';
import { A7Router, Debug } from '@ark7/utils';
import debug from 'debug';
import { LocalStorage } from 'ngx-webstorage';
import _ from 'underscore';

import { UserRedirectComponent } from '../../user-redirect-component';

const d = debug('a7-ui-auth:SignUpFormComponent2');

enum SignUpFormStage {
  VERIFIED_IMPORT = 0,
  EMAIL,
  NAME,
  ALREADY_REGISTERED,
  INTERNAL_SERVER_ERROR,
  NOT_AVAILABLE,
}

@Component({
  selector: 'v2-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
})
export class SignUpFormComponent2
  extends UserRedirectComponent
  implements OnChanges, OnInit {
  @Input() formTitle: string = 'Begin Your Ark7 Journey';

  @Input() social: boolean = false;

  @Input() cta: string = 'Sign Up';
  @Input() customizedRegistrationResult: boolean = false;

  @Input() email: string;

  @LocalStorage() newUserName: { first: string; last: string };

  @Input() app: 'renters' | 'main_site' = 'main_site';
  form: UntypedFormGroup;
  form2: UntypedFormGroup;
  signingUp: boolean = false;
  stage: SignUpFormStage = SignUpFormStage.EMAIL;

  @Input() disableAutoFocus = false;

  @ViewChild('emailInputField') emailInputField: ElementRef;
  @ViewChild('passwordInputField') passwordInputField: ElementRef;

  progressor = new LoadingProgressor();
  constructor(
    private router: A7Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private statsResource: StatsResource,
    private logger: Logger,
    private gtag: GtagService,
    private trackingService: TrackingService,
    private verifiedResource: VerifiedResource,
    ipInfo: IpInfoService,
  ) {
    super(userService, router);
    ipInfo.isSensitiveCountry().subscribe((sensitive) => {
      if (sensitive) {
        this.stage = SignUpFormStage.NOT_AVAILABLE;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.email && changes.email.currentValue && this.form) {
      const email = changes.email.currentValue;
      this.form.controls.email.setValue(email);
      this.form.controls.email.disable();
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          //model-mongoose/src/schemas/email.ts
          Validators.pattern(
            /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/,
          ),
        ],
        this.validateEmailNotTaken.bind(this),
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          // Validators.pattern(/[$^.*[\]{}()"!@#%&/,\\':;|_~`-]/),
          Validators.pattern(/[0-9]/),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[a-z]/),
        ],
      ],
    });

    this.form2 = this.fb.group({
      first: ['', Validators.required],
      last: ['', Validators.required],
    });

    this.safeSubscribe(this.route.queryParams, (params) => {
      if (params.email && !this.form.controls.email.value) {
        this.form.controls.email.setValue(params.email);
      }
      if (params.sharedCredentialsUuid) {
        this.progressor.watchAsyncCall(() =>
          this.importVerifiedUser(params.sharedCredentialsUuid),
        );
      }
    });

    if (!this.disableAutoFocus) {
      _.delay(() => {
        if (this.emailInputField) {
          if (this.isValidEmail(this.emailInputField.nativeElement.value)) {
            this.passwordInputField?.nativeElement?.focus();
          } else {
            this.emailInputField.nativeElement.focus();
          }
        }
      }, 400);
    }
  }

  async importVerifiedUser(uuid: string) {
    this.stage = SignUpFormStage.VERIFIED_IMPORT;
    try {
      const importResult = await this.verifiedResource.importUser({
        uuid,
      });
      if (importResult.existingUser) {
        this.stage = SignUpFormStage.ALREADY_REGISTERED;
      } else {
        const sub = this.form.statusChanges.subscribe((s) => {
          d(`form state ${s}, error ${this.form.errors}`);
          if (s === 'VALID') {
            this.signup(true);
            sub.unsubscribe();
          }

          /**
           * This is only happening when user email is in Cognito but not in our Record.
           */
          if (
            this.emailControl.errors?.['checkEmail']?.message?.includes(
              'already registered',
            )
          ) {
            this.stage = SignUpFormStage.ALREADY_REGISTERED;
          }
        });
        this.form.controls.email.setValue(importResult.email);
        this.form.controls.password.setValue(generateRandomPassword());
      }
    } catch (e) {
      this.stage = SignUpFormStage.EMAIL;
    }
  }

  get emailControl() {
    return this.form.controls.email;
  }

  get passwordControl() {
    return this.form.controls.password;
  }

  isValidEmail(value: string): boolean {
    // email regex taken from http://emailregex.com/
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(value);
  }

  @Debug({ d })
  async validateEmailNotTaken(control: AbstractControl): Promise<any> {
    try {
      if (!this.isValidEmail(control.value)) {
        return null;
      }

      const result = await this.statsResource.checkEmail({
        email: control.value,
        app: this.app,
      });

      return result.ok ? null : { checkEmail: result };
    } catch (error) {
      // Unblocking the server failure.
      return null;
    }
  }

  @Debug({ d })
  async signup(skipName = false) {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.warn('form invalid' + this.form.errors);
      return;
    }

    this.signingUp = true;

    try {
      const email = this.form.controls.email.value.trim();

      const password = this.form.controls.password.value.trim();

      await this.userService.signUp({
        email,
        password,
        mainSiteTOS: this.app === 'main_site',
        rentersTOS: this.app === 'renters',
        referrer: this.trackingService.referrer,
        trackingCode: this.trackingService.trackingCode,
        leadCode: this.trackingService.leadCode,
      });

      // emit sign up converesion for google ads
      if (this.gtag.isAdsTrackingEnabled) {
        this.gtag.sendEvent(GtagEventName.SIGN_UP, {
          send_to: this.gtag.adsConversionSignUp,
        });
      }

      // emit wellput pixels
      this.trackingService.emitWellputPixel('a', 'register');

      this.trackingService.clearReferral();

      // unset redirectTo to avoid login auto redirect
      this.redirectTo = null;
      try {
        await this.userService.signIn({
          email,
          password,
        });
      } catch (e) {}

      // verification email sent
      if (skipName) {
        await this.emailAndIn();
      } else {
        this.stage = SignUpFormStage.NAME;
      }
    } catch (e) {
      console.error('sign up error: ', e);
      switch (e.code) {
        case SignUpErrorCode.USERNAME_EXISTS:
          this.stage = SignUpFormStage.ALREADY_REGISTERED;
          break;
        case SignUpErrorCode.INVALID_PASSWORD:
          const reason =
            {
              'Password did not conform with policy: Password not long enough':
                'Password not long enough',
              'Password did not conform with policy: Password must have symbol characters':
                'Password must have symbol characters',
            }[e.message] || 'Password is invalid';

          this.form.controls.password.setErrors({
            invalid: reason,
          });
          return;
        default:
          this.logger.error('User sign up failed', {
            error: e,
          });
          this.stage = SignUpFormStage.INTERNAL_SERVER_ERROR;
      }
    } finally {
      this.signingUp = false;
    }
  }

  @Debug({ d })
  async login() {
    this.signingUp = true;

    // name is optional now for sign up
    if (
      !_.isEmpty(this.form2.controls.first.value) ||
      !_.isEmpty(this.form2.controls.last.value)
    ) {
      const user = this.userService.currentUser as User;
      try {
        await user.profiles.$update({
          name: {
            first: this.form2.controls.first.value.trim(),
            last: this.form2.controls.last.value.trim(),
          },
        });
      } catch (_ignore) {}
    }
    await this.emailAndIn();

    this.signingUp = false;
  }

  @Debug({ d })
  reset() {
    // TODO: This needs to be called.
    this.form.patchValue({
      email: '',
      password: '',
      confirmPassword: '',
    });

    this.form.markAsUntouched();

    this.signingUp = false;
    // move to sign up tab
    this.stage = SignUpFormStage.EMAIL;
  }

  private async emailAndIn() {
    try {
      await this.userService.currentUser.sendEmailVerification();
    } catch (_ignore) {}

    this.router.navigate(['/']);
  }
}

function generateRandomPassword(length: number = 12): string {
  if (length < 4) {
    throw new Error('Password length must be at least 4 for diversity.');
  }

  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialCharacters = '!@#$%^&*()-_=+';

  const getRandomCharacter = (chars: string) => {
    return chars[Math.floor(Math.random() * chars.length)];
  };

  // Ensure at least one character from each category
  let password =
    getRandomCharacter(upperCase) +
    getRandomCharacter(lowerCase) +
    getRandomCharacter(numbers) +
    getRandomCharacter(specialCharacters);

  const allCharacters = upperCase + lowerCase + numbers + specialCharacters;
  for (let i = 4; i < length; i++) {
    password += getRandomCharacter(allCharacters);
  }

  // Shuffle the characters so that the fixed characters are not always at the beginning
  return password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');
}
