import { A7Router, SubscribeComponent } from '@ark7/utils';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import {
  AfterViewInit,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Directive({
  selector: '[a7DialogBaseContents]',
})
export class A7DialogBaseContentsDirective {
  constructor(public template: TemplateRef<{ $implicit: any }>) {}
}

@Directive({
  selector: '[a7DialogBaseActions]',
})
export class A7DialogBaseActionsDirective {
  constructor(public template: TemplateRef<{ $implicit: any }>) {}
}

@Component({
  selector: 'a7-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.scss'],
})
export class DialogBaseComponent
  extends SubscribeComponent
  implements AfterViewInit, OnDestroy {
  @ViewChild('dialogBase') dialogBase: TemplateRef<any>;

  @ContentChild(A7DialogBaseContentsDirective)
  contents: A7DialogBaseContentsDirective;
  /**
   * Use template allow proper response to [mat-dialog-close]="true"
   */
  @ContentChild(A7DialogBaseActionsDirective)
  actions: A7DialogBaseActionsDirective;

  @Input() panelClass: string | string[] = ['v2-w-80', 'smooth'];
  @Input() title: string | false = '';
  @Input() titleClass = 'h5 color-green-grey-1';
  @Input() matchPathParam: string;
  @Input() matchQueryParam: string;
  @Input() popupStorageKey: string;
  @Input() autoShowTimes = 0;
  @Input() dialogBarClass = 'bg-blue-grey-1';
  @Input() actionDivider = true;
  @Input() dialogCloseButtonClass = '';

  @Output() closed = new EventEmitter<void>();
  @Output() opened = new EventEmitter<void>();

  private displayingPopup = false;

  dialogRef: MatDialogRef<any>;
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: A7Router,
    private localStorageService: LocalStorageService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.matchPathParam) {
      this.safeSubscribe(this.route.params, (params) => {
        if (this.matchPathParam === params.popup) {
          this.showDialogImpl();
        }
      });
    } else if (this.matchQueryParam) {
      this.safeSubscribe(this.route.queryParams, (params) => {
        if (this.matchQueryParam === params.popup) {
          this.showDialogImpl();
        }
      });
    }

    const localStorageKey =
      this.popupStorageKey ??
      `autoPop-${this.matchPathParam || this.matchQueryParam}`;
    let autoPop = this.localStorageService.retrieve(localStorageKey) || 0;
    if (this.autoShowTimes > autoPop) {
      this.showDialog();
      autoPop++;
      this.localStorageService.store(localStorageKey, autoPop);
    }
  }

  ngOnDestroy() {
    this.close();
  }

  showDialog() {
    // try to show by navigation if we want to encode parameters
    if (this.matchPathParam) {
      // this is needed because unlike queryParams, params are not merged
      if (this.route.snapshot.params.popup !== this.matchPathParam) {
        this.navi(this.matchPathParam);
      } else {
        this.showDialogImpl();
      }
    } else if (this.matchQueryParam) {
      this.query(this.matchQueryParam);
    } else {
      this.showDialogImpl();
    }
  }

  close() {
    this.dialogRef?.close();
  }

  private navi(param: string) {
    //manually 'merge' the params
    if (this.route.snapshot.params.popup) {
      this.router.navigateByUrl(
        this.router.url.replace(this.route.snapshot.params.popup, param),
      );
    } else {
      this.router.navigate(['popup', param], { relativeTo: this.route });
    }
  }

  private query(param: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: { popup: param },
      queryParamsHandling: 'merge',
    };
    this.router.navigate([], navigationExtras);
  }

  private showDialogImpl() {
    if (this.displayingPopup) {
      return;
    }
    this.displayingPopup = true;
    this.dialogRef = this.dialog.open(this.dialogBase, {
      panelClass: this.panelClass,
      autoFocus: true,
      restoreFocus: true,
    });

    this.opened.emit();

    this.safeSubscribe(this.dialogRef.afterClosed(), () => {
      this.displayingPopup = false;
      this.removeQueryParamOrParam();
      this.closed.emit();
    });
  }

  private removeQueryParamOrParam() {
    if (this.matchPathParam) {
      // Remove or navigate without this route parameter
      const url = this.router.url.replace(`/popup/${this.matchPathParam}`, '');
      this.router.navigateByUrl(url);
    } else if (this.matchQueryParam) {
      // Remove or navigate without this query parameter
      const params = { ...this.route.snapshot.queryParams };
      delete params.popup;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: params,
      });
    }
  }
}
