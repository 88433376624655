<div class="full-size-">
  <div class="edge flex-row-between-center">
    <div></div>
    <div class="pointer"
         (click)="close()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
    </div>
  </div>
  <div class="content">
    <iframe [width]="width"
            [height]="height"
            [src]="src | safe:'resourceUrl' "
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="1"></iframe>
  </div>
  <div class="edge">
  </div>
</div>
