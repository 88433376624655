import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'v2-home-featured-in',
  templateUrl: './home-featured-in.component.html',
  styleUrls: ['./home-featured-in.component.scss'],
})
export class FeatureInComponent extends V2BaseComponent implements OnInit {
  logos = [
    ['/assets/v2-featured-in/yahoo.svg', 'v2-max-h-28px', 'yahoo'],
    ['/assets/v2-featured-in/forbes.svg', 'v2-max-h-32px', 'forbes'],
    ['/assets/v2-featured-in/mw.svg', 'v2-max-h-16px', 'marketwatch'],
    ['/assets/v2-featured-in/mi.svg', 'v2-max-h-28px', 'markets insider'],
    ['/assets/v2-featured-in/bz.svg', 'v2-max-h-16px', 'benzinga'],
    ['/assets/v2-featured-in/tec.svg', 'v2-max-h-2', 'tech 100'],
  ];

  ngOnInit() {}

  constructor(layoutService: A7LayoutService) {
    super(layoutService);
  }
}
