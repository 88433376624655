import _ from 'underscore';
import { A7Model, Model, ModelizeOptions } from '@ark7/model';
import { from } from 'rxjs';
import { observableToPromise } from '@ark7/utils';

export interface IPaginationData<T, Q = any> {
  pageSize: number;
  page: number;
  total: number;
  data: T[];
  agg?: Q;

  loadData?: (query: PaginationQuery) => Promise<IPaginationData<T, Q>>;
  refresh?: (query: PaginationRefreshOptions) => Promise<IPaginationData<T, Q>>;
}

@A7Model({})
export class PaginationData extends Model implements IPaginationData<any> {
  pageSize: number;
  page: number;
  total: number;
  data: any[];

  // Indicate if the current pagination is under loading.
  $loading: boolean;

  static modelize(
    o: any,
    options: ModelizeOptions & { model?: typeof Model } = {},
  ) {
    const res = Model.modelize.call(this, o, options);
    if (options.model) {
      res.data = _.map(res.data, (d, idx: number) =>
        (options.model as any).modelize(d, {
          meta: {
            $parent: res,
            $isArray: true,
            $index: idx,
            $path: 'data',
            $observer: options.meta?.$observer,
            $resource: options.meta?.$resource,
            $modifier: options.meta?.$modifier,
          },
          attachFieldMetadata: true,
        } as any),
      );
    }
    return res;
  }

  async loadData<T, Q>(query: PaginationQuery): Promise<IPaginationData<T, Q>> {
    let params = _.extend(
      {},
      query.query == null ? this.$modifier : query.query,
      _.pick(query, 'page', 'size'),
    );

    if (query.query && query.replaceQuery) {
      params = _.extend({}, query.query, _.pick(query, 'page', 'size'));
    }

    if (params.size == 0) {
      delete params.size;
    }

    if (query.hiccup) {
      const d = PaginationData.modelize(
        {
          pageSize: query.size,
          page: query.page,
          total: this.total,
          data: null,
          $loading: true,
        },
        {
          meta: {
            $observer: this.$observer,
            $resource: this.$resource,
            $path: this.$path,
            $modifier: this.$modifier,
          },
        },
      );

      this.$observer.next(d);
    }

    const data = await observableToPromise(
      from(this.$resource[this.$path].call(this.$resource, params)),
    );

    if (this.$observer != null) {
      const originalObserver = (data as PaginationData).$observer;
      (data as PaginationData).$attach({ $observer: this.$observer });
      originalObserver.subscribe((x: any) => this.$observer.next(x));
      this.$observer.next(data);
    }

    return data as any;
  }

  refresh<T, Q>(
    query: PaginationRefreshOptions = {},
  ): Promise<IPaginationData<T, Q>> {
    return this.loadData(
      _.extend({}, query, {
        page: this.page,
        size: this.pageSize,
      }),
    );
  }
}

export interface PaginationQuery {
  page?: number;
  size?: number;
  hiccup?: boolean;
  query?: any;
  replaceQuery?: boolean;
}

export type PaginationRefreshOptions = Omit<PaginationQuery, 'page' | 'size'>;

export function isPaginationData<T>(p: any): p is IPaginationData<T> {
  return p.pageSize != null && p.page != null && p.total != null;
}
