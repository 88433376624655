<div [ngClass]="[
    'full-width v2-max-w-120 text-center flex-column-center-center v2-row-gap-16px',
    sm ? 'v2-padding-64px-v' : 'v2-padding-8-v',
    v2PaddingH,
  ]">
  <div class="h1 color-green-grey-1">Why Ark7?</div>
  <div class="body-1 color-green-grey-2">Ark7 has decided to side with the many. We are committed to making<br />
    real estate investment accessible to everyone. Key benefits:</div>
  <div class="full-width flex-row-center-center flex-wrap">
    <div *ngFor="let item of items"
         class="v2-margin-auto v2-padding-4-v flex-column-center-center text-center"
         [ngClass]="[sm ? 'v2-w-24' : 'v2-w-34']">
      <img class="v2-w-4 v2-h-4"
           [src]="item.icon"
           [alt]="item.label" />
      <div class="h4 v2-margin-16px-t color-green-grey-1 v2-max-w-20">{{ item.label }}</div>
    </div>
  </div>
</div>