<div [ngClass]="[
    topClass,
    'full-width z-index-highest',
    'flex-row-between-center',
    v2PaddingH,
    bgClass,
    sm ? 'v2-h-6' : 'v2-h-8',
    fixed ? 'fixed' : ''
  ]">
  <v2-logo class="flex-scale"
           [height]="sm ? '24px' : atTop ? '32px' : '36px'"
           [routerLinkStr]="routerLinkStr">
  </v2-logo>
  <ng-container *ngIf="mode === 'full'">
    <ng-container *ngIf="!sm">
      <div class="h5 v2-margin-2-h color-green-grey-3 pointer"
           routerLink="/about">
        About
      </div>
      <div class="h5 v2-margin-2-h color-green-grey-3 pointer new-badge"
           routerLink="/app">
        App
      </div>
      <a class="v2-margin-2-h h5 color-green-grey-3 pointer"
         href="https://ark7.com/blog/">
        Learn
      </a>
      <div class="v2-separator-v-2 v2-margin-2-h v2-h-2"></div>
      <div class="h5 v2-margin-2-h color-green-grey-3 pointer"
           routerLink="/a/sign-in">
        Login
      </div>
    </ng-container>
    <button [ngClass]="[
        'v2-radius-10px',
        'pointer smooth',
        sm
          ? 'v2-padding-1-h v2-padding-8px-v'
          : 'v2-padding-2-h v2-padding-1-v',
        'v2-margin-2-l',
        'h5',
        atTop
          ? 'text-white-high-emphasis bg-500 no-border'
          : 'text-primary bg-white border-300 v2-border-1px',
        xs && mo ? 'hide' : ''
      ]"
            routerLink="/a/sign-up">
      Sign up for free
    </button>
    <ng-container *ngIf="sm">
      <a7-mat-menu (menuOpened)="mo = true"
                   (menuClosed)="mo = false"
                   [mode]="xs ? 'fullscreen' : 'window'">
        <button button-slot
                class="flex bg-white v2-border-0px v2-padding-0 v2-margin-2-l">
          <img [ngClass]="['smooth v2-h-32px', xs && mo ? 'hide' : ' ']"
               [src]="'/assets/v2-menu.svg'" />
          <img [ngClass]="[
              'smooth v2-h-20px v2-margin-8px-r',
              xs && mo ? '' : 'hide'
            ]"
               [src]="'/assets/v2-greenX.svg'" />
        </button>

        <div menu-slot
             [ngClass]="['v2-h-24 flex-column-evenly-start v2-padding-2-h']">
          <div class="h5 color-green-grey-1"
               routerLink="/about">About</div>
          <div class="h5 color-green-grey-1 new-badge"
               routerLink="/app">
            App
          </div>
          <a class="h5 color-green-grey-1"
             href="https://ark7.com/blog/">
            Learn
          </a>
          <div class="h5 color-green-grey-1"
               routerLink="/a/sign-in">Login</div>
          <button [ngClass]="[
              'v2-radius-10px border-300 v2-border-1px',
              'pointer smooth',
              'v2-padding-2-h v2-padding-1-v',
              'h5',
              xs ? 'text-white-high-emphasis bg-500' : 'text-primary bg-white'
            ]"
                  routerLink="/a/sign-up">
            Sign up for free
          </button>
        </div>
      </a7-mat-menu>
    </ng-container>
  </ng-container>
  <button *ngIf="mode === 'login'"
          [ngClass]="[
      'v2-w-8 v2-h-44px',
      'v2-border-0px v2-radius-8px elevation-lowest',
      'pointer bg-white',
      ' text-primary h5'
    ]"
          routerLink="/a/sign-in">
    login
  </button>
  <button *ngIf="mode === 'signup'"
          [ngClass]="[
      'v2-w-8 v2-h-44px',
      'v2-border-0px v2-radius-8px elevation-lowest',
      'pointer bg-white',
      ' text-primary h5'
    ]"
          routerLink="/a/sign-up">
    Sign Up
  </button>
  <a7-social-share *ngIf="mode === 'social'"></a7-social-share>
</div>

<div [ngClass]="['v2-h-0', !fixed ? '' : sm ? 'v2-margin-6-b' : 'v2-margin-8-b']"
     #beacon></div>