import { A7ResourcesCommonModule } from '@ark7/resources-common';
import { A7SharedComponentsModule } from '@ark7/shared-components';
import { A7UiAuthModule } from '@ark7/ui-auth';
// import * as Hammer from '@egjs/hammerjs';
import { CommonModule } from '@angular/common';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import {
//   HAMMER_GESTURE_CONFIG,
//   HammerGestureConfig,
// } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

import { AboutComponent } from './pages/about/about.component';
import { HomeComponent } from './pages/home/home.component';
import { LogOnComponent } from './pages/log-on/log-on.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { WebV2PartsModule } from './parts/v2-parts.module';
import { WebV2RoutingModule } from './web-v2-routing.module';

// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
//     pan: { direction: Hammer.DIRECTION_ALL },
//   };
// }
@NgModule({
  imports: [
    WebV2RoutingModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatDividerModule,
    WebV2PartsModule,
    A7UiAuthModule,
    A7ResourcesCommonModule,
    A7SharedComponentsModule,
  ],
  declarations: [
    HomeComponent,
    AboutComponent,
    SignUpComponent,
    LogOnComponent,
  ],
  exports: [HomeComponent, AboutComponent, SignUpComponent, LogOnComponent],
  providers: [
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: MyHammerConfig,
    // },
  ],
})
export class WebV2Module {
  constructor(library: FaIconLibrary) {
    library.addIcons(faArrowLeft, faArrowRight, faAngleLeft, faAngleRight);
  }
}
