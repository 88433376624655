<div class="footer">
  <img src="/assets/round-logo.webp"
       class="round-logo"
       alt="ark7 logo">
  <div class="text-center v2-margin-20px-t h2 white">How Ark7 Works?</div>
  <div class="text-center v2-max-w-40 v2-margin-1-t v2-margin-32px-b margin-h-auto body-2 color-green-gray-2">Ark7
    empowers all
    investors to buy, hold, and sell fractional
    shares of quality
    rental properties nationwide for monthly income and long-term appreciation. <a class="underline white"
       href="https://ark7.com/faq?faqId=665737f3c98b64d91bc98380"
       target="_blank">Learn more</a></div>
  <ul class="margin-auto">
    <li class="h5"><img src="/assets/icon-safe.webp">Flexible Investment</li>
    <li class="h5"><img src="/assets/icon-safe.webp">True Property Ownership</li>
    <li class="h5"><img src="/assets/icon-safe.webp">No Operational Headaches</li>
    <li class="h5"><img src="/assets/icon-safe.webp">Sell When Needed</li>
    <li class="h5"><img src="/assets/icon-safe.webp">Consistent Passive Income</li>
  </ul>
</div>
<div class="footer-padding"></div>
