import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Identity } from '@ark7/identity';
import { A7LayoutService, PageSize } from '@ark7/layout';
import {
  A7_RESOURCES_USER,
  BasicUserResource,
  Logger,
  SignInErrorCode,
  UserService,
} from '@ark7/resources-common';
import { UserBehaviorService } from '@ark7/socket';
import { a7EmailValidator, A7Router, Debug, SubscribeComponent } from '@ark7/utils';
import debug from 'debug';
import _ from 'underscore';

const d = debug('a7-ui-auth:SignInFormComponent');

enum SignInFormStage {
  DEFAULT = 0,
  REGISTERED_SUCCESSFULLY = 1,
  INTERNAL_SERVER_ERROR = 2,
}

// If set, this sign-in form is for oauth, and has oauth specific logics.
enum OAuthConnectClient {
  NONE = 'NONE',
  MONEYMADE = 'MONEYMADE',
}

@Component({
  selector: 'a7-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss'],
})
export class SignInFormComponent extends SubscribeComponent implements OnInit {
  @Input() formTitle: string;
  @Input() allowedRedirectHosts: string[];
  @Input() host: string;
  @Input() logo: string = 'icon-square-logo-weight-2';
  @Input() social: boolean = false;
  @Input() oauthClient: string = OAuthConnectClient.NONE;
  @Output() signInEvent = new EventEmitter<boolean>();

  @HostBinding('class.a7-ui-auth-sign-in-form')
  @HostBinding('class.a7-ui-auth-form')
  _hostBinding = true;

  pageSize: PageSize;

  form: UntypedFormGroup;
  loggingIn: boolean = false;
  stage: SignInFormStage = 0;

  private redirectTo: string = '/';

  constructor(
    fb: UntypedFormBuilder,
    route: ActivatedRoute,
    layout: A7LayoutService,
    @Inject(PLATFORM_ID) platformId: string,
    private router: A7Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
    @Inject(A7_RESOURCES_USER)
    private userResource: BasicUserResource<Identity>,
    private logger: Logger,
    private userBehavior: UserBehaviorService,
  ) {
    super();

    this.form = fb.group({
      username: [
        '',
        [Validators.required, Validators.email, a7EmailValidator()],
      ],
      password: ['', [Validators.required]],
    });

    route.queryParams.subscribe((params) => {
      if (params.email) {
        this.form.controls.username.setValue(params.email);
      }
      if (params.redirectTo && isPlatformBrowser(platformId)) {
        this.redirectTo = new URL(
          params.redirectTo,
          window?.location.href,
        ).toString();
      }
    });

    this.safeSubscribe(
      layout.pageSize,
      (pageSize) => (this.pageSize = pageSize),
    );
  }

  get formTitleWithDefault() {
    return this.formTitle != null
      ? this.formTitle
      : this.pageSize !== PageSize.XS
      ? 'Welcome back to Ark7'
      : 'Sign in to Ark7';
  }

  ngOnInit() {
    this.safeSubscribe(this.userService.userChange$, (user) => {
      // Defer the redirect to avoid circular redirection.
      _.defer(() => {
        const redirectTo = new URL(this.redirectTo, window?.location.href);

        if (user != null && this.router.url.startsWith('/a/sign-in')) {
          if (this.allowedRedirectHosts.indexOf(redirectTo.host) >= 0) {
            d('host in allowedRedirectHosts, hard redirect');
            window.location.href = redirectTo.toString();
          } else if (redirectTo.host === this.host) {
            d('hosts are the same, try router navigate');
            const url = redirectTo
              .toString()
              .substring(redirectTo.origin.length);
            this.router.navigateByUrl(url);
          } else {
            d('router navigate to root');
            this.router.navigate(['/']);
          }
        }
      });
    });
  }

  @Debug({ d })
  async login() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.form.disable();

    const username = this.form.controls.username.value.trim();
    const password = this.form.controls.password.value.trim();

    try {
      this.loggingIn = true;
      await this.userService.signIn({
        email: username,
        password,
      });
      if (!this.userService.isRenter) {
        try {
          this.userResource.emitSignInEvent({});
        } catch (e) {}
        try {
          this.userBehavior.signIn();
        } catch (e) {}
      }
      if (this.oauthClient !== OAuthConnectClient.NONE) {
        this.signInEvent.emit(true);
      }
      // sign in successfully, will trigger deferred redirection.
    } catch (e) {
      d('Sign In Error: %O', e);

      this.form.enable();

      if (
        e.status === 401 &&
        e.body &&
        e.body.meta &&
        e.body.meta.tos === true
      ) {
        this.router.navigate(['/a/accept-tos']);
        return;
      }

      if (
        e.status === 400 &&
        e.body &&
        e.body.message === 'User does not exist.'
      ) {
        this.form.controls.username.setErrors({
          userNotExist: true,
        });
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (
        e.status === 400 &&
        e.body &&
        e.body.message === 'Incorrect username or password.'
      ) {
        this.form.controls.password.setValue('');
        this.form.controls.password.setErrors({
          unauthorized: true,
        });
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (
        e.status === 400 &&
        e.body &&
        e.body.message === 'User is not confirmed.'
      ) {
        this.stage = SignInFormStage.REGISTERED_SUCCESSFULLY;
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (
        e.code === SignInErrorCode.NOT_AUTHORIZED ||
        e.code === SignInErrorCode.USER_NOT_FOUND
      ) {
        this.form.controls.password.setValue('');
        this.form.controls.password.setErrors({
          unauthorized: true,
        });
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (e.code === SignInErrorCode.USER_NOT_CONFIRMED_EXCEPTION) {
        this.stage = SignInFormStage.REGISTERED_SUCCESSFULLY;
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (e.status !== 0) {
        this.logger.error('User sign in failed', {
          error: e,
        });

        this.snackBar.open('Server error, please try again later', '', {
          duration: 3000,
        });
      }
      this.loggingIn = false;
    }
  }

  get email() {
    return this.form.controls.username.value;
  }
}
