import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { A7LayoutService, V2BaseComponent } from '@ark7/layout';

@Component({
  selector: 'v2-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends V2BaseComponent
  implements AfterViewInit, OnDestroy {
  @Input()
  mode: 'full' | 'login' | 'signup' | 'social' | 'none' = 'full';

  @Input()
  bgClass = 'bg-white';

  @Input()
  fixed = true;

  @Input()
  topClass = 'v2-top-0';

  @Input()
  routerLinkStr = '/';

  mo = false;

  private observer: IntersectionObserver; // not available for ssr
  atTop = false;

  @ViewChild('beacon') beacon: ElementRef;

  browserRendering = false;

  constructor(
    layoutService: A7LayoutService,

    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    super(layoutService);
    this.browserRendering = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void {
    if (!this.beacon || !this.browserRendering) {
      return;
    }
    this.observer = new IntersectionObserver(
      (entries, _observer) => {
        this.atTop = !entries[0].isIntersecting;
      },
      {
        root: null,
        threshold: 0.1,
      },
    );
    this.observer?.observe(this.beacon.nativeElement);
  }

  ngOnDestroy() {
    if (!this.beacon || !this.browserRendering) {
      return;
    }
    this.observer?.unobserve(this.beacon.nativeElement);
  }
}
