import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'a7-sent-verification-email',
  templateUrl: './sent-verification-email.component.html',
  styleUrls: ['./sent-verification-email.component.scss'],
})
export class SentVerificationEmailComponent implements OnInit {
  @Input() formTitle: string = 'EMAIL SENT';
  @Input() email: string;

  @Output() clickSignInBtn = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
