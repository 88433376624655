import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable, InjectionToken } from '@angular/core';

export const A7_ALLOWED_FEATURES = new InjectionToken<Array<string>>(
  'A7_ALLOWED_FEATURES',
);

@Injectable({
  providedIn: 'root',
})
export class FeatureGuardService  {
  constructor(
    @Inject(A7_ALLOWED_FEATURES) private allowedFeatures: Array<string>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const feature: string = route.data.feature || '';
    return this.allowedFeatures?.includes(feature) || false;
  }
}
