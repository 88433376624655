<app-page>

  <div class="full-width v2-max-w-100 margin-auto flex-column-center-center v2-margin-4-t">
    <div class="display-1 v2-margin-4-t">Top Articles</div>

    <div class="v2-margin-7-t flex-row-center-start flex-wrap v2-col-gap-40px v2-row-gap-4">
      <a *ngFor="let faq of topFAQs;"
         class="faq-box display-block"
         [href]="faqLink(faq._id.toString())">
        <h3>{{$any(faq.article).title}}</h3>
        <div class="faq-description v2-margin-8px-t body-2 color-green-grey-3">{{$any(faq.article).description}}</div>
      </a>
    </div>

    <div class="display-1 v2-margin-11-t">By Topic</div>

    <div class="v2-margin-5-t v2-margin-11-b flex-row-center-start flex-wrap v2-col-gap-40px v2-row-gap-5">
      <a *ngFor="let category of categories;"
         class="faq-category-box text-center display-block"
         [href]="faqCategoryLink(category)">
        <img src="{{category.imageUrl}}"
             class="v2-radius-8px v2-w-12" />
        <h2 class="v2-margin-20px-t">{{category.name}}</h2>
        <div class="body-1 color-green-grey-3">{{category.description}}</div>
      </a>
    </div>

    <app-webinar-card size="large"
                      class="full-width v2-margin-11-b"
                      [ngClass]="mobile ? 'v2-padding-2-h' : 'v2-padding-4-h'" />
  </div>
</app-page>
