import _ from 'underscore';
import { A7LayoutService, A7PageSizeMatcher } from '@ark7/layout';
import { A7Router } from '@ark7/utils';
import { ChatbotResource, UserService } from '@ark7/resources-common';
import {
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  Conversation,
  ConversationRole,
  SupportConversation,
} from '@ark7/core-business-models';
import { LoadingProgressor } from '@ark7/loading';
import { SLIDE_DOWN } from '@ark7/animations';
import { debounceTime } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'a7-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  animations: [SLIDE_DOWN],
})
export class ChatbotComponent {
  @A7PageSizeMatcher('XS-') xs;
  ConversationRole = ConversationRole;

  enabled = false;
  showDialog = false;
  startTime = new Date();
  userInput: string;

  @ViewChild('chats', { static: false }) myDivRef: ElementRef;

  progressor = new LoadingProgressor();

  agentFollowUpAllowedAfter = 3;

  supportConversation: SupportConversation = SupportConversation.modelize({
    conversations: [
      {
        role: ConversationRole.MODEL,
        msg:
          "Welcome. Please be advised that Ark7's operations are exclusive to the U.S., without international business engagements. How may I provide assistance today?",
      },
      // {
      //   role: ConversationRole.USER,
      //   msg: 'Hi there, you can ask me anything about Ark7.',
      // },
      // {
      //   role: ConversationRole.MODEL,
      //   msg: 'Hi there, you can ask me anything about Ark7.',
      // },
      // {
      //   role: ConversationRole.USER,
      //   msg: 'Hi there, you can ask me anything about Ark7.',
      // },
    ] as Conversation[],
  } as any);

  constructor(
    private chatbotResource: ChatbotResource,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object,
    router: A7Router,
    layoutService: A7LayoutService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const disableOnOnPages = [
        '/share',
        '/welcome',
        '/trending-properties',
        '/legal/promo',
        '/u/tax-form-delivery',
      ];

      if (layoutService.currentWidth < 960) {
        disableOnOnPages.push('/investments');
        disableOnOnPages.push('/u/funds');
      }

      router.events.pipe(debounceTime(100)).subscribe(() => {
        this.enabled = !_.any(disableOnOnPages, (v) =>
          router.url.startsWith(v),
        );

        this.showDialog = !this.xs && router.url.startsWith('/help');
      });
    }
  }

  get showAssistButtons() {
    return (
      this.supportConversation.conversations?.length >=
        this.agentFollowUpAllowedAfter &&
      !this.progressor.isLoading &&
      !this.supportConversation.emailSent
    );
  }

  scrollToBottom(): void {
    _.delay(() => {
      try {
        this.myDivRef.nativeElement.scrollTop = this.myDivRef.nativeElement.scrollHeight;
      } catch (err) {
        console.log(err);
      }
    }, 200);
  }

  async processInput() {
    if (this.userInput.trim().length === 0) {
      return;
    }

    if (this.supportConversation.emailRequested) {
      if (!this.obtainEmail()) {
        return;
      }

      this.supportConversation.conversations?.push({
        role: ConversationRole.MODEL,
        msg:
          'We will get back to you ASAP. You can also attend our regular webinar for more information: https://ark7.com/webinar/81882467340',
      } as Conversation);
    } else {
      this.pushUserMessage();
    }

    this.scrollToBottom();

    await this.backendProcess();

    this.scrollToBottom();
  }

  private obtainEmail(): boolean {
    const newUserMsg = this.pushUserMessage();
    const email = extractEmail(newUserMsg);
    if (email) {
      this.supportConversation.userEmail = email;
    } else {
      this.supportConversation.conversations?.push({
        role: ConversationRole.MODEL,
        msg: 'Please enter an valid email.',
      } as Conversation);
      this.scrollToBottom();
    }
    return !!email;
  }

  private pushUserMessage(): string {
    const newUserMsg = this.userInput.trim();
    this.userInput = '';

    this.supportConversation.conversations?.push({
      role: ConversationRole.USER,
      msg: newUserMsg,
    } as Conversation);
    return newUserMsg;
  }

  requestFollowup() {
    if (this.supportConversation?.emailRequested) {
      return;
    }
    this.supportConversation.emailRequested = true;
    this.supportConversation.conversations?.push({
      role: ConversationRole.MODEL,
      msg: 'Please enter your email.',
    } as Conversation);
    if (this.userService.hasCurrentUser) {
      this.userInput = this.userService.currentUser?.email;
    }
    this.scrollToBottom();
  }

  private async backendProcess() {
    this.supportConversation = (await this.progressor.watchAsyncCall(() =>
      this.supportConversation._id
        ? this.supportConversation.$save()
        : this.chatbotResource.create(this.supportConversation),
    )) as SupportConversation;
  }
}

function extractEmail(text: string): string | undefined {
  // Email regular expression pattern
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

  // Search for an email in the given text
  const match = text.match(emailRegex);

  // If an email is found, return it; otherwise, return undefined
  return match ? match[0] : undefined;
}
