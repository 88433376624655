import _ from 'underscore';
import debug from 'debug';
import { A7Router, Debug, SubscribeDirective } from '@ark7/utils';
import { ActivatedRoute } from '@angular/router';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Observable, fromEvent } from 'rxjs';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { isPlatformBrowser } from '@angular/common';

import { ContentContainerService } from '../services/content-container.service';
import { LayoutSectionDirective } from './layout-section.directive';

const d = debug('a7-layout:ContentContainerDirective');

export interface ContentContainer {
  width: number;
  height: number;
  innerWidth: number;
  innerHeight: number;
  offsetTop: number;
  offsetLeft: number;
  scrollTop: number;
  scrollLeft: number;
}

@Directive({
  selector: '[a7ContentContainer]',
})
export class ContentContainerDirective
  extends SubscribeDirective
  implements AfterViewInit, OnInit, OnDestroy {
  @Input() a7ContentContainerFocusSectionDelta: number = 0;
  @Input() a7ContentContainerAutoScroll: boolean = true;
  @Input() a7ContentContainerDuration: number = 800;
  @Output()
  activeLayoutSectionChange = new EventEmitter<LayoutSectionDirective>();

  scroll: Observable<Event>;
  fragment: string;
  activeLayoutSection: LayoutSectionDirective;

  fragmentLocked: boolean = false;

  constructor(
    public host: ElementRef,
    private contentContainer: ContentContainerService,
    private route: ActivatedRoute,
    private router: A7Router,
    private scrollToService: ScrollToService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    super();
    this.scroll = fromEvent(host.nativeElement, 'scroll');
    this.checkFragment = this.checkFragment.bind(this);
  }

  ngOnInit() {
    if (
      isPlatformBrowser(this.platformId) &&
      this.a7ContentContainerAutoScroll
    ) {
      this.safeSubscribe(this.route.fragment, (fragment) => {
        d('fragment changed: %O', fragment);
        this.fragment = fragment;
        _.defer(this.checkFragment);
      });

      this.safeSubscribe(this.router.events, (event) => {
        if (event instanceof NavigationEnd && this.fragment == null) {
          this.host.nativeElement.scrollTop = 0;
        }
      });
    }
  }

  ngAfterViewInit() {
    this.contentContainer.setContentContainer(this);
    if (isPlatformBrowser(this.platformId)) {
      _.delay(this.checkFragment, 500);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.contentContainer.unsetContentContainer();
  }

  setActiveLayoutSection(
    activeLayoutSection: LayoutSectionDirective,
  ): LayoutSectionDirective {
    this.activeLayoutSection = activeLayoutSection;
    this.activeLayoutSectionChange.emit(this.activeLayoutSection);
    return this.activeLayoutSection;
  }

  unsetActiveLayoutSection(
    layoutSection: LayoutSectionDirective,
  ): LayoutSectionDirective {
    if (this.activeLayoutSection === layoutSection) {
      this.activeLayoutSection = null;
      this.activeLayoutSectionChange.emit(this.activeLayoutSection);
    }

    return this.activeLayoutSection;
  }

  get size(): ContentContainer {
    return {
      width: this.host.nativeElement.offsetWidth,
      height: this.host.nativeElement.offsetHeight,
      innerWidth: this.host.nativeElement.scrollWidth,
      innerHeight: this.host.nativeElement.scrollHeight,
      offsetTop: this.host.nativeElement.offsetTop,
      offsetLeft: this.host.nativeElement.offsetHeight,
      scrollTop: this.host.nativeElement.scrollTop,
      scrollLeft: this.host.nativeElement.scrollLeft,
    };
  }

  @Debug({ d })
  private checkFragment(retry: number = 2) {
    if (this.fragment) {
      this.fragmentLocked = true;
      d('fragment locked');
      this.scrollToService
        .scrollTo({
          target: this.fragment,
          duration: this.a7ContentContainerDuration,
          offset: -this.a7ContentContainerFocusSectionDelta + 4,
          easing: 'easeOutElastic',
        })
        .subscribe(
          () => {},
          () => {
            if (retry >= 0) {
              _.delay(() => this.checkFragment(retry - 1), 500);
            } else {
              this.fragmentLocked = false;
              d('fragment unlocked due to error');
            }
          },
          () => {
            this.scrollToService.scrollTo({
              target: this.fragment,
              duration: 0,
              offset: -this.a7ContentContainerFocusSectionDelta + 4,
            });
            this.fragmentLocked = false;
            d('fragment unlocked');
          },
        );
    }
  }
}
