<app-page>

  <div class="site-container">

    <h2>
      {{unsubscribe ? 'Unsubscribe for' : 'Email Preferences'}}: {{subscription?.email}}
    </h2>

    <div *ngIf="!unsubscribeSucceed"
         [formGroup]="form"
         class="flex-column-start-start flex-gap-12 v2-margin-5-b">
      <mat-slide-toggle formControlName="general"
                        color="primary">
        Property Launch and Promotions
      </mat-slide-toggle>
      <mat-slide-toggle formControlName="propertyOperationUpdates"
                        color="primary">
        Property Operation Updates
      </mat-slide-toggle>
      <mat-slide-toggle formControlName="productFeatureUpdates"
                        color="primary">
        Product Feature Updates
      </mat-slide-toggle>
      <mat-slide-toggle formControlName="tradingOrderUpdates"
                        color="primary">
        Trading Order Updates
      </mat-slide-toggle>
      <mat-slide-toggle formControlName="disableAll"
                        color="primary">
        Unsubscribe All Marketing Emails
      </mat-slide-toggle>

      <button mat-flat-button
              color="primary"
              class="v2-margin-2-t"
              [disabled]="progressor.isLoading"
              (click)="savePreference()">Save</button>
    </div>

    <div *ngIf="unsubscribeSucceed">
      We are sorry to see you go, will miss you!
    </div>
  </div>
</app-page>
