import { AppConfigurationService } from '@ark7/resources-common';
import { Component, Input } from '@angular/core';

import { CarrierBaseComponent } from '../carrier-base/carrier-base.component';

@Component({
  selector: 'a7-carrier-distribution',
  templateUrl: './carrier-distribution.component.html',
  styleUrls: ['./carrier-distribution.component.scss'],
})
export class CarrierDistributionComponent extends CarrierBaseComponent {
  @Input() mobile = false;
  @Input() dClass = 'label-2 color-green-grey-3';
  @Input() layoutClass = 'flex-column-between-start';
  @Input() numberClass = this.mobile ? 'h4' : 'h1';
  @Input() decimalPlaceClass = this.mobile ? 'h4' : 'h1';

  constructor(private configurationService: AppConfigurationService) {
    super();
  }

  public forcePerShareMode = false;
  get perShareMode() {
    return this.forcePerShareMode;
  }

  get showSwitch() {
    return !this.hasDividendBoost && this.carrier.dividendPerShare?.amount > 0;
  }

  switch() {
    this.forcePerShareMode = !this.forcePerShareMode;
  }

  get distributionRateWithBoost() {
    return this.carrier.isIpoOffering
      ? this.carrier.dividendRate + this.configurationService.dividendBoostRate
      : this.carrier.dividendRate;
  }

  get hasDividendBoost() {
    return (
      this.carrier.isIpoOffering && this.configurationService.hasDividendBoost
    );
  }

  get dClassWithBoost() {
    return this.hasDividendBoost
      ? this.dClass + ' v2-margin-n16px-r'
      : this.dClass;
  }

  get dividendRateWithBoostTooltip() {
    return this.carrier.isIpoOffering
      ? this.configurationService.dividendRateWithBoostTooltip
      : this.configurationService.secondaryMarketDividendRateTooltip;
  }

  get dividendPerShareWithBoostTooltip() {
    return this.configurationService.dividendPerShareWithBoostTooltip;
  }
}
