import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { RequestStatusService } from './services/request-status.service';
import { SignalService } from './services/signal.service';

// the following comment makes typescript happy for class with static field
// @dynamic
@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class A7Resource2Module {
  private static signalService: SignalService;
  private static httpClient: HttpClient;
  private static requestStatusService: RequestStatusService;

  static getRequestStatusService() {
    if (this.requestStatusService == null) {
      throw new Error(
        'A7Resource2Module has not been initialized, please add A7Resource2Module to your dependency.',
      );
    }
    return this.requestStatusService;
  }

  static getSignalService() {
    if (this.signalService == null) {
      throw new Error(
        'A7Resource2Module has not been initialized, please add A7Resource2Module to your dependency.',
      );
    }
    return this.signalService;
  }

  static getHttpClient() {
    if (this.httpClient == null) {
      throw new Error(
        'A7Resource2Module has not been initialized, please add A7Resource2Module to your dependency.',
      );
    }
    return this.httpClient;
  }

  constructor(
    signalService: SignalService,
    httpClient: HttpClient,
    requestStatusService: RequestStatusService,
  ) {
    A7Resource2Module.signalService = signalService;
    A7Resource2Module.httpClient = httpClient;
    A7Resource2Module.requestStatusService = requestStatusService;
  }
}
