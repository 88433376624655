import { Directive, OnInit, OnDestroy, Input } from '@angular/core';
import { A7LayoutService } from '../services/a7-layout.service';

@Directive({
  selector: '[a7DesignSystem]',
})
export class DesignSystemDirective implements OnDestroy, OnInit {
  @Input() a7DesignSystem: string;
  @Input() ionic: boolean = false;

  constructor(private layoutService: A7LayoutService) {}

  ngOnInit() {
    if (!this.ionic) {
      this.layoutService.addDesignSystem(this);
    }
  }

  ngOnDestroy() {
    if (!this.ionic) {
      this.layoutService.removeDesignSystem(this);
    }
  }

  ionViewWillEnter() {
    if (this.ionic) {
      this.layoutService.addDesignSystem(this);
    }
  }

  ionViewDidLeave() {
    if (this.ionic) {
      this.layoutService.removeDesignSystem(this);
    }
  }
}
