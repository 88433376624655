import _ from 'underscore';
import { A7LayoutService, A7PageSizeMatcher } from '@ark7/layout';
import { A7Router, a7EmailValidator } from '@ark7/utils';
import {
  AppConfigurationService,
  LeadResource,
  TrackingService,
  VideoPlayComponent,
} from '@ark7/resources-common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ark7-intro',
  templateUrl: './ark7-intro.component.html',
  styleUrls: ['./ark7-intro.component.scss'],
})
export class Ark7IntroComponent {
  @A7PageSizeMatcher('SM-') sm;
  @A7PageSizeMatcher('MD-') md;

  @ViewChild('heroEmailInput') heroEmailInput: ElementRef;
  myForm: FormGroup;
  constructor(
    private configurationService: AppConfigurationService,
    private leadResource: LeadResource,
    private trackingResource: TrackingService,
    private router: A7Router,
    private fb: FormBuilder,
    protected layoutService: A7LayoutService,
    private dialog: MatDialog,
  ) {
    this.myForm = this.fb.group({
      heroEmailInput: [
        '',
        [Validators.required, Validators.email, a7EmailValidator()],
      ],
    });
  }

  get showPromoMessage() {
    return !_.isEmpty(
      this.configurationService.getConfig('web.landing.promoBannerMessage'),
    );
  }

  get promoHeroMessage() {
    return this.configurationService.getConfig('web.landing.promoHeroMessage');
  }

  get heroImage(): string {
    return this.configurationService.landingHeroImage;
  }

  get heroSectionClass(): string {
    return this.configurationService.landingHeroSectionClass;
  }

  get heroImageClass(): string {
    return this.configurationService.landingHeroImageClass;
  }

  get heroTitle(): string {
    return this.configurationService.landingHeroTitle.replace('*', '');
  }

  get showStarSign(): boolean {
    return this.configurationService.landingHeroTitle.endsWith('*');
  }

  get heroSubtitle(): string {
    return this.configurationService.landingHeroSubtitle;
  }

  get shouldShowHeroCtaEmailInput(): boolean {
    return this.configurationService.landingHeroCtaType === 'EMAIL_INPUT';
  }
  get heroCtaLink(): string {
    return !_.isEmpty(this.heroEmailInput?.nativeElement?.value)
      ? '/a/sign-up?email=' +
          encodeURIComponent(this.heroEmailInput?.nativeElement.value)
      : '/a/sign-up';
  }

  get showError() {
    const formInput = this.myForm.get('heroEmailInput');
    return formInput.invalid && this.ctaClicked;
  }

  private ctaClicked = false;
  async onHeroCta() {
    this.ctaClicked = true;
    if (this.myForm.invalid) {
      return;
    }

    // window.location.href = this.heroCtaLink;
    await this.leadResource.create({
      email: this.heroEmailInput?.nativeElement.value,
      referrer: this.trackingResource.referrer,
      tracking: this.trackingResource.trackingCode,
    });
    if (this.heroEmailInput?.nativeElement?.value) {
      this.trackingResource.leadEmail = this.heroEmailInput.nativeElement.value;
    }
    this.router.navigate(['/trending-properties']);
  }

  get heroCtaText(): string {
    return this.configurationService.landingHeroCtaText;
  }

  hideAnnouncementBanner: boolean;
  get showAnnouncementMessage() {
    return (
      !this.hideAnnouncementBanner &&
      !_.isEmpty(this.configurationService.announcementMessage)
    );
  }

  get announcementMessage() {
    return this.configurationService.announcementMessage;
  }

  get hasAnnouncementVideoUrl() {
    return !_.isEmpty(this.announcementVideoUrl);
  }

  get announcementVideoUrl() {
    return this.configurationService.announcementVideoUrl;
  }

  get announcementVideoCtaText() {
    return this.configurationService.announcementVideoCtaText ?? 'Watch Video';
  }

  onPlayVideo(videoUrl: string) {
    let videoId;
    const videoIdMatch = videoUrl.match(/youtu\.be\/([\w-]+)/);
    if (videoIdMatch && videoIdMatch[1]) {
      videoId = videoIdMatch[1];
    }

    if (_.isEmpty(videoId)) {
      return;
    }

    const videoWidth = Math.min(this.layoutService.currentWidth, 800);
    const videoHeight = (videoWidth / 800) * 450;

    this.dialog.open(VideoPlayComponent, {
      width: `${videoWidth}px`,
      height: `${videoHeight + 120}px`,
      maxWidth: '100vw',
      data: {
        videoId,
        width: `${videoWidth}px`,
        height: `${videoHeight}px`,
      },
    });
  }

  closeAnnouncementBanner() {
    this.hideAnnouncementBanner = true;
  }
}
