import {
  IResourceMethodObservable,
  IResourceMethodObservableStrict,
  ResourceRequestMethod,
} from '@ngx-resource/core';

import { A7Resource } from './resource';
import { A7ResourceAction } from './action';

export abstract class A7ResourceSingletonObservable<
  TFull = any,
  TModifier = {}
> extends A7Resource {
  @A7ResourceAction({
    asObservable: true,
    subSignalTopic: 'get',
  })
  get: IResourceMethodObservable<TModifier, TFull>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    asObservable: true,
    subSignalTopic: 'update',
  })
  update: IResourceMethodObservableStrict<TFull, TModifier, void, TFull>;
}
