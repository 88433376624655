import { AfterViewInit, Directive } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Directive({
  selector: '[a7-map-info]',
})
export class MapInfoDirective implements AfterViewInit {
  constructor(private host: MapInfoWindow, private info: MapMarker) { }

  ngAfterViewInit() {
    this.host.open(this.info);
  }
}
