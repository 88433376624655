<div class="full-width"
     [ngClass]="layout">
  <div *ngIf="percent !== null"
       [ngClass]="[
    'full-width v2-h-4px bg-blue-grey-2 v2-radius-8px full-height'
  ]">
    <div [ngClass]="[
      'v2-h-4px v2-h-4px v2-radius-8px',
      'v2-w-' + percent + 'pct',
      percent <= 25 ? 'bg-warn' : 'bg-500'
    ]"></div>
  </div>

  <div>
    <h5 class="inline-block"
        [ngClass]="bClass ??(percent <= 25 ? 'text-warn' : 'text-primary')">
      {{ bText }}
    </h5>

    <span *ngIf="!!slash && !!bText">{{slash}}</span>

    <a7-moving-number *ngIf="!bText"
                      [ngClass]="['color-green-grey-1 inline-block', cClass]"
                      [number]="cText"
                      [decimalPlaceClass]="decimalPlaceClass">
    </a7-moving-number>

    <span *ngIf="bText"
          [ngClass]="['color-green-grey-1 inline-block', cClass]">{{ cText }}
    </span>
  </div>

  <div class="relative" [ngClass]="dClass">
    {{ dText }}
    <span *ngIf="toolTip"
          [cTip]="toolTip">
      <fa-icon [icon]="['far', 'question-circle']"> </fa-icon>
    </span>
  </div>

</div>
