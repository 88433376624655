<div [ngClass]="[
    'v2-w-28',
    'v2-border-1px border-blue-grey-2 v2-radius-24px',
    'flex-column-start-center',
    'v2-padding-4-v v2-padding-40px-h',
    sm ? '' : 'v2-min-h-51'
  ]">
  <img class="v2-max-h-15"
       [src]="picSrc"
       [alt]="picAlt" />
  <div [ngClass]="['color-green-grey-1  text-center v2-margin-1-v', md ? 'h2' : 'h3']">
    {{ title }}
  </div>
  <div class="body-1 color-green-grey-2">{{ details }}</div>
</div>
