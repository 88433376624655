import * as _ from 'underscore';

import { DEFAULT_TIMING } from './defaults';

export function translateTiming(timing: number | string): string {
  if (_.isNumber(timing)) {
    return `${timing}ms`;
  } else if (timing == null) {
    return DEFAULT_TIMING;
  } else {
    return timing;
  }
}
