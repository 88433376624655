import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPercent,
} from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import debug from 'debug';
import moment from 'moment';
import _ from 'underscore';

import { Debug } from '../utils/debug';

const d = debug('a7-utils:FormatPipe');

@Pipe({
  name: 'format',
})
export class FormatPipe implements PipeTransform {
  @Debug({ d })
  transform(value: any, f: Format, ...args: any[]): any {
    return format(value, f, ...args);
  }
}

export type Format =
  | 'date'
  | 'time'
  | 'money'
  | 'percent'
  | 'duration'
  | string;

export function format(value: any, f: string, ...args: string[]) {
  if (f == null) {
    f = '';
  }

  const parts = f.split(':').concat(args);
  const fStr: Format = _.first(parts) as any;

  parts.splice(0, 1);

  switch (fStr) {
    case 'date':
      return formatDate(value, parts[0] || 'mediumDate', 'en-US', parts[1]);

    case 'time':
      return `${formatDate(
        value,
        parts[0] || 'mediumDate',
        'en-US',
        parts[1],
      )} ${formatDate(value, parts[0] || 'shortTime', 'en-US', parts[1])} ${
        new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
          .format(new Date())
          .split(',')[1]
      }`;

    case 'money':
      if (parts[0]) {
        return formatCurrency(value, 'en-US', '$', 'USD', parts[0]);
      } else {
        const integerPart = formatCurrency(value, 'en-US', '$', 'USD', '.0-0');
        if (integerPart === `\$${value}`) {
          return integerPart;
        } else {
          return formatCurrency(value, 'en-US', '$', 'USD', '.2-2');
        }
      }

    case 'percent':
      return formatPercent(value, 'en-US', parts[0] || '.0-2');

    case 'number':
      return formatNumber(value, 'en-US', parts[0]);

    case 'duration':
      const ret = moment.duration(value, 'seconds').humanize();
      return ret === 'a few seconds' ? '0' : ret;

    default:
      return value?.toString();
  }
}
