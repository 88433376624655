import * as _ from 'underscore';
import { Inject, Injectable } from '@angular/core';

import {
  APPLICATION_META,
  ApplicationMeta,
} from '../application/configuration';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(
    @Inject(APPLICATION_META) private applicationMeta: ApplicationMeta,
  ) {}

  getApplicationMeta(): ApplicationMeta {
    return _.extend(this.applicationMeta, {
      clientTimestamp: new Date().toISOString(),
    });
  }
}
