import { Component } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-download-app-popup',
  templateUrl: './download-app-popup.component.html',
  styleUrls: ['./download-app-popup.component.scss'],
})
export class DownloadAppPopupComponent {
  @LocalStorage() trackingCode: string;
  @LocalStorage() referrer: string;

  constructor(private dialogRef: MatDialogRef<DownloadAppPopupComponent>) {}

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }

  get downloadUrl() {
    const queryParmas = new URLSearchParams({
      r: this.referrer ?? '',
      tc: this.trackingCode ?? '',
    });
    return `https://ark7.com/download-link?${queryParmas.toString()}`;
  }
}
