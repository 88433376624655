import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class YoutubeApiLoaderService {
  // Signal once is sufficient.
  private apiLoaded = new ReplaySubject<void>(1);
  get loaded(): Observable<void> {
    return this.apiLoaded;
  }

  constructor(@Inject(DOCUMENT) private document: any) {
    this.load();
  }

  private async load() {
    // This code loads the IFrame Player API code asynchronously, according to the instructions at
    // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
    const tag = this.document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    this.apiLoaded.next();
  }
}
