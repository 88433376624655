import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'v2-home-feature-block',
  templateUrl: './home-feature-block.component.html',
  styleUrls: ['./home-feature-block.component.scss'],
})
export class HomeFeatureBlockComponent
  extends V2BaseComponent
  implements OnInit {
  @Input()
  title = '';

  @Input()
  features = [];

  @Input()
  bullet = true;

  @Input()
  reverse = false;

  @Input()
  footNote = '';

  @Input()
  learnMoreLink = '';

  ngOnInit(): void {}

  constructor(layoutService: A7LayoutService) {
    super(layoutService);
  }
}
