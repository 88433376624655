import { Component, Input } from '@angular/core';
import { CarrierBaseComponent } from '../carrier-base/carrier-base.component';

@Component({
  selector: 'a7-carrier-about',
  templateUrl: './carrier-about.component.html',
  styleUrls: ['./carrier-about.component.scss'],
})
export class CarrierAboutComponent extends CarrierBaseComponent {
  @Input() showSchools = true;
}
