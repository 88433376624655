import {
  A7_ALLOWED_FEATURES,
  FeatureGuardService as FeatureGuard,
  SeoRoutingHelperService,
} from '@ark7/utils';
import { FooterComponent } from '@ark7/shared-components';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './web-v2/pages/about/about.component';
import { ContactComponent } from './contact/contact.component';
import { DalmoreDisclosureComponent } from './dalmore-disclosure/dalmore-disclosure.component';
import { DalmoreSecondaryMarketTradingAgreementComponent } from './dalmore-secondary-market-trading-agreement/dalmore-secondary-market-trading-agreement.component';
import { DownloadLinkComponent } from './download/download-link/download-link.component';
import { EmailPreferencesComponent } from './email-preferences/email-preferences.component';
import { FAQArticleComponent } from './help/faq-article/faq-article.component';
import { FAQCategoryComponent } from './help/faq-category/faq-category.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './web-v2/pages/home/home.component';
import { Mobile2Component } from './download/mobile2/mobile2.component';
import { PromoTermsComponent } from './promo-terms/promo-terms.component';
import { ReferralInvitationComponent } from './join/referral-invitation/referral-invitation.component';
import { ReferralInvitationResultComponent } from './join/referral-invitation-result/referral-invitation-result.component';
import { ReferralTermsAndConditionsComponent } from './referral-terms-and-conditions/referral-terms-and-conditions.component';
import { environment } from '../environments/environment';

const routers: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    data: {
      title: 'Ark7 - Online Real Estate Investment App',
      description:
        'Invest in real estate online and buy shares in rental properties. Earn passive income through Ark7 with no hassle.',
      canonical: '/',
    },
  },
  {
    path: 'about',
    component: AboutComponent,
    pathMatch: 'full',
    data: {
      title: 'About Ark7 - Invest in Rental Properties',
      description:
        "Learn more about Ark7's mission and how it makes real estate investing accessible and hassle-free.",
      canonical: '/about',
    },
  },
  {
    path: 'footer',
    component: FooterComponent,
    pathMatch: 'full',
    data: {
      title: 'Ark7 Inc',
      description: 'Terms and Conditions',
      canonical: '/footer',
    },
  },
  {
    path: 'contact',
    component: ContactComponent,
    pathMatch: 'full',
    data: {
      title: 'Ark7 - Contact Us',
      description:
        'Contact Ark7 customer service for inquiries and start investing in real estate properties.',
      canonical: '/contact',
      jsonLd: {
        type: 'Organization',
        data: {
          name: 'Ark7',
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: '+1-650-318-1060',
            contactType: 'Customer service',
          },
        },
      },
    },
  },
  {
    path: 'help',
    component: HelpComponent,
    pathMatch: 'full',
    data: {
      title: 'Ark7 - Help Center',
      description:
        'Contact Ark7 customer service for inquiries and start investing in real estate properties.',
      canonical: '/help',
    },
  },
  {
    path: 'faq',
    component: FAQArticleComponent,
    pathMatch: 'full',
    data: {
      title: 'How Ark7 Works? - Ark7 Help',
      description:
        'The best thing about real estate investment, primarily residential rental homes, it is tangible, profitable, and stable.',
      canonical: '/faq',
    },
  },
  {
    path: 'faq-category',
    component: FAQCategoryComponent,
    pathMatch: 'full',
    data: {
      title: 'Investing Basics - Ark7 Help',
      description: 'Explore more about investing on Ark7',
      canonical: '/faq-category',
    },
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule,
      ),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./terms-and-conditions/terms-and-conditions.module').then(
        (m) => m.TermsAndConditionsModule,
      ),
  },
  {
    path: 'referral-terms-and-conditions',
    component: ReferralTermsAndConditionsComponent,
    pathMatch: 'full',
    data: {
      title: 'ARK7 Referral Program Terms and Conditions',
      description:
        'Learn about the ARK7 referral program and earn rewards by referring friends to Ark7.',
      canonical: '/referral-terms-and-conditions',
    },
  },
  {
    path: 'legal/promo',
    component: PromoTermsComponent,
    pathMatch: 'full',
    data: {
      title: 'Ark7 Investor Bonuses Promotion',
      description:
        'Learn about the Investor Bonuses promotion offered by Ark7.',
      canonical: '/legal/promo',
    },
  },
  {
    path: 'legal/dalmore-disclosure',
    component: DalmoreDisclosureComponent,
    pathMatch: 'full',
    data: {
      title: 'Dalmore Reg A Website Disclosure',
      description: 'Learn about Reg A offering materials.',
      canonical: '/legal/dalmore-disclosure',
    },
  },
  {
    path: 'legal/dalmore-secondary-market-trading-agreement',
    component: DalmoreSecondaryMarketTradingAgreementComponent,
    pathMatch: 'full',
    data: {
      title: 'Dalmore Secondary Market Trading Agreement',
      description:
        'Learn about the Dalmore secondary market trading agreement.',
      canonical: '/legal/dalmore-secondary-market-trading-agreement',
    },
  },
  {
    path: 'email-preferences',
    component: EmailPreferencesComponent,
    pathMatch: 'full',
    data: {
      title: 'Email Preferences',
      description:
        'Manage your Ark7 email preferences and customize your subscription settings.',
      canonical: '/email-preferences',
    },
  },
  {
    path: 'a',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'j',
    component: ReferralInvitationComponent,
    data: {
      title: "You've been invited to join Ark7",
      description:
        'Join Ark7 by referral and start investing in real estate properties.',
      canonical: '/j',
    },
  },
  {
    path: 'j-succeed',
    component: ReferralInvitationResultComponent,
    data: {
      title: "You've been invited to join Ark7",
      description:
        'Join Ark7 by referral and start investing in real estate properties!',
      canonical: '/j-succeed',
    },
  },
  {
    path: 'ira',
    loadChildren: () =>
      import('./campaign/ira-landing/ira-landing.module').then(
        (m) => m.IraLandingModule,
      ),
  },
  {
    path: 'join-us',
    loadChildren: () =>
      import('./campaign/join-us/join-us.module').then((m) => m.JoinUsModule),
  },
  {
    path: 'share-investments',
    loadChildren: () =>
      import('./share-investment/share-investment.module').then(
        (m) => m.ShareInvestmentModule,
      ),
    data: {
      canonical: '/share-investments',
    },
  },
  {
    path: 'share-best-investments',
    loadChildren: () =>
      import(
        './share-investment/share-best-investment/share-best-investment.module'
      ).then((m) => m.ShareBestInvestmentModule),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./landing-plus/landing-welcome/landing-welcome.module').then(
        (m) => m.LandingWelcomeModule,
      ),
  },
  {
    path: 'trending-properties',
    loadChildren: () =>
      import(
        './landing-plus/landing-properties/landing-properties.module'
      ).then((m) => m.LandingPropertiesModule),
  },
  {
    path: 'app',
    component: Mobile2Component,
    data: {
      title: 'Ark7 Mobile App',
      description:
        'Download the Ark7 mobile app and invest in real estate properties on the go.',
      canonical: '/app',
    },
  },
  {
    path: 'download-link',
    component: DownloadLinkComponent,
    data: {
      title: 'Ark7 App Download',
      description:
        'Get the download link for the Ark7 mobile app and start investing in real estate properties.',
      canonical: '/download-link',
    },
  },

  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routers, {})],
  exports: [RouterModule],
  providers: [
    FeatureGuard,
    {
      provide: A7_ALLOWED_FEATURES,
      useValue: environment.allowedFeatures || [],
    },
  ],
})
export class RoutingModule {
  constructor(_seo: SeoRoutingHelperService) {}
}
