<div [ngClass]="[
    'color-blue-grey-4 full-width v2-max-w-120',
    v2PaddingH,
    sm ? 'flex-column-between-center' : 'flex-row-between-center '
  ]">

  <div *ngIf="sm"
       class="h3 v2-margin-2-b color-green-grey-1">Featured in</div>

  <div class="full-width scroll-x scroll-bar-hidden">
    <div class="flex-row-between-center full-width v2-min-w-63">
      <div *ngIf="!sm"
           class="h3 color-green-grey-1">Featured in</div>
      <img *ngFor="let logo of logos"
           [src]="logo[0]"
           [alt]="logo[2]"
           class="hover-change"
           [ngClass]="logo[1]" />
    </div>
  </div>
</div>