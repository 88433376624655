import {
  A7ResourceCRUDObservable,
  A7ResourceParams,
  ResourceHandler,
} from '@ark7/resource2';
import { FAQCategory } from '@ark7/core-business-models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/faqs/categories',
  withCredentials: false,
  model: FAQCategory,
})
export class FAQCategoryResource extends A7ResourceCRUDObservable<
  FAQCategory,
  { parentCategory?: string }
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }
}
