export function getBrowserProperties(): BrowserProperties {
  return {
    userAgent: navigator.userAgent,
    window: {
      width: window?.innerWidth,
      height: window?.innerHeight,
      screenX: window?.screenX,
      screenY: window?.screenY,
    },

    screen: {
      width: screen.width,
      height: screen.height,
    },
  };
}

export interface BrowserProperties {
  userAgent: string;
  window: WindowProperty;
  screen: WindowSize;
}

export interface WindowSize {
  width: number;
  height: number;
}

export interface WindowProperty extends WindowSize {
  screenX: number;
  screenY: number;
}
