import _ from 'underscore';
import urlJoin from 'url-join';
import {
  IResourceActionInner,
  IResourceParams,
  Resource,
  ResourceGlobalConfig,
  ResourceHandler,
  ResourceParams,
} from '@ngx-resource/core';

import { RequestContext } from './action';

export interface IA7ResourceParams extends IResourceParams {
  /* Link the model to the current resource */
  linkModel?: boolean;

  /* UI Prefix to the router */
  routerPrefix?: string;
}

export function A7ResourceParams(params?: IA7ResourceParams) {
  if (params.pathPrefix && ResourceGlobalConfig.pathPrefix) {
    params = _.defaults(
      {
        pathPrefix: urlJoin(
          ResourceGlobalConfig.pathPrefix as any,
          params.pathPrefix,
        ),
      },
      params,
    );
  }
  return ResourceParams(params);
}

export class A7Resource extends Resource {
  $lastOptions: IResourceActionInner & RequestContext;

  constructor(handler: ResourceHandler) {
    super(handler);

    const resourceOptions = this.getResourceOptions();
    if (resourceOptions.linkModel) {
      resourceOptions.model.$foreignResource = this;
    }
  }

  getResourceOptions: () => IResourceParams;
}

const oldRestActions = A7Resource.prototype.$restAction;

A7Resource.prototype.$restAction = function $restAction(
  this: A7Resource,
  options: IResourceActionInner,
) {
  this.$lastOptions = options;
  return oldRestActions.apply(this, arguments);
};
