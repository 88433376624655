import { Injectable } from '@angular/core';
import { Carrier } from '@ark7/core-business-models';
import {
  A7ResourceCRUDObservable,
  A7ResourceParams,
  ResourceHandler,
} from '@ark7/resource2';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/anon/carriers',
  withCredentials: false,
  model: Carrier,
})
export class AnonCarrierResource extends A7ResourceCRUDObservable<
  Carrier,
  { tags?: string; ids?: string[]; iraOnly?: boolean }
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }
}

export interface SsrCarrierInfo {
  nickname: string;
}

export const SSR_CARRIERS: { [key: string]: SsrCarrierInfo } = {
  '64d31f15b5603672658fadef': {
    nickname: 'Chicago-C4',
  },
  '651343b656dd7adec7ec0e29': {
    nickname: 'Atlanta-C6',
  },
  '65282f1bc4b08d014b520922': {
    nickname: 'Atlanta-C5',
  },
  '62f1abd3a1588fc9a87a8a29': {
    nickname: 'Atlanta-C3',
  },
  '651342c956dd7adec7ebf9e1': {
    nickname: 'Alexandria-C2',
  },
  '62f1abef37fdb5e637acc4f3': {
    nickname: 'Urbana-S11',
  },
  '62f1abf5c9fcad1274cf568f': {
    nickname: 'Chicago-C1',
  },
  '62f55161c4cd7dd8dc91e362': {
    nickname: 'Atlanta-T4',
  },
  '62f55135c4cd7dd8dc91e2de': {
    nickname: 'Atlanta-T3',
  },
  '62f1ac1da1588fc9a87a8a9b': {
    nickname: 'Tampa-S10',
  },
  '62bd01e1e5598bc12e0123da': {
    nickname: 'Dallas-S9',
  },
  '62c4c5b86c4ab199355f2ae4': {
    nickname: 'Dallas-S8',
  },
  '62bd0154f87ffd1c6ea7ea58': {
    nickname: 'Dallas-S7',
  },
  '6233cdb459542bc51bfe4da9': {
    nickname: 'Arizona City-S6',
  },
  '61e46c0a170ea5b518abf75b': {
    nickname: 'Philadelphia-D2',
  },
  '600715d7a1ad09002c32ce97': {
    nickname: 'Seattle-D1',
  },
  '60071619a1ad09002c32cf50': {
    nickname: 'Chandler-S5',
  },
  '60071604a1ad09002c32cf03': {
    nickname: 'Chandler-S4',
  },
  '61e46c39d18214d6abf6d6f4': {
    nickname: 'Philadelphia-T2',
  },
  '6007163ca1ad09002c32cf8c': {
    nickname: 'Memphis-M4',
  },
  '600715efe609c00034d24130': {
    nickname: 'Philadelphia-T1',
  },
  '600713f8e609c00034d23e24': {
    nickname: 'Austin-S3',
  },
  '5f191dce2d467d002da7c104': {
    nickname: 'Austin-S2',
  },
  '5f17114f5c096b002db13ea5': {
    nickname: 'Austin-S1',
  },
  '5f132b100fd6fa0032f63322': {
    nickname: 'Berkeley-M3',
  },
  '5dc338bb82885c0011c333f4': {
    nickname: 'Berkeley-M2',
  },
  '5d812ddcbfb8100016af9cd3': {
    nickname: 'Berkeley-M1',
  },
};
