<div [ngClass]="[
    'full-width block smooth relative pointer v2-radius-8px  overflow-hidden z-index-higher',
    expanded ? 'v2-border-1px border-primary' : 'v2-max-h-4'
  ]">
  <div class="absolute v2-right-1 v2-top-1 v2-w-2 v2-h-2 v2-radius-8px smooth center-inner click-through"
       [ngStyle]="{
      transform: expanded ? 'translate(0px, 4px)' : 'rotate(180deg) translate(0px, 4px)'
    }">
    &#8963;
  </div>
  <div [ngClass]="[
      'smooth',
      'v2-margin-' + (expanded ? 0 : selected) * 4 + '-t-n'
    ]">
    <div *ngFor="let c of choices; let i = index"
         class="v2-h-4 v2-padding-1 bg-blue-grey-1"
         (click)="selected = i; expanded = !expanded">
      {{ c | case: 'title' }}
    </div>
  </div>
</div>