import {
  A7Resource,
  A7ResourceAction,
  A7ResourceParams,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  withCredentials: true,
  pathPrefix: '/api/v2/stats',
})
export class StatsResource extends A7Resource {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/check-email',
    recover: null,
    model: null,
  })
  checkEmail: IResourceMethodPromise<CheckEmailRequest, CheckEmailResponse>;
}

export interface CheckEmailRequest {
  email: string;
  app: 'renters' | 'main_site';
}

export interface CheckEmailResponseBaseUserStatus {
  // If the record has been created under Allosaurus database.
  created: boolean;

  // If the user has accepted the term of services.
  acceptedTos: boolean;
}

export interface CheckEmailResponse {
  // The email address.
  email: string;

  // Whether the email address is okay to register.
  ok: boolean;

  // If ok is false, the error code.
  code?: string;

  // If ok is false, the error message to display.
  message?: string;

  // If the email is registered on AWS Cognito.
  exists: boolean;

  // If the email is verified on AWS Cognito.
  emailVerified: boolean;

  status: CheckEmailResponseBaseUserStatus;
}
