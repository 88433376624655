import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { A7PageSizeMatcher } from '@ark7/layout';

@Component({
  selector: 'a7-global-notification',
  templateUrl: './global-notification.component.html',
  styleUrls: ['./global-notification.component.scss'],
})
export class GlobalNotificationComponent implements OnInit {
  @A7PageSizeMatcher('XS-') xs;

  constructor(
    public dialogRef: MatDialogRef<GlobalNotificationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      pic: string;
      title: string;
      note: string;
      cta: string;
    },
  ) {}

  ngOnInit(): void {}
}
