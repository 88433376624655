<div [ngClass]="[
    'full-width v2-max-w-120 text-center flex-column-center-center v2-row-gap-16px',
    sm ? 'v2-padding-64px-v' : 'v2-padding-8-v',
    v2PaddingH,
  ]">
  <div class="h1 color-green-grey-1 v2-margin-3-b">
    Build Your Wealth with Ark7
  </div>
  <div class="full-width flex-row-center-center flex-wrap v2-row-gap-3 v2-col-gap-3">
    <div *ngFor="let item of items"
         class="v2-margin-auto block-change v2-w-34 flex-column-center-center text-center bg-blue-grey-1 v2-radius-24px border-box"
         [ngClass]="[sm ? 'v2-padding-2 v2-h-21' : 'v2-padding-5-v v2-h-30']"
         [hoverClass]="['v2-border-1px', 'border-500']">
      <img class="v2-w-4 v2-h-4"
           [src]="item.icon"
           [alt]="item.title" />
      <div class="h3 v2-margin-2-t color-green-grey-1 v2-max-w-26 v2-margin-3-b"
           [ngClass]="[sm ? '' : 'v2-min-h-6 ']">
        {{ item.title }}
      </div>
      <button [routerLink]="item.link"
              [queryParams]="item.params()"
              class="v2-margin-1-t v2-radius-8px v2-border-0px text-white-high-emphasis bg-500 pointer h5 v2-padding-16px-h v2-padding-1-v">
        {{ item.button }}
        <img class="v2-w-2 v2-h-2 vertical-align-middle v2-margin-4px-l"
             src="/assets/v2-icons/right-arrow-circle.svg"
             alt="arrow" />
      </button>
    </div>
  </div>
</div>