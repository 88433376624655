import { Component, Input } from '@angular/core';

@Component({
  selector: 'a7-carrier-captions',
  templateUrl: './carrier-captions.component.html',
  styleUrls: ['./carrier-captions.component.scss'],
})
export class CarrierCaptionsComponent {
  @Input() bold: string[][] = [];
  @Input() gray: string[] = [];
}
