import { A7ResourcesCommonModule } from '@ark7/resources-common';
import { A7SharedComponentsModule } from '@ark7/shared-components';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { PageComponent } from './page.component';
import { WebV2PartsModule } from '../web-v2/parts/v2-parts.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    A7ResourcesCommonModule,
    A7SharedComponentsModule,
    WebV2PartsModule,
  ],
  declarations: [PageComponent, HeaderComponent],
  exports: [PageComponent, HeaderComponent],
})
export class PageModule {}
