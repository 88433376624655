import { Currency, Money } from '@ark7/common-models';
import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@angular/common';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  transform(value: Money, format: string = '.2-2'): string {
    switch (value && value.currency) {
      case Currency.USD:
        return formatCurrency(value.amount, 'en-US', '$', 'USD', format);

      default:
        return value && value.amount.toString();
    }
  }
}
