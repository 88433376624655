import {
  A7ResourceAction,
  A7ResourceCRUD,
  A7ResourceParams,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';
import { Injectable } from '@angular/core';
import {
  RegistrationForm,
  RegistrationFormName,
} from '@ark7/core-business-models';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/registration-forms',
  withCredentials: true,
  model: RegistrationForm,
})
export class RegistrationFormResource extends A7ResourceCRUD<
  RegistrationForm,
  {}
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/',
  })
  getOrCreate: IResourceMethodPromise<
    {
      formName: RegistrationFormName;
      referrer: string;
      tracking: string;
      email: string;
    },
    RegistrationForm
  >;
}
