<a7-auth-form-tab [formTitle]="formTitle">
  <p>
    We have sent a confirmation link to your email address:
    <span class="text-primary">{{email}}</span>
  </p>
  <p>
    If you don't receive it within 5 minutes, please check your spam folder.
  </p>

  <button a7-auth-form-actions
          mat-flat-button
          color="primary"
          [routerLink]="['/a/sign-in']"
          (click)="clickSignInBtn.emit()">
    Login
  </button>
</a7-auth-form-tab>
