import _ from 'underscore';
import moment from 'moment';
import { $$ } from '@ark7/model';
import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { FAQ } from '@ark7/core-business-models';
import { FAQResource } from '@ark7/resources-common';

import { HelpBaseComponent } from '../help-base.component';

@Component({
  selector: 'app-faq-article',
  templateUrl: './faq-article.component.html',
  styleUrls: ['./faq-article.component.scss'],
})
export class FAQArticleComponent extends HelpBaseComponent {
  faq: FAQ;

  relatedFaqs?: FAQ[];

  faqContent?: SafeHtml;

  constructor(
    private route: ActivatedRoute,
    private faqResource: FAQResource,
    private titleService: Title,
    private readonly meta: Meta,
    private sanitizer: DomSanitizer,
  ) {
    super();
    this.safeSubscribe(this.route.queryParams, async (params) => {
      this.faqResource.get({ _id: params.faqId }).subscribe((res) => {
        this.faq = res;
        this.faqContent = this.sanitizer.bypassSecurityTrustHtml(
          $$(this.faq.article).content,
        );

        this.titleService.setTitle(`${$$(this.faq.article).title} - Ark7 Help`);
        this.meta.updateTag({
          name: 'description',
          content: $$(this.faq.article).description,
        });

        this.faqResource
          .queryWithPagination({
            category: $$(this.faq.category)._id.toString(),
            size: 10,
          })
          .subscribe((res) => {
            this.relatedFaqs = _.filter(
              res.data ?? [],
              (x) => x._id != this.faq._id,
            );
          });
      });
    });
  }

  get faqUpdateTime() {
    const ts = moment($$(this.faq.article).publishDate);
    return ts.fromNow();
  }
}
