import debug from 'debug';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const d = debug('resource2:signal');

@Injectable({
  providedIn: 'root',
})
export class SignalService {
  private topicMap = new Map<string, Subject<SignalMessage<any>>>();

  constructor() {}

  getTopic<T>(topic: string): Subject<SignalMessage<T>> {
    if (!this.topicMap.has(topic)) {
      const subject = new Subject<SignalMessage<T>>();
      subject.subscribe(x => {
        d('Signal on topic: %O, %O', topic, x);
      });

      const pos = topic.lastIndexOf(':');
      if (pos !== -1) {
        const parentTopic = topic.substr(0, pos);
        const parentSubject = this.getTopic(parentTopic);
        subject.subscribe(v => {
          parentSubject.next(v);
        });
      }

      this.topicMap.set(topic, subject);
    }

    return this.topicMap.get(topic);
  }
}

export interface SignalMessage<T> {
  topic: string;
  resourceId?: string;
  data?: T;
  err?: any;
}
