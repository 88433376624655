<a *ngIf="routerLinkStr; else noLink"
   [routerLink]="routerLinkStr">
  <img class="v2-obj-contain pointer smooth"
       [ngStyle]="{ 'height': height , 'filter': filter}"
       [src]="logoSrc"
       alt="Ark7" />
</a>
<ng-template #noLink>
  <img class="v2-obj-contain smooth"
       [ngStyle]="{ 'height': height , 'filter': filter}"
       [src]="logoSrc"
       alt="Ark7" />
</ng-template>