import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LoadingProgressor } from '@ark7/loading';
import { SubscribeComponent } from '@ark7/utils';
import { Subscription } from '@ark7/core-business-models';
import { SubscriptionResource } from '@ark7/resources-common';

@Component({
  selector: 'app-email-preferences',
  templateUrl: './email-preferences.component.html',
  styleUrls: ['./email-preferences.component.scss'],
})
export class EmailPreferencesComponent
  extends SubscribeComponent
  implements OnInit {
  handle: string;
  unsubscribe: boolean;
  subscription: Subscription;
  form: UntypedFormGroup;

  unsubscribeSucceed: boolean;

  progressor: LoadingProgressor = new LoadingProgressor({
    estimateLoadingTime: 200,
  });

  constructor(
    private subscriptionResource: SubscriptionResource,
    route: ActivatedRoute,
    fb: UntypedFormBuilder,
  ) {
    super();

    this.form = fb.group({
      disableAll: [false],
      general: [false],
      propertyOperationUpdates: [false],
      productFeatureUpdates: [false],
      tradingOrderUpdates: [false],
    });

    this.safeSubscribe(this.form.controls.disableAll.valueChanges, (val) => {
      this.form.patchValue({
        general: !val,
        propertyOperationUpdates: !val,
        productFeatureUpdates: !val,
        tradingOrderUpdates: !val,
      });
    });

    this.safeSubscribe(route.queryParams, (params) => {
      if (params.handle) {
        this.handle = params.handle;
      }
      if (params.unsubscribe != null) {
        this.unsubscribe = true;
        this.form.controls.disableAll.setValue(true);
      }

      this.load();
    });
  }

  ngOnInit(): void {}

  async load() {
    if (this.handle) {
      this.subscription = await this.progressor.watchAsyncCall(() =>
        this.subscriptionResource.get({
          _id: this.handle,
        }),
      );

      if (!this.unsubscribe) {
        this.form.patchValue(this.subscription.options.marketings);
      }
    }
  }

  async savePreference() {
    this.subscription._id = this.subscription.handle;

    this.subscription = await this.progressor.watchAsyncCall(
      () =>
        this.subscription.options.marketings.$update(this.form.value) as any,
    );

    if (this.unsubscribe && this.subscription.options.marketings.disableAll) {
      this.unsubscribeSucceed = true;
    }
  }
}
