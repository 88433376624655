<v2-home-feature-poster [headline]="heroTitle"
                        [showStarSign]="showStarSign"
                        [points]="[
    showPromoMessage
      ? promoHeroMessage
      : heroSubtitle
  ]"
                        [imgSrc]="heroImage"
                        sectionClass="clear-padding-right"
                        [imageClass]="heroImageClass"
                        imgAlt="Invest in rental properties"
                        [colGap]="sm ? 'v2-col-gap-7' : 'v2-col-gap-1'">
  <ng-container *ngIf="shouldShowHeroCtaEmailInput">
    <div>
      <div class="v2-col-gap-1 v2-row-gap-1"
           [formGroup]="myForm"
           [ngClass]="[
          sm ? 'flex-column-center-center' : 'flex-row-start-center flex-wrap'
        ]">
        <mat-form-field [hideRequiredMarker]="true"
                        appearance="outline"
                        class="bg-blue-grey-2 color-green-grey-1 v2-w-26 v2-max-w-100pct v2-padding-2-h v2-padding-10px-v v2-radius-12px home-no-padding-email">
          <input #heroEmailInput
                 formControlName="heroEmailInput"
                 class="h4 color-green-grey-1 v2-margin-0-v"
                 autocomplete="email"
                 (keyup.enter)="onHeroCta()"
                 placeholder="Email"
                 matInput />
        </mat-form-field>

        <mat-error *ngIf="md && showError"
                   class="full-width label-2">Email is required.</mat-error>

        <button [ngClass]="[
            'cta-button v2-padding-12px-v flex-row-center-center v2-padding-24px-h v2-radius-10px border-300 v2-border-0px text-white-high-emphasis h4 bg-green-grey-9 pointer',
            sm
              ? 'v2-w-26 v2-max-w-100pct'
              : 'v2-margin-2-r'
          ]"
                (click)="onHeroCta()">
          {{ heroCtaText }}
        </button>

        <mat-error *ngIf="!md && showError"
                   class="full-width label-2">Email is required.</mat-error>
      </div>

      <a7-social-sign-in class="a7-ui-auth-form_section v2-margin-16px-t"
                         wordClasses="h4 color-green-grey-3"
                         mode="mini">
      </a7-social-sign-in>
    </div>
  </ng-container>

  <ng-container *ngIf="!shouldShowHeroCtaEmailInput">
    <button [ngClass]="[
        'flex-row-center-center v2-padding-24px-h v2-radius-10px border-300 v2-border-0px text-white-high-emphasis h4 bg-500 pointer',
        sm ? 'v2-w-25 v2-padding-12px-v' : 'v2-padding-16px-v v2-margin-2-r'
      ]"
            routerLink="/a/sign-up">
      <img src="/assets/v2-icons/plane.svg"
           class="svg-white v2-margin-8px-r" />
      {{ heroCtaText }}
    </button>
  </ng-container>
</v2-home-feature-poster>

<div *ngIf="showAnnouncementMessage"
     class="announcement-banner flex-row-start-center fixed z-index-highest v2-padding-1 v2-radius-8px label-1 white">
  <img src="/assets/v2-icons/announcement-icon.svg"
       class="v2-margin-8px-r v2-h-2 v2-w-2" />
  <span class="v2-margin-3-r">{{announcementMessage}}
    <a *ngIf="hasAnnouncementVideoUrl"
       class="v2-margin-1-l"
       (click)="onPlayVideo(announcementVideoUrl)">
      <img src="/assets/play-light.svg"
           alt="play video"
           class="vertical-align-middle v2-margin-4px-r" />
      <span class="color-light-green">{{announcementVideoCtaText}}</span>
    </a>
  </span>
  <span class="pointer v2-right-2 close-icon absolute"
        (click)="closeAnnouncementBanner()">&times;</span>
</div>
