<div [ngClass]="[
    'bg-white',
    sm
      ? 'flex-column-start-center v2-radius-14px  v2-padding-2-h v2-padding-40px-t v2-row-gap-1  v2-margin-4-v'
      : 'flex-row-center-start v2-radius-28px  v2-padding-4  v2-margin-4-t v2-margin-2-b',
    sm
      ? 'v2-w-27 v2-h-47'
      : md
      ? 'v2-w-61 v2-h-36 v2-margin-4-h'
      : 'v2-w-75 v2-h-40 v2-margin-6-h'
  ]">
  <img [src]="profile.profileImgUrl"
       alt="user profile image"
       [ngClass]="[
      sm ? 'v2-w-7 v2-h-7' : 'v2-w-11 v2-h-11 v2-margin-4-r'
    ]" />
  <div [ngClass]="[
      sm ? 'flex-column-center-center' : 'flex-column-center-start'
    ]">
    <div class="h2 color-green-grey-1">{{ profile.name }}</div>
    <ul [ngClass]="[ sm ? 'text-center v2-margin-8px-t' : '' ]">
      <li *ngFor="let item of profile.profiles"
          class="h5 color-green-grey-2">· {{ item }}</li>
    </ul>
    <div [ngClass]="[
        'body-1 color-green-grey-3',
        sm ? 'text-center v2-margin-2-b' : 'v2-margin-2-t'
      ]">
      <p *ngFor="let comment of profile.comments"
         innerHTML="{{ comment.text }}">
      </p>
    </div>
  </div>
</div>