<div [ngClass]="{
    'homepage-typography': false,
    'mobile-typography': sm
  }">
  <v2-header></v2-header>


  <div [ngClass]="[
      'v2-padding-5-t bg-white flex-column-center-center',
      sm ? 'v2-padding-3-b' : 'v2-padding-7-b'
    ]">
    <v2-home-feature-poster headline="Ark7 has decided to side with the many. We are committed to making real estate investment accessible to everyone."
                            headlineClass="headline"
                            [points]="[]"
                            imgSrc="/assets/v2-lego.svg"
                            imgAlt="Simplify the complicated"
                            colGap="v2-col-gap-15">
    </v2-home-feature-poster>
  </div>

  <div [ngClass]="[
      ' bg-blue-grey-1 flex-column-center-center',
      md ? 'v2-row-gap-4 v2-padding-5-v' : 'v2-padding-8-v v2-row-gap-6'
    ]">
    <div class="h1 color-green-grey-1 v2-padding-1-h text-center">The Idea and a Personal Story</div>
    <v2-home-feature-block class="full-width"
                           [bullet]="false"
                           [features]="[
        'Andy lives in the San Francisco Bay Area with family. In 2019, he wanted to invest in real estate in the Bay Area, but found it required a great deal of time and money to purchase and maintain a property. He also tested investing in REITs, the user flexibility didn’t meet expectations either.'
      ]">
      <img src="/assets/about-story.svg"
           class="v2-w-95pct"
           alt="Ark7 Story" />
    </v2-home-feature-block>

    <img *ngIf="!sm"
         src="/assets/v2-then-arrow.svg"
         alt="arrow"
         class="v2-margin-5-b-n v2-margin-8-t-n z-index-high" />

    <v2-home-feature-block class="full-width"
                           [reverse]="true"
                           [bullet]="false"
                           [features]="[
                           'As an ex-Google engineer, when there’s no optimal solution, he creates one. Ark7 was then born out of Andy’s own bedroom to solve this dilemma - for people just like him - by offering high quality, professionally managed, rental homes investment in shares.'
      ]">
      <img src="/assets/about-idea.svg"
           class="v2-w-95pct"
           alt="Ark7 Idea" />
    </v2-home-feature-block>

    <img *ngIf="!sm"
         src="/assets/v2-then-arrow.svg"
         alt="arrow"
         class="flip-x v2-margin-5-b-n v2-margin-8-t-n z-index-high" />

    <v2-home-feature-block class="full-width"
                           [bullet]="false"
                           [features]="[
        'Today, Ark7 has grown to 60+ talented members, serving over 90k+ investors and funded $19MM+ property value across 10 states and expanding. We are still building Ark7 like we are in Day One.'
      ]">
      <img src="/assets/about-coop.svg"
           class="v2-w-95pct"
           alt="Ark7 Founder" />
    </v2-home-feature-block>
  </div>

  <div [ngClass]="[
      'flex-column-center-center full-width bg-white v2-padding-17-b',
      v2PaddingH, 
      sm ? 'v2-padding-5-t' : 'v2-padding-7-t'
    ]">
    <div class="h1 v2-max-w-61 color-green-grey-1 text-center">
      Ark7 is founded upon the value of community, efficiency and transparency.
    </div>
    <div [ngClass]="[
        'full-width v2-max-w-120 v2-margin-2-t flex-row-wrap-center-start',
        sm ? 'v2-row-gap-2' : ''
      ]">
      <ng-container *ngFor="let t of tiles">
        <v2-feature-tile [picSrc]="t[0]"
                         [title]="t[1]"
                         [details]="t[2]"
                         [picAlt]="t[1]"
                         class="v2-margin-2">
        </v2-feature-tile>
      </ng-container>
    </div>

    <button [ngClass]="[
    'flex-row-center-center v2-margin-2-t v2-padding-24px-h v2-radius-10px border-300 v2-border-0px text-white-high-emphasis h4 bg-500 pointer',
    sm ? 'full-width v2-padding-16px-v' : 'v2-min-w-25 v2-padding-20px-v'
  ]"
            routerLink="/a/sign-up">
      Join the community
    </button>
  </div>

  <v2-page-footer [topPadding]="xs ? 'v2-padding-6-t' : 'v2-padding-9-t'"></v2-page-footer>
</div>
