import { ErrorHandler, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Logger } from './logger.service';

export class A7ResourcesCommonErrorHandler extends ErrorHandler {
  constructor(
    private logger: Logger,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    super();
  }

  handleError(error: any) {
    super.handleError(error);
    if (isPlatformBrowser(this.platformId)) {
      this.logger.warn('client side error', error);
    }
  }
}
