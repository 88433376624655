import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ContentContainerDirective } from './directives/content-container.directive';
import { DesignSystemDirective } from './directives/design-system.directive';
import { ExperimentInterceptor } from './interceptors/experiment.interceptor';
import { GTAG_CONFIG, LayoutConfigs } from './declarations';
import { LayoutSectionDirective } from './directives/layout-section.directive';
import { LayoutSubscribeComponent } from './layout.subscribe.component';
import { PageSizeDirective } from './directives/page-size.directive';

@NgModule({
  declarations: [
    PageSizeDirective,
    DesignSystemDirective,
    LayoutSectionDirective,
    ContentContainerDirective,
    LayoutSubscribeComponent,
  ],
  imports: [CommonModule],
  exports: [
    PageSizeDirective,
    DesignSystemDirective,
    LayoutSectionDirective,
    ContentContainerDirective,
  ],
  providers: [],
})
export class A7LayoutModule {
  static forRoot(
    configs: LayoutConfigs = {},
  ): ModuleWithProviders<A7LayoutModule> {
    return {
      ngModule: A7LayoutModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ExperimentInterceptor,
          multi: true,
        },
        {
          provide: GTAG_CONFIG,
          useValue: configs.gtag,
        },
      ],
    };
  }
}
