export const environment = {
  env: 'staging',
  production: true,
  host: 'staging.ark7.com',
  apiHost: 'https://ark7-corp-allosaurus-staging.us-west1.ark7.com',
  authenticatedRedirectUrl: '/dashboard',
  prodHost: 'https://staging.ark7.com',
  allowedRedirectHost: ['admin.staging.ark7.com', 'staging.ark7.com'],
  gtag: {
    trackingId: 'UA-163373891-2',
    ga4TrackingId: null,
    adsTrackingId: null,
    adsConversionSignUp: null,
    adsConversionInvestment: null,
  },
  cognito: {
    authOptions: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-west-2:bde6385f-b1ea-4842-be96-b919bfb82bfc',

      // REQUIRED - Amazon Cognito Region
      region: 'us-west-2',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: 'us-west-2',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-west-2_HVdDIWucM',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '7987poji324c1u086344ct1or4',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: '.ark7.com',
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
      },

      // OPTIONAL - customized storage object
      // storage: new MyStorage(),

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  },
  allowedFeatures: [],
  clientId: '9301',
  clientSecret: 'Ue7ZaafTqHX3k8aqTD4x',
};
