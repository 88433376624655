import _ from 'underscore';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten',
})
export class ShortenPipe implements PipeTransform {
  transform(value: string, length: number | string = 30): any {
    if (value == null) {
      return null;
    }

    const l = _.isNumber(length) ? length : Number.parseInt(length, 10);

    if (value.length > l) {
      const idx = value.lastIndexOf('.');
      const l1 = l - (value.length - idx) - 6;
      if (idx >= 3 && l1 > 0) {
        return `${value.substr(0, l1)}...${value.substring(idx - 3)}`;
      } else {
        return l > 10 ? value.substr(0, l - 3) + '...' : value.substr(0, l);
      }
    }
    return value;
  }
}
