<div class="label-content text-center"
     *ngIf="alwaysExpand || pageSize === 'XL' || pageSize === 'LG' || pageSize === 'MD'">
   By clicking the button, I agree to
   <a href="https://ark7.com/terms-and-conditions"
      target="_blank"
      color="primary">Ark7's Terms & Conditions</a>,
   <a href="https://ark7.com/privacy-policy"
      target="_blank"
      color="primary">Privacy Policy</a>

   <ng-container *ngIf="toggleDwolla">
      , and Dwolla's
      <a href="https://www.dwolla.com/legal/tos/"
         target="_blank"
         color="primary">Terms of Services</a> and
      <a href="https://www.dwolla.com/legal/privacy/"
         target="_blank"
         color="primary">Privacy Policy</a>
   </ng-container>.
</div>

<div class="label-content text-center margin-sm-t"
     *ngIf="!alwaysExpand && (pageSize === 'SM' || pageSize === 'XS')">
   By proceeding, you agree with our
   <a href="https://ark7.com/terms-and-conditions"
      target="_blank"
      color="primary">Terms & Conditions</a>.
</div>
