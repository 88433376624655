import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { AboutComponent } from './pages/about/about.component';
// import { HomeComponent } from './pages/home/home.component';
// import { LogOnComponent } from './pages/log-on/log-on.component';
// import { SignUpComponent } from './pages/sign-up/sign-up.component';

const routes: Routes = [
  // {
  //   path: 'home',
  //   component: HomeComponent,
  //   data: {
  //     title: 'Ark7',
  //   },
  // },
  // {
  //   path: 'about',
  //   component: AboutComponent,
  //   data: {
  //     title: 'About - Ark7',
  //   },
  // },
  // {
  //   path: 'sign-up',
  //   component: SignUpComponent,
  //   data: {
  //     title: 'Sing Up with Ark7',
  //   },
  // },
  // {
  //   path: 'sign-in',
  //   component: LogOnComponent,
  //   data: {
  //     title: 'Sing Up with Ark7',
  //   },
  // },
  // {
  //   path: '**',
  //   redirectTo: 'home',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebV2RoutingModule {}
