<mat-tab-group [selectedIndex]="stage"
               class="a7-ui-auth-form_tab-group">
  <!-- selectedIndex 0: Sign-in Form -->
  <mat-tab>
    <a7-auth-form-tab [formGroup]="form">
      <ng-container a7-auth-form-title>
        <ng-container *ngIf="logo">
          <a *a7PageSize="'SM,XS'"
             href="/">
            <div class="text-center text-primary large-logo">
              <span [ngClass]="[logo]"></span>
            </div>
          </a>
        </ng-container>
        <h1 class="text-center color-green-grey-1">
          {{ formTitle ?? formTitleWithDefault }}
        </h1>
      </ng-container>
      <mat-form-field class="a7-form-field v2-margin-1-b"
                      [hideRequiredMarker]="true">
        <div matPrefix
             class="v2-h-5 v2-w-4 center-inner">
          <img src="/assets/v2-email.svg"
               alt="email" />
        </div>
        <input matInput
               name="email"
               type="email"
               autocomplete="email"
               autocorrect="off"
               autocapitalize="none"
               formControlName="username"
               placeholder="Email Address" />

        <!-- <mat-placeholder class="color-green-grey-3">Email Address</mat-placeholder> -->

        <mat-error *ngIf="
            form.controls['username'].hasError('email') ||
            form.controls['username'].hasError('required')
          ">
          Please enter your email address
        </mat-error>
        <mat-error *ngIf="form.controls['username'].hasError('userNotExist')">
          User does not exist
        </mat-error>
      </mat-form-field>
      <mat-form-field class="a7-form-field v2-margin-1-b"
                      [hideRequiredMarker]="true">
        <div matPrefix
             class="v2-h-5 v2-w-4 center-inner">
          <img src="/assets/v2-password.svg"
               alt="password" />
        </div>

        <input matInput
               type="password"
               autocomplete="current-password"
               formControlName="password"
               placeholder="Password" />

        <!-- <mat-placeholder class="color-green-grey-3">Password</mat-placeholder> -->

        <mat-error *ngIf="form.controls['password'].hasError('unauthorized')">
          Email and password do not match
        </mat-error>
        <mat-error *ngIf="form.controls['password'].hasError('required')">
          Please enter your password
        </mat-error>
      </mat-form-field>
      <button a7-auth-form-actions
              mat-flat-button
              [disabled]="loggingIn"
              color="primary"
              (click)="login()"
              class="h4">
        Login
      </button>
      <ng-container a7-auth-form-bottom>
        <div class="form-info text-primary">
          <a color="primary"
             class="button-2"
             [routerLink]="'/a/forgot-password'">Forgot password?</a>
        </div>

        <div *a7PageSize="'SM,XS'">
          <a [routerLink]="'/a/sign-up'"
             class="button-2"
             color="primary">Create New Account</a>
        </div>

        <div *a7PageSize="'XL,LG,MD'"
             class="button-3 text-primary">
          Don’t have an account yet?
          <a [routerLink]="'/a/sign-up'"
             class="button-2"
             color="primary">Sign up</a>
        </div>
      </ng-container>
    </a7-auth-form-tab>
    <a7-social-sign-in *ngIf="social"
                       class="a7-ui-auth-form_section"
                       [blueFb]="false"
                       wordClasses="h4 color-green-grey-3"></a7-social-sign-in>
  </mat-tab>

  <!-- selectedIndex 1: registered successfully -->
  <mat-tab>
    <a7-sent-verification-email formTitle="WELCOME TO ARK7"
                                [email]="email"
                                (clickSignInBtn)="stage = 0">
    </a7-sent-verification-email>
  </mat-tab>

  <!-- selectedIndex 2: two FA SEND -->
  <mat-tab>
    <div class="h1 color-green-grey-1 text-center">2-Step Authentication</div>
    <div class="body-2 color-green-grey-3 v2-margin-40px-t">
      Your account is protected by 2-step authentication. Please select a
      preferred method to receive your verification code:
    </div>

    <mat-select class="full-width v2-margin-40px-t block bg-blue-grey-1 v2-padding-2-h v2-padding-16px-v v2-radius-8px"
                [(value)]="verificationMethod">
      <mat-option [value]="method"
                  *ngFor="let method of TwoFAVerificationMethod">{{ method | case: 'title' }}</mat-option>
    </mat-select>

    <button mat-flat-button
            color="primary"
            [disabled]="sendingTwoFACode"
            (click)="send2FACode()"
            class="h4 full-width v2-margin-40px-t">
      Send Code
    </button>
  </mat-tab>

  <!-- selectedIndex 2: two FA VERIFY -->
  <mat-tab>
    <div class="v2-themed-inputs">
      <div class="center-inner">
        <div (click)="stage = 2"
             class="v2-margin-1-r pointer">
          <img src="/assets/v2-back-grey.svg"
               alt="back" />
        </div>
        <div class="text-center">
          <div class="h1 color-green-grey-1 nowrap">2-Step Authentication</div>
        </div>
        <div></div>
      </div>

      <div class="body-2 color-green-grey-3 v2-margin-40px-t">
        Please enter the verification code we sent to you via
        {{ verificationMethod | case: 'title' | case: 'lower' }}. The code will
        expire in 10 minutes.
      </div>

      <a7-code-input #codeInput
                     class="v2-margin-40px-t"
                     (codeEntered)="autoTriggerVerify.next()">
      </a7-code-input>

      <div *ngIf="verify2FAFailed"
           class="body-2 color-error-1 v2-margin-4px-t text-center">
        Invalid code
      </div>

      <div class="v2-margin-20px-t full-width text-center"
           [ngClass]="{ 'color-green-grey-3 label-2': !resendEnable, 'text-primary body-2': resendEnable }">
        <span *ngIf="!resendEnable">Resend in {{ count }} seconds</span>
        <a *ngIf="resendEnable"
           (click)="send2FACode()">Resend Code</a>
      </div>

      <button mat-flat-button
              color="primary"
              [disabled]="!codeInput?.form?.valid"
              (click)="verify2FACode()"
              class="h4 full-width v2-margin-40px-t">
        Verify
      </button>
    </div>
  </mat-tab>

  <!-- selectedIndex 4: internal server error -->
  <mat-tab>
    <a7-internal-server-error (back)="stage = 0"> </a7-internal-server-error>
  </mat-tab>
</mat-tab-group>
