import { Injectable } from '@angular/core';
import {
  A7Resource,
  A7ResourceAction,
  A7ResourceParams,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  withCredentials: true,
  pathPrefix: '/api/v2/verified',
})
export class VerifiedResource extends A7Resource {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    model: null,
    method: ResourceRequestMethod.Get,
    path: '/{!uuid}',
  })
  importUser: IResourceMethodPromise<{ uuid: string }, ImportUserResponse>;
}

export interface ImportUserResponse {
  email: string;
  existingUser: boolean; // true if email registered or imported before
}
