import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ContentContainer,
  ContentContainerDirective,
} from '../directives/content-container.directive';
import { LayoutSectionDirective } from '../directives';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContentContainerService {
  contentContainer: ContentContainerDirective;
  scroll: Observable<Event>;
  activeLayoutSection: Observable<LayoutSectionDirective>;

  _activeLayoutSection = new EventEmitter();

  constructor() {}

  get fragmentLocked(): boolean {
    return this.contentContainer && this.contentContainer.fragmentLocked;
  }

  setContentContainer(contentContainer: ContentContainerDirective) {
    this.contentContainer = contentContainer;
    this.scroll = contentContainer.scroll;

    this.activeLayoutSection = this._activeLayoutSection.pipe(
      distinctUntilChanged(),
    );
  }

  unsetContentContainer() {
    this.contentContainer = null;
  }

  setActiveLayoutSection(activeLayoutSection: LayoutSectionDirective) {
    if (this.contentContainer) {
      this._activeLayoutSection.next(
        this.contentContainer.setActiveLayoutSection(activeLayoutSection),
      );
    }
  }

  unsetActiveLayoutSection(layoutSection: LayoutSectionDirective) {
    if (this.contentContainer) {
      this._activeLayoutSection.next(
        this.contentContainer.unsetActiveLayoutSection(layoutSection),
      );
    }
  }

  get size(): ContentContainer {
    return this.contentContainer ? this.contentContainer.size : null;
  }
}
