import { Component, Input, ViewChild } from '@angular/core';
import { Carrier, User } from '@ark7/core-business-models';
import { A7PageSizeMatcher } from '@ark7/layout';
import {
  AppConfigurationService,
  UserService,
  UserSubscribeComponent,
} from '@ark7/resources-common';
import { ClipboardService } from 'ngx-clipboard';
import { addParamsSignature, updateQueryParam } from '../lib';
@Component({
  selector: 'a7-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
})
export class SocialShareComponent extends UserSubscribeComponent<User> {
  @A7PageSizeMatcher('XS-') xs;

  @ViewChild('dialogBase') dialogBase;

  @Input() text = 'Share';
  @Input() url: string = undefined;
  @Input() headingSize: string = 'h4';
  @Input() carrier: Carrier;
  constructor(
    private clipboard: ClipboardService,
    private configurationService: AppConfigurationService,
    userService: UserService,
  ) {
    super(userService);
  }

  share() {
    this.clipboard.copyFromContent(this.shareLink);
    this.text = 'Copied!';
  }

  get shareLink(): string {
    let url = updateQueryParam(
      this.url ?? window.location.href,
      'r',
      this.user?.referralCode,
    );
    url = updateQueryParam(
      url,
      'tc',
      this.configurationService.referralTrackingCode,
    );

    const signedUrl = addParamsSignature(url);
    return signedUrl;
  }

  get shareMessage(): string {
    const fireEmoji = '\u{1F525}';
    const houseEmoji = '\u{1F3D8}';
    const message = `${fireEmoji} Check out this promising rental investment opportunity in ${this.carrier.address?.city} with Ark7, and let me know what you think! ${houseEmoji} Join me to earn passive income on your own items.`;
    return message;
  }

  // share popup on property page
  onShareClick() {
    if (this.carrier) {
      this.dialogBase.showDialog();
    } else {
      this.share();
    }
  }
}
