<div class="dialog-padding">
  <h1 mat-dialog-title>You are about to be signed out of Ark7</h1>
  <div class="padding-2-v body-2">
    <p>
      For security reasons, your connection times out after you've been inactive for a while.
    </p>
    <p>
      Click Continue to stay signed in.
    </p>
  </div>
  <div mat-dialog-actions>
    <div class="flex-row-between-center full-width">
      <div class="flex-scale"></div>
      <button mat-flat-button
              mat-dialog-close
              color="primary"
              (click)="continue()">
        Continue
      </button>
    </div>
  </div>
</div>
