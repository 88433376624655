<ng-container *ngIf="loadingContent.state === 'loading' && !barMode">
  <fa-icon [icon]="['fas', 'spinner']"
           [spin]="true"></fa-icon>
  <ng-content></ng-content>
</ng-container>
<ng-container *ngIf="loadingContent.state === 'loading' && barMode">
  <mat-progress-bar color="primary"
                    mode="indeterminate"></mat-progress-bar>
</ng-container>

<div *ngIf="loadingContent.state === 'failure'"
     class="text-warn"
     [matTooltip]="detailMessage">
  <fa-icon [icon]="['fas', 'fill-drip']"></fa-icon>
  {{errorMessage || 'Loading content failed! Please try again later.'}}
</div>

<div *ngIf="loadingContent.state === 'success'"
     class="text-primary"
     [matTooltip]="detailMessage">
  <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
  {{successMessage}}
</div>
