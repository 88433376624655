<mat-tab-group [selectedIndex]="stage"
               class="a7-ui-auth-form_tab-group">
  <!-- selectedIndex 0 -->
  <mat-tab>
    <a7-auth-form-tab [formTitle]="formTitle"
                      [formGroup]="form">

      <mat-form-field class="a7-form-field"
                      [hideRequiredMarker]="true"
                      >
        <span *ngIf="!v2"
              matPrefix
              class="form-icon icon-user-weight-3">
        </span>
        <input matInput
               formControlName="first"
               name="first"
               placeholder="First name">
        <mat-error *ngIf="form.controls['first'].hasError('required')">
          Please enter your first name.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="a7-form-field"
                      [hideRequiredMarker]="true"
                      >
        <span *ngIf="!v2"
              matPrefix
              class="form-icon icon-user-weight-3">
        </span>
        <input matInput
               formControlName="last"
               name="last"
               placeholder="Last name">
        <mat-error *ngIf="form.controls['last'].hasError('required')">
          Please enter your last name.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="a7-form-field"
                      [hideRequiredMarker]="true"
                      >
        <span *ngIf="!v2"
              matPrefix
              class="form-icon icon-email-weight-3">
        </span>

        <input matInput
               formControlName="email"
               name="email"
               placeholder="Email"
               type="email"
               autocomplete="email"
               autocorrect="off"
               autocapitalize="none">
        <mat-error *ngIf="emailControl.hasError('checkEmail')">
          {{emailControl.errors['checkEmail'].message}}
        </mat-error>
        <mat-error *ngIf="emailControl.hasError('required') || emailControl.hasError('email')">
          Please enter your email.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="a7-form-field"
                      [hideRequiredMarker]="true"
                      >
        <span *ngIf="!v2"
              matPrefix
              class="form-icon icon-lock-weight-3">
        </span>
        <input matInput
               formControlName="password"
               name="password"
               placeholder="Password"
               type="password"
               autocomplete="new-password">
        <mat-error *ngIf="passwordControl.hasError('required')">
          Please enter a password.
        </mat-error>
        <mat-error *ngIf="passwordControl.hasError('minlength')">
          Please enter at least 8 characters.
        </mat-error>
        <mat-error *ngIf="passwordControl.hasError('pattern') && !passwordControl.hasError('minlength')"
                   [ngSwitch]="passwordControl.errors['pattern'].requiredPattern">
          <ng-container *ngSwitchCase="'/[A-Z]/'">
            Please enter at least 1 upper character.
          </ng-container>
          <ng-container *ngSwitchCase="'/[a-z]/'">
            Please enter at least 1 lower character.
          </ng-container>
          <ng-container *ngSwitchCase="'/[0-9]/'">
            Please enter at least 1 lower character.
          </ng-container>
          <ng-container *ngSwitchDefault>
            Please enter at least 1 special character.
          </ng-container>
        </mat-error>
        <mat-error *ngIf="passwordControl.hasError('invalid')">
          {{passwordControl.errors['invalid']}}
        </mat-error>
      </mat-form-field>



      <ng-content select="[a7-sign-up-form-tos]">
      </ng-content>

      <button a7-auth-form-actions
              mat-flat-button
              [disabled]="signingUp"
              color="primary"
              (click)="signup()">
        {{cta}}
      </button>



      <ng-container a7-auth-form-bottom>
        <ng-container *ngIf="signInLink">
          <div *a7PageSize="'XL,LG,MD'"
               class="text-center text-primary button-3 margin-sm-t">
            Already with us?
            <a [routerLink]="['/a/sign-in']"
               class="button-2"
               color="primary">
              Sign In
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="getAppLink">
          <div class="text-center text-primary button-3 margin-sm-t">
            Already signed up?
            <a [routerLink]="['/app']"
               class="button-2"
               color="primary">
              Get The App
            </a>
          </div>
        </ng-container>

        <ng-content select="[a7-sign-up-form-tos-post]">
        </ng-content>
      </ng-container>

    </a7-auth-form-tab>

    <a7-social-sign-in *ngIf="social"
                       class="a7-ui-auth-form_section"
                       action="Continue"></a7-social-sign-in>
  </mat-tab>

  <!-- selectedIndex 1: already registered -->
  <mat-tab>
    <a7-auth-form-tab formTitle="ALREADY REGISTERED">
      Your email is already registered with us.

      <ng-container a7-auth-form-actions>
        <button mat-flat-button
                color="primary"
                [routerLink]="['/a/sign-in']">
          Login
        </button>

        <button mat-button
                color="primary"
                [routerLink]="['/a/forgot-password']">
          Reset password
        </button>
      </ng-container>
    </a7-auth-form-tab>
  </mat-tab>

  <!-- selectedIndex 2: internal server error -->
  <mat-tab>
    <a7-internal-server-error (back)="stage = 0">
    </a7-internal-server-error>
  </mat-tab>

  <!-- selectedIndex 3: registered successfully -->
  <mat-tab>
    <a7-sent-verification-email *ngIf="!customizedRegistrationResult"
                                formTitle="Welcome to Ark7"
                                [email]="emailControl.value">
    </a7-sent-verification-email>

    <ng-content select="[a7-sign-up-form-registration-successful]">
    </ng-content>
  </mat-tab>
</mat-tab-group>
