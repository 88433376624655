import { CarrierRef } from '@ark7/common-models';
import { Pipe, PipeTransform } from '@angular/core';
import { Ref } from '@ark7/model';

@Pipe({
  name: 'asCarrierRef',
  pure: true,
})
export class AsCarrierRefPipe implements PipeTransform {
  transform(value: Ref<CarrierRef>): CarrierRef {
    return value as CarrierRef;
  }
}
