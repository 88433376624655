<div [ngClass]="['overflow-hidden']"
     [ngStyle]="{
    width: initWidth ? initWidth + 'px' : '100%'
  }">
  <div [ngClass]="['flex-row-around-center', smoothClass]"
       [ngStyle]="{
      width: panels.length * initWidth + 'px',
      transform: 'translate(' + selected * -initWidth + 'px)'
    }">
    <div *ngFor="let p of panels; let i = index"
         class="inline-block a7-switch-panel-junior-panel"
         [ngStyle]="{
        width: initWidth + 'px'
      }">
      <ng-container *ngIf="!lazyRendering || i === selected"
                    [ngTemplateOutlet]="p.template"></ng-container>
    </div>
  </div>
</div>