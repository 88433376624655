import { Injectable } from '@angular/core';
import { Identity } from '@ark7/identity';
import {
  A7ResourceAction,
  A7ResourceParams,
  A7ResourceSingletonObservable,
  IResourceMethodObservable,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';

export function USER_RECOVER(err: any, _resource: BasicUserResource<Identity>) {
  const meta: any = err.body && err.body.meta;
  if (
    err?.status === 401 &&
    // Not because of term of service
    (meta == null || meta.tos == null) &&
    err?.body?.message !== 'two_factor_verification_required'
  ) {
    return null;
  }
  throw err;
}

const referrer = typeof document !== 'undefined' ? document.referrer : '';
const uri = typeof location !== 'undefined' ? location.href : '';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  withCredentials: true,
  model: Identity,
  recover: USER_RECOVER,
  pathPrefix: '/api/v2/user',
  headers: {
    'Origin-Referrer': referrer,
    'Origin-Uri': uri,
  },
})
export class BasicUserResource<
  T extends Identity = Identity
> extends A7ResourceSingletonObservable<T> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/email-verified-event',
  })
  emitEmailVerifiedEvent: IResourceMethodPromise<{ email: string }, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/sign-in-event',
  })
  emitSignInEvent: IResourceMethodPromise<{}, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/sign-out-event',
  })
  emitSignOutEvent: IResourceMethodPromise<{}, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/sign-out',
  })
  signOut: IResourceMethodPromise<{}, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/sign-out-elsewhere',
  })
  signOutElsewhere: IResourceMethodPromise<{}, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/request-deletion',
  })
  _requestDeletion: IResourceMethodPromise<{}, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/confirm-deletion',
  })
  _confirmDeletion: IResourceMethodPromise<{ code: string; reason: string }, T>;

  /**
   * Use user.sendPhoneVerification instead.
   */
  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/send-phone-verification',
    asObservable: true,
  })
  _sendPhoneVerification: IResourceMethodObservable<{}, T>;

  /**
   * Use user.verifyPhone instead.
   */
  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/verify-phone',
    asObservable: true,
  })
  _verifyPhone: IResourceMethodObservable<{ code: string }, T>;

  /**
   * Use user.verifyEmail instead.
   */
  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/verify-email',
    asObservable: true,
  })
  _verifyEmail: IResourceMethodObservable<{ code: string }, T>;

  /**
   * Use user.verifyDwolla instead.
   */
  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/verify-dwolla',
    asObservable: true,
  })
  _verifyDwolla: IResourceMethodPromise<{}, T>;

  /**
   * Use user.sendEmailVerification instead.
   */
  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/send-email-verification-post',
    asObservable: true,
  })
  _sendEmailVerification: IResourceMethodObservable<{}, T>;

  /**
   * Use user.changeEmail instead.
   */
  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/change-email',
    asObservable: true,
  })
  _changeEmail: IResourceMethodObservable<{ newEmail: string }, T>;

  /**
   * Use user.changePassword instead.
   */
  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/change-password',
    asObservable: true,
  })
  _changePassword: IResourceMethodObservable<
    { oldPassword: string; newPassword: string },
    T
  >;
}
