import _ from 'underscore';
import { Carrier } from '@ark7/core-business-models';
import { Component, Input, OnInit } from '@angular/core';
import { PropertyPOI, PropertyPOIType } from '@ark7/property-models';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-investment-location-schools',
  templateUrl: './investment-location-schools.component.html',
  styleUrls: ['./investment-location-schools.component.scss'],
})
export class InvestmentLocationSchoolsComponent implements OnInit {
  @Input() carrier: Carrier;

  _viewAll: boolean = false;
  counter: number = 0;
  schools: PropertyPOI[] = [];
  formatNumber = formatNumber;

  constructor() {}

  ngOnInit() {
    this.refresh();
  }

  private refresh() {
    this.schools = _.filter(
      this.carrier.locations?.pois,
      (p) => p.type === PropertyPOIType.SCHOOL,
    ).sort(
      (a, b) =>
        (b.greatSchoolData.rating ?? 0) - (a.greatSchoolData.rating ?? 0),
    );
    this.counter = this.schools.length;
    if (!this._viewAll) {
      this.schools = _.first(this.schools, 4);
    }
  }

  viewAll(all: boolean) {
    this._viewAll = all;
    this.refresh();
  }
}
