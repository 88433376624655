import * as _ from 'underscore';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export * from './fade';
export * from './slide';


export const STATE_HIDE_2 = style({
  position: 'absolute',
  top: 0,
  left: 0,
  opacity: 0,
  width: '100%',
  transform: 'translateY(100%)',
  display: 'none',
});

export const STATE_HIDE_2_OUT = style({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  opacity: 0,
  transform: 'none',
});

export const STATE_HIDE_2_IN = style({
  position: 'relative',
  opacity: 0,
  transform: 'none',
});

export const STATE_SHOW_IN_2 = style({
  position: 'relative',
  opacity: 1,
  transform: 'none',
});

export const STATE_SHOW_OUT_2 = style({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  opacity: 1,
  transform: 'none',
});

export const ANIMATE = trigger('animate', [
  state('in', STATE_SHOW_IN_2),
  state('hide', STATE_HIDE_2),

  state('fadeIn', STATE_SHOW_IN_2),
  state('fadeOut', STATE_HIDE_2),
  state('slideUpIn', STATE_SHOW_IN_2),
  state('slideUpOut', STATE_HIDE_2),

  state('*', STATE_SHOW_IN_2),

  transition('* => fadeIn', [
    STATE_HIDE_2_IN,
    animate('200ms 0ms ease-out', STATE_SHOW_IN_2),
  ]),

  transition('* => fadeOut', [
    STATE_SHOW_OUT_2,
    animate('200ms 0ms ease-out', STATE_HIDE_2_OUT),
  ]),

  transition('* => slideUpIn', [
    STATE_HIDE_2_IN,
    style({
      opacity: 1,
      transform: 'translateY(100%)',
    }),
    animate('200ms 0ms ease-out', STATE_SHOW_IN_2),
  ]),

  transition('* => slideUpOut', [
    STATE_SHOW_OUT_2,
    animate(
      '200ms 0ms ease-out',
      style({
        opacity: 0,
        transform: 'translateY(-100%)',
      }),
    ),
  ]),
]);
