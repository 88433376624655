<div class="full-width block relative">
  <ng-content></ng-content>

  <div [ngClass]="[
      'flex-row-between-center absolute v2-top-0 v2-left-0 full-size swipe-trap',
      buttonShape === 'circle' ? 'v2-padding-4px-h' : '']"
       (swipeleft)="next()"
       (swiperight)="prev()">
    <ng-container *ngTemplateOutlet="button; context: { left: true }"></ng-container>

    <div *ngIf="showBottomDots"
         class="flex-column-end-center full-height">
      <div class="flex-row-center-center v2-col-gap-1"
           [ngStyle]="{
          transform: 'translate(0px, ' + dotYOffSet + ')'
        }">
        <div *ngFor="let item of [].constructor(count); let i = index"
             [ngClass]="[
            'v2-h-1 v2-w-1 index-circle border-circle',
            i === index ? dotClass : 'bg-blue-grey-4',
            dotClickable ? 'pointer' : ''
          ]"
             (click)="dotClickable && (index = i); $event.stopPropagation()"></div>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="button; context: { left: false }"></ng-container>
  </div>
</div>

<ng-template #button
             let-left="left">
  <button [ngClass]="[
      'bg-white pointer v2-border-0px box-shadow-1 center-inner',
      buttonClasses(left)
    ]"
          [ngStyle]="{
      'width.px': buttonSize,
      height: buttonShape === 'circle' ? buttonSize + 'px' : '100%',
      'padding.px': buttonSize / 8,
      transform:
        'translate(' +
        buttonOffSet(left) +
        'px, 0) rotate(' +
        (left ? 0 : 180) +
        'deg)'
    }"
          (click)="left ? prev() : next(); $event.stopPropagation()">
    <svg width="32"
         height="32"
         viewBox="0 0 32 32"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M20.6448 7.32659C20.1713 6.89115 19.4035 6.89112 18.9299 7.32659L10.3552 15.2115C9.88161 15.647 9.88161 16.353 10.3552 16.7885L18.9299 24.6734C19.4034 25.1089 20.1713 25.1089 20.6448 24.6734C21.1184 24.2379 21.1184 23.5319 20.6448 23.0964L12.9276 16L20.6448 8.90357C21.1184 8.46811 21.1184 7.76203 20.6448 7.32659Z"
            fill="#3B4742" />
    </svg>
  </button>
</ng-template>
