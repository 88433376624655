<div class="body-1 color-green-grey-2 v2-margin-20px-t flex-column-between-start v2-row-gap-8px">
  <div *ngFor="let p of ((carrier.introduction?.summary?.split('\n') ?? []))"
       [innerHtml]="p"></div>
</div>

<div class="full-width flex-row-start-center flex-wrap v2-col-gap-3 v2-margin-2-t">
  <div class="v2-w-30"
       *ngFor="let field of ['walkScore', 'transitScore']">
    <ng-container *ngTemplateOutlet="scoreWidget;context: {field}">
    </ng-container>
  </div>
</div>

<app-investment-location-schools *ngIf="showSchools"
                                 [carrier]="carrier"
                                 class="full-size v2-margin-20px-t">
</app-investment-location-schools>


<ng-template #scoreWidget
             let-field="field">
  <div class="flex-row-between-center full-width v2-col-gap-16px"
       *ngIf="carrier.locations?.accessibility[field] > 0">
    <div class="label-1 color-green-grey-2">
      {{ field | case: 'title' }}
    </div>

    <div class="relative flex-scale v2-h-8px v2-radius-8px bg-blue-grey-2 overflow-hidden">
      <div [ngClass]="[
          'full-height bg-green-grey-2 v2-radius-8px',
          'v2-w-' + carrier.locations?.accessibility[field] + 'pct'
        ]"></div>
    </div>
    <div>
      <span class="h5 color-green-grey-2">
        {{ carrier.locations?.accessibility[field] }}
      </span>
      <span class="footer-2 color-green-grey-3"> /100 </span>
    </div>
  </div>
</ng-template>