<mat-expansion-panel [hideToggle]="hideToggle"
                     [expanded]="expanded"
                     [disabled]="disabled">
  <mat-expansion-panel-header class="v2-margin-1-t v2-margin-2-b">
    <mat-panel-title>
      <div class="h4 text-white-high-emphasis">{{ header }}</div>
    </mat-panel-title>
    <mat-panel-description> </mat-panel-description>
  </mat-expansion-panel-header>
  <div *ngFor="let m of menu"
       [ngClass]="[
      'h5 color-green-grey-3 v2-margin-8px-b pointer',
      hideToggle ? '' : 'v2-margin-1-l'
    ]">
    <a *ngIf="m.href"
       [href]="m.href"
       target="{{ m.target }}"> {{ m.text }}</a>
  </div>
</mat-expansion-panel>