import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { A7LayoutService, PageSize } from '@ark7/layout';
import { UserService } from '@ark7/resources-common';
import { a7EmailValidator, A7Router, Debug, forms } from '@ark7/utils';
import debug from 'debug';

import { UserRedirectComponent } from '../user-redirect-component';

const d = debug('a7-ui-auth:ForgotPasswordFormComponent');

enum ForgotPasswordFormStage {
  DEFAULT = 0,
  INSTRUCTION_SENT = 1,
  INTERNAL_SERVER_ERROR = 2,
}

@Component({
  selector: 'a7-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent
  extends UserRedirectComponent
  implements OnInit {
  @Input() app: 'renters' | 'main_site' = 'main_site';

  form: UntypedFormGroup;
  stage: ForgotPasswordFormStage = ForgotPasswordFormStage.DEFAULT;
  sending: boolean = false;
  pageSize: PageSize;

  @HostBinding('class.a7-ui-auth-forgot-password-form')
  @HostBinding('class.a7-ui-auth-form')
  _hostBinding = true;

  constructor(
    router: A7Router,
    layout: A7LayoutService,
    private fb: UntypedFormBuilder,
    private userService: UserService,
  ) {
    super(userService, router);

    this.safeSubscribe(
      layout.pageSize,
      (pageSize) => (this.pageSize = pageSize),
    );

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email, a7EmailValidator()]],
    });
  }

  ngOnInit() {}

  reset() {}

  get email() {
    return this.form.controls.email.value;
  }

  @Debug({ d })
  async sendResetPasswordEmail(event: Event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!this.form.valid) {
      forms.validateAllFormFields(this.form);
      return;
    }

    this.sending = true;

    try {
      await this.userService.forgotPassword({
        email: this.form.controls.email.value.trim(),
      });
      this.stage = ForgotPasswordFormStage.INSTRUCTION_SENT;
    } catch (e) {
      this.stage = ForgotPasswordFormStage.INTERNAL_SERVER_ERROR;
    } finally {
      this.sending = false;
    }
  }
}
