import {
  A7ResourceAction,
  A7ResourceParams,
  A7ResourceSingletonObservable,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';
import { Identity } from '@ark7/identity';
import { Injectable } from '@angular/core';
import { Name } from '@ark7/common-models';
import { TwoFAVerificationMethod } from '@ark7/core-business-models';

const referrer = typeof document !== 'undefined' ? document.referrer : '';
const uri = typeof location !== 'undefined' ? location.href : '';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  withCredentials: true,
  pathPrefix: '/api/v1/auth',
  headers: {
    'Origin-Referrer': referrer,
    'Origin-Uri': uri,
  },
})
export class AuthResource extends A7ResourceSingletonObservable<Identity> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/cognito',
  })
  signIn: IResourceMethodPromise<{ username: string; password: string }, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/login',
  })
  login: IResourceMethodPromise<{ username: string; password: string }, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/referrer-name/{!referralCode}',
  })
  referrerName: IResourceMethodPromise<{ referralCode: string }, Name>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/confirm-sign-up',
  })
  confirmSignUp: IResourceMethodPromise<{ email: string }, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/forgot-password',
  })
  forgotPassword: IResourceMethodPromise<{ email: string }, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/initiate-forgot-password',
  })
  initiateForgotPassword: IResourceMethodPromise<{ email: string }, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/forgot-password-reset',
  })
  forgotPasswordReset: IResourceMethodPromise<
    { email: string; code: string; password: string },
    void
  >;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/send-two-fa-code',
  })
  sendTwoFACode: IResourceMethodPromise<
    { method: TwoFAVerificationMethod },
    void
  >;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/verify-two-fa-code',
  })
  verifyTwoFACode: IResourceMethodPromise<
    { code: string; method: TwoFAVerificationMethod },
    void
  >;
}
