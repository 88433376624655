import * as changeCase from 'change-case';
import _ from 'underscore';
import debug from 'debug';
import { Debug } from '@ark7/utils';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { throwError } from 'rxjs';

const d = debug('a7-layout:RequestInterceptor');

@Injectable()
export class ExperimentInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private storage: LocalStorageService,
  ) {
    if (!isPlatformBrowser(platformId)) {
      return;
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!isPlatformBrowser(this.platformId)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      map(value => {
        if (value instanceof HttpResponse) {
          this.processHeaders(value);
        }
        return value;
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          this.processHeaders(err);
        }
        return throwError(err);
      }),
    );
  }

  @Debug({ d })
  private processHeaders(resp: HttpResponse<any> | HttpErrorResponse) {
    for (const key of resp.headers.keys()) {
      if (!key.startsWith('flag-')) {
        continue;
      }

      this.storage.store(changeCase.camel(key), resp.headers.get(key));
    }
  }
}
