import debug from 'debug';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Debug } from '@ark7/utils';
import { UserService } from '@ark7/resources-common';

const d = debug('a7-ui-auth:EmailVerificationRequiredComponent');

@Component({
  selector: 'a7-email-verification-required',
  templateUrl: './email-verification-required.component.html',
  styleUrls: ['./email-verification-required.component.scss'],
})
export class EmailVerificationRequiredComponent implements OnInit {
  @Input() formTitle: string;
  @Input() email: string;

  @Output() succeeded = new EventEmitter();
  @Output() failed = new EventEmitter();

  constructor(private userService: UserService) {}

  ngOnInit() {}

  @Debug({ d })
  async sendEmailVerification() {
    try {
      await this.userService.currentUser.sendEmailVerification({
        email: this.email,
      });
      this.succeeded.emit();
    } catch (e) {
      this.failed.emit(e);
    }
  }
}
