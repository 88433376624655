import _ from 'underscore';
import moment from 'moment';
import sha1 from 'simple-sha1';
import { ActivatedRoute } from '@angular/router';
import { Country } from '@ark7/common-models';
import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { MarketingConversionType } from '@ark7/core-business-models';

import { AnonTrackingResource } from '../resources/anon-tracking-resource';
import { AppConfigurationService } from './configuration.service';
import { BasicUserResource } from '../resources/user-resource';
import { Logger } from './logger.service';
import { MarketingConversionResource } from '../resources/conversion-resource';
import { UserService } from './user.service';

// impact pixels
declare let ire: Function;

// wellput pixels
declare let clickmagick_cmc: { log: Function };

// facebook pixels
declare let fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  @LocalStorage() trackingCode: string;

  @LocalStorage() leadCode: string;
  @LocalStorage() leadEmail: string;

  @LocalStorage() referrer: string;
  @LocalStorage() impactClickId: string;

  constructor(
    private userService: UserService,
    private marketingConversionResource: MarketingConversionResource,
    activeRoute: ActivatedRoute,
    private userResource: BasicUserResource,
    private configurationService: AppConfigurationService,
    private logger: Logger,
    private trackingResource: AnonTrackingResource,
  ) {
    activeRoute.queryParams.subscribe((urlQueryParams) => {
      if (urlQueryParams.tc) {
        this.trackingCode = urlQueryParams.tc;
      }
      if (urlQueryParams.l) {
        this.leadCode = urlQueryParams.l;
      }
      if (urlQueryParams.le) {
        this.leadEmail = urlQueryParams.le;
      }
      if (urlQueryParams.r) {
        this.referrer = urlQueryParams.r;
      }
      if (urlQueryParams.irclickid) {
        this.impactClickId = urlQueryParams.irclickid;
      }
      this.sendSignal();
    });
  }

  private sendSignal() {
    if (_.isEmpty(this.trackingCode)) {
      return;
    }

    const queryParams: {
      code: string;
      leadCode?: string;
      conversionType: MarketingConversionType;
      impactClickId?: string;
    } = {
      code: this.trackingCode,
      conversionType: MarketingConversionType.VIEW,
    };

    if (this.leadCode) {
      queryParams.leadCode = this.leadCode;
    }

    if (this.impactClickId) {
      queryParams.impactClickId = this.impactClickId;
    }

    this.marketingConversionResource.signal(queryParams);
  }

  isValidInvestor(): boolean {
    if (
      this.configurationService.impactVerifyEmail &&
      !this.userService.currentUser.status.emailVerified
    ) {
      return false;
    }

    if (!this.configurationService.impactValidateProfile) {
      return true;
    }

    if (!this.userService.currentUser.profiles?.isBasicProfileCompleted) {
      return false;
    }

    const year = this.userService.currentUser.profiles?.dateOfBirth?.year ?? 0;
    const age = moment().year() - year;
    if (age < 18 || age > 125) {
      return false;
    }

    if (
      this.userService.currentUser.profiles?.address?.country !==
      Country.UNITED_STATES
    ) {
      return false;
    }

    return true;
  }

  async checkToReportEvent() {
    try {
      if (
        typeof ire !== 'undefined' &&
        this.userService.currentUser != null &&
        this.configurationService.isConfigLoaded &&
        // only for users with register tc from the impact campaign
        this.userService.currentUser.tracking?.campaign ===
          '62fd7e04e354d41077e6eafe'
      ) {
        const customerId = sha1.sync(
          this.userService.currentUser._id.toString(),
        );
        const emailSha1 = sha1.sync(
          this.userService.currentUser.email.toString(),
        );

        ire('identify', {
          customerId,
          customerEmail: emailSha1,
        });

        // report impact tracking for leads sign-up conversion
        if (
          !this.userService.currentUser.status.ireCalled &&
          this.isValidInvestor()
        ) {
          this.userService.currentUser.status.ireCalled = true;

          if (Math.random() < this.configurationService.impactSamplingRate) {
            ire(
              'trackConversion',
              32280,
              {
                orderId: emailSha1,
                customerId,
                customerEmail: emailSha1,
              },
              {
                verifySiteDefinitionMatch: true,
              },
            );
          }

          await this.userResource.update({
            'status.ireCalled': true,
          } as any);
          // why the following call fail on $update is undefined?
          // await this.userService.currentUser.status.$update({
          //   ireCalled: true,
          // });
        }
      }
    } catch (e) {
      console.error('error on checkToReportEvent: ', e);
      this.logger.error('error on checkToReportEvent:', { error: e });
    }
  }

  emitWellputPixel(type: 'a' | 'e' | 's', ref?: string, amount?: number) {
    // only for users with register tc
    if (
      typeof clickmagick_cmc !== 'undefined' &&
      clickmagick_cmc?.log &&
      this.userService.currentUser?.tracking != null
    ) {
      try {
        if (type == 's') {
          clickmagick_cmc.log(type, ref, amount);
        } else {
          clickmagick_cmc.log(type, ref);
        }
      } catch (e) {
        console.log('emitWellputPixel error: ', e);
      }
    }
  }

  emitFackbookPixel(event: string) {
    if (typeof fbq !== 'undefined') {
      fbq('track', event);
    }
  }

  get trackingParams() {
    const params = [];
    if (this.referrer) {
      params.push(`r=${this.referrer}`);
    }
    if (this.trackingCode) {
      params.push(`tc=${this.trackingCode}`);
    }
    if (this.leadCode) {
      params.push(`l=${this.leadCode}`);
    }
    return params.length > 0 ? '?' + params.join('&') : '';
  }
  get downloadQueryParams() {
    return {
      r: this.referrer,
      tc: this.trackingCode,
    };
  }
  clearReferral() {
    this.referrer = null;
  }

  async getTracking() {
    if (!_.isEmpty(this.trackingCode)) {
      try {
        return await this.trackingResource.getByCode({
          trackingCode: this.trackingCode,
        });
      } catch (_ignore) {
        return undefined;
      }
    }
    return undefined;
  }
}
