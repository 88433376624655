import './config';

import * as Hammer from '@egjs/hammerjs';
import { A7InfoModule } from '@ark7/info';
import { A7InputModule } from '@ark7/input';
import {
  A7LayoutModule,
  A7LayoutService,
  GLOBAL_CUSTOM_DIMENSIONS,
  GtagService,
} from '@ark7/layout';
import { A7LoadingModule } from '@ark7/loading';
import {
  A7ResourcesCommonModule,
  API_CLIENT_INFO,
} from '@ark7/resources-common';
import { A7SharedComponentsModule } from '@ark7/shared-components';
import { A7SocketModule, A7_SOCKET_HOST } from '@ark7/socket';
import { A7TooltipsModule } from '@ark7/tooltips';
import { A7UiAuthModule } from '@ark7/ui-auth';
import { A7UtilsModule, APPLICATION_META } from '@ark7/utils';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
// Use egjs here to allow prerender
import { CommonModule } from '@angular/common';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ReactiveFormsModule } from '@angular/forms';
import { ResourceHandler } from '@ark7/resource2';
import { ResourceHandlerHttpClient } from '@ngx-resource/handler-ngx-http';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import {
  faApple,
  faFacebookSquare,
  faGoogle,
  faInstagram,
  faLinkedin,
  faSlack,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowAltCircleDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faEllipsisV,
  faEnvelope,
  faFillDrip,
  faLaptop,
  faLock,
  faPlayCircle,
  faPlus,
  faRedo,
  faShieldAlt,
  faSortDown,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle as faCheckCircle2 } from '@fortawesome/free-regular-svg-icons';

import packageInfo from '../../../../package.json';
import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { DalmoreDisclosureComponent } from './dalmore-disclosure/dalmore-disclosure.component';
import { DalmoreSecondaryMarketTradingAgreementComponent } from './dalmore-secondary-market-trading-agreement/dalmore-secondary-market-trading-agreement.component';
import { DownloadLinkComponent } from './download/download-link/download-link.component';
import { EmailPreferencesComponent } from './email-preferences/email-preferences.component';
import { FAQArticleComponent } from './help/faq-article/faq-article.component';
import { FAQCategoryComponent } from './help/faq-category/faq-category.component';
import { FeatureColumnComponent } from './layouts/features/feature-column/feature-column.component';
import { HelpComponent } from './help/help.component';
import { HrefWithQueryDirective } from './campaign/_shared/href-with-query/href-with-query.directive';
import { Mobile2Component } from './download/mobile2/mobile2.component';
import { MobileSeedRegisterFormComponent } from './programs/mobile-seed-user/register-form/register-form.component';
import { MobileSeedUserComponent } from './programs/mobile-seed-user/mobile-seed-user.component';
import { PageModule } from './page/page.module';
import { PerkTileComponent } from './campaign/_shared/perk-tile/perk-tile.component';
import { PromoTermsComponent } from './promo-terms/promo-terms.component';
import { ReferralFeaturesComponent } from './join/referral-sign-up/referral-features/referral-features.component';
import { ReferralInvitationComponent } from './join/referral-invitation/referral-invitation.component';
import { ReferralInvitationResultComponent } from './join/referral-invitation-result/referral-invitation-result.component';
import { ReferralSignUpComponent } from './join/referral-sign-up/referral-sign-up.component';
import { ReferralTermsAndConditionsComponent } from './referral-terms-and-conditions/referral-terms-and-conditions.component';
import { RoutingModule } from './routing.module';
import { SectionComponent } from './layouts/section/section.component';
import { SectionTitleComponent } from './layouts/section/section-title/section-title.component';
import { SimpleHeadlineComponent } from './layouts/headline/simple-headline/simple-headline.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { WebV2Module } from './web-v2/web-v2.module';
import { WebV2PartsModule } from './web-v2/parts/v2-parts.module';
import { WebinarCardComponent } from './help/webinar-card/webinar-card.component';
import { environment } from '../environments/environment';

/**
 * We have to import above first.
 */

let isBrowser: boolean = true;
// Can't just embed to isBrowser, angular complaints it.
if (typeof window === 'undefined') {
  isBrowser = false;
}
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pan: { direction: Hammer.DIRECTION_ALL },
  };
}
@NgModule({
  declarations: [
    AppComponent,
    Mobile2Component,
    ContactComponent,
    HelpComponent,
    FAQArticleComponent,
    FAQCategoryComponent,
    WebinarCardComponent,
    DownloadLinkComponent,
    EmailPreferencesComponent,
    FeatureColumnComponent,
    HrefWithQueryDirective,
    PerkTileComponent,
    MobileSeedRegisterFormComponent,
    MobileSeedUserComponent,
    ReferralFeaturesComponent,
    ReferralInvitationComponent,
    ReferralInvitationResultComponent,
    ReferralSignUpComponent,
    ReferralTermsAndConditionsComponent,
    PromoTermsComponent,
    DalmoreDisclosureComponent,
    DalmoreSecondaryMarketTradingAgreementComponent,
    SectionComponent,
    SectionTitleComponent,
    SimpleHeadlineComponent,
    SubscriptionComponent,
  ],
  imports: [
    A7UtilsModule,
    A7TooltipsModule,
    A7InfoModule,
    A7LayoutModule.forRoot({
      gtag: {
        trackingId: environment.gtag.trackingId,
        ga4TrackingId: environment.gtag.ga4TrackingId,
        adsTrackingId: environment.gtag.adsTrackingId,
        adsConversionSignUp: environment.gtag.adsConversionSignUp,
        adsConversionInvestment: environment.gtag.adsConversionInvestment,
        dimensions: GLOBAL_CUSTOM_DIMENSIONS,
      },
    }),
    A7LoadingModule,
    A7ResourcesCommonModule.forRoot({
      authOptions: environment.cognito.authOptions,
      logErrorsToServer: isBrowser,
    }),
    A7InputModule,
    A7SharedComponentsModule,
    A7SocketModule,
    A7UiAuthModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ark7-aurora' }),
    CommonModule,
    FontAwesomeModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatInputModule,
    MatRadioModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgxWebstorageModule.forRoot(),
    PageModule,
    ReactiveFormsModule,
    RoutingModule,
    ScrollToModule.forRoot(),
    WebV2Module,
    WebV2PartsModule,
  ]
    // HammerModule has to go later
    // https://stackoverflow.com/questions/65350121/app-wont-recognize-gestures-like-pan-using-hammer-js-in-angular-11
    .concat([HammerModule]),
  providers: [
    {
      provide: APPLICATION_META,
      useValue: {
        serviceName: 'brontosaurus',
        env: environment.env,
        version: packageInfo.version,
      },
    },
    {
      provide: API_CLIENT_INFO,
      useValue: {
        clientId: environment.clientId,
        clientSecret: environment.clientSecret,
      },
    },
    {
      provide: A7_SOCKET_HOST,
      useValue: environment.apiHost,
    },
    // ResourceHandler has been passed through from @ark7/resource, Universal
    // doesn't work with ResourceModule.forRoot() since it's not the original
    // one.
    {
      provide: ResourceHandler,
      useClass: ResourceHandlerHttpClient,
      deps: [HttpClient],
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    library: FaIconLibrary,
    _layout: A7LayoutService,
    _gtag: GtagService,
  ) {
    library.addIcons(
      faArrowLeft,
      faArrowRight,
      faFillDrip,
      faBars,
      faChartPie,
      faCheck,
      faCheckCircle,
      faCheckCircle2,
      faChevronLeft,
      faChevronRight,
      faCircle,
      faEllipsisV,
      faEnvelope,
      faFacebookSquare,
      faTwitterSquare,
      faLinkedin,
      faTwitterSquare,
      faSlack,
      faLaptop,
      faLock,
      faPlayCircle,
      faArrowAltCircleDown,
      faPlus,
      faRedo,
      faShieldAlt,
      faSortDown,
      faTimes,
      faSpinner,
      faInstagram,
      faApple,
      faGoogle,
    );
    /**
     * Without below, we can not generate blg.js from webpack
     */
    // helloWorldBlog();
  }
}
