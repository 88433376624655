import { Component } from '@angular/core';

@Component({
  selector: 'a7-share-footer',
  templateUrl: './share-footer.component.html',
  styleUrls: ['./share-footer.component.scss']
})
export class ShareFooterComponent {

}
