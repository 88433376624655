import { Component, OnDestroy } from '@angular/core';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * A base component class to help components to deal with subscription memory
 * leak problem.
 *
 * If the sub-class implements OnDestroy interface, call super.ngOnDestroy().
 */
@Component({
  selector: 'a7-subscribe-component',
  template: '<div></div>',
})
export class SubscribeComponent implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  safeSubscribe<T>(
    subject: Observable<T>,
    fn: (obj: T) => void,
    errorFn?: (error: any) => void,
  ) {
    subject.pipe(takeUntil(this.unsubscribe$)).subscribe(fn, errorFn);
  }

  takeUntilDestroyed<T>(): OperatorFunction<T, T> {
    return takeUntil(this.unsubscribe$);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
