import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
} from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'a7-moving-number',
  templateUrl: './moving-number.component.html',
  styleUrls: ['./moving-number.component.scss'],
  animations: [
    trigger('slide', [
      state(
        '*',
        style({
          marginTop: '-{{ marginTop }}px',
        }),
        {
          params: { marginTop: 0 },
        },
      ),
      transition('* <=> *', [animate('0.1s 0s ease-out')]),
    ]),
  ],
})
export class MovingNumberComponent implements AfterViewInit, OnChanges {
  @Input() number: string | number;
  @Input() height: number = 0;
  @Input() decimalPlaceClass = '';

  numStr: string;
  target: number[] = [];
  dotPos = Number.MAX_VALUE;

  constructor(private host: ElementRef) {}

  ngOnChanges() {
    if (this.number == null) {
      return;
    }

    this.numStr = this.number.toString();

    this.dotPos = this.numStr.indexOf('.');
    this.dotPos = this.dotPos > 0 ? this.dotPos : Number.MAX_VALUE;

    this.target = _.map(this.numStr, (c) => {
      const x = c >= '0' && c <= '9' ? c.charCodeAt(0) - '0'.charCodeAt(0) : 10;
      return x * this.height;
    });
  }

  ngAfterViewInit() {
    _.defer(() => {
      const el = this.host.nativeElement;
      const lineHeight = window?.getComputedStyle(el, null).lineHeight;
      if (lineHeight) {
        this.height = Number.parseFloat(lineHeight);
      }
      this.ngOnChanges();
    });
  }

  isDigit(i: number) {
    return this.target[i] !== 10 * this.height;
  }
}
