import { A7LayoutModule } from '@ark7/layout';
import { NgModule } from '@angular/core';

import { ExternalLinkDirective } from './directives/external-link.directive';

@NgModule({
  declarations: [ExternalLinkDirective],
  imports: [A7LayoutModule],
  exports: [ExternalLinkDirective],
})
export class A7SocketModule {}
