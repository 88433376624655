<button class="a7-button-override"
        (click)="onClick($event)"
        matRipple
        [matRippleColor]="RippleColor[theme]"
        [ngClass]="classes"
        (mouseover)="state = 'hovered'"
        (mouseleave)="state = 'standard'"
        (mousedown)="state = 'pressed'"
        (mouseup)="state = 'hovered'">
  <a7-loading-content [progressor]="progressor">
    <a7-spin-loading-indicator errorMessage="failed"
                               a7-loading-indicator>
    </a7-spin-loading-indicator>
    <ng-content></ng-content>
  </a7-loading-content>
</button>