import _ from 'underscore';
import { $$ } from '@ark7/model';
import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { FAQ, FAQCategory } from '@ark7/core-business-models';
import { FAQCategoryResource, FAQResource } from '@ark7/resources-common';
import { Meta, Title } from '@angular/platform-browser';

import { HelpBaseComponent } from '../help-base.component';

@Component({
  selector: 'app-faq-category',
  templateUrl: './faq-category.component.html',
  styleUrls: ['./faq-category.component.scss'],
})
export class FAQCategoryComponent extends HelpBaseComponent {
  currentCategory: FAQCategory;

  categories: FAQCategory[];

  faqCatgoryList: FAQ[][] = [];

  constructor(
    private route: ActivatedRoute,
    private faqResource: FAQResource,
    private faqCategoryResource: FAQCategoryResource,
    private titleService: Title,
    private readonly meta: Meta,
  ) {
    super();
    this.safeSubscribe(this.route.queryParams, async (params) => {
      this.faqResource
        .queryWithPagination({ category: params.categoryId, size: 20 })
        .subscribe((res) => {
          const faqsByCategory: Map<string, FAQ[]> = new Map();

          _.each(res.data, (faq) => {
            if (faqsByCategory.has($$(faq.category).name)) {
              faqsByCategory.get($$(faq.category).name).push(faq);
            } else {
              faqsByCategory.set($$(faq.category).name, [faq]);
            }
          });

          faqsByCategory.forEach((value: FAQ[], key: string) => {
            this.faqCatgoryList.push(value);
          });

          this.faqCatgoryList.sort(
            (a, b) =>
              $$(b[0].category).sortWeight - $$(a[0].category).sortWeight,
          );
        });

      this.faqCategoryResource
        .queryWithPagination({ parentCategory: null, size: 10 })
        .subscribe((res) => {
          this.categories = res.data;
          _.each(res.data, (c) => {
            if (c._id.toString() === params.categoryId) {
              this.currentCategory = c;

              this.titleService.setTitle(
                `${this.currentCategory.name} - Ark7 Help`,
              );
              this.meta.updateTag({
                name: 'description',
                content: this.currentCategory.description,
              });
            }
          });
        });
    });
  }
}
