import { InjectionToken } from '@angular/core';

export const GTAG_CONFIG = new InjectionToken<string>('GTAG_CONFIG');

export interface GtagConfig {
  trackingId: string;
  ga4TrackingId?: string;
  adsTrackingId?: string;
  adsConversionSignUp?: string;
  adsConversionInvestment?: string;
  dimensions?: GtagCustomFieldMap;
  metrics?: GtagCustomFieldMap;
  requireUserId?: boolean;
}

export interface LayoutConfigs {
  gtag?: GtagConfig;
}

// https://developers.google.com/gtagjs/reference/event
export enum GtagEventName {
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_TO_CART = 'add_to_cart',
  ADD_TO_WISHLIST = 'add_to_wishlist',
  BEGIN_CHECKOUT = 'begin_checkout',
  CHECKOUT_PROGRESS = 'checkout_progress',
  GENERATE_LEAD = 'generate_lead',
  LOGIN = 'login',
  PURCHASE = 'purchase',
  REFUND = 'refund',
  REMOVE_FROM_CART = 'remove_from_cart',
  SEARCH = 'search',
  SELECT_CONTENT = 'select_content',
  SET_CHECKOUT_OPTION = 'set_checkout_option',
  SHARE = 'share',
  SIGN_UP = 'sign_up',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_PROMOTION = 'view_promotion',
  VIEW_SEARCH_RESULTS = 'view_search_results',

  TIMING_COMPLETE = 'timing_complete',

  // Customized:
  VIDEO_AUTO_PLAY_START = 'video_auto_play_start',
  VIDEO_AUTO_PLAY_END = 'video_auto_play_end',
  OPEN_EXTERNAL_LINK = 'open_external_link',
  INVESTMENT = 'investment',
}

export enum GtagDefaultCategory {
  ECOMMERCE = 'ecommerce',
  ENGAGEMENT = 'engagement',

  VIDEO_AUTO_PLAY = 'video_auto_play',
}

export enum GtagDefaultLabel {
  METHOD = 'method',
  SEARCH_TERM = 'search_term',
  CONTENT_TYPE = 'content_type',
}

// https://developers.google.com/gtagjs/reference/parameter
export interface EventParams {
  name?: string;
  content_type?: string;
  method?: string;
  value?: string | number;
  event_label?: string;
  event_category?: string;
  non_interaction?: boolean;
  [key: string]: any;
}

export const GLOBAL_CUSTOM_DIMENSIONS: GtagCustomFieldMap = {
  dimension1: 'landingTheme',
};

export const GLOBAL_CUSTOM_METRICS: GtagCustomFieldMap = {};

export interface GtagCustomFieldMap {
  [key: string]: string;
}
