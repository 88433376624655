import _ from 'underscore';
import debug from 'debug';
import {
  A7Model,
  Ark7ModelMetadata,
  ModelizeMetadata,
  ModelizeOptions,
  StrictModel,
} from '@ark7/model';
import { ReplaySubject, Subject } from 'rxjs';
import { withInheritedProps as dotty } from 'object-path';
import { observableToPromise } from '@ark7/utils';

import { A7Resource2Module } from './resource2.module';
import { A7ResourceCRUD } from './crud';
import { A7ResourceCRUDObservable } from './crud-observable';
import {
  IPaginationData,
  PaginationData,
  isPaginationData,
} from './pagination';

const d = debug('a7-resource:model');

declare module '@ark7/model/core/fields' {
  // tslint:disable-next-line: no-empty-interface
  export interface ModelizeMetadata {
    $dirty?: boolean;
    $resource?: any;
    $foreignResource?: any;
    $pagination?: IPaginationData<any, any>;
    $modifier?: any;
    $observer?: ReplaySubject<any>;
    $loading?: boolean;
  }
}

export interface ExtentA7Model extends ModelizeMetadata {}

declare module '@ark7/model/core/model' {
  // tslint:disable-next-line: no-empty-interface
  export interface StrictModel extends ExtentA7Model {
    /**
     * Set field and modelize the result, not saving to the server.
     */
    $set(name: string, obj: any): this;

    /**
     * Update the server and local instance.
     */
    $update<T extends Model>(obj: object): Promise<T>;

    $save<T extends Model>(): Promise<T>;

    $delete<T extends Model>(): Promise<T>;

    $metadata?(): Ark7ModelMetadata;

    $processResponse?(obj: any): Promise<any>;

    $root?(): Model;

    $clone(options?: CloneOptions): this;

    $copy(obj: any): this;
  }
}

const oldModelize = StrictModel.modelize;

StrictModel.modelize = function modelize(
  o: any,
  options: ModelizeOptions = {},
) {
  const m = oldModelize.call(
    this,
    o,
    _.extend({}, options, {
      attachFieldMetadata: true,
      allowReference: true,
    }),
  );

  if (m?.constructor.$foreignResource) {
    m.$attach({ $foreignResource: m.constructor.$foreignResource });
  }

  return m;
};

StrictModel.prototype.$metadata = function $metadata() {
  return A7Model.getMetadata((this as any).__proto__.constructor.name);
};

StrictModel.prototype.$set = function $set<T extends StrictModel>(
  this: T,
  name: string,
  obj: any,
) {
  const metadata = this.$metadata();
  const idx = name.indexOf('.');

  if (idx === -1) {
    if (metadata.combinedFields.has(name)) {
      const field = metadata.combinedFields.get(name);

      this[name] = field.modelize(obj, {
        meta: {
          $parent: this,
          $path: name,
        },
      });
    } else {
      this[name] = obj;
    }
  } else {
    const first = name.substr(0, idx);
    const last = name.substring(idx + 1);

    if (metadata.combinedFields.has(first)) {
      const field = metadata.combinedFields.get(first);
      if (this[first] == null) {
        this[first] = field.modelize(
          {},
          { meta: { $parent: this, $path: first } },
        );
      }

      this[first].$set(last, obj);
    } else {
      dotty.set(this, name, obj);
    }
  }

  this.$attach({ $dirty: true });

  return this;
};

StrictModel.prototype.$update = async function $update<T>(
  this: StrictModel,
  obj: object,
): Promise<T> {
  // If it's not the root, call recursively.
  if (
    this.$parent != null &&
    !_.isArray(this.$parent) &&
    !(this.$parent instanceof PaginationData) &&
    this.$foreignResource == null
  ) {
    const newObj = this.$isArray
      ? addPrefixToObjectKey(obj, this.$path + '.' + this.$index + '.')
      : addPrefixToObjectKey(obj, this.$path + '.');

    return this.$parent.$update(newObj);
  }

  /* this.$parent?.$resource is for pagination */
  const $resource =
    this.$foreignResource ??
    ((this.$resource ?? this.$parent?.$resource) as
      | A7ResourceCRUDObservable
      | A7ResourceCRUD);

  this.$loading = true;

  A7Resource2Module.getRequestStatusService().incLoadingRequest();

  try {
    const updateResult = await $resource.update(obj, this.$modifier, {
      _id: (this as any)._id,
    } as any);

    return this.$processResponse(updateResult);
  } finally {
    this.$loading = false;
    A7Resource2Module.getRequestStatusService().decLoadingRequest();
  }
};

StrictModel.prototype.$root = function $root<T extends StrictModel>(this: T) {
  return _.isArray(this.$parent) // Non-pagination response array
    ? this.$parent
    : this.$parent == null
    ? this
    : this.$parent.$root();
};

StrictModel.prototype.$processResponse = async function $processResponse<
  T extends StrictModel
>(this: T, obj: StrictModel): Promise<any> {
  d('$processResponse() called', obj);

  obj = obj instanceof Subject ? await observableToPromise(obj) : obj;

  const root: StrictModel = this.$root() as any;

  if (obj == null || obj.$attach == null) {
    if (this.$isArray) {
      d(
        'processResponse() found item to remove parent: %O, path: $O, oLen: %O, index: %O',
        this.$parent,
        this.$path,
        this.$parent[this.$path].length,
        this.$index,
      );
      this.$parent[this.$path].splice(this.$index, 1);

      _.each(this.$parent[this.$path], (val, idx) => {
        if (val?.$attach) {
          val.$attach({ $index: idx });
        }
      });
    }
  } else {
    const objKeys = _.filter(_.keys(obj), (k) => !k.startsWith('$'));
    const thisKeys = _.filter(_.keys(this), (k) => !k.startsWith('$'));

    d(
      'processResponse() patch %o with %o, obj keys: %o, this keys: %o',
      this,
      obj,
      objKeys,
      thisKeys,
    );

    for (const key of objKeys) {
      if (this[key] !== obj[key]) {
        const val = obj[key];

        if (_.isArray(val)) {
          if (this[key] == null) {
            this[key] = [];
          }

          for (let i = 0; i < val.length; i++) {
            const v = val[i];

            if (v instanceof StrictModel) {
              v.$attach({ $parent: this });
            }
            // TODO: This might be able to change to deep copy if there is a
            // performance problem.
            this[key][i] = v;
          }

          if (val.length < this[key].length) {
            this[key].splice(val.length, this[key].length - val.length);
          }
        } else {
          if (val instanceof StrictModel) {
            val.$attach({ $parent: this });
          }
          // TODO: This might be able to change to deep copy if there is a
          // performance problem.
          this[key] = val;
        }
      }
    }

    for (const key of _.difference(thisKeys, objKeys)) {
      delete this[key];
    }
  }

  obj = root === this ? obj : root;

  const observer = this.$observer ?? this.$parent?.$observer;

  d(
    'processResponse() sendToObserver: %O with this: %O, observer: %O',
    obj,
    this,
    observer,
  );

  observer?.next(root);

  d('processResponse() returns: %O', root);
  return root;
};

StrictModel.prototype.$save = async function $save(this: StrictModel) {
  return this.$update(this.toJSON());
  // return this.$update(this.$clone({ deep: true }));
};

StrictModel.prototype.$delete = async function $delete<T extends StrictModel>(
  this: T,
) {
  d('delete() called: %O', this);
  if (
    this.$parent != null &&
    this.$foreignResource == null &&
    !isPaginationData(this.$parent)
  ) {
    if (this.$isArray && _.isArray(this.$parent)) {
      return this.$processResponse(
        (this.$resource as A7ResourceCRUD).remove({
          _id: (this as any)._id?.toString(),
        }),
      );
    } else if (this.$isArray) {
      const parentArray: object[] = this.$parent[this.$path];

      parentArray.splice(this.$index, 1);

      return await this.$parent.$update({
        [this.$path]: parentArray,
      });
    } else {
      const ins = this.$parent.toJSON();
      delete ins[this.$path];
      return this.$parent.$update(ins);
    }
  } else {
    const deleteResult = await ((this.$resource ??
      this.$foreignResource) as A7ResourceCRUD).remove({
      _id: (this as any)._id?.toString(),
    });

    d('$resource.remove() returns: %O', deleteResult);

    return this.$processResponse(deleteResult);
  }
};

StrictModel.prototype.$clone = function $clone(
  this: StrictModel,
  options: CloneOptions = {},
) {
  if (!options.deep) {
    const proto = Object.getPrototypeOf(this);
    Object.setPrototypeOf(this, null);
    const o = _.omit(
      _.clone(this),
      '$abort',
      '$promise',
      '$resolved',
      !options?.withId ? '_id' : '___',
    );
    Object.setPrototypeOf(this, proto);
    Object.setPrototypeOf(o, proto);

    return o;
  } else {
    return (this as any).__proto__.constructor.modelize(this.toJSON());
  }
};

StrictModel.prototype.$copy = function $copy(this: StrictModel, obj: any) {
  const ret = this.$clone();
  _.extend(ret, obj);
  return ret;
};

export function mapKey(obj: any, fn: (any: string) => string) {
  const result = {};
  _.each(obj, (v: any, k: any) => {
    result[fn(k)] = v;
  });
  return result;
}

export function addPrefixToObjectKey(obj: any, prefix: string) {
  return mapKey(obj, (key) => prefix + key);
}

export interface CloneOptions {
  deep?: boolean;
  withId?: boolean;
}
