import * as changeCase from 'change-case';
import { Pipe, PipeTransform } from '@angular/core';

import { transformDisplay } from '../utils';

@Pipe({
  name: 'case',
})
export class CasePipe implements PipeTransform {
  transform(value: any, args: any = 'title'): any {
    return transformDisplay((changeCase[args] as any)(value));
  }
}
