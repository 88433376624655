import {
  IResourceMethodPromise,
  IResourceMethodPromiseStrict,
  ResourceRequestMethod,
} from '@ngx-resource/core';

import { A7Resource } from './resource';
import { A7ResourceAction } from './action';

export abstract class A7ResourceSingleton<
  TFull = any,
  TModifier = {}
> extends A7Resource {
  @A7ResourceAction({
    subSignalTopic: 'get',
  })
  get: IResourceMethodPromise<TModifier, TFull>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    subSignalTopic: 'update',
  })
  update: IResourceMethodPromiseStrict<TFull, TModifier, void, TFull>;
}
