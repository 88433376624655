import {
  A7ResourceAction,
  A7ResourceParams,
  A7ResourceSingleton,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  withCredentials: true,
  pathPrefix: '/api/v2/client',
})
export class ClientResource extends A7ResourceSingleton<any> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/client-server-request-event',
  })
  emitClientServerRequestEvent: IResourceMethodPromise<{}, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/client-access-log',
  })
  emitClientAccessLog: IResourceMethodPromise<{}, void>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/logs',
  })
  emitClientLog: IResourceMethodPromise<ClientLogRequest, void>;

  private requestEventBuffer = [];
  private requestEventEmitTimeoutHandler = null;

  private async doEmitClientServerRequestEvents() {
    const eventBuffer = this.requestEventBuffer;
    this.requestEventBuffer = [];
    await this.emitClientServerRequestEvent(eventBuffer);
  }

  enqueueClientServerRequestEvent(body: {}) {
    this.requestEventBuffer.push(body);

    if (this.requestEventBuffer.length >= 10) {
      this.doEmitClientServerRequestEvents();
    } else {
      if (this.requestEventEmitTimeoutHandler !== null) {
        clearTimeout(this.requestEventEmitTimeoutHandler);
      }

      this.requestEventEmitTimeoutHandler = setTimeout(() => {
        this.doEmitClientServerRequestEvents();
      }, 5000);
    }
  }
}

export interface ClientLogRequest {
  level: 'debug' | 'info' | 'warn' | 'error';
  message: string;
  payload?: string;
}
