import _ from 'underscore';
import { HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Model } from '@ark7/model';
import { Observable, Observer, from, of } from 'rxjs';
import { flatMap, map, shareReplay, tap } from 'rxjs/operators';

import { A7Resource2Module } from './resource2.module';

export function InstanceMethod(
  options: InstanceMethodOptions = {},
): PropertyDecorator {
  return (target: any, propertyName: string) => {
    const fn = target[propertyName];

    target[propertyName] = async function (this: Model, ...args: any[]) {
      const observer: Observer<any> = this.$observer;

      if (!options.noObserver && observer == null) {
        throw new Error('Instance object missing observer');
      }
      this.$loading = true;
      A7Resource2Module.getRequestStatusService().incLoadingRequest();

      try {
        let ret: any = await fn.call(this, ...args);

        if (!options.noObserver) {
          ret = await this.$processResponse(ret);
        }

        if (options.extend) {
          _.extend(this, ret);
        }

        this.$loading = false;

        return ret;
      } finally {
        A7Resource2Module.getRequestStatusService().decLoadingRequest();
      }
    };

    return {};
  };
}

export function InstanceFormUploadMethod(
  options: InstanceMethodOptions,
): PropertyDecorator {
  return (target: any, propertyName: string) => {
    target[propertyName] = function (
      this: Model,
      url: string,
      formData: FormData,
    ): Observable<HttpEvent<any>> {
      const observer: Observer<any> = this.$observer;

      if (!options.noObserver && observer == null) {
        throw new Error('Instance object missing observer');
      }

      return A7Resource2Module.getHttpClient()
        .request(
          new HttpRequest('POST', url, formData, {
            withCredentials: true,
            responseType: 'json',
            reportProgress: true,
          }),
        )
        .pipe(
          flatMap((x) => {
            if (x.type === HttpEventType.Response) {
              const cls = (this as any).__proto__.constructor;
              const typed = cls.modelize(x.body);
              return (options.noObserver
                ? of(typed)
                : from(this.$processResponse(typed))
              ).pipe(
                tap((processed) => {
                  if (options.extend) {
                    _.extend(this, processed);
                  }
                }),
                map((processed) => {
                  return x.clone<Model>({ body: processed });
                }),
              );
            }
            return of(x);
          }),
          shareReplay(1),
        );
    };

    return {};
  };
}

export interface InstanceMethodOptions {
  noObserver?: boolean;
  extend?: boolean;
}
