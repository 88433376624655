import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PhoneNumber } from '@ark7/model';
import _ from 'underscore';

import { isValidPhoneNumber } from 'libphonenumber-js';
import { SequentialInputGroupDirective } from '../restrictive-input/sequential-input-group.directive';

const PHONE_REGEX = /\+1 (\d\d\d)-(\d\d\d)-(\d\d\d\d)/;

@Component({
  selector: 'a7-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit, OnChanges {
  formGroup: UntypedFormGroup;

  @ViewChild(SequentialInputGroupDirective, { static: true })
  input: SequentialInputGroupDirective;

  @Input() phoneNumber: PhoneNumber;

  constructor(protected fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formGroup) {
      this.updatePhoneNumber();
    }
  }

  ngOnInit() {
    this.createForm();
  }

  validate() {
    const phoneNumber = this.rawValue;
    try {
      return phoneNumber !== null && isValidPhoneNumber(phoneNumber, 'US');
    } catch (e) {
      return false;
    }
  }

  updatePhoneNumber() {
    const groups = PHONE_REGEX.exec(this.phoneNumber?.toString()) || [];
    this.formGroup.controls.p1.setValue(groups[1] || '');
    this.formGroup.controls.p2.setValue(groups[2] || '');
    this.formGroup.controls.p3.setValue(groups[3] || '');
  }

  createForm() {
    this.formGroup = this.fb.group({
      p1: [
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)],
      ],
      p2: [
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)],
      ],
      p3: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
    });
    this.updatePhoneNumber();
  }

  get isEmpty() {
    const value = this.formGroup.value;
    return _.isEmpty(value.p1) && _.isEmpty(value.p2) && _.isEmpty(value.p3);
  }

  get rawValue() {
    const value = this.formGroup.value;
    const phoneNumber = `+1 ${value.p1}-${value.p2}-${value.p3}`;
    return phoneNumber;
  }

  get value() {
    if (!this.validate()) {
      return null;
    }
    return this.rawValue;
  }

  disable() {
    this.formGroup.disable();
  }

  enable() {
    this.formGroup.enable();
  }
}
