import { A7PageSizeMatcher } from '@ark7/layout';
import { Component } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-mobile2',
  templateUrl: './mobile2.component.html',
  styleUrls: ['./mobile2.component.scss'],
})
export class Mobile2Component {
  @A7PageSizeMatcher('XS-') xs: boolean;
  @A7PageSizeMatcher('MD-') mobile: boolean;
  @LocalStorage() trackingCode: string;
  @LocalStorage() referrer: string;

  sections = [
    {
      title: 'Discover & Invest',
      image: 'assets/app-pic-discover.webp',
      height: 520,
      padding: 40,
      desc:
        'Invest in a curated selection of prime rental properties across the U.S. with just a few taps.',
    },
    {
      title: 'Manage',
      image: 'assets/app-pic-manage.webp',
      height: 520,
      padding: 40,
      desc:
        'Check your shares and track monthly dividends all in one secure place. Leave behind the struggles of traditional real estate for good.',
    },
    {
      title: 'Trade',
      image: 'assets/app-pic-trade.webp',
      height: 520,
      padding: 40,
      desc:
        'Sell shares on the trading market with ease and enjoy the potential for property appreciation.',
    },
    {
      title: 'Insights',
      image: 'assets/app-pic-insights.webp',
      height: 520,
      padding: 40,
      desc:
        'Invest smartly with real-time offer updates, operational highlights, trading insights, and more.',
    },
    {
      title: 'Grow',
      image: 'assets/app-pic-grow.webp',
      height: 400,
      padding: 60,
      desc:
        'Watch your real estate income flow in. Best of all, it’s hassle-free and no hidden costs ever.',
    },
  ];

  constructor() {}

  get heroMessage() {
    return 'Real Estate Passive Income For Every Investor';
  }

  get downloadQueryParams() {
    return {
      r: this.referrer,
      tc: this.trackingCode,
    };
  }
}
