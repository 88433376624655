import * as uuid from 'uuid';
import _ from 'underscore';
import debug from 'debug';
import sha1 from 'simple-sha1';
import utf16ToUTF8Array from '@stdlib/string-utf16-to-utf8-array';
import { A7Router, ApplicationService } from '@ark7/utils';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Identity } from '@ark7/identity';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

import { AccessLogService } from '../services/access-log.service';
import { AppConfigurationService } from '../services/configuration.service';
import { ClientResource } from '../resources/client-resource';
import { UserService } from '../services/user.service';
import { urlToLocation } from '../utils';

const d = debug('a7-resources-common:RequestInterceptor');

export const API_CLIENT_INFO = new InjectionToken<string>('API_CLIENT_INFO');
export interface ApiClientInfo {
  clientId: string;
  clientSecret: string;
}

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private user: Identity;

  constructor(
    private accessLogService: AccessLogService,
    private userService: UserService,
    private clientResource: ClientResource,
    private router: A7Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(API_CLIENT_INFO) private c: ApiClientInfo,
    private applicationService: ApplicationService,
    private configurationService: AppConfigurationService,
  ) {
    if (!isPlatformBrowser(platformId)) {
      return;
    }

    this.userService.userChange$.subscribe((user) => {
      this.user = user;
    });
  }

  s = 128;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!isPlatformBrowser(this.platformId)) {
      return next.handle(request);
    }

    d(
      'RequestInterceptor.intercept - url: %O, request: %O',
      request.url,
      request,
    );

    const url = new URL(request.urlWithParams, location.href);

    if (
      url.pathname.startsWith('/api/v1/client') ||
      url.pathname.startsWith('/api/v2/client') ||
      url.pathname.startsWith('/bradmin/api/v2/client')
    ) {
      return next.handle(request);
    }

    const startTime = Date.now();
    const meta = this.applicationService.getApplicationMeta();

    const body = {
      meta,
      browserProperties: {},
      location: urlToLocation(this.router.url),
      requestId: uuid.v4(),
      request: {
        method: request.method.toUpperCase(),
        path: url.pathname,
        queryMap: url.searchParams,
        userId: this.user && this.user.uuid,
      },
      sessionId: this.accessLogService.sessionId,
      sessionSeq: this.accessLogService.sessionSeq,
      response: {
        status: 200,
        body: '',
        requestId: '',
      },
      performance: {
        durationInMs: 0,
      },
    };

    const expTags = this.configurationService.getConfig('app.expTags');

    const setHeaders: any = {
      'Request-Id': body.requestId,
    };

    if (
      request.url.indexOf('ark7.com') !== -1 ||
      request.url.startsWith('http://localhost')
    ) {
      if (!_.isEmpty(expTags)) {
        setHeaders['exp-tags'] = expTags.join(',');
      }

      setHeaders['x-app-version'] = meta.version;
      setHeaders['x-client-type'] = meta.serviceName;
      setHeaders['x-client-id'] = this.c.clientId;
      setHeaders['request'] = this._s(request);
    }

    request = request.clone({
      setHeaders,
    });

    return next.handle(request).pipe(
      map((value) => {
        if (value instanceof HttpResponse) {
          this.processHeaders(value);
          body.response.status = value.status;
          body.performance.durationInMs = Date.now() - startTime;

          // don't emit admin request logs
          if (
            body.request.path == null ||
            (body.request.path.indexOf('/bradmin/api/') === -1 &&
              body.request.path.indexOf('/api/v2/admin/') === -1)
          ) {
            this.clientResource.enqueueClientServerRequestEvent(body);
          }
        }
        return value;
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          this.processHeaders(err);
          body.response.status = err.status;
          body.performance.durationInMs = Date.now() - startTime;

          this.clientResource.enqueueClientServerRequestEvent(body);
        }
        return throwError(err);
      }),
    );
  }

  private processHeaders(x: HttpResponse<any> | HttpErrorResponse) {
    const userSideEffects = x.headers.get('user-side-effects');

    if (userSideEffects === 'fetch') {
      _.defer(() => this.userService.fetch());
    }
  }

  // not mean to be readable
  private _s(request: HttpRequest<any>) {
    const c = this.c.clientSecret,
      d = request.method.toUpperCase(),
      url = new URL(request.urlWithParams, location.href);
    const requestPath = url.pathname.replace('/bradmin', '');

    url.searchParams.sort();
    let queryString = '',
      body = '';
    url.searchParams.forEach((value, key) => {
      if (queryString !== '') {
        queryString += '&';
      }

      queryString += `${key}=${value}`;
    });

    if (d === 'POST') {
      const serializeBody = request.serializeBody();
      body =
        serializeBody != null
          ? serializeBody.toString().substring(0, this.s)
          : '';
    }
    const parts = [c, d, requestPath, queryString, body, c];

    const sign = sha1
      .sync(utf16ToUTF8Array(parts.join('|')) as any)
      .substring(0, 16);

    return sign;
  }
}
