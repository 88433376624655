import {
  A7ResourceAction,
  A7ResourceCRUDObservable,
  A7ResourceParams,
  IPaginationData,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';
import { Carrier, Trade } from '@ark7/core-business-models';
import { Injectable } from '@angular/core';
import {
  Money,
  Timestamp,
  UserFacingOperatingStatements,
} from '@ark7/common-models';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/carriers',
  withCredentials: true,
  model: Carrier,
})
export class CarrierResource extends A7ResourceCRUDObservable<
  Carrier,
  { tags?: string; ids?: string[]; iraOnly?: boolean }
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!_id}',
    cacheMilli: 1000 * 30,
  })
  getWithCache: IResourceMethodPromise<
    {
      _id: string;
    },
    Carrier
  >;

  @A7ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!_id}',
    query: { returnUnfundedMarketDepth: true },
  })
  getWithUnfundedOrder: IResourceMethodPromise<
    {
      _id: string;
    },
    Carrier
  >;

  @A7ResourceAction({
    model: null,
    method: ResourceRequestMethod.Get,
    path: '/{!_id}/share-prices',
  })
  querySharePrices: IResourceMethodPromise<SharePricesQuery, SharePrice[]>;

  @A7ResourceAction({
    model: Carrier,
    method: ResourceRequestMethod.Get,
    path: '/openOfferings',
  })
  queryOpenOfferings: IResourceMethodPromise<
    { iraOnly?: boolean; ark7Plus?: boolean },
    Carrier[]
  >;

  @A7ResourceAction({
    model: Carrier,
    method: ResourceRequestMethod.Get,
    pagination: true,
    path: '/tradingMarket',
  })
  queryTradingMarket: IResourceMethodPromise<
    { sort?: string; page?: number; size?: number },
    IPaginationData<Carrier>
  >;

  @A7ResourceAction({
    model: null,
    method: ResourceRequestMethod.Get,
    path: '/{!_id}/operating-statements',
  })
  queryUserFacingOperatingStatements: IResourceMethodPromise<
    {
      _id: string;
    },
    UserFacingOperatingStatements
  >;

  @A7ResourceAction({
    model: null,
    method: ResourceRequestMethod.Get,
    path: '/{!_id}/trades',
  })
  queryTrades: IResourceMethodPromise<
    {
      _id: string;
    },
    IPaginationData<Trade>
  >;
}

export enum SharePriceGranularity {
  DAY = 'DAY',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export interface SharePricesQuery {
  _id: string;
  granularity: SharePriceGranularity;
  startDate?: Date;
  endDate?: Date;
  env?: string; // testing only
}

// don't introduce the @ark7/bookkeeping lib here, as resources-common is used in renter app
export interface SharePrice {
  carrier_id: string;

  start: Timestamp;

  end: Timestamp;

  granularity: SharePriceGranularity;

  open: Money;
  closed: Money;
  low: Money;
  high: Money;
  average: Money;

  volume: number;

  change: Money;

  changeRate: number;
}
