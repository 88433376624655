import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';

import { A7Router } from './services/a7-router.service';
import { AddTimePipe } from './pipes/add-time.pipe';
import { ApplicationService } from './services/application.service';
import { AsAnyPipe } from './pipes/as-any.pipe';
import { CasePipe } from './pipes/case.pipe';
import { ChildrenCountPipe } from './pipes/children-count.pipe';
import { DebugDirective } from './dev-tools/debug-panel/debug.directive';
import { DebugPanelComponent } from './dev-tools/debug-panel/debug-panel.component';
import { DebugService } from './dev-tools/debug-panel/debug.service';
import { DecodeUriPipe } from './pipes/decode-uri.pipe';
import { DoubleClickDirective } from './directives/double-click.directive';
import { DownloadDirective } from './directives/download.directive';
import { EndOfPipe } from './pipes/end-of.pipe';
import { FeatureGuardService } from './services/feature-guard.service';
import { FileService } from './services/file.service';
import { FillInObjectPipe } from './pipes/fill-in-object.pipe';
import { FormatPipe } from './pipes/format.pipe';
import { NlinkDirective } from './directives/nlink.directive';
import { ParenthesesPipe } from './pipes/parentheses.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SamplePipe } from './pipes/sample.pipe';
import { ShortenMongoIdPipe } from './pipes/shorten-mongo-id.pipe';
import { ShortenPipe } from './pipes/shorten.pipe';
import { ShortenUuidPipe } from './pipes/shorten-uuid.pipe';
import { StartOfPipe } from './pipes/start-of.pipe';
import { SubscribeComponent } from './subscribe/subscribe-component';
import { SubscribeDirective } from './subscribe/subscribe-directive';

@NgModule({
  declarations: [
    AddTimePipe,
    AsAnyPipe,
    CasePipe,
    ChildrenCountPipe,
    DebugDirective,
    DebugPanelComponent,
    DecodeUriPipe,
    DoubleClickDirective,
    DownloadDirective,
    EndOfPipe,
    FormatPipe,
    NlinkDirective,
    ParenthesesPipe,
    SafePipe,
    SamplePipe,
    ShortenMongoIdPipe,
    ShortenPipe,
    ShortenUuidPipe,
    StartOfPipe,
    SubscribeComponent,
    SubscribeDirective,
    FillInObjectPipe,
  ],
  imports: [CommonModule, DragDropModule],
  exports: [
    AddTimePipe,
    AsAnyPipe,
    CasePipe,
    ChildrenCountPipe,
    DebugDirective,
    DebugPanelComponent,
    DecodeUriPipe,
    DoubleClickDirective,
    DownloadDirective,
    EndOfPipe,
    FormatPipe,
    NlinkDirective,
    ParenthesesPipe,
    SafePipe,
    SamplePipe,
    ShortenMongoIdPipe,
    ShortenPipe,
    ShortenUuidPipe,
    StartOfPipe,
    FillInObjectPipe,
  ],
  providers: [
    ApplicationService,
    DebugService,
    AsAnyPipe,
    CasePipe,
    ChildrenCountPipe,
    FeatureGuardService,
    FileService,
    ParenthesesPipe,
    ShortenPipe,
    A7Router,
  ],
})
export class A7UtilsModule {}
