import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'v2-inplace-dropdown-select',
  templateUrl: './inplace-dropdown-select.component.html',
  styleUrls: ['./inplace-dropdown-select.component.scss'],
})
export class InplaceDropdownSelectComponent implements OnInit {
  @Input()
  choices: any[] = ['Buy', 'Sell'];

  @Output()
  selectedChange = new EventEmitter<number>();

  expanded = false;

  _selected = 0;
  @Input()
  set selected(v: number) {
    if (v === this._selected) {
      return;
    }
    this._selected = v;
    this.selectedChange.next(v);
  }
  get selected() {
    return this._selected;
  }

  constructor() {}

  ngOnInit(): void {}
}
