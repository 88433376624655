import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'v2-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent {
  @Input() svgClass = '';
  @Output()
  clicked = new EventEmitter<void>();
}
