import { A7Router } from '@ark7/utils';
import {
  AccessLogService,
  GlobalNotificationService,
  TrackingService,
  UserService,
} from '@ark7/resources-common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  HostBinding,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { ExperimentService, GtagService } from '@ark7/layout';
import { Identity } from '@ark7/identity';
import { NavigationEnd } from '@angular/router';
import { SocketService, UserBehaviorService } from '@ark7/socket';
import { isPlatformBrowser } from '@angular/common';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  user: Identity;

  @HostBinding('class.ark7') _hostClass = true;

  // not using local storage here, as expired auth may cause the login form hidden
  userAuthorized: Date;

  @HostBinding('class.hide')
  get _hideClass() {
    return !(this.hide === false) || !isPlatformBrowser(this.platformId);
  }

  private hide: boolean;
  private initialized = false;

  constructor(
    _accessLogService: AccessLogService,
    _experiment: ExperimentService,
    _socket: SocketService,
    _userBehavior: UserBehaviorService,
    _trackingService: TrackingService,
    breakpointObserver: BreakpointObserver,
    gtag: GtagService,
    renderer: Renderer2,
    private router: A7Router,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: string,
    protected globalNotificationService: GlobalNotificationService,
  ) {
    _socket.connect();

    this.userService.userChange$.subscribe((user) => {
      this.user = user;
      if (user != null) {
        this.userAuthorized = new Date();
        gtag.setUserId(this.user.uuid.toString());
      }
      this.checkAuthentication();
      if (this.hide == null) {
        this.hide = false;
      }
    });

    if (isPlatformBrowser(platformId)) {
      breakpointObserver
        .observe([Breakpoints.HandsetPortrait])
        .subscribe((result) => {
          if (result.matches) {
            renderer.addClass(document.body, 'handset');
          } else {
            renderer.removeClass(document.body, 'handset');
          }
        });
    }
  }

  async ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initialized = true;
        if (isPlatformBrowser(this.platformId)) {
          window?.scrollTo(0, 0);
        }
        this.checkAuthentication();
      }
    });

    // First time to fetch the user
    if (isPlatformBrowser(this.platformId)) {
      try {
        await this.userService.fetch();
      } catch (e) {
        this.hide = false;
      }
    }

    if (this.userAuthorized == null) {
      this.hide = false;
    }

    this.userService.userChange$.subscribe((user) => {
      if (user != null) {
        this.userAuthorized = new Date();
      }
    });
  }

  private checkAuthentication() {
    if (
      this.initialized &&
      isPlatformBrowser(this.platformId) &&
      (this.router.url === '/' || this.router.url.startsWith('/#')) &&
      this.user != null
    ) {
      this.hide = true;
      if (this.router.url === environment.authenticatedRedirectUrl) {
        window?.location.reload();
      } else {
        window.location.href = environment.authenticatedRedirectUrl;
      }
    }
  }
}
