<app-page>
  <v2-two-columns-page [mobile]="mobile"
                       class="v2-margin-10-b"
                       rightClasses="v2-padding-8px-h"
                       mobileLayout="flex-column-start-center  v2-row-gap-2">
    <div left
         *ngIf="currentCategory != null"
         class="">
      <a href="/help"
         class="h5 color-green-grey-3 flex-row-start-center"> <img src="/assets/v2-back-grey.svg"
             alt="back"
             class="v2-margin-4px-r back-icon" />
        <div class="back-text">Back</div>
      </a>

      <div class="v2-margin-16px-t display-1 color-green-grey-1">{{currentCategory.name}}</div>
      <div class="body-1 color-green-grey-3 v2-margin-8px-t">{{currentCategory.description}}</div>

      <div class="v2-margin-48px-t"
           *ngFor="let faqs of faqCatgoryList">
        <h2 class="color-green-grey-1">{{$any(faqs[0].category).name}}</h2>
        <a *ngFor="let faq of faqs;"
           class="faq-box v2-margin-32px-t display-block bg-blue-grey-1 v2-padding-2 v2-radius-8px"
           [href]="faqLink(faq._id.toString())">
          <div class="h3 color-green-grey-1">{{$any(faq.article).title}}</div>
          <div class="body-2 color-green-grey-3 v2-margin-4px-t">{{$any(faq.article).description}}</div>
        </a>
      </div>
    </div>
    <div right>
      <div *ngIf="categories != null"
           class="v2-border-1px border-green-grey-4 v2-radius-8px v2-padding-2">
        <h3 class="color-green-grey-1 v2-margin-16px-b">Topics</h3>
        <div *ngFor="let category of categories;"
             class="body-2 color-green-grey-3 v2-margin-8px-t">
          <a *ngIf="category._id == currentCategory._id"
             [href]="faqCategoryLink(category)"
             class="h4 color-green-grey-1">
            <img src="assets/play-green.svg"
                 class="play-icon" />
            {{category.name}}
          </a>
          <a *ngIf="category._id != currentCategory._id"
             [href]="faqCategoryLink(category)"
             class="category-link">
            {{category.name}}
          </a>
        </div>
      </div>
      <app-webinar-card />
    </div>
  </v2-two-columns-page>
</app-page>
