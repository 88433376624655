import _ from 'underscore';
import { AfterViewInit, Component, OnInit } from '@angular/core';

import { DebugService } from './debug.service';

@Component({
  selector: 'a7-debug-panel',
  templateUrl: './debug-panel.component.html',
  styleUrls: ['./debug-panel.component.scss'],
})
export class DebugPanelComponent implements AfterViewInit, OnInit {
  // Injected by DebugService.
  debugService: DebugService;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
