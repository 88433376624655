import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'a7-page-index',
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss'],
})
export class PageIndexComponent {
  @Input() count = 3;

  @Input() buttonSize = 60;
  @Input()
  buttonStyle: 'within_container' | 'between_card' | 'none' = 'between_card';
  @Input() buttonShape: 'square' | 'circle' = 'circle';

  @Input() showBottomDots = true;
  @Input() dotYOffSet = '24px';
  @Input() dotClass = 'bg-white';
  @Input() dotClickable = true;

  @Input() allowCircleBack = true;

  private _index = 2;

  @Input()
  set index(i) {
    this._index = i;
    this.indexChange.next(this._index);
  }
  get index() {
    return this._index;
  }

  buttonOffSet(left: boolean) {
    return (
      (left ? -1 : 1) *
      (this.buttonStyle === 'between_card' ? this.buttonSize / 2 : 0)
    );
  }

  buttonClasses(left: boolean) {
    if (this.buttonStyle === 'none') {
      return 'transparent';
    } else if (!this.allowCircleBack && left && this.index === 0) {
      return 'transparent';
    } else if (
      !this.allowCircleBack &&
      !left &&
      this.index === this.count - 1
    ) {
      return 'transparent';
    } else if (this.buttonShape === 'square') {
      return 'v2-opacity-80';
    } else {
      return 'border-circle';
    }
  }

  @Output()
  indexChange = new EventEmitter<number>();

  next() {
    if (!this.allowCircleBack && this.index === this.count - 1) return;
    this.index = (this.index + 1) % this.count;
  }

  prev() {
    if (!this.allowCircleBack && this.index === 0) return;
    this.index = (this.index - 1 + this.count) % this.count;
  }
}
