import _ from 'underscore';
import { Debugger } from 'debug';

type AnyFunction = (...args: any[]) => any;

export function Debug(options: DebugOptions): MethodDecorator {
  return <AnyFunction>(
    function(
      _target: Object,
      propertyKey: string,
      descriptor: TypedPropertyDescriptor<AnyFunction>,
    ): TypedPropertyDescriptor<AnyFunction> {
      const oldFunc: AnyFunction = descriptor.value;

      descriptor.value = function() {
        const args = arguments;
        const format = `${propertyKey}(${_.times(
          arguments.length,
          () => '%O',
        ).join(', ')})`;

        options.d(`|> ${format}`, ...args);

        const result = oldFunc.apply(this, args);

        if (result && result.then) {
          return new Promise((resolve, reject) => {
            result.then(
              (fr: any) => {
                options.d(`<| ${format}: %O`, ...args, fr);
                resolve(fr);
              },
              (reason: any) => {
                options.d(`E| ${format}: throws %O`, ...args, reason);
                reject(reason);
              },
            );
          });
        } else {
          options.d(`<| ${format}: %O`, ...args, result);
        }
        return result;
      };

      return descriptor;
    }
  );
}

export interface DebugOptions {
  d: Debugger;
}
