import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { A7LayoutService, A7PageSizeMatcher } from '../../services';

@Injectable()
export class V2BaseComponent implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();
  v2PaddingH = 'v2-padding-5-h';

  @A7PageSizeMatcher('MD-')
  md: boolean;

  @A7PageSizeMatcher('SM-')
  sm: boolean;

  @A7PageSizeMatcher('XS-')
  xs: boolean;

  get width() {
    return this.layoutService.currentWidth;
  }

  constructor(protected layoutService: A7LayoutService) {
    this.safeSubscribe(layoutService.width, (_w) => {
      this.v2PaddingH = this.sm
        ? 'v2-padding-2-h'
        : this.md
        ? 'v2-padding-4-h'
        : 'v2-padding-5-h';
    });
  }

  safeSubscribe<T>(
    subject: Observable<T>,
    fn: (obj: T) => void,
    errorFn?: (error: any) => void,
  ) {
    subject.pipe(takeUntil(this.unsubscribe$)).subscribe(fn, errorFn);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
