import {
  A7ResourceAction,
  A7ResourceCRUD,
  A7ResourceParams,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';
import { IResourceMethodPromise } from '@ngx-resource/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/user/moneymade',
  withCredentials: true,
})
export class MoneymadeResource extends A7ResourceCRUD<null> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/oauth',
  })
  moneymadeOauth: IResourceMethodPromise<
    { payload: string; signature: string },
    void
  >;
}
