import { A7PageSizeMatcher } from '@ark7/layout';
import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  constructor() {}

  @HostBinding('class.mobile')
  @A7PageSizeMatcher('XS')
  mobile: boolean;

  ngOnInit() {}
}
