import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { a7EmailValidator, forms } from '@ark7/utils';

@Component({
  selector: 'create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent extends V2BaseComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(fb: UntypedFormBuilder, l: A7LayoutService) {
    super(l);
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email, a7EmailValidator()]],
    });
  }

  ngOnInit() {}

  async next() {
    if (!this.form.valid) {
      forms.validateAllFormFields(this.form);
      return;
    }

    window.location.href =
      '/a/sign-up?email=' + encodeURIComponent(this.form.controls.email.value);
  }
}
