<form>
  <div class="a7-ui-auth-form-title a7-ui-auth-form_section">
    <h1 class="h2">{{formTitle}}</h1>
    <ng-content select="[a7-auth-form-title]"></ng-content>
  </div>

  <div class="info-section a7-ui-auth-form_section">
    <ng-content select="[a7-auth-form-body]"></ng-content>
    <ng-content></ng-content>
  </div>

  <div class="action-section a7-ui-auth-form_section"
       fxLayout="row wrap"
       fxLayoutAlign="center center">
    <ng-content select="[a7-auth-form-actions]"></ng-content>
  </div>

  <div class="bottom-section a7-ui-auth-form_section">
    <ng-content select="[a7-auth-form-bottom]"></ng-content>
  </div>
</form>