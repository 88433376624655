<div [ngStyle]="{ height: '27px', 'padding-right': '2px' }"
     (click)="clicked.next()"
     class="full-width flex-row-end-center pointer">
  <svg width="15"
       height="15"
       viewBox="0 0 15 15"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
       [ngClass]="svgClass">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.6901 0.465988C13.0855 0.0705813 13.7266 0.0705814 14.122 0.465987C14.5174 0.861393 14.5174 1.50247 14.122 1.89788L8.9317 7.08818L14.3016 12.4581C14.697 12.8535 14.697 13.4946 14.3016 13.89C13.9062 14.2854 13.2651 14.2854 12.8697 13.89L7.49981 8.52007L2.12991 13.89C1.73451 14.2854 1.09343 14.2854 0.69802 13.89C0.302614 13.4946 0.302615 12.8535 0.698021 12.4581L6.06791 7.08818L0.877611 1.89788C0.482205 1.50247 0.482205 0.861395 0.87761 0.465988C1.27302 0.0705822 1.9141 0.0705822 2.3095 0.465989L7.49981 5.65629L12.6901 0.465988Z"
          fill="#3B4742" />
  </svg>
</div>