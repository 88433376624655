import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'v2-feature-tile',
  templateUrl: './feature-tile.component.html',
  styleUrls: ['./feature-tile.component.scss'],
})
export class FeatureTileComponent extends V2BaseComponent {
  @Input()
  picSrc = '';
  @Input()
  picAlt = '';
  @Input()
  title = '';
  @Input()
  details = '';

  constructor(layoutService: A7LayoutService) {
    super(layoutService);
  }
}
