<ng-container *ngIf="counter > 0">
  <div class="label-1 color-green-grey-2">Schools</div>

  <div class="full-width flex-row-start-center flex-wrap v2-col-gap-3 v2-row-gap-16px v2-margin-1-t">
    <div *ngFor="let s of schools"
         class="v2-w-30 flex-row-start-center v2-col-gap-1">
      <div class="v2-min-w-4 v2-w-4 v2-h-4 v2-radius-8px border-green-grey-2 v2-border-1px  center-inner">
        <div class="flex-row-center-end">
          <h4 [ngClass]="s.greatSchoolData.rating ? '' : 'color-green-grey-3'">
            {{
               s.greatSchoolData.rating
               ? (s.greatSchoolData.rating | number: '.0-0')
               : 'N/A'
              }}
          </h4>
          <span class="label-2  color-green-grey-3">{{
              s.greatSchoolData.rating ? '/10' : ''
            }}</span>
        </div>
      </div>

      <div class="flex-column-between-start">
        <h4>{{ s.title }}</h4>
        <span class="label-2 color-green-grey-3">
          <span *ngIf="s.distanceInMiles">
            {{ formatNumber(s.distanceInMiles, 'en-US', '.0-1') }} miles
          </span>
          <span>Level: {{ s.greatSchoolData.grades || 'unknown' }}</span>
        </span>
      </div>
    </div>
  </div>
  <div class="h5 underline v2-margin-2-t pointer"
       *ngIf="!_viewAll && counter > 4"
       (click)="viewAll(true)">
    Show All
  </div>
</ng-container>
