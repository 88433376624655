import _ from 'underscore';
import {
  AnimationMetadata,
  AnimationReferenceMetadata,
  AnimationStyleMetadata,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

import * as expansion from './expansion';
import * as fading from './fading';

export enum AnimateState {
  VOID = 'void',
  IN = 'in',
  HIDE = 'hide',
  FADE_IN = 'fadeIn',
  FADE_IN_LEFT = 'fadeInLeft',
  FADE_IN_RIGHT = 'fadeInRight',
  FADE_IN_UP = 'fadeInUp',
  FADE_IN_DOWN = 'fadeInDown',
  FADE_OUT = 'fadeOut',
  FADE_OUT_LEFT = 'fadeOutLeft',
  FADE_OUT_RIGHT = 'fadeOutRight',
  FADE_OUT_UP = 'fadeOutUp',
  FADE_OUT_DOWN = 'fadeOutDown',

  FADE_IN_POST_SHOW = 'fadeInPost',
  FADE_IN_LEFT_POST_SHOW = 'fadeInLeftPost',
  FADE_IN_RIGHT_POST_SHOW = 'fadeInRightPost',
  FADE_IN_UP_POST_SHOW = 'fadeInUpPost',
  FADE_IN_DOWN_POST_SHOW = 'fadeInDownPost',
  FADE_OUT_POST_HIDE = 'fadeOutPost',
  FADE_OUT_LEFT_POST_HIDE = 'fadeOutLeftPost',
  FADE_OUT_RIGHT_POST_HIDE = 'fadeOutRightPost',
  FADE_OUT_UP_POST_HIDE = 'fadeOutUpPost',
  FADE_OUT_DOWN_POST_HIDE = 'fadeOutDownPost',

  EXPAND_IN = 'expandIn',
  EXPAND_IN_SMOOTH = 'expandInSmooth',
  EXPAND_OUT = 'expandOut',
  EXPAND_OUT_SMOOTH = 'expandOutSmooth',

  EXPAND_IN_POST_SHOW = 'expandInPost',
  EXPAND_IN_SMOOTH_POST_SHOW = 'expandInSmoothPost',
  EXPAND_OUT_POST_HIDE = 'expandOutPost',
  EXPAND_OUT_SMOOTH_POST_HIDE = 'expandOutSmoothPost',
}

export const STATE_SHOW_IN = style({
  position: 'relative',
  opacity: 1,
  transform: 'none',
  display: 'block',
});

export const STATE_HIDE = style({
  position: 'absolute',
  top: 0,
  left: 0,
  opacity: 0,
  width: '100%',
  transform: 'translateY(10000%)',
  display: 'none',
});

export const STATE_HIDE_IN = style({
  position: 'relative',
  opacity: 0,
  transform: 'none',
  display: 'block',
});

export const STATE_HIDE_OUT = style({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  opacity: 0,
  transform: 'translateY(10000%)',
  display: 'block',
});

export const STATE_SHOW_OUT = style({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  opacity: 1,
  transform: 'none',
  display: 'block',
});

const showStyleMetadatas = [STATE_SHOW_IN, STATE_HIDE_IN];
const hideStyleMetadatas = [STATE_HIDE_OUT, STATE_SHOW_OUT];
const postShowStyleMetadatas = [STATE_SHOW_IN, STATE_HIDE_OUT];
const postHideStyleMetadatas = [STATE_HIDE_OUT, STATE_SHOW_IN];

const metadatas: AnimationMetadata[] = _.chain([
  [AnimateState.FADE_IN, ...showStyleMetadatas, fading.fadeIn],
  [AnimateState.FADE_IN_LEFT, ...showStyleMetadatas, fading.fadeInLeft],
  [AnimateState.FADE_IN_RIGHT, ...showStyleMetadatas, fading.fadeInRight],
  [AnimateState.FADE_IN_UP, ...showStyleMetadatas, fading.fadeInUp],
  [AnimateState.FADE_IN_DOWN, ...showStyleMetadatas, fading.fadeInDown],

  [AnimateState.FADE_OUT, ...hideStyleMetadatas, fading.fadeOut],
  [AnimateState.FADE_OUT_LEFT, ...hideStyleMetadatas, fading.fadeOutLeft],
  [AnimateState.FADE_OUT_RIGHT, ...hideStyleMetadatas, fading.fadeOutRight],
  [AnimateState.FADE_OUT_UP, ...hideStyleMetadatas, fading.fadeOutUp],
  [AnimateState.FADE_OUT_DOWN, ...hideStyleMetadatas, fading.fadeOutDown],

  [AnimateState.FADE_IN_POST_SHOW, ...postShowStyleMetadatas, fading.fadeIn],
  [
    AnimateState.FADE_IN_LEFT_POST_SHOW,
    ...postShowStyleMetadatas,
    fading.fadeInLeft,
  ],
  [
    AnimateState.FADE_IN_RIGHT_POST_SHOW,
    ...postShowStyleMetadatas,
    fading.fadeInRight,
  ],
  [
    AnimateState.FADE_IN_UP_POST_SHOW,
    ...postShowStyleMetadatas,
    fading.fadeInUp,
  ],
  [
    AnimateState.FADE_IN_DOWN_POST_SHOW,
    ...postShowStyleMetadatas,
    fading.fadeInDown,
  ],

  [AnimateState.FADE_OUT_POST_HIDE, ...postHideStyleMetadatas, fading.fadeOut],
  [
    AnimateState.FADE_OUT_LEFT_POST_HIDE,
    ...postHideStyleMetadatas,
    fading.fadeOutLeft,
  ],
  [
    AnimateState.FADE_OUT_RIGHT_POST_HIDE,
    ...postHideStyleMetadatas,
    fading.fadeOutRight,
  ],
  [
    AnimateState.FADE_OUT_UP_POST_HIDE,
    ...postHideStyleMetadatas,
    fading.fadeOutUp,
  ],
  [
    AnimateState.FADE_OUT_DOWN_POST_HIDE,
    ...postHideStyleMetadatas,
    fading.fadeOutDown,
  ],

  [AnimateState.EXPAND_IN, ...showStyleMetadatas, expansion.expandIn],
  [
    AnimateState.EXPAND_IN_SMOOTH,
    ...showStyleMetadatas,
    expansion.expandInSmooth,
  ],

  [AnimateState.EXPAND_OUT, ...hideStyleMetadatas, expansion.expandOut],
  [
    AnimateState.EXPAND_OUT_SMOOTH,
    ...hideStyleMetadatas,
    expansion.expandOutSmooth,
  ],

  [
    AnimateState.EXPAND_IN_POST_SHOW,
    ...postShowStyleMetadatas,
    expansion.expandIn,
  ],
  [
    AnimateState.EXPAND_IN_SMOOTH_POST_SHOW,
    ...postShowStyleMetadatas,
    expansion.expandInSmooth,
  ],

  [
    AnimateState.EXPAND_OUT_POST_HIDE,
    ...postHideStyleMetadatas,
    expansion.expandOut,
  ],
  [
    AnimateState.EXPAND_OUT_SMOOTH_POST_HIDE,
    ...postHideStyleMetadatas,
    expansion.expandOutSmooth,
  ],
])
  .map(
    (
      m: [
        AnimateState,
        AnimationStyleMetadata,
        AnimationStyleMetadata,
        AnimationReferenceMetadata,
      ],
    ) => [
      state(m[0], m[1]),
      transition(`* => ${m[0]}`, [m[2], useAnimation(m[3])]),
    ],
  )
  .flatten()
  .value() as any;

export const animateTrigger = trigger('animate', [
  state(AnimateState.IN, STATE_SHOW_IN),
  state(AnimateState.HIDE, STATE_HIDE),
  state(AnimateState.VOID, STATE_HIDE),

  ...metadatas,
]);
