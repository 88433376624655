import { A7Router } from '@ark7/utils';
import { AccountType } from '@ark7/identity';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { UserService } from './user.service';

/**
 * Routes the user to the proper account (Individual or IRA) on the UI.
 */
@Injectable({
  providedIn: 'root',
})
export class AccountRoutingService {
  constructor(
    activeRoute: ActivatedRoute,
    userService: UserService,
    router: A7Router,
  ) {
    userService.userChange$.pipe(first()).subscribe((user) => {
      activeRoute.queryParams.subscribe((params) => {
        if (params.account && this.checkAccountType(params.account)) {
          for (const account of user.accounts) {
            if (account.type.toLowerCase() === params.account.toLowerCase()) {
              user.account = account;
              // clear the route param regardless. We are not retaining the account to display through url.
              router.navigate([], {
                relativeTo: activeRoute,
                queryParams: { account: null },
                queryParamsHandling: 'merge',
              });
              break;
            }
          }
        }
      });
    });
  }

  private checkAccountType(account: string): boolean {
    return (<any>Object).values(AccountType).includes(account.toUpperCase());
  }
}
