import { formatCurrency } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  A7LayoutService,
  A7PageSizeMatcher,
  LayoutSubscribeComponent,
} from '@ark7/layout';
import {
  AppConfigurationService,
  AuthResource,
  TrackingService,
} from '@ark7/resources-common';
import * as changeCase from 'change-case';
import _ from 'underscore';

@Component({
  selector: 'app-referral-invitation',
  templateUrl: './referral-invitation.component.html',
  styleUrls: ['./referral-invitation.component.scss'],
})
export class ReferralInvitationComponent
  extends LayoutSubscribeComponent
  implements OnInit {
  private referralCode: string;
  private referrerName: string;

  constructor(
    layout: A7LayoutService,
    route: ActivatedRoute,
    private authResource: AuthResource,
    private configurationService: AppConfigurationService,
    public trackingService: TrackingService,
  ) {
    super(layout);

    this.safeSubscribe(route.queryParams, (params) => {
      if (params.r) {
        this.referralCode = params.r;
        this.fetchReferralName();
      }
    });
  }

  @HostBinding('class.mobile')
  @A7PageSizeMatcher('XS')
  mobile: boolean;

  ngOnInit(): void {}

  async fetchReferralName() {
    if (!_.isEmpty(this.referralCode)) {
      const referrerName = await this.authResource.referrerName({
        referralCode: this.referralCode,
      });
      this.referrerName = changeCase.title(
        _.isEmpty(referrerName.first) ? referrerName.last : referrerName.first,
      );
    }
  }

  get greetingTitle() {
    if (_.isEmpty(this.referrerName) || this.referrerName === 'Ark7') {
      return "You've been invited to join Ark7";
    } else {
      return `${this.referrerName} has invited you to join Ark7`;
    }
  }

  get minInvestmentAmount() {
    const amount = this.configurationService.getConfig(
      'app.referral.minInvestmentAmount',
    );
    return amount == null
      ? ''
      : formatCurrency(amount, 'en-US', '$', 'USD', '.0');
  }

  get refereeBonusAmount() {
    const amount = this.configurationService.getConfig(
      'app.referral.refereeBonusAmount',
    );
    return amount == null
      ? ''
      : formatCurrency(amount, 'en-US', '$', 'USD', '.0');
  }

  get referralInvitationDesc() {
    return this.configurationService.referralInvitationDesc
      .replace(
        '<subheading2>#MIN_INVESTMENT_AMOUNT#</subheading2>',
        `<span>${this.minInvestmentAmount}</span>`,
      )
      .replace(
        '<subheading2>#REFEREE_BONUS_AMOUNT#</subheading2>',
        `<span class="h5 color-green-grey-1">${this.refereeBonusAmount}</span>`,
      );
  }
}
