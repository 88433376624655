import * as _ from 'underscore';
import { animation, style, animate, keyframes } from '@angular/animations';

import { DEFAULT_TIMING } from './defaults';
import { translateTiming } from './utils';

export interface ExpandParams {
  timing?: number | string;
  delay?: number | string;
  height1?: number | string;
  height2?: number | string;
  opacity1?: number;
  opacity2?: number;
}

export function expand(options: ExpandParams = {}) {
  const params: ExpandParams = _.defaults({}, options, {
    timing: DEFAULT_TIMING,
    delay: '0s',
    height1: '0px',
    height2: '0px',
    opacity1: 1,
    opacity2: 1,
  });
  params.timing = translateTiming(params.timing);
  params.delay = translateTiming(params.delay);

  return animation(
    animate(
      '{{ timing }} {{ delay }}',
      keyframes([
        style({
          overflow: 'hidden',
          opacity: '{{ opacity1 }}',
          height: '{{ height1 }}',
          offset: 0,
        }),
        style({
          overflow: 'hidden',
          opacity: '{{ opacity2 }}',
          height: '{{ height2 }}',
          offset: 1,
        }),
      ]),
    ),
    { params },
  );
}

export const expandIn = expand({ height2: '*' });
export const expandInSmooth = expand({ height2: '*', opacity1: 0 });

export const expandOut = expand({ height1: '*' });
export const expandOutSmooth = expand({ height1: '*', opacity2: 0 });
