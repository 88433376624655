import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'v2-video-play',
  templateUrl: './video-play.component.html',
  styleUrls: ['./video-play.component.scss'],
})
export class VideoPlayComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<VideoPlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.afterClosed().subscribe(() => {});
    this.videoId = data.videoId;
    this.width = data.width ?? '100%';
    this.height = data.height ?? '100%';
  }

  videoId: string;

  width = '100%';

  height = '100%';

  ngOnInit() {}
  get src() {
    return `https://www.youtube.com/embed/${
      this.videoId ?? 'zV2S8aPPolY'
    }?autoplay=1&color=white&fs=0&modestbranding=1&rel=0&showinfo=0&enablejsapi=1&origin=https%3A%2F%2Fark7.com&widgetid=1`;
  }

  close() {
    this.dialogRef.close();
  }
}
