import { Component } from '@angular/core';
import { FAQ, FAQCategory } from '@ark7/core-business-models';
import { FAQCategoryResource, FAQResource } from '@ark7/resources-common';

import { HelpBaseComponent } from './help-base.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent extends HelpBaseComponent {
  topFAQs: FAQ[];

  categories: FAQCategory[];

  constructor(
    private faqResource: FAQResource,
    private faqCategoryResource: FAQCategoryResource,
  ) {
    super();
    this.faqResource
      .queryWithPagination({ tags: 'TOP', size: 6 })
      .subscribe((res) => {
        this.topFAQs = res.data;
      });

    this.faqCategoryResource
      .queryWithPagination({ parentCategory: null, size: 6 })
      .subscribe((res) => {
        this.categories = res.data;
      });
  }
}
