import _ from 'underscore';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';

import { LoadingProgressService } from '../loading-progress.service';
import { LoadingProgressor, LoadingState } from '../loading-progressor';

@Component({
  selector: 'a7-loading-content',
  templateUrl: './loading-content.component.html',
  styleUrls: ['./loading-content.component.scss'],
})
export class LoadingContentComponent implements OnInit, OnChanges {
  @Input() state: LoadingState;
  @Input() progress: number;

  @Input() progressor: LoadingProgressor;
  @Input() global: boolean = false;

  @Input() watch: object | Promise<any> | Observable<any>;

  @HostBinding('class.inline')
  @Input()
  inline: boolean = false;

  @Output() stateChanges = new EventEmitter<LoadingState>();

  @HostBinding('class.a7-loading-content')
  _hostBinding = true;

  @HostBinding('style.min-height.px')
  get minHeightPx(): number {
    if (this.state !== LoadingState.IDLE) {
      return this.minLoadingHeightPx;
    } else {
      return 0;
    }
  }

  minLoadingHeightPx: number = 0;

  constructor(private loadingProgressService: LoadingProgressService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.state) {
      this.stateChanges.emit(this.state);
    }
    if (changes.watch) {
      const value = changes.watch.currentValue;

      if (value == null) {
        this.state = LoadingState.LOADING;
      } else {
        this.state = LoadingState.SUCCESS;
      }
    }
  }

  ngOnInit() {
    if (this.global) {
      this.progressor = this.loadingProgressService.progressor;
    }
    if (this.progressor) {
      this.progressor.progress.subscribe((progress) => {
        this.state = progress.state;
        this.stateChanges.emit(this.state);
        this.progress = progress.progress;
      });
    }
  }

  @HostBinding('class.a7-loading-content-hide-content')
  get hideContent(): boolean {
    return (
      this.state === LoadingState.LOADING ||
      this.state === LoadingState.FAILURE ||
      this.state === LoadingState.SUCCESS
    );
  }
}
