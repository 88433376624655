import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SubscribeComponent } from '@ark7/utils';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'a7-auto-sign-out-dialog',
  templateUrl: './auto-sign-out-dialog.component.html',
  styleUrls: ['./auto-sign-out-dialog.component.scss'],
})
export class AutoSignOutDialogComponent extends SubscribeComponent
  implements OnInit {
  constructor(private dialogRef: MatDialogRef<AutoSignOutDialogComponent>) {
    super();
  }

  ngOnInit(): void {}

  continue() {
    this.dialogRef.close(true);
  }
}
