<div [matMenuTriggerFor]="floatingMenu"
     #trigger="matMenuTrigger"
     (menuOpened)="menuOpened.next()"
     (menuClosed)="menuClosed.next()">
  <ng-content select="[button-slot]"></ng-content>
</div>

<mat-menu #floatingMenu="matMenu"
          [backdropClass]="backdropClass"
          [class]="mode"
          [hasBackdrop]="true"
          xPosition="before">
  <!-- eager creation -->
  <ng-content select="[menu-slot]"></ng-content>
  <!-- lazy creation -->
  <ng-container *ngIf="trigger.menuOpen">
    <ng-container *ngFor="let item of menuItems"
                  [ngTemplateOutlet]="item.template"></ng-container>
  </ng-container>
</mat-menu>
