import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'v2-why-ark7',
  templateUrl: './why-ark7.component.html',
  styleUrls: ['./why-ark7.component.scss'],
})
export class WhyArk7Component extends V2BaseComponent implements OnInit {
  items = [
    {
      label: 'Wide range of high-yield properties nationwide',
      icon: '/assets/v2-icons/why-ark7-high-yield.svg',
    },
    {
      label: 'Affordable to buy',
      icon: '/assets/v2-icons/why-ark7-affordable.svg',
    },
    {
      label: 'Flexible to sell',
      icon: '/assets/v2-icons/why-ark7-flexible.svg',
    },
    {
      label: 'Full operation on us',
      icon: '/assets/v2-icons/why-ark7-full-operation.svg',
    },
    {
      label: 'Your data and money secured',
      icon: '/assets/v2-icons/why-ark7-secured.svg',
    },
    {
      label: 'Anytime, everywhere',
      icon: '/assets/v2-icons/why-ark7-anytime.svg',
    },
  ];

  ngOnInit() {}

  constructor(layoutService: A7LayoutService) {
    super(layoutService);
  }
}
