import {
  A7ResourceCRUDObservable,
  A7ResourceParams,
  ResourceHandler,
} from '@ark7/resource2';
import { FAQ } from '@ark7/core-business-models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/faqs',
  withCredentials: false,
  model: FAQ,
})
export class FAQResource extends A7ResourceCRUDObservable<
  FAQ,
  { tags?: string; category?: string }
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }
}
