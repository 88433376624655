import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AccessLogService } from '../services/access-log.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private accessLogService: AccessLogService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Session-Id': this.accessLogService.sessionId,
        'Session-Seq': this.accessLogService.sessionSeq.toString(),
      },
    });

    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          return throwError(err);
        } else {
          return next.handle(request);
        }
      }),
    );
  }
}
