<mat-tab-group [selectedIndex]="stage"
               class="a7-ui-auth-form_tab-group">
  <!-- selectedIndex 0: Reset password form -->
  <mat-tab>
    <a7-auth-form-tab [formGroup]="form">
      <div class="center-inner">
        <div class="color-green-grey-1 h1 nowrap">
          Reset Your Password
        </div>
      </div>
      <ng-container *ngIf="!useToken">
        <mat-form-field class="full-width a7-form-field v2-margin-40px-t"
                        [hideRequiredMarker]="true">
          <div matPrefix
               class="v2-h-5 v2-w-4 center-inner">
            <img src="/assets/v2-email.svg"
                 alt="email" />
          </div>

          <input matInput
                 name="email"
                 formControlName="email"
                 placeholder="Email">
          <!-- <mat-placeholder class="color-green-grey-3 body-1">Email</mat-placeholder> -->
          <mat-error *ngIf="
          form.controls.email.hasError('email') ||
          form.controls.email.hasError('required')">
            Please enter your email.</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width a7-form-field v2-margin-1-t"
                        [hideRequiredMarker]="true">
          <div matPrefix
               class="v2-h-5 v2-w-4 center-inner">
            <span class="form-icon text-primary icon-code-weight-3">
            </span>
          </div>

          <input matInput
                 name="code"
                 formControlName="code"
                 placeholder="Code">
          <!-- <mat-placeholder class="color-green-grey-3 body-1">Code</mat-placeholder> -->
          <mat-error *ngIf="form.controls.code.hasError('required')">
            Please enter the code you received.</mat-error>
        </mat-form-field>
      </ng-container>

      <mat-form-field class="full-width a7-form-field v2-margin-1-t"
                      [hideRequiredMarker]="true">
        <div matPrefix
             class="v2-h-5 v2-w-4 center-inner">
          <img src="/assets/v2-password.svg"
               alt="password" />
        </div>

        <input matInput
               type="password"
               autocomplete="new-password"
               name="password"
               formControlName="password"
               placeholder="Password">
        <!-- <mat-placeholder class="color-green-grey-3 body-1">Password</mat-placeholder> -->

        <mat-error *ngIf="form.controls.password.hasError('required')">
          Please enter a password.
        </mat-error>
        <mat-error *ngIf="form.controls.password.hasError('minlength')">
          Please enter at least 8 characters.
        </mat-error>
        <mat-error *ngIf="form.controls.password.hasError('pattern') && !form.controls.password.hasError('minlength')"
                   [ngSwitch]="form.controls.password.errors['pattern'].requiredPattern">
          <ng-container *ngSwitchCase="'/[A-Z]/'">
            Please enter at least 1 upper character.
          </ng-container>
          <ng-container *ngSwitchCase="'/[a-z]/'">
            Please enter at least 1 lower character.
          </ng-container>
          <ng-container *ngSwitchCase="'/[0-9]/'">
            Please enter at least 1 lower character.
          </ng-container>
        </mat-error>
        <mat-error *ngIf="form.controls.password.hasError('invalid')">
          Password is invalid.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width a7-form-field v2-margin-1-b v2-margin-1-t"
                      [hideRequiredMarker]="true">
        <div matPrefix
             class="v2-h-5 v2-w-4 center-inner">
          <img src="/assets/v2-password.svg"
               alt="password" />
        </div>

        <input matInput
               type="password"
               autocomplete="new-password"
               name="confirmPassword"
               formControlName="confirmPassword"
               placeholder="Confirm Password">
        <!-- <mat-placeholder class="color-green-grey-3 body-1">Confirm Password</mat-placeholder> -->

        <mat-error *ngIf="form.controls.confirmPassword.hasError('match')">
          Password doesn't match.
        </mat-error>
      </mat-form-field>

      <button a7-auth-form-actions
              mat-flat-button
              color="primary"
              [disabled]="!form.valid || sending"
              (click)="resetPassword()"
              class="h4">
        Reset Password
      </button>

    </a7-auth-form-tab>
  </mat-tab>

  <!-- selectedIndex 1: Token expired -->
  <mat-tab>
    <a7-auth-form-tab>
      <div class="center-inner">
        <div class="color-green-grey-1 h1 nowrap">
          Token Expired
        </div>
      </div>
      <div class="body-2 color-green-grey-3 v2-margin-40px-t">
        The token has been expired.
      </div>
      <div class="center-inner v2-margin-40px-t">
        <img src="/assets/v2-fail.svg"
             alt="back" />
      </div>
      <button a7-auth-form-actions
              mat-flat-button
              class="h5 v2-margin-40px-t border border-blue-grey-4 text-green-grey-1"
              [routerLink]="['/a/forgot-password']">
        Forgot password
      </button>
    </a7-auth-form-tab>
  </mat-tab>

  <!-- selectedIndex 2: Code mismatch -->
  <mat-tab>
    <a7-auth-form-tab>
      <div class="center-inner">
        <div class="color-green-grey-1 h1 nowrap">
          Code Mismatch
        </div>
      </div>
      <div class="body-2 color-green-grey-3 v2-margin-40px-t">
        The password reset code is invalid, please click the following button to obtain a new code.
      </div>
      <div class="center-inner v2-margin-40px-t">
        <img src="/assets/v2-fail.svg"
             alt="back" />
      </div>
      <button a7-auth-form-actions
              mat-flat-button
              class="h5 v2-margin-40px-t border border-blue-grey-4 text-green-grey-1"
              [routerLink]="['/a/forgot-password']">
        Forgot password
      </button>
    </a7-auth-form-tab>
  </mat-tab>

  <!-- selectedIndex 3: internal server error -->
  <mat-tab>
    <a7-auth-form-tab>
      <div class="center-inner">
        <div class="color-green-grey-1 h1">
          Oops, something wrong on our side
        </div>
      </div>
      <div class="body-2 color-green-grey-3 v2-margin-40px-t">
        We are facing an unexpected error, please try again later!
      </div>
      <div class="center-inner v2-margin-40px-t">
        <img src="/assets/v2-fail.svg"
             alt="back" />
      </div>
      <button a7-auth-form-actions
              mat-flat-button
              class="h5 v2-margin-40px-t border border-blue-grey-4 text-green-grey-1"
              [routerLink]="['/a/forgot-password']">
        Go back
      </button>
    </a7-auth-form-tab>
  </mat-tab>

  <!-- selectedIndex 4: Reset success -->
  <mat-tab>
    <a7-auth-form-tab>
      <div class="center-inner">
        <div class="color-green-grey-1 h1 nowrap">
          Password Reset Successful
        </div>
      </div>
      <div class="body-2 color-green-grey-3 v2-margin-40px-t">
        Your password has been updated successfully. Please use your new password to login.
      </div>
      <div class="center-inner v2-margin-40px-t">
        <img src="/assets/v2-success.svg"
             alt="back" />
      </div>
      <button a7-auth-form-actions
              mat-flat-button
              color="primary"
              class="h4 v2-margin-40px-t"
              (click)="navigateToSignIn()">
        Login
      </button>
    </a7-auth-form-tab>
  </mat-tab>
</mat-tab-group>
