import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { fromEvent, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import _ from 'underscore';

import { DesignSystemDirective } from '../directives/design-system.directive';

export enum PageSize {
  XS = 'XS', // 599.98-
  SM = 'SM', // 600 959.98
  MD = 'MD', // 960 1279.98
  LG = 'LG', // 1280 1919.98
  XL = 'XL', // 1920+
}

export let layoutService: A7LayoutService;

@Injectable({
  providedIn: 'root',
})
export class A7LayoutService {
  columns: Observable<number>;

  _pageSize: PageSize;
  pageSize: Observable<PageSize>;

  width: ReplaySubject<number> = new ReplaySubject(1);
  height: ReplaySubject<number> = new ReplaySubject(1);
  currentWidth = 0;
  currentHeight = 0;

  lastDs: string;
  dsStack: DesignSystemDirective[] = [];

  private vh: Observable<number>;
  private renderer2: Renderer2;

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) platformId: string,
    rendererFactory: RendererFactory2,
  ) {
    // Initiate global layout service instance.
    if (layoutService == null) {
      layoutService = this;
    }

    if (isPlatformBrowser(platformId)) {
      this.columns = fromEvent(window, 'resize')
        // tslint:disable-next-line:deprecation
        .pipe(startWith(null))
        .pipe(
          map(() => {
            if (this.breakpointObserver.isMatched('(max-width: 599px)')) {
              return 4;
            } else if (
              this.breakpointObserver.isMatched('(max-width: 839px)')
            ) {
              return 8;
            } else {
              return 12;
            }
          }),
        )
        .pipe(distinctUntilChanged());

      this.pageSize = fromEvent(window, 'resize')
        // tslint:disable-next-line:deprecation
        .pipe(startWith(null))
        .pipe(
          map(() => {
            if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
              return PageSize.XS;
            } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
              return PageSize.SM;
            } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
              return PageSize.MD;
            } else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
              return PageSize.LG;
            } else {
              return PageSize.XL;
            }
          }),
        )
        .pipe(distinctUntilChanged());

      this.vh = fromEvent(window, 'resize')
        // tslint:disable-next-line:deprecation
        .pipe(startWith(null))
        .pipe(
          tap(() => {
            const w = window?.innerWidth;
            this.width.next(w);
            this.currentWidth = w;

            const h = window?.innerHeight;
            this.height.next(h);
            this.currentHeight = h;
          }),
        )
        .pipe(map(() => window?.innerHeight * 0.01))
        .pipe(distinctUntilChanged());

      this.pageSize.subscribe((ps) => {
        this._pageSize = ps;
      });

      this.vh.subscribe((vh) => {
        // set the value in the --vh custom property to the root of the document
        this.document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    } else {
      this.columns = of();
      this.pageSize = of();
      this.vh = of();
    }

    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  addDesignSystem(ds: DesignSystemDirective) {
    this.dsStack.push(ds);
    this.injectDesignSystem();
  }

  removeDesignSystem(ds: DesignSystemDirective) {
    const index = this.dsStack.indexOf(ds);
    if (index >= 0) {
      this.dsStack.splice(index, 1);
    }
    this.injectDesignSystem();
  }

  private injectDesignSystem() {
    const lastDs = _.last(this.dsStack);
    const ds = lastDs == null ? null : `ds-${lastDs.a7DesignSystem}`;

    if (ds === this.lastDs) {
      return;
    }

    if (this.lastDs != null) {
      this.renderer2.removeClass(this.document.body, this.lastDs);
    }

    if (ds != null) {
      this.renderer2.addClass(this.document.body, ds);
    }

    this.lastDs = ds;
  }
}
