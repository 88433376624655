import debug from 'debug';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

const d = debug('resource2:RequestStatusService');

@Injectable({
  providedIn: 'root',
})
export class RequestStatusService {
  status: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private loadingRequest: number = 0;

  constructor() {}

  incLoadingRequest() {
    this.loadingRequest++;
    this.update();
    d('inc loading request: %o', this.loadingRequest);
  }

  decLoadingRequest() {
    this.loadingRequest--;
    this.update();
    d('dec loading request: %o', this.loadingRequest);
  }

  private update() {
    if (this.status.value !== this.loadingRequest > 0) {
      d('updating request status: %o', this.loadingRequest > 0);
      this.status.next(this.loadingRequest > 0);
    }
  }
}
