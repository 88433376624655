import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestStatusService } from '@ark7/resource2';
import debug from 'debug';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, fromEvent, merge, Observable, timer } from 'rxjs';
import { filter, throttleTime, withLatestFrom } from 'rxjs/operators';

import {
  A7_EXECUTE_SIGN_OUT_TIME,
  A7_SIGN_OUT_DIALOG_TIME,
} from '../declarations';
import { AutoSignOutDialogComponent } from '../dialogs/auto-sign-out-dialog/auto-sign-out-dialog.component';
import { UserService } from './user.service';

const epochTime = () => {
  return new Date().getTime();
};

const d = debug('resources-common:AutoSignOutService');

@Injectable({
  providedIn: 'root',
})
export class AutoSignOutService {
  // Trigger by user's active behaviors on current window.
  public selfActive$: Observable<any>;

  // Last local active, synced across all instances
  @LocalStorage()
  private lastActive = epochTime();
  private waitingForClick$ = new BehaviorSubject(false);

  get waitingForClick(): BehaviorSubject<boolean> {
    return this.waitingForClick$;
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    @Optional()
    @Inject(A7_SIGN_OUT_DIALOG_TIME)
    private signOutDialogTime: number = 1000,
    @Optional()
    @Inject(A7_EXECUTE_SIGN_OUT_TIME)
    private executeSignOutTime: number = 20000,
    private requestStatusService: RequestStatusService,
  ) {}

  public start() {
    this.selfActive$ = merge(
      fromEvent(this.document, 'click'),
      fromEvent(this.document, 'keydown'),
      fromEvent(this.document, 'mousemove'),
      fromEvent(this.document, 'onmousewheel'),
      fromEvent(this.document, 'onwheel'),
      fromEvent(this.document, 'onscroll'),
    );

    merge(
      this.selfActive$,
      this.requestStatusService.status,
      this.localStorageService.observe('lastActive'),
    )
      .pipe(
        withLatestFrom(this.waitingForClick$),
        filter(([, waitingForClick]) => !waitingForClick),
        throttleTime(500), // no need to be too aggressive
      )
      .subscribe(() => {
        d('Receive active signal');
        this.resetWaitingStatus();
      });

    // Validate our status every once a while.
    const sub = timer(1000, 1000)
      .pipe(withLatestFrom(this.waitingForClick$))
      .subscribe(([, waitingForClick]) => {
        d(
          'Checking auto sign out period, requestStatus: %o, remain: %os',
          this.requestStatusService.status.value,
          (this.lastActive + this.signOutDialogTime - epochTime()) / 1000,
        );

        if (this.requestStatusService.status.value) {
          return;
        }

        const now = epochTime();
        if (
          // This also capture the case that we wake from sleep.
          now - this.lastActive >
          this.signOutDialogTime + this.executeSignOutTime
        ) {
          this.userService.signOut();
          sub.unsubscribe();
          this.resetWaitingStatus();
        } else if (
          now - this.lastActive > this.signOutDialogTime + 1000 &&
          !waitingForClick
        ) {
          this.collectContinueSignal();
        }
      });
  }

  public resetWaitingStatus() {
    this.lastActive = epochTime();
    this.waitingForClick$.next(false);
  }

  private collectContinueSignal() {
    this.waitingForClick$.next(true);
    let dialog = this.dialog.open(AutoSignOutDialogComponent, {
      width: '400px',
      disableClose: true,
    });

    const subscription = this.waitingForClick$.subscribe(
      (isWaitingForContinueSignal) => {
        if (!isWaitingForContinueSignal && dialog) {
          dialog.close();
          subscription.unsubscribe();
          dialog = null;
        }
      },
    );

    dialog.afterClosed().subscribe((cont: boolean) => {
      if (cont) {
        this.resetWaitingStatus();
        subscription.unsubscribe();
        dialog = null;
      }
    });
  }
}
