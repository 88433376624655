<ng-template #dialogBase>
  <div class="dialog-base full-width flex-column-start-center v2-max-86vh">
    <v2-dialog-bar *ngIf="title !== false"
                   [title]="title"
                   [titleClass]="titleClass"
                   class="z-index-high sticky full-width v2-top-0"
                   [dialogRef]="dialogRef|asAny"
                   [topBarClass]="dialogBarClass"
                   [svgClass]="dialogCloseButtonClass">
    </v2-dialog-bar>
    <ng-container [ngTemplateOutlet]="contents?.template"></ng-container>

    <div *ngIf="actionDivider && actions?.template"
         class="v2-separator-h-1 full-width v2-margin-16px-v"></div>

    <ng-container [ngTemplateOutlet]="actions?.template"></ng-container>
  </div>
</ng-template>
