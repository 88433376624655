import debug from 'debug';
import { Debug } from '@ark7/utils';
import { Directive, ElementRef, HostListener } from '@angular/core';

import { UserBehaviorService } from '../services/user-behavior.service';

const d = debug('socket:ExternalLinkDirective');

@Directive({
  selector: '[a7-external-link],a[target="_blank"]',
})
export class ExternalLinkDirective {
  constructor(
    private host: ElementRef,
    private userBehavior: UserBehaviorService,
  ) {}

  @Debug({ d })
  @HostListener('click')
  onClick() {
    const link = this.host.nativeElement.href;
    this.userBehavior.openExternalLink({ link });
  }
}
