import { A7LayoutService } from '@ark7/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingProgressor } from '@ark7/loading';
import { SubscribeDirective } from '@ark7/utils';

@Component({
  selector: 'v2-two-columns-page',
  templateUrl: './two-columns-page.component.html',
  styleUrls: ['./two-columns-page.component.scss'],
})
export class TwoColumnsPageComponent
  extends SubscribeDirective
  implements OnDestroy, OnInit {
  @Input()
  progressor: LoadingProgressor = new LoadingProgressor();

  @Input()
  mobile = false;

  @Input()
  overallPadding = 'v2-padding-2-h v2-padding-2-b  v2-padding-40px-t';
  @Input()
  rightClasses =
    'v2-border-1px border-green-grey-4 v2-radius-8px v2-padding-32px-v v2-padding-2-h';
  @Input()
  mobileLayout =
    'flex-column-reverse-between-center v2-row-gap-2 v2-padding-2-v';
  @Input()
  hideRightOnMobile = false;

  @Input()
  topStickyClass = 'v2-top-7';

  @Input()
  webLayout = 'flex-row-between-start v2-col-gap-2';

  windowHeight = 0;

  constructor(private layoutService: A7LayoutService) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.safeSubscribe(this.layoutService.height, (h) => {
      this.windowHeight = h;
    });
  }
}
