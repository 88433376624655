<a7-auth-form-tab formTitle="Oops, something wrong on our side!">
  <p> We are facing an unexpected error, please try again later!</p>

  <button a7-auth-form-actions
          mat-flat-button
          color="primary"
          (click)="back.emit($event)">
    Go back
  </button>
</a7-auth-form-tab>
