<!-- distribution:
ipo: avg dividend rate,
secondary: avg dividend per share
-->
<div class="flex-row-start-start">
  <v2-abcd-block *ngIf="!perShareMode"
                 [cText]="distributionRateWithBoost | percent: '.0-2'"
                 [cClass]="numberClass"
                 [decimalPlaceClass]="decimalPlaceClass"
                 dText="Distribution"
                 [dClass]="dClassWithBoost"
                 [layout]="layoutClass"
                 [toolTip]="dividendRateWithBoostTooltip">
  </v2-abcd-block>


  <v2-abcd-block *ngIf="perShareMode"
                 [bText]="carrier.dividendPerShare | money"
                 [bClass]="numberClass"
                 cText="share"
                 dText="Distribution"
                 [dClass]="dClass"
                 [layout]="layoutClass"
                 [toolTip]="dividendPerShareWithBoostTooltip">
  </v2-abcd-block>

  <img *ngIf="hasDividendBoost"
       class="dividend-boost-icon"
       src="assets/dividend-boost-icon.svg" />

  <svg *ngIf="showSwitch"
       [ngClass]="['pointer', 
                  perShareMode ? 'svg-translate-share':'svg-translate-dividend']"
       (click)="switch()"
       width="25"
       height="24"
       viewBox="0 0 25 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5993 20.4485C17.6246 20.4485 21.6985 16.3746 21.6985 11.3493C21.6985 6.32388 17.6246 2.25 12.5993 2.25C7.57388 2.25 3.5 6.32388 3.5 11.3493C3.5 16.3746 7.57388 20.4485 12.5993 20.4485ZM12.5993 19.0486C16.8515 19.0486 20.2986 15.6015 20.2986 11.3493C20.2986 7.09702 16.8515 3.64989 12.5993 3.64989C8.34701 3.64989 4.89989 7.09702 4.89989 11.3493C4.89989 15.6015 8.34701 19.0486 12.5993 19.0486ZM8.25827 9.20482C7.94125 9.49633 7.94125 9.98526 8.25827 10.2768C8.41135 10.4175 8.61173 10.4957 8.81969 10.4957H10.7205L10.7189 10.4942H16.2244C16.6413 10.4942 16.9793 10.1562 16.9793 9.73927C16.9793 9.32234 16.6413 8.98435 16.2244 8.98435H10.7222L11.6706 8.11223C11.9876 7.82073 11.9876 7.33179 11.6706 7.04027C11.3745 6.76798 10.9083 6.768 10.6122 7.04027L8.25827 9.20482ZM16.7416 12.2244C17.0586 12.5159 17.0586 13.0048 16.7416 13.2964L14.3877 15.4609C14.0916 15.7332 13.6253 15.7332 13.3292 15.4609C13.0122 15.1694 13.0122 14.6805 13.3292 14.389L14.2776 13.5168H8.77543C8.3585 13.5168 8.02051 13.1788 8.02051 12.7619C8.02051 12.345 8.3585 12.007 8.77543 12.007H14.281L14.2794 12.0055H16.1801C16.3881 12.0055 16.5885 12.0836 16.7416 12.2244Z"
          fill="#8F9492" />
  </svg>

</div>
