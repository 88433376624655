import { A7Router } from '@ark7/utils';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UserService } from '@ark7/resources-common';

import { UserRedirectComponent } from '../user-redirect-component';

@Component({
  selector: 'a7-accept-tos-form',
  templateUrl: './accept-tos-form.component.html',
  styleUrls: ['./accept-tos-form.component.scss'],
})
export class AcceptTosFormComponent
  extends UserRedirectComponent
  implements OnInit {
  @Input() tosFields: string;

  @HostBinding('class.a7-ui-auth-accept-tos-form')
  @HostBinding('class.a7-ui-auth-form')
  _hostBinding = true;

  constructor(private userService: UserService, private router: A7Router) {
    super(userService, router);
  }

  async ngOnInit() {
    const hasCurrentUser = await this.userService.hasCurrentUser();
    if (!hasCurrentUser) {
      this.router.navigate(['/a/sign-in']);
    }
  }

  async acceptTos() {
    const apps = this.tosFields.split(' ');
    await this.userService.acceptTos(apps);
  }
}
