import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'v2-foot-drop',
  templateUrl: './foot-drop.component.html',
  styleUrls: ['./foot-drop.component.scss'],
})
export class FootDropComponent implements OnInit {
  @Input()
  hideToggle = true;
  @Input()
  disabled = true;
  @Input()
  expanded = true;
  @Input()
  header = '';

  @Input()
  menu: {
    text: string;
    href?: string;
    target?: string;
  }[] = [];

  constructor() {}

  ngOnInit(): void {}
}
