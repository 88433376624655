import {
  A7ResourceAction,
  A7ResourceCRUDObservable,
  A7ResourceParams,
  IResourceMethodPromise,
  ResourceHandler,
  ResourceRequestMethod,
} from '@ark7/resource2';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/anon/trackings',
  withCredentials: true,
})
export class AnonTrackingResource extends A7ResourceCRUDObservable<{}, {}> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @A7ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/code/{!trackingCode}',
  })
  getByCode: IResourceMethodPromise<
    { trackingCode: string },
    {
      campaign: { _id: string };
      channel: { _id: string };
      platform: { _id: string };
    }
  >;
}
