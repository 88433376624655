 <div class="full-width flex-column-center-center label-2 v2-margin-16px-t">
   <div *ngFor="let boldTxt of bold"
        class="h5 color-green-grey-2">
     {{ boldTxt[0] }}

     <span *ngIf="boldTxt[1]"
           class="v2-margin-4px-l"
           [cTip]="boldTxt[1]">
       <fa-icon [icon]="['far', 'question-circle']"> </fa-icon>
     </span>
   </div>

   <div *ngFor="let grayTxt of gray"
        class="color-green-grey-3">
     {{ grayTxt }}
   </div>
 </div>