import { A7ButtonModule } from '@ark7/button';
import { A7ResourcesCommonModule } from '@ark7/resources-common';
import { A7SharedComponentsModule } from '@ark7/shared-components';
import { A7UiAuthModule } from '@ark7/ui-auth';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LottieModule } from 'ngx-lottie';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Ark7IntroComponent } from './ark7-intro/ark7-intro.component';
import { BuildWealthComponent } from './build-wealth/build-wealth.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { FeatureInComponent } from './home-featured-in/home-featured-in.component';
import { FeatureTileComponent } from './feature-tile/feature-tile.component';
import { FeaturedPropertiesComponent } from './featured-properties/featured-properties.component';
import { FeaturedUserComponent } from './featured-users/featured-user/featured-user.component';
import { FeaturedUsersComponent } from './featured-users/featured-users.component';
import { HomeFeatureBlockComponent } from './home-feature-block/home-feature-block.component';
import { HomeFeaturePosterComponent } from './home-feature-poster/home-feature-poster.component';
import { HomeStatsComponent } from './home-stats/home-stats.component';
import { LogOnBaseComponent } from './log-on-base/log-on-base.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { WhyArk7Component } from './why-ark7/why-ark7.component';

@NgModule({
  imports: [
    A7ButtonModule,
    A7ResourcesCommonModule,
    A7SharedComponentsModule,
    A7UiAuthModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    ReactiveFormsModule,
    RouterModule,
    LottieModule.forRoot({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
  ],
  declarations: [
    FeatureInComponent,
    HomeStatsComponent,
    CreateAccountComponent,
    WhyArk7Component,
    BuildWealthComponent,
    FeaturedPropertiesComponent,
    FeaturedUsersComponent,
    SubscriptionComponent,
    FeaturedUserComponent,
    HomeFeatureBlockComponent,
    HomeFeaturePosterComponent,
    FeatureTileComponent,
    LogOnBaseComponent,
    Ark7IntroComponent,
  ],
  exports: [
    FeatureInComponent,
    HomeStatsComponent,
    CreateAccountComponent,
    WhyArk7Component,
    BuildWealthComponent,
    FeaturedPropertiesComponent,
    FeaturedUsersComponent,
    SubscriptionComponent,
    HomeFeatureBlockComponent,
    HomeFeaturePosterComponent,
    FeatureTileComponent,
    LogOnBaseComponent,
    Ark7IntroComponent,
  ],
})
export class WebV2PartsModule {}
