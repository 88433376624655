import * as uuid from 'uuid';
import _ from 'underscore';
import { __exportStar } from 'tslib';
import { A7Router, ApplicationService } from '@ark7/utils';
import {
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { Identity } from '@ark7/identity';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { AppConfigurationService } from './configuration.service';
import { ClientResource } from '../resources/client-resource';
import { UserService } from './user.service';
import { urlToLocation } from '../utils';

/**
 * Emit the access log to the server.
 *
 * **NOTE**: In order to record the first request, inject the service under the
 * root application module.
 *
 * TODO: Find a better way for early injection, so that no need consumer to
 * inject manually.
 */
@Injectable({
  providedIn: 'root',
})
export class AccessLogService {
  private user: Identity;

  sessionSeq: number = 0;
  sessionId = uuid.v4();

  constructor(
    private userService: UserService,
    private clientResource: ClientResource,
    private router: A7Router,
    private applicationService: ApplicationService,
    private configurationService: AppConfigurationService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    if (!isPlatformBrowser(platformId)) {
      return;
    }

    this.userService.userChange$.subscribe((user) => (this.user = user));

    this.recordAccessLog();
  }

  private recordAccessLog() {
    let accessLog: any;

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        accessLog = {
          meta: this.applicationService.getApplicationMeta(),
          browserProperties: {},
          request: urlToLocation(event.url),
          requestId: uuid.v4(),
          sessionId: this.sessionId,
          sessionSeq: ++this.sessionSeq,
          referer: document?.referrer,
          auth: {
            userId: this.user?.uuid ?? undefined,
          },
          response: {
            succeed: true,
            status: 200,
          },
          performance: {
            durationInMs: Date.now(),
          },
        };
      }

      if (event instanceof NavigationEnd || event instanceof NavigationError) {
        accessLog.request = urlToLocation(event.url);

        accessLog.performance.durationInMs =
          Date.now() - accessLog.performance.durationInMs;

        accessLog.response.succeed = event instanceof NavigationEnd;

        // accessLog.response.status = event instanceof NavigationEnd ? 200 : 422;
        // keep slient for this error
        accessLog.response.status = 200;

        if (event instanceof NavigationError) {
          accessLog.response.body =
            event.error?.toString() + '\n' + event.error?.stack?.toString();
        }

        _.defer(() => {
          this.enqueueRequestLog(accessLog);
        });
      }
    });
  }

  private logBuffer = [];
  private logEmitTimeoutHandler = null;

  private async doEmitRequestLogs() {
    const buffer = this.logBuffer;
    this.logBuffer = [];

    for (const accessLog of buffer) {
      // Have a better chance to acquire the user id.
      accessLog.auth.userId = this.user?.uuid ?? accessLog.auth.userId;

      const expTags = this.configurationService.getConfig('app.expTags');
      if (!_.isEmpty(expTags)) {
        accessLog.request.queryMap = _.extend({}, accessLog.request.queryMap, {
          expTags: expTags.join(','),
        });
      }
    }

    await this.clientResource.emitClientAccessLog(buffer);
  }

  enqueueRequestLog(log) {
    this.logBuffer.push(log);

    if (this.logBuffer.length >= 5) {
      this.doEmitRequestLogs();
    } else {
      if (this.logEmitTimeoutHandler != null) {
        clearTimeout(this.logEmitTimeoutHandler);
      }

      this.logEmitTimeoutHandler = setTimeout(() => {
        this.doEmitRequestLogs();
      }, 2000);
    }
  }
}
