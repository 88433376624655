<a7-dialog-base #dialogBase
                [panelClass]="['v2-w-50', 'smooth']"
                title="Share"
                [titleClass]="xs ? 'h3 color-green-gray-1' : 'h5 color-green-gray-1'">
  <div *a7DialogBaseContents
       [ngClass]="['full-width flex-column-between-start share-dialog-class',
                  xs ? 'v2-padding-2-h v2-padding-16px-t v2-padding-40px-b'
                  : 'v2-padding-40px-h v2-padding-32px-t v2-padding-40px-b']">
    <div [ngClass]="['flex-row-start-center',
                    xs ? 'v2-padding-28px-b v2-col-gap-1' : 'v2-padding-32px-b v2-col-gap-2']">
      <img [ngClass]="['v2-radius-8px', xs ? 'v2-h-4' : 'v2-h-5']"
           src="{{ carrier.imgUrl }}?d=458x288" />
      <div class="flex-column-between-center">
        <div class="flex-row-start-center full-width">
          <div [ngClass]="['color-green-grey-1', xs ? 'h4' : 'h3']">
            {{ carrier.nickname }}
          </div>
          <img *ngIf="carrier.isRegDOffering"
               class="v2-w-4 v2-margin-16px-l"
               src="/assets/ark7plus.svg"
               cTip="Accredited investors only" />
        </div>
        <div [ngClass]="['color-green-grey-2', xs ? 'footer-4' : 'footer-2']">
          {{ carrier.info.displayTypeShort }} · {{ carrier.info.displayUnit }} ·
          {{ carrier.info.floorSizeInSF | number }} sqft ·
          {{ carrier.info.builtInYear }} built
        </div>
      </div>
    </div>

    <share-buttons theme="circles-light"
                   [ngClass]="['full-width', xs ? 'mobile-share' : 'desktop-share']"
                   [include]="['email', 'copy', 'whatsapp', 'twitter', 'facebook']"
                   [showIcon]="true"
                   [showText]="true"
                   [url]="shareLink"
                   [description]="shareMessage">
    </share-buttons>
  </div>
</a7-dialog-base>

<span #shareButton
      class="flex-row-center-center v2-col-gap-4px pointer"
      (click)="onShareClick()">
  <img src="/assets/Share2.svg" />
  <span [ngClass]="headingSize"
        class="color-green-grey-2">{{ text }}</span>
</span>
