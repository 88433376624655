<div [ngClass]="[
    'overflow-hidden full-size relative',
    borderRadiusClass,
    mode === 'oneThree' ? 'flex-row-between-center v2-col-gap-8px' : 'block'
  ]">
  <!-- left side -->
  <div [ngClass]="[
      'relative  overflow-hidden',
      mode === 'oneThree' && !mobile
        ? 'v2-min-w-75pct full-height'
        : 'full-size'
    ]">
    <!-- pics -->
    <a7-page-index *ngIf="['small'].includes(mode)"
                   (mouseenter)="hover = true"
                   (mouseleave)="hover = false"
                   [buttonSize]="buttonSize"
                   [buttonStyle]="hover ? 'within_container' : 'none'"
                   [showBottomDots]="mode === 'small'"
                   dotYOffSet="-6px"
                   [(index)]="selected"
                   [count]="pics.length"
                   [ngClass]="[isUpComing && mode === 'small' ? '' : 'pointer']"
                   (click)="imgClick()"
                   (auxclick)="middleClick()">
      <div *ngIf="!slideOnChange"
           class="full-width v2-h-18 block">
        <img *ngFor="let p of pics; let i = index"
             [src]="p"
             class="slide full-size absolute v2-left-0 v2-top-0 smoother v2-obj-cover"
             [ngClass]="[i === selected ? 'show' : '']" />
      </div>
      <a7-switch-panel-junior *ngIf="slideOnChange"
                              [selected]="selected">
        <ng-container *ngFor="let p of pics">
          <img *a7Panel
               [src]="p"
               class="slide show smooth full-width v2-obj-cover v2-h-18" />
        </ng-container>
      </a7-switch-panel-junior>
    </a7-page-index>
    <img [src]="pics[0]"
         *ngIf="['oneThree', 'trending'].includes(mode)"
         [ngClass]="['full-size v2-obj-cover', canClick ? 'pointer' : '']"
         (click)="imgClick()"
         (auxclick)="middleClick()" />
    <!-- decorations -->
    <div [ngClass]="[
        'absolute v2-top-0 v2-left-0 full-size click-through flex-column-start-start',
     
      ]">
      <div [ngClass]="['full-width flex-scale relative']">
        <img *ngIf="showPlus"
             class="absolute v2-top-2 v2-right-2"
             [ngClass]="['v2-w-5']"
             src="/assets/v2-a7p-tile.svg"
             cTip="Accredited investors only" />
        <div *ngIf="
            ['small', 'trending'].includes(mode) &&
            (orderStarts || preOrderStarts) &&
            !hasGuaranteedOffer
          "
             class="absolute v2-bottom-0 v2-left-0 v2-w-9 v2-h-5 start-note bg-black-60 center-inner">
          <div class="flex-column-center-start">
            <div class="color-green-grey-4 footer-2">
              {{ preOrderStarts ? 'Pre-Order in' : 'Orders start in' }}
            </div>
            <h4 class="text-white-high-emphasis text-start">
              {{ preOrderStarts || orderStarts }}
            </h4>
          </div>
        </div>
        <div *ngIf="['small', 'trending'].includes(mode) && hasGuaranteedOffer"
             class="absolute v2-bottom-0 v2-left-0 v2-w-7 v2-h-5 start-note bg-black-60 center-inner">
          <div class="flex-column-center-start">
            <h4 class="color-red-5 text-start">
              {{ guaranteedRateOffer.guaranteedRate | percent: '.2-2' }}
            </h4>
            <div class="text-white-high-emphasis footer-2">Min. Return</div>
          </div>
        </div>
        <div class="absolute v2-left-0 v2-bottom-0 v2-margin-2 flex-row-between-center v2-col-gap-1 v2-h-3">
          <div *ngIf="videoId && !['small', 'trending'].includes(mode)"
               class="label-1 text-white-high-emphasis v2-padding-1-h full-height bg-black-60 v2-radius-8px pointer center-inner non-click-through"
               (click)="openVideo(); $event.stopPropagation()">
            <img src="/assets/v2-video.svg" />
          </div>
        </div>
        <div *ngIf="stripe"
             class="footer-1 v2-h-2 v2-w-20 flex-row-center-center absolute v2-right-0 v2-bottom-0 stripe text-white-high-emphasis"
             [ngClass]="stripeClass">
          <img *ngIf="stripeImg"
               [src]="stripeImg"
               class="v2-h-16px v2-margin-2px-r" />
          <span>{{ stripe }}</span>
        </div>
      </div>

      <ng-container *ngIf="hasGuaranteedOffer">
        <a7-dialog-base #dialogBase
                        matchQueryParam="minReturn"
                        [autoShowTimes]="['oneThree'].includes(mode) ? 1 : 0">
          <div *a7DialogBaseContents
               class="full-width flex-column-start-start v2-max-w-65 v2-padding-5-b v2-padding-1-h v2-margin-1-t">
            <div class="full-width flex-row-center-center v2-col-gap-5 flex-wrap">
              <img src="/assets/m_return.svg" />
              <div>
                <div class="h1">Special Offer</div>
                <div class="display-1">
                  <span class="text-primary">
                    {{ guaranteedRateOffer.guaranteedRate | percent: '.2-2' }}
                  </span>
                  Minimum Return
                </div>
              </div>
            </div>
            <div class="v2-margin-16px-t color-green-grey-2">
              Buy {{ carrier.nickname }} shares now to qualify for
              <strong>a minimum rate</strong> of cash distributions ({{
                guaranteedRateOffer.guaranteedRate | percent: '.2-2'
              }}
              and higher) for 12 months.*
            </div>
            <div class="flex-row-start-stretch full-width v2-col-gap-20px v2-margin-16px-t">
              <div class="v2-radius-4px bg-blue-grey-2 v2-w-4px"></div>
              <div class="color-green-grey-2">
                <h3 class="color-green-grey-1">Monthly Distribution Example</h3>
                <div>
                  If the actual return is higher than
                  {{ guaranteedRateOffer.guaranteedRate | percent: '.2-2' }},
                  the actual return rate will be distributed.
                </div>
                <div>
                  If the actual return is lower than
                  {{ guaranteedRateOffer.guaranteedRate | percent: '.2-2' }},
                  the minimum rate of
                  {{ guaranteedRateOffer.guaranteedRate | percent: '.2-2' }}
                  will be distributed.
                </div>
              </div>
            </div>
            <div class="full-width relative v2-margin-2-v-n">
              <img class="full-width"
                   src="/assets/min_return_chart_2.svg" />

              <span class="h5 text-primary absolute v2-right-0 v2-top-55pct">{{
                guaranteedRateOffer.guaranteedRate | percent: '.2-2'
              }}</span>
            </div>

            <div class="full-width flex-row-between-center z-index-high">
              <span *ngFor="let t of ['Jul', 'Sep', 'Nov', '2024']"
                    class="label-2 color-green-grey-3">
                {{ t }}</span>
            </div>

            <div class="full-width flex-row-center-center v2-margin-8px-t h5 v2-col-gap-40px flex-wrap">
              <div class="flex-row-center-center v2-col-gap-4px">
                <div class="v2-w-1 v2-h-1 v2-radius-2px bg-yellow-6"></div>
                <div>Actual Return</div>
              </div>
              <div class="flex-row-center-center v2-col-gap-4px">
                <div class="v2-w-1 v2-h-1 v2-radius-2px bg-500"></div>
                <div>Distributed Return</div>
              </div>
            </div>

            <div class="label-2 color-green-grey-3 full-width v2-margin-16px-t">
              *Chart is for illustration purposes only. Promotion is valid
              between
              {{ guaranteedRateOffer.promotionStartTime | date }} -
              {{ guaranteedRateOffer.promotionEndTime | date }} or when
              {{ carrier.nickname }} shares are sold out, whichever comes first.
              In order to receive the advertised annualized return of
              {{ guaranteedRateOffer.guaranteedRate | percent: '.2-2' }} and
              higher for
              {{ guaranteedRateOffer.guaranteedTerm.asMonths() }} months, the
              investment must be made by
              {{ guaranteedRateOffer.promotionEndTime | date }}.
            </div>
          </div>
        </a7-dialog-base>

        <div class="full-width text-white-high-emphasis v2-padding-8px-v v2-padding-2-h bg-red-6 flex-row-between-center pointer non-click-through z-index-high"
             *ngIf="mode === 'oneThree'"
             (click)="dialogBase.showDialog()">
          <img src="/assets/guarentee_time.svg" />
          <span class="flex-scale">
            Special Offer:
            <span class="h4">{{
              guaranteedRateOffer.guaranteedRate | percent: '.2-2'
            }}</span>
            Minimum Return</span>
          <img class="svg-white"
               src="/assets/Right.svg" />
        </div>
      </ng-container>
    </div>
  </div>
  <!-- right side -->
  <div *ngIf="mode === 'oneThree' && !mobile"
       class="flex-column-between-center v2-row-gap-8px full-height">
    <img *ngFor="let p of sidePics"
         [ngClass]="['full-size v2-obj-cover', canClick ? 'pointer' : '']"
         [src]="p"
         (click)="imgClick()"
         (auxclick)="middleClick()" />
  </div>
  <!-- sold out cover -->
  <div *ngIf="carrier.isSoldOut"
       class="bg-black-30 full-size absolute v2-bottom-0 v2-left-0 click-through"></div>
</div>
