import _ from 'underscore';
import { A7PageSizeMatcher } from '@ark7/layout';
import { Carrier, User } from '@ark7/core-business-models';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'a7-carrier-base',
  templateUrl: './carrier-base.component.html',
  styleUrls: ['./carrier-base.component.scss'],
})
export class CarrierBaseComponent {
  @Input() carrier: Carrier;
  @Input() user: User;
  @A7PageSizeMatcher('XS-') xs;
  @A7PageSizeMatcher('SM-') sm;
  @A7PageSizeMatcher('MD-') md;

  get streetAddressOrCity() {
    return _.isEmpty(this.carrier.address?.streetAddress)
      ? this.carrier.address?.city
      : this.carrier.address?.streetAddress;
  }

  private _showPlus = true;
  @Input()
  set showPlus(v: boolean) {
    this._showPlus = v;
  }

  get showPlus() {
    return this.carrier.isRegDOffering && this._showPlus;
  }
}
