import { HttpParams } from '@angular/common/http';
import { humanizeDuration } from '@ark7/common-models';
import moment from 'moment';
import { sha1 } from 'object-hash';

export function getCountDownString(millisecondsLeft: number): string {
  const toDesc = moment.duration(millisecondsLeft, 'milliseconds');

  if (toDesc.asDays() > 1) {
    return humanizeDuration(toDesc.asMilliseconds());
  }

  return `${atLeastTwoDigits(toDesc.hours())}:${atLeastTwoDigits(
    toDesc.minutes(),
  )}:${atLeastTwoDigits(toDesc.seconds())}`;
}

export function atLeastTwoDigits(num: number) {
  const n = '0' + num;
  return n.slice(n.length - 2, n.length);
}

export type CountdownUnit = 'day' | 'hour' | 'min' | 'sec';

export interface CountdownItem {
  number: number;
  unit: CountdownUnit;
}

export function getCountDownItem(targetDate: Date): CountdownItem[] {
  // Get the current date and time
  const currentDate = new Date();

  // Calculate the time remaining in milliseconds
  let timeRemaining = targetDate.getTime() - currentDate.getTime();

  if (timeRemaining < 0) {
    // If the event is in the past, return zeros
    return [
      { number: 0, unit: 'day' },
      { number: 0, unit: 'hour' },
      { number: 0, unit: 'min' },
      { number: 0, unit: 'sec' },
    ];
  }

  // Calculate days remaining
  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  timeRemaining -= days * (1000 * 60 * 60 * 24);

  // Calculate hours remaining
  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  timeRemaining -= hours * (1000 * 60 * 60);

  // Calculate minutes remaining
  const minutes = Math.floor(timeRemaining / (1000 * 60));
  timeRemaining -= minutes * (1000 * 60);

  // Calculate seconds remaining
  const seconds = Math.floor(timeRemaining / 1000);

  return [
    { number: days, unit: 'day' },
    { number: hours, unit: 'hour' },
    { number: minutes, unit: 'min' },
    { number: seconds, unit: 'sec' },
  ];
}

export function updateQueryParam(
  url: string,
  key: string,
  value: string,
): string {
  if (!value) {
    return url;
  }

  let params = new HttpParams({ fromString: url.split('?')[1] });

  params = params.set(key, value);

  return `${url.split('?')[0]}?${params.toString()}`;
}

export function addParamsSignature(url: string): string {
  let params = new HttpParams({ fromString: url.split('?')[1] });
  const r = params.get('r');
  const date = params.get('date');
  const carriedId = params.get('carrierId');
  const sig = sha1(`${r}|${date}|${carriedId}`).slice(-8);
  params = params.set('sig', sig);
  return `${url.split('?')[0]}?${params.toString()}`;
}
