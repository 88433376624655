import { Component, HostBinding, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Logger, UserService } from '@ark7/resources-common';
import { a7EmailValidator, A7Router, Debug, forms } from '@ark7/utils';
import debug from 'debug';

import { UserRedirectComponent } from '../user-redirect-component';

const d = debug('a7-ui-auth:ResetPasswordFormComponent');

enum ResetPasswordFormStage {
  DEFAULT = 0,
  TOKEN_EXPIRED = 1,
  CODE_MISMATCH = 2,
  INTERNAL_SERVER_ERROR = 3,
  RESET_SUCCESS = 4,
}

@Component({
  selector: 'a7-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent
  extends UserRedirectComponent
  implements OnInit {
  form: UntypedFormGroup;
  useToken: boolean = false;
  sending: boolean = false;
  stage: ResetPasswordFormStage = ResetPasswordFormStage.DEFAULT;

  @HostBinding('class.a7-ui-auth-reset-password-form')
  @HostBinding('class.a7-ui-auth-form')
  _hostBinding = true;

  constructor(
    fb: UntypedFormBuilder,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private router: A7Router,
    private logger: Logger,
  ) {
    super(userService, router);

    this.form = fb.group({
      email: ['', [Validators.required, Validators.email, a7EmailValidator()]],
      code: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[a-z]/),
          Validators.pattern(/[0-9]/),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          () => {
            if (
              this.form &&
              this.form.controls.confirmPassword.value &&
              this.form.controls.password.value !==
                this.form.controls.confirmPassword.value
            ) {
              return {
                match: {
                  valid: false,
                },
              };
            }
          },
        ],
      ],
    });

    this.form.controls.password.valueChanges.subscribe(() => {
      this.form.controls.confirmPassword.updateValueAndValidity({
        onlySelf: true,
      });
    });

    this.activeRoute.queryParams.subscribe((params) => {
      const { email, code } = params;
      if (email != null && code != null) {
        this.useToken = true;
        this.form.patchValue({
          email,
          code,
        });
      }
    });
  }

  ngOnInit() {}

  navigateToSignIn() {
    const email = this.form.controls.email.value;
    this.router.navigate(['/a/sign-in'], { queryParams: { email } });
  }

  @Debug({ d })
  async resetPassword() {
    if (!this.form.valid) {
      forms.validateAllFormFields(this.form);
      return;
    }

    try {
      this.sending = true;
      const email = this.form.controls.email.value.trim();
      const password = this.form.controls.password.value.trim();
      await this.userService.confirmPassword({
        email,
        code: this.form.controls.code.value,
        password,
      });

      this.stage = ResetPasswordFormStage.RESET_SUCCESS;
    } catch (e) {
      this.logger.error('Reset password failed', { error: e });

      this.stage =
        e && e.name === 'ExpiredCodeError'
          ? ResetPasswordFormStage.TOKEN_EXPIRED
          : e && e.name === 'CodeMismatchException'
          ? ResetPasswordFormStage.CODE_MISMATCH
          : ResetPasswordFormStage.INTERNAL_SERVER_ERROR;
    } finally {
      this.sending = false;
    }
  }
}
