import _ from 'underscore';
import debug from 'debug';
import io from 'socket.io-client';
import uuid from 'uuid/v4';
import { ApplicationMeta, ApplicationService, Debug } from '@ark7/utils';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UserService } from '@ark7/resources-common';
import { withInheritedProps as dotty } from 'object-path';
import { first } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

import { A7_SOCKET_HOST } from './declarations';

const d = debug('socket');

const referrer = typeof document !== 'undefined' ? document.referrer : '';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: typeof io.Socket;

  queueMsg: SocketMessage[] = [];

  sessionId = uuid();

  isConnected: boolean;

  constructor(
    @Inject(A7_SOCKET_HOST) private host: string,
    @Inject(PLATFORM_ID) private platformId: string,
    private http: HttpClient,
    private applicationService: ApplicationService,
    private userService: UserService,
  ) {
    if (isPlatformBrowser(platformId)) {
      setInterval(this.sendMessage.bind(this), 500);
    }
  }

  connect() {
    if (isPlatformBrowser(this.platformId)) {
      this.userService.userChange$.pipe(first()).subscribe(() => {
        this.http
          .get(this.host + '/api/v2/tokens/socket-token', {
            withCredentials: true,
          })
          .subscribe((token: Token) => {
            if (token) {
              this.socket = io(this.host, {
                query: { token: token.token, sessionId: this.sessionId },
              });

              this.isConnected = true;

              this.socket.on('disconnect', () => {
                d('socket is disconnected');
                this.isConnected = false;
              });

              this.socket.on('reconnect', () => {
                d('socket is reconnected');
                this.isConnected = true;
              });
            }
          });
      });
    }
  }

  @Debug({ d })
  send<T>(message: SocketMessage<T>) {
    if (message) {
      message.metadata = this.applicationService.getApplicationMeta();
      message.referrer = referrer;
      dotty.set(message, 'metadata.ts', Date.now());
      message.id = uuid();
      this.queueMsg.push(message);
      this.sendMessage();
    }
  }

  private async sendMessage() {
    while (!_.isEmpty(this.queueMsg) && this.isConnected) {
      const msg = _.first(this.queueMsg);
      dotty.set(msg, 'metadata.sentTs', Date.now());
      this.socket.send(JSON.stringify(msg));
      this.queueMsg.splice(0, 1);
      d('send message %O', msg);
    }
  }
}

export interface Token {
  token: string;
  expireAt: number;
}

export interface SocketMessageMetadata extends ApplicationMeta {
  ts?: number;
  sentTs?: number;
}

export interface SocketMessage<T = any> {
  topic: string;
  id?: string;
  metadata?: SocketMessageMetadata;
  message: T;
  referrer?: string;
}
