import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { A7LoadingModule } from '@ark7/loading';
import { A7ButtonComponent } from './a7-button/a7-button.component';
@NgModule({
  declarations: [A7ButtonComponent],
  imports: [CommonModule, MatRippleModule, A7LoadingModule],
  exports: [A7ButtonComponent],
})
export class A7ButtonModule {}
