import debug from 'debug';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Debug } from '@ark7/utils';
import { formatPercent } from '@angular/common';

import {
  LoadingProgressState,
  LoadingProgressor,
  LoadingState,
} from '../loading-progressor';

const d = debug('a7-loading:FileUploadPanelComponent');

@Component({
  selector: 'a7-file-upload-panel',
  templateUrl: './file-upload-panel.component.html',
  styleUrls: ['./file-upload-panel.component.scss'],
})
export class FileUploadPanelComponent implements OnChanges, OnInit {
  @Output() cancel = new EventEmitter();

  @Input() errorMessage: string;
  @Input() progressor: LoadingProgressor;

  label: string = 'Submitting...';
  progress: string = '84%';
  time: string = '2 seconds left';
  state: LoadingProgressState;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.errorMessage) {
      this.refresh();
    }
  }

  ngOnInit(): void {
    this.progressor.progress.subscribe((state) => {
      d('refresh state: %O', state);
      this.state = state;
      this.refresh();
    });
  }

  @Debug({ d })
  refresh() {
    this.progress = formatPercent(
      (this.state.progress ?? 0) / 100.0,
      'en-US',
      '.0-0',
    );

    this.label =
      this.state.state === LoadingState.IDLE
        ? 'Not Started'
        : this.state.state === LoadingState.FAILURE
        ? `Upload failed${this.errorMessage ? ': ' + this.errorMessage : ''}`
        : this.state.state === LoadingState.SUCCESS
        ? 'Upload succeed'
        : this.state.progress < 40
        ? 'Submitting...'
        : this.state.progress >= 60
        ? 'Downloading...'
        : 'Server is processing uploaded file...';

    this.time =
      this.state.state === LoadingState.FAILURE ||
      this.state.state === LoadingState.SUCCESS
        ? 'Completed'
        : this.state.state === LoadingState.IDLE
        ? 'N/A'
        : 'estimating';
  }

  @Debug({ d })
  onCancel() {
    this.cancel.emit();
  }
}
