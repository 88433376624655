import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';

import { A7ActionableTabs } from './a7-actionable-tabs/a7-actionable-tabs.component';
import {
  A7ActionableTabsItemActionsDirective,
  A7ActionableTabsItemTabDirective,
} from './a7-actionable-tabs/a7-actionable-tabs-item/a7-actionable-tabs-item.directive';
import { A7ActionableTabsItemComponent } from './a7-actionable-tabs/a7-actionable-tabs-item/a7-actionable-tabs-item.component';
import { A7PanelComponent } from './a7-panel/a7-panel.component';
import { A7SwitchPanelComponent } from './a7-switch-panel/a7-switch-panel.component';
import { A7SwitchPanelGroupDirective } from './a7-switch-panel/a7-switch-panel-group.directive';

@NgModule({
  declarations: [
    A7PanelComponent,
    A7SwitchPanelComponent,
    A7SwitchPanelGroupDirective,
    A7ActionableTabs,
    A7ActionableTabsItemActionsDirective,
    A7ActionableTabsItemComponent,
    A7ActionableTabsItemTabDirective,
  ],
  imports: [CommonModule, MatTabsModule, MatDialogModule],
  exports: [
    A7PanelComponent,
    A7SwitchPanelComponent,
    A7SwitchPanelGroupDirective,
    A7ActionableTabs,
    A7ActionableTabsItemActionsDirective,
    A7ActionableTabsItemComponent,
    A7ActionableTabsItemTabDirective,
  ],
})
export class A7PanelModule {}
