import { Injectable } from '@angular/core';
import { LoadingProgressor } from './loading-progressor';

@Injectable({
  providedIn: 'root',
})
export class LoadingProgressService {
  progressor: LoadingProgressor;

  constructor() {
    this.progressor = new LoadingProgressor({
      estimateLoadingTime: 200,
    });
  }
}
