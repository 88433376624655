import { Pipe, PipeTransform } from '@angular/core';
import { RenterRef } from '@ark7/common-models';

@Pipe({
  name: 'asRenterRef',
})
export class AsRenterRefPipe implements PipeTransform {
  transform(value: any): RenterRef {
    return value;
  }
}
