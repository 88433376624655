import { Injectable } from '@angular/core';
import {
  A7ResourceCRUDObservable,
  A7ResourceParams,
  ResourceHandler,
} from '@ark7/resource2';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/leads',
  withCredentials: true,
})
export class LeadResource extends A7ResourceCRUDObservable<
  LeadCreation,
  LeadQuery
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }
}

export interface LeadQuery {}

export interface LeadCreation {
  email: string;
  referrer: string;
  tracking: string;
}
