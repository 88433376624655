import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { toPartialMatchRegex } from './extend-regexp';

@Directive({
  selector: '[a7Restriction]',
})
export class RestrictiveInputDirective implements OnInit {
  @Input() a7Restriction: string;

  @Output() inputEnd = new EventEmitter<string>();

  partialReg: RegExp;
  reg: RegExp;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.reg = new RegExp('^' + this.a7Restriction + '$');
    this.partialReg = toPartialMatchRegex(this.reg);
  }

  @HostListener('keypress', ['$event'])
  onKeypressHandler(event: KeyboardEvent): void {
    if (event.key.length !== 1) {
      return;
    }

    const value: string = this.el.nativeElement.value;

    const start: number = (event.target as any).selectionStart;
    const end: number = (event.target as any).selectionEnd;

    const target: string =
      value.substring(0, start) + event.key + value.substring(end);

    if (!this.partialReg.test(target)) {
      event.preventDefault();
      return;
    }

    if (this.reg.test(target)) {
      this.inputEnd.emit(target);
    }
  }
}
