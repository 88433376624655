import _ from 'underscore';

import * as forms from './forms';
import * as time from './time';
import * as trigger from './trigger';

export { forms, time, trigger };

export function mapKey(obj: any, fn: (any: string) => string) {
  const result = {};
  _.each(obj, (v: any, k: any) => {
    result[fn(k)] = v;
  });
  return result;
}

export function addPrefixToObjectKey(obj: any, prefix: string) {
  return mapKey(obj, (key) => prefix + key);
}

export function transformDisplay(value: string) {
  switch (value) {
    case 'Single Family':
      return 'Single-Family';
    case 'Multi Family':
      return 'Multi-Family';
    default:
      return value
        .replace('Ssn', 'SSN')
        .replace('Itin', 'ITIN')
        .replace('Llc', 'LLC')
        .replace('Ira', 'IRA')
        .replace('Id ', 'ID ');
  }
}

export function transformArea(num: number): string {
  return num > 5000 ? `${(num / 43560).toPrecision(2)} Acres` : `${num} Sf`;
}
