import { Component } from '@angular/core';
import { Identity } from '@ark7/identity';
import { SubscribeComponent } from '@ark7/utils';

import { UserService } from '../services/user.service';

/**
 * Extend to subscribe a user object, this class will prevent a memory leak
 * caused by uncompleted subscription.
 */
@Component({
  selector: 'a7-user-subscribe-component',
  template: '',
})
export class UserSubscribeComponent<
  T extends Identity
> extends SubscribeComponent {
  protected _user: T;

  constructor(protected userService: UserService) {
    super();
    this.safeSubscribe(userService.userChange$, (user) => (this.user = user));
  }

  get user() {
    return this._user;
  }

  set user(user: T) {
    this._user = user;
    this.onUserUpdate(user);
  }

  /**
   * Override if want to observe the change.
   */
  onUserUpdate(_user: T) {}

  async signOut() {
    await this.userService.signOut();
  }
}
