import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'a7-auth-form-tab',
  templateUrl: './auth-form-tab.component.html',
  styleUrls: ['./auth-form-tab.component.scss'],
})
export class AuthFormTabComponent implements OnInit {
  @Input() formTitle: string;

  constructor() {}

  ngOnInit() {}
}
