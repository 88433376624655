import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgModule } from '@angular/core';

import { MapInfoDirective } from './map-info.directive';
import { MapMarkerComponent } from './map-marker/map-marker.component';

@NgModule({
  declarations: [MapInfoDirective, MapMarkerComponent],
  imports: [CommonModule, GoogleMapsModule, FontAwesomeModule],
  exports: [MapInfoDirective, MapMarkerComponent],
})
export class A7MapsModule {}
