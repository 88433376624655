import { Identity } from '@ark7/identity';
import { A7Model, Mixin, StrictModel } from '@ark7/model';
import { InstanceMethod } from '@ark7/resource2';
import { Auth } from '@aws-amplify/auth';

import { BasicUserResource } from '../resources/user-resource';

@A7Model({})
export class LocalIdentity extends StrictModel {
  @InstanceMethod()
  async verifyEmail<T extends Identity>(code: string) {
    return (this.$resource as BasicUserResource<T>)._verifyEmail({ code });
  }

  @InstanceMethod()
  async sendPhoneVerification<T extends Identity>() {
    return (this.$resource as BasicUserResource<T>)._sendPhoneVerification({});
  }

  @InstanceMethod()
  async requestDeletion<T extends Identity>() {
    return (this.$resource as BasicUserResource<T>)._requestDeletion({});
  }

  async confirmDeletion<T extends Identity>(code: string, reason: string) {
    return (this.$resource as BasicUserResource<T>)._confirmDeletion({
      code,
      reason,
    });
  }

  @InstanceMethod()
  async verifyPhone<T extends Identity>(code: string) {
    return (this.$resource as BasicUserResource<T>)._verifyPhone({ code });
  }

  @InstanceMethod()
  async verifyDwolla<T extends Identity>() {
    return (this.$resource as BasicUserResource<T>)._verifyDwolla({});
  }

  @InstanceMethod()
  async sendEmailVerification<T extends Identity>() {
    return (this.$resource as BasicUserResource<T>)._sendEmailVerification({});
  }

  @InstanceMethod()
  async changeEmail<T extends Identity>(options: { newEmail: string }) {
    return (this.$resource as BasicUserResource<T>)._changeEmail(options);
  }

  async changePassword<T extends Identity>(options: {
    oldPassword: string;
    newPassword: string;
  }) {
    await Auth.signOut();
    const user = await Auth.signIn(this.email.toString(), options.oldPassword);
    await Auth.changePassword(user, options.oldPassword, options.newPassword);
    try {
      await (this.$resource as BasicUserResource<T>).signOutElsewhere();
    } catch (_ignore) {}
  }
}

export interface LocalIdentity extends Identity {}

Mixin(LocalIdentity)(Identity);

declare module '@ark7/identity/identity/identity' {
  interface IIdentity {
    verifyEmail: (code: string) => Promise<any>;
    verifyPhone: (code: string) => Promise<any>;
    verifyDwolla: () => Promise<any>;
    sendEmailVerification: () => Promise<any>;
    sendPhoneVerification: () => Promise<any>;
    requestDeletion: () => Promise<any>;
    confirmDeletion: (code: string, reason: string) => Promise<any>;
    changeEmail: (options: { newEmail: string }) => Promise<any>;
    changePassword: (options: {
      oldPassword: string;
      newPassword: string;
    }) => Promise<void>;
  }
}
