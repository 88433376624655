import debug from 'debug';
import { A7Router, Debug } from '@ark7/utils';
import { BasicUserResource, UserService } from '@ark7/resources-common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { UserBehaviorService } from '@ark7/socket';

const d = debug('a7-ui-auth:SignOutFormComponent');

@Component({
  selector: 'a7-sign-out-form',
  templateUrl: './sign-out-form.component.html',
  styleUrls: ['./sign-out-form.component.scss'],
})
export class SignOutFormComponent implements OnInit {
  constructor(
    private userService: UserService,
    private router: A7Router,
    private userResource: BasicUserResource,
    private userBehavior: UserBehaviorService,
  ) {}

  @HostBinding('class.a7-ui-auth-sign-out-form')
  @HostBinding('class.a7-ui-auth-form')
  _hostBinding = true;

  @Debug({ d })
  async ngOnInit() {
    if (!this.userService.isRenter) {
      try {
        this.userResource.emitSignOutEvent();
      } catch (e) {
        // Best effort to emit user sign out event.
      }
      try {
        this.userBehavior.signOut();
      } catch (e) {
        // Best effort to emit user sign out event.
      }
    }

    await this.userService.signOut();

    this.router.navigate(['/']);
  }
}
