import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MovingNumberComponent } from './moving-number/moving-number.component';

@NgModule({
  declarations: [MovingNumberComponent],
  imports: [CommonModule],
  exports: [MovingNumberComponent],
})
export class A7MovingNumberModule {}
