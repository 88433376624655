import { Component, Input } from '@angular/core';
import { A7PageSizeMatcher } from '@ark7/layout';

@Component({
  selector: 'v2-property-section',
  templateUrl: './property-section.component.html',
  styleUrls: ['./property-section.component.scss'],
})
export class PropertySectionComponent {
  @Input() sectionName = '';

  @Input() useSectionLayout = true;

  @Input() topMargin = 'v2-margin-7-t';

  @A7PageSizeMatcher('XS') xs;
}
