import * as CryptoJS from 'crypto-js';
import _ from 'underscore';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import debug from 'debug';
import { CognitoUser } from '@aws-amplify/auth/lib';
import { Debug, observableToPromise } from '@ark7/utils';
import { Identity } from '@ark7/identity';
import { Inject, Injectable } from '@angular/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { ReplaySubject } from 'rxjs';
import { ResourceGlobalConfig } from '@ngx-resource/core';
import { TwoFAVerificationMethod } from '@ark7/core-business-models';

import {
  A7ResourcesCommonModuleConfig,
  A7_RESOURCES_CONFIG,
  A7_RESOURCES_USER,
} from '../declarations';
import { API_CLIENT_INFO, ApiClientInfo } from '../auth/request.interceptor';
import { AuthResource } from '../resources/auth-resource';
import { BasicUserResource } from '../resources/user-resource';

const d = debug('a7-resources-common:UserService');

export let userService: UserService;

export const USER_ID_KEY = 'userID';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // We can not use tracking service due to circular deps.
  @LocalStorage()
  referrer: string;

  @LocalStorage()
  trackingCode: string;

  @LocalStorage()
  leadCode: string;

  @LocalStorage(USER_ID_KEY)
  userID: string;

  // Changes for event subscription
  userChange$ = new ReplaySubject<any>(1);
  // Current value, for get & set.
  private _currentUser: any;

  signingOut: boolean;

  get currentUser(): any {
    return this._currentUser;
  }
  set currentUser(user: any) {
    this._currentUser = user;
    this.userChange$.next(user);
  }

  // User signed out or timed out, after an active session.
  private _userSignedOut = false;
  get userSignedOut(): boolean {
    return this._userSignedOut;
  }

  constructor(
    @Inject(A7_RESOURCES_CONFIG) config: A7ResourcesCommonModuleConfig,
    @Inject(A7_RESOURCES_USER)
    private userResource: BasicUserResource<Identity>,
    private authResource: AuthResource,
    private localStorage: LocalStorageService,
    @Inject(API_CLIENT_INFO) private c: ApiClientInfo,
  ) {
    Amplify.configure(config.authOptions);

    if (userService == null) {
      userService = this;
    }

    this.localStorage.observe(USER_ID_KEY).subscribe((userID: string) => {
      if (userID == null && !this.signingOut && this.currentUser != null) {
        location.reload();
      }
    });
  }

  @Debug({ d })
  async signUp(req: SignUpRequest) {
    const email = req.email.toLowerCase();
    const attributes = {
      email,
    };

    if (req.name != null) {
      attributes['given_name'] = req.name.first;
      attributes['family_name'] = req.name.last;
    }

    if (req.referrer != null) {
      attributes['custom:referrer'] = req.referrer;
    }

    if (req.mainSiteTOS) {
      attributes['custom:main_site_tos'] = 'true';
    }

    if (req.rentersTOS) {
      attributes['custom:renters_tos'] = 'true';
    }

    if (req.trackingCode) {
      attributes['custom:tracking_code'] = req.trackingCode;
    }
    if (req.leadCode) {
      attributes['custom:lead_code'] = req.leadCode;
    }

    const result = await Auth.signUp({
      username: email,
      password: req.password,
      attributes,
    });

    await this.authResource.confirmSignUp({ email });

    return result;
  }

  get isRenter(): boolean {
    return this._currentUser?.hasOwnProperty('activeContracts') ?? false;
  }

  async signIn(req: SignInRequest) {
    await this.authResource.login({
      username: req.email,
      password: this._s(req.email, req.password),
    });
    await this.fetch();
    this._userSignedOut = false;
  }

  async cognitoSignIn(req: SignInRequest) {
    return await Auth.signIn(req.email, req.password);
  }

  signInGoogle() {
    location.href =
      ResourceGlobalConfig.pathPrefix +
      '/api/v1/auth/google' +
      this.trackingParams();
  }

  signInFacebook() {
    location.href =
      ResourceGlobalConfig.pathPrefix +
      '/api/v1/auth/facebook' +
      this.trackingParams();
  }

  signInApple() {
    location.href =
      ResourceGlobalConfig.pathPrefix +
      '/api/v1/auth/apple' +
      this.trackingParams();
  }

  trackingParams() {
    const params = [];
    if (this.referrer) {
      params.push(`r=${this.referrer}`);
    }
    if (this.trackingCode) {
      params.push(`tc=${this.trackingCode}`);
    }
    if (this.leadCode) {
      params.push(`l=${this.leadCode}`);
    }
    return params.length > 0 ? '?' + params.join('&') : '';
  }

  @Debug({ d })
  async forgotPassword(req: ForgotPasswordRequest) {
    await this.authResource.initiateForgotPassword({ email: req.email });
  }

  @Debug({ d })
  async confirmPassword(req: IConfirmPassword) {
    await this.signOut();
    await this.authResource.forgotPasswordReset({
      email: req.email,
      code: req.code,
      password: req.password,
    });
    await this.fetch();
  }

  @Debug({ d })
  async verifyUserAttribute(user: CognitoUser, attribute: string) {
    await Auth.verifyUserAttribute(user, attribute);
  }

  @Debug({ d })
  async signOut() {
    try {
      this.signingOut = true;
      await this.userResource.signOut();
      await Auth.signOut();
    } catch (e) {
      console.log('ignored signOut error: ', e);
    }
    this._userSignedOut = true;
    this.currentUser = null;
    this.userID = null;
    this.signingOut = false;
  }

  resetUser() {
    this.currentUser = null;
    this.userID = null;
  }

  @Debug({ d })
  async acceptTos(apps: string[]) {
    const user = await Auth.currentUserPoolUser({
      bypassCache: true,
    });

    const attributes = _.chain(apps)
      .map((app) => [`custom:${app}_tos`, 'true'])
      .object()
      .value();

    await Auth.updateUserAttributes(user, attributes);
    await Auth.currentUserPoolUser({ bypassCache: true });
    await this.fetch();
  }

  @Debug({ d })
  async unacceptTos(apps: string[]) {
    const user = await Auth.currentUserPoolUser({
      bypassCache: true,
    });

    const attributes = _.chain(apps)
      .map((app) => [`custom:${app}_tos`, 'false'])
      .object()
      .value();

    await Auth.updateUserAttributes(user, attributes);
    await Auth.currentUserPoolUser({ bypassCache: true });
    await this.fetch();
  }

  @Debug({ d })
  async fetch(options: UserFetchOptions = {}) {
    const userObserver = this.userResource.get(options);

    userObserver.subscribe((user) => {
      d('subscribed user: %O', user);
      this.currentUser = user;

      this.userID = user?._id.toString() ?? null;
    });

    return observableToPromise(userObserver);
  }

  @Debug({ d })
  async hasCurrentUser(): Promise<boolean> {
    try {
      const user = await Auth.currentUserPoolUser();
      return user != null;
    } catch (e) {
      return false;
    }
  }

  async sendTwoFACode(method: TwoFAVerificationMethod) {
    await this.authResource.sendTwoFACode({ method });
  }

  async verifyTwoFACode(code: string, method: TwoFAVerificationMethod) {
    await this.authResource.verifyTwoFACode({ code, method });
  }

  _s(a: string, b: string) {
    const p = `${a}${b}`,
      c = this.c.clientSecret,
      k = `${c.substring(0, 6)}${c}${c.substring(c.length - 6)}`;

    try {
      const iv: CryptoJS.lib.WordArray = CryptoJS.lib.WordArray.random(16);

      const encrypted: CryptoJS.WordArray = CryptoJS.AES.encrypt(
        p,
        CryptoJS.enc.Utf8.parse(k),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        },
      );

      return iv.toString(CryptoJS.enc.Hex) + encrypted.toString();
    } catch (e) {
      console.error(e);
    }
  }
}

export interface UserFetchOptions {
  advanced?: boolean;
}

export enum SignUpErrorCode {
  USERNAME_EXISTS = 'UsernameExistsException',
  INVALID_PASSWORD = 'InvalidPasswordException',
}

export enum SignInErrorCode {
  NOT_AUTHORIZED = 'NotAuthorizedException',
  USER_NOT_FOUND = 'UserNotFoundException',
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException',
}

export interface SignUpRequest {
  email: string;
  password: string;
  name?: {
    first: string;
    last: string;
  };
  referrer?: string;
  mainSiteTOS?: boolean;
  rentersTOS?: boolean;
  trackingCode?: string;
  leadCode?: string;
}

export interface SignInRequest {
  email: string;
  password: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface ForgotPasswordResult {
  deliveryMedium: 'SMS' | 'EMAIL';
  destination: string;
}

export interface IConfirmPassword {
  email: string;
  code: string;
  password: string;
}

export interface ConfirmSignUpRequest {
  email: string;
  code: string;
}

export function InjectUser(): PropertyDecorator {
  return (target: any, propertyKey: string | symbol) => {
    Object.defineProperty(target, propertyKey, {
      get: () => userService && userService.currentUser,
    });
  };
}
