import { A7PageSizeMatcher } from '@ark7/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dalmore-disclosure',
  templateUrl: './dalmore-disclosure.component.html',
  styleUrls: ['./dalmore-disclosure.component.scss'],
})
export class DalmoreDisclosureComponent {
  @A7PageSizeMatcher('SM-') sm;
}
