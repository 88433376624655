<div class="site-container">
  <div class="margin-h-auto v2-margin-7-t">
    <img src="/assets/referral-invite.svg"
         class="unselectable display-block margin-auto v2-w-20">
  </div>

  <div class="info-container">
    <div class="v2-max-w-40 margin-h-auto text-center unselectable h1 v2-margin-1-b color-green-gray-1"> {{
      greetingTitle }}</div>

    <div class="v2-max-w-40 margin-auto body-2 color-green-grey-3"
         innerHTML="{{referralInvitationDesc}}"></div>

    <div class="sign-up-form"
         #signUpForm>
      <a7-sign-up-form [social]="false"
                       [partner]="false"
                       [signInLink]="false"
                       [version]="2"
                       [customizedRegistrationResult]="true"
                       redirectToWhenSucceed="/j-succeed"
                       cta="Create Account"
                       formTitle="Register">
        <div class="tos-label-content text-center body-2"
             a7-sign-up-form-tos-post>
          By proceeding, you agree with our<br />
          <a href="https://ark7.com/terms-and-conditions"
             target="_blank"
             color="primary">Terms & Conditions</a>.
        </div>
      </a7-sign-up-form>
    </div>

    <mat-divider class="divider"></mat-divider>
    <div class="already-signed-up">
      <div class="subheading-2">Already signed up?</div>

      <a color="primary"
         class="round-button"
         [routerLink]="['/download-link']"
         [queryParams]="trackingService.downloadQueryParams">Get the app</a>
    </div>
  </div>

</div>

<a7-share-footer> </a7-share-footer>