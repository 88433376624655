import * as _ from 'underscore';
import { Model, isSameModel } from '@ark7/model';
import { ResourceRequestMethod } from '@ngx-resource/core';
import { map } from 'rxjs/operators';

import { A7Resource } from './resource';
import { A7ResourceAction } from './action';
import { IPaginationData, PaginationQuery } from './pagination';
import {
  IResourceMethodObservable,
  IResourceMethodObservableStrict,
} from './definitions';

export abstract class A7ResourceCRUDObservable<
  TFull = any,
  TQuery = {},
  TModifier = {},
  TShort = TFull
> extends A7Resource {
  @A7ResourceAction({
    asObservable: true,
    isArray: true,
    subSignalTopic: 'query',
  })
  query: IResourceMethodObservable<TQuery & TModifier, TShort[]>;

  @A7ResourceAction({
    asObservable: true,
    pagination: true,
    subSignalTopic: 'queryWithPagination',
  })
  queryWithPagination: IResourceMethodObservable<
    TQuery & TModifier & PaginationQuery,
    IPaginationData<TShort>
  >;

  @A7ResourceAction({
    path: '/{!_id}',
    asObservable: true,
    subSignalTopic: 'get',
  })
  get: IResourceMethodObservable<{ _id: string } & TModifier, TFull>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    asObservable: true,
    subSignalTopic: 'create',
  })
  create: IResourceMethodObservableStrict<
    Partial<TFull>,
    TModifier,
    void,
    TFull
  >;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!_id}',
    asObservable: true,
    subSignalTopic: 'update',
  })
  update: IResourceMethodObservableStrict<
    TFull,
    TModifier,
    { _id: string },
    TFull
  >;

  @A7ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!_id}',
    asObservable: true,
    subSignalTopic: 'remove',
  })
  remove: IResourceMethodObservable<{ _id: string }, void | TFull>;

  searchFn(options: A7ResourceCRUDObservableSearchOptions<TQuery>) {
    return (value: string) => {
      return this.query(
        _.extend({}, options.query, {
          [options.field]: value,
        }),
      ).pipe(map((data) => excludeModels(data as any, options.excludes)));
    };
  }

  searchWithPaginationFn(
    options: A7ResourceCRUDObservableSearchOptions<
      TQuery & PaginationQuery
    > = {},
  ) {
    return (value: string) => {
      return this.queryWithPagination(
        _.extend({}, options.query, {
          [options.field ?? '^searchKey']: value,
        }),
      ).pipe(map((data) => excludeModels(data.data as any, options.excludes)));
    };
  }
}

function excludeModels(data: Model[], excludes: Model[]) {
  if (_.isEmpty(excludes)) {
    return data;
  }

  return _.filter(data, (d) => !_.any(excludes, (e) => isSameModel(e, d)));
}

export interface A7ResourceCRUDObservableSearchOptions<T> {
  field?: string;
  query?: T;
  excludes?: Model[];
}
