<app-page>

  <div class="site-container ark7-resource v2-min-h-50">
    <h1>Contact Us</h1>

    <mat-divider></mat-divider>

    <div [class.flex-row-between-start]="!mobile"
         [class.flex-column-start-start]="mobile">
      <div>
        <h2>Address</h2>

        <div>Ark7 Inc.</div>
        <div>1 Ferry Building, Ste 201</div>
        <div>San Francisco, CA 94111</div>
        <div>United States</div>
      </div>
      <!-- <h2>Phone</h2>

        <p>
          <a href="tel:+14152750701">+1 415-275-0701</a>
        </p>
        <p>Mon-Sun 8am-8pm PDT</p> -->
      <div>
        <h2>Customer Support</h2>
        <p>
          <a href="mailto:support@ark7.com">support&#64;ark7.com</a>
        </p>
        <p>
          <a href="https://calendly.com/ark7-yu/1-on-1-chat-with-ark7"
             target="_blank">1 on 1 Chat with Ark7</a>
        </p>
        <p>
          <a href="https://ark7.com/help"
             target="_blank">Ark7 Help Center</a>
        </p>
      </div>
    </div>
  </div>
</app-page>
