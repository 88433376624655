<div class="site-layout"
     [class.handset]="handset">
  <v2-header [mode]="headerMode"
             [routerLinkStr]="headerLogoLink"></v2-header>
  <div #content>
    <ng-content></ng-content>
  </div>
  <v2-page-footer [showNavi]="footerShowNavi"
                  [topPadding]="xs ? 'v2-padding-6-t' : 'v2-padding-9-t'"></v2-page-footer>
</div>
