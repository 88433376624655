import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';

import { FileUploadPanelComponent } from './file-upload-panel/file-upload-panel.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { LoadingContentComponent } from './loading-content/loading-content.component';
import { LoadingIndicatorDirective } from './directives/loading-indicator.directive';
import { LoadingLogoComponent } from './loading-logo/loading-logo.component';
import { LoadingProgressService } from './loading-progress.service';
import { ProgressSpinnerIndicatorComponent } from './progress-spinner-indicator/progress-spinner-indicator.component';
import { SpinLoadingIndicatorComponent } from './spin-loading-indicator/spin-loading-indicator.component';

@NgModule({
  declarations: [
    FileUploadPanelComponent,
    LoadingButtonComponent,
    LoadingContentComponent,
    LoadingIndicatorDirective,
    LoadingLogoComponent,
    SpinLoadingIndicatorComponent,
    ProgressSpinnerIndicatorComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
  exports: [
    FileUploadPanelComponent,
    LoadingButtonComponent,
    LoadingContentComponent,
    LoadingLogoComponent,
    LoadingIndicatorDirective,
    SpinLoadingIndicatorComponent,
    ProgressSpinnerIndicatorComponent,
  ],
  providers: [LoadingProgressService],
})
export class A7LoadingModule {}
