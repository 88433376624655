import _ from 'underscore';
import debug from 'debug';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';

import { DebugDirective } from './debug.directive';
import { DebugPanelComponent } from './debug-panel.component';

const d = debug('a7-debug');

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  static instance: DebugService;

  private componentRef: ComponentRef<DebugPanelComponent>;
  private debugStack: DebugDirective[] = [];
  private snapStack: DebugDirective[] = [];
  private lastKeyTime: number = 0;

  snapMode: boolean = false;
  debugger: debug.Debugger;

  constructor(
    private ref: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {
    DebugService.instance = this;

    document.addEventListener('keydown', (event) => this.onKeyPress(event));

    this.debugger = d;

    if (this.debugger.enabled) {
      this.openDebugPanel();
    }
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.metaKey && event.shiftKey && event.code === 'KeyS') {
      if (Date.now() - this.lastKeyTime < 600) {
        // Double press.
        this.toggleDebugPanel();
      } else {
        // Single press.
        this.snapMode = !this.snapMode;

        if (this.snapMode) {
          this.snapStack = _.clone(this.debugStack);
        }
      }

      this.lastKeyTime = Date.now();
    }
  }

  get stack(): DebugDirective[] {
    return this.snapMode ? this.snapStack : this.debugStack;
  }

  pushStack(item: DebugDirective) {
    if (!this.debugStack.includes(item)) {
      this.debugStack.push(item);
    }
  }

  popStack(item: DebugDirective) {
    const index = this.debugStack.indexOf(item);

    if (index >= 0) {
      this.debugStack.splice(index, 1);
    }
  }

  openDebugPanel() {
    if (this.componentRef != null) {
      return;
    }

    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(DebugPanelComponent)
      .create(this.injector);

    (this.componentRef.instance as any).debugService = this;

    this.ref.attachView(this.componentRef.hostView);

    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);

    this.snapMode = false;
    this.debugger.enabled = true;
  }

  closeDebugPanel() {
    if (this.componentRef) {
      this.ref.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }

    this.snapMode = false;
  }

  toggleDebugPanel() {
    if (this.componentRef) {
      this.closeDebugPanel();
    } else {
      this.openDebugPanel();
    }
  }
}
