<div class="webinar-card v2-radius-8px v2-padding-16px-v v2-padding-20px-h bg-primary v2-margin-2-t"
     [ngClass]="size == 'large' ? 'flex-column-center-center large' : ''">
  <div class="color-primary-100"
       [ngClass]="size == 'large' ? 'label-2' : 'footer-2'">Upcoming Webinar</div>
  <div class="white"
       [ngClass]="size == 'large' ? 'h2 text-center' : 'h3'">How to invest in rental homes with Ark7?
  </div>
  <div class="color-primary-100 margin-4px-t"
       [ngClass]="size == 'large' ? 'h5' : 'label-1'">Fridays at 12:00PM PDT
    bi-weekly</div>
  <a class="h5 v2-w-12 v2-padding-8px-v bg-white display-block text-center v2-radius-8px v2-margin-12px-t"
     color="primary"
     href="https://ark7.com/webinar/81882467340"
     target="_blank">
    Secure My Seat
  </a>
</div>
