import { CarrierStats } from '@ark7/common-models';
import { Pipe, PipeTransform } from '@angular/core';
import { Ref } from '@ark7/model';

@Pipe({
  name: 'asCarrierStats',
  pure: true,
})
export class AsCarrierStatsPipe implements PipeTransform {
  transform(value: Ref<CarrierStats>): CarrierStats {
    return value as CarrierStats;
  }
}
