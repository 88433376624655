import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'v2-auth-form-tab',
  templateUrl: './auth-form-tab.component.html',
  styleUrls: ['./auth-form-tab.component.scss'],
})
export class AuthFormTabComponent2 implements OnInit {
  @Input() formTitle: string;

  constructor() {}

  ngOnInit() {}
}
