import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'v2-home-feature-poster',
  templateUrl: './home-feature-poster.component.html',
  styleUrls: ['./home-feature-poster.component.scss'],
})
export class HomeFeaturePosterComponent
  extends V2BaseComponent
  implements OnInit
{
  @Input()
  headline: string;
  @Input()
  headlineClass = 'display-1';

  @Input()
  showStarSign = false;

  @Input()
  paddingLeft = '';

  @Input()
  sectionClass = '';

  @Input()
  imageClass = '';

  @Input()
  points: string[];
  @Input()
  imgSrc: string;

  @Input()
  imgAlt: string = '';

  @Input()
  colGap = 'v2-col-gap-7';

  ngOnInit(): void {}

  loaded = false;

  constructor(layoutService: A7LayoutService) {
    super(layoutService);
  }
}
