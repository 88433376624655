import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'v2-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  constructor() {}
  @Input()
  logoSrc = '/assets/v2-logo.svg';

  @Input()
  height = '32px';

  @Input()
  filter = '';

  @Input()
  routerLinkStr = '/';

  ngOnInit(): void {}
}
