import _ from 'underscore';
import { A7LayoutService, LayoutSubscribeComponent } from '@ark7/layout';
import { ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  MarketingConversionResource,
  TrackingService,
} from '@ark7/resources-common';
import { MarketingConversionType } from '@ark7/core-business-models';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-download-link',
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.scss'],
})
export class DownloadLinkComponent
  extends LayoutSubscribeComponent
  implements OnInit {
  constructor(
    activeRoute: ActivatedRoute,
    layout: A7LayoutService,
    private marketingConversionResource: MarketingConversionResource,
    private trackingService: TrackingService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    super(layout);
  }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId) && !this.isWechat) {
      if (!_.isEmpty(this.trackingService.trackingCode)) {
        try {
          await this.marketingConversionResource.signal({
            code: this.trackingService.trackingCode,
            leadCode: this.trackingService.leadCode,
            conversionType: MarketingConversionType.CLICK,
          });
        } catch (_) {}
      }
      location.href = this.downloadUrl;
    }
  }

  get isWechat() {
    return (
      isPlatformBrowser(this.platformId) &&
      navigator.userAgent.indexOf('MicroMessenger') !== -1
    );
  }

  get isAppleDevice() {
    return (
      isPlatformBrowser(this.platformId) &&
      !!navigator.platform &&
      /iPad|iPhone|iPod|Mac/.test(navigator.platform)
    );
  }

  get downloadUrl() {
    return this.isAppleDevice
      ? 'https://ark7-corp-allosaurus.us-west1.ark7.com/api/v2/conversions/signal?code=HS7PD'
      : 'https://ark7-corp-allosaurus.us-west1.ark7.com/api/v2/conversions/signal?code=L3BRY';
  }
}
