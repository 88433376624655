<div class="flex-row-center-center v2-col-gap-1 v2-margin-40px-t v2-themed-inputs"
     [formGroup]="form"
     (done)="onVerifycodeEntered($event)"
     a7SequentialInputGroup>
  <mat-form-field *ngFor="let controlName of form.controls | keyvalue"
                  [hideRequiredMarker]="true"
                  class="v2-w-52px verify-code-input-box">
    <input autocomplete="off"
           class="h3"
           matInput
           inputmode="numeric"
           a7Restriction="\d"
           a7SequentialInput
           [name]="controlName.key"
           [formControlName]="controlName.key" />
  </mat-form-field>
</div>
