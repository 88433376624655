import { transition, trigger, useAnimation } from '@angular/animations';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Carrier } from '@ark7/core-business-models';
import { A7PageSizeMatcher } from '@ark7/layout';
import { PropertyPOI, PropertyPOIType } from '@ark7/property-models';
import { bounceInY } from 'ng-animate';
import _ from 'underscore';
const POI_TYPES = [
  { label: 'Companies', type: PropertyPOIType.COMPANY },
  { label: 'Coffee Shop', type: PropertyPOIType.COFFEE_SHOP },
  { label: 'Retail Shops', type: PropertyPOIType.RETAIL_SHOP },
  { label: 'Restaurant', type: PropertyPOIType.RESTAURANT },
  { label: 'Park', type: PropertyPOIType.PARK },
  { label: 'Transportation Hubs', type: PropertyPOIType.TRANSPORTATION },
  { label: 'Schools', type: PropertyPOIType.SCHOOL },
  { label: 'Hospitals', type: PropertyPOIType.HOSPITAL },
  { label: 'Gym Clubs', type: PropertyPOIType.GYM },
];

const TypeMap: { [key in PropertyPOIType]?: string } = {
  [PropertyPOIType.RESTAURANT]: 'utensils',
  [PropertyPOIType.PARK]: 'tree',
  [PropertyPOIType.COFFEE_SHOP]: 'coffee',
  [PropertyPOIType.GYM]: 'running',
  [PropertyPOIType.RETAIL_SHOP]: 'shopping-cart',
  [PropertyPOIType.HOSPITAL]: 'plus',
  [PropertyPOIType.SCHOOL]: 'school',
  [PropertyPOIType.TRANSPORTATION]: 'subway',
};

@Component({
  selector: 'app-investment-location-pois',
  templateUrl: './investment-location-pois.component.html',
  styleUrls: ['./investment-location-pois.component.scss'],
  animations: [
    trigger('bounce', [
      transition(':enter', []),
      transition(':leave', []),
      transition(
        '* => *',
        useAnimation(bounceInY('-2px', '5px', '-2px', '1px')),
      ),
    ]),
  ],
})
export class InvestmentLocationPoisComponent implements OnInit, OnDestroy {
  @Input()
  mobile = false;
  @Input() carrier: Carrier;
  @A7PageSizeMatcher('XS')
  useSmall: boolean;
  mapCenter: google.maps.LatLng;
  mapOptions: google.maps.MapOptions = {
    streetViewControl: false,
    styles: [
      { featureType: 'poi', stylers: [{ visibility: 'off' }] },
      { featureType: 'transit', stylers: [{ visibility: 'off' }] },
    ],
    mapTypeControl: false,
    mapTypeControlOptions: {
      position: 7,
    },
    fullscreenControl: false,
    gestureHandling: 'greedy', // one finger move
  };
  markerOption: google.maps.MarkerOptions = {
    icon: {
      path: 'M0,0 v1 h1 v-1',
      fillColor: 'white',
      fillOpacity: 1,
      strokeColor: 'rgba(0, 0, 0, 0.15)',
      strokeWeight: 1,
      labelOrigin: new google.maps.Point(0, -25),
    },
  };
  infoOption: google.maps.InfoWindowOptions = {
    disableAutoPan: true,
  };
  homeInfoOption: google.maps.InfoWindowOptions = {
    disableAutoPan: true,
    zIndex: 1000,
  };
  counters: Array<{
    label: string;
    type: PropertyPOIType;
    count: number;
  }> = [];
  sideIndex: number = 0;

  _selectedIndex = 0;
  set selectedIndex(v) {
    if (v === this._selectedIndex) {
      return;
    }
    this._selectedIndex = v;
    this.filterByPOIType(v === 0 ? null : this.counters[v - 1].type);
  }
  get selectedIndex() {
    return this._selectedIndex;
  }

  selectedPoiType: PropertyPOIType;
  selectedPoi: PropertyPOI;
  popedUp = false;
  poiFilter: POIFilter = {};
  selectedPois: BouncePOI[] = [];
  @ViewChild('popUpComponent') popUpComponent: TemplateRef<any>;
  popUpComponentRef: DialogRef;

  private nativeEleWidth = this.host.nativeElement.offsetWidth;
  get mapWidth(): string {
    return this.popedUp
      ? this.mobile
        ? '100%'
        : '65vw'
      : `${this.nativeEleWidth}px`;
  }
  private nativeEleHeight = this.host.nativeElement.offsetHeight;
  get mapHeight(): string {
    return this.popedUp ? '100%' : `${this.nativeEleHeight}px`;
  }
  private refreshInterval: any;
  constructor(private host: ElementRef, private dialog: Dialog) {}
  ngOnInit() {
    this.mapCenter = this.carrier.locations?.mapInitialLocation.toLatLng();
    this.refreshCounter();
    this.refresh();
    this.refreshInterval = setInterval(() => this.refreshNativeSize(), 1000);
  }
  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }

  refreshNativeSize() {
    this.nativeEleWidth = this.host.nativeElement.offsetWidth;
    this.nativeEleHeight = this.host.nativeElement.offsetHeight;
  }
  enumToType(t: PropertyPOIType) {
    return TypeMap[t];
  }

  get labels() {
    return ['All'].concat(this.counters?.map((c) => c.label) ?? []);
  }

  private refreshCounter() {
    this.counters = [].concat(
      _.map(POI_TYPES, (t) => ({
        label: t.label,
        type: t.type,
        count: 0,
      })),
    );
    for (const poi of this.carrier.locations?.pois) {
      const c = _.find(this.counters, (counter) => counter.type === poi.type);
      if (c) {
        c.count++;
      }
    }
  }
  filterByPOIType(type: PropertyPOIType) {
    this.sideIndex = type == null ? 0 : 1;
    this.selectedPoiType = type;
    this.selectedPoi = null;
    this.refresh();
  }
  selectPOI(poi: BouncePOI) {
    this.selectedPoiType = poi.type;
    this.selectedPoi = poi;
    this.selectedIndex =
      this.counters.indexOf(_.find(this.counters, (c) => c.type === poi.type)) +
      1;
    this.sideIndex = 1;
    poi.bounce = (Number.parseInt(poi.bounce || '0', 10) + 1).toString();
    this.refresh();
  }
  popup() {
    this.popedUp = true;
    this.popUpComponentRef = this.dialog.open(this.popUpComponent, {
      autoFocus: true,
      restoreFocus: true,
    });
    this.popUpComponentRef.closed.subscribe((_x) => (this.popedUp = false));
  }
  private refresh() {
    this.selectedPois = _.filter(
      this.carrier.locations?.pois,
      (p) => this.selectedPoiType == null || p.type === this.selectedPoiType,
    );
  }
}
export interface POIFilter {
  type?: PropertyPOIType;
  poi?: string;
}
export interface BouncePOI extends PropertyPOI {
  bounce?: string;
}
