<mat-tab-group [selectedIndex]="stage"
               class="a7-ui-auth-form_tab-group">
  <mat-tab>
    <v2-auth-form-tab formTitle="IMPORTING USER">
      Verified Inc.

      <a7-loading-content [progressor]="progressor">
        <a7-loading-logo class="v2-w-3 v2-h-3"
                         a7-loading-indicator>
        </a7-loading-logo>
        <div class="text-primary v2-margin-2-v">
          Import succeeded! Logging you in ...
        </div>

        <ng-content></ng-content>
      </a7-loading-content>
    </v2-auth-form-tab>
  </mat-tab>
  <!-- selectedIndex 1 -->
  <mat-tab>
    <v2-auth-form-tab [formTitle]="formTitle"
                      [formGroup]="form">
      <mat-form-field class="a7-form-field v2-margin-1-b"
                      [hideRequiredMarker]="true">
        <div matPrefix
             class="v2-h-5 v2-w-4 center-inner">
          <img src="/assets/v2-email.svg"
               alt="email" />
        </div>

        <input #emailInputField
               matInput
               formControlName="email"
               name="email"
               type="email"
               autocomplete="email"
               autocorrect="off"
               autocapitalize="none"
               placeholder="Email" />
        <!-- <mat-placeholder class="color-green-grey-3">Email</mat-placeholder> -->

        <mat-error *ngIf="emailControl.hasError('checkEmail')">
          {{ emailControl.errors['checkEmail'].message }}
        </mat-error>
        <mat-error *ngIf="
            emailControl.hasError('required') ||
            emailControl.hasError('email') ||
            emailControl.hasError('pattern')
          ">
          Please enter your email.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="a7-form-field v2-margin-1-b"
                      [hideRequiredMarker]="true">
        <div matPrefix
             class="v2-h-5 v2-w-4 center-inner">
          <img src="/assets/v2-password.svg"
               alt="password" />
        </div>

        <input #passwordInputField
               matInput
               formControlName="password"
               name="password"
               type="password"
               autocomplete="new-password"
               placeholder="Password" />
        <!-- <mat-placeholder class="color-green-grey-3">Password</mat-placeholder> -->
        <mat-error *ngIf="passwordControl.hasError('required')">
          Please enter a password.
        </mat-error>
        <mat-error *ngIf="passwordControl.hasError('minlength')">
          Please enter at least 8 characters.
        </mat-error>
        <mat-error *ngIf="
            passwordControl.hasError('pattern') &&
            !passwordControl.hasError('minlength')
          "
                   [ngSwitch]="passwordControl.errors['pattern'].requiredPattern">
          <ng-container *ngSwitchCase="'/[A-Z]/'">
            Please enter at least 1 upper character.
          </ng-container>
          <ng-container *ngSwitchCase="'/[a-z]/'">
            Please enter at least 1 lower character.
          </ng-container>
          <ng-container *ngSwitchCase="'/[0-9]/'">
            Please enter at least 1 lower character.
          </ng-container>
          <ng-container *ngSwitchDefault>
            Please enter at least 1 special character.
          </ng-container>
        </mat-error>
        <mat-error *ngIf="passwordControl.hasError('invalid')">
          {{ passwordControl.errors['invalid'] }}
        </mat-error>
      </mat-form-field>

      <ng-content select="[a7-sign-up-form-tos]"> </ng-content>

      <button a7-auth-form-actions
              mat-flat-button
              [disabled]="signingUp"
              color="primary"
              [ngClass]="'v2-padding-16px-v v2-padding-24px-h'"
              (click)="signup()"
              class="h4">
        {{ cta }}
      </button>

      <ng-container a7-auth-form-bottom>
        <ng-content select="[a7-sign-up-form-tos-post]"> </ng-content>
      </ng-container>
    </v2-auth-form-tab>

    <a7-social-sign-in *ngIf="social"
                       class="a7-ui-auth-form_section"
                       wordClasses="h4 color-green-grey-3"
                       [blueFb]="false">
    </a7-social-sign-in>
  </mat-tab>
  <!-- selectedIndex 2-->
  <mat-tab>
    <v2-auth-form-tab formTitle="Welcome to Ark7"
                      [formGroup]="form2">
      <div class="body-2 color-green-grey-3">
        <div class="v2-margin-32px-b">
          Congratulations on your decision to choose Ark7 to build up your
          real-estate investment portfolio!
        </div>
        <div class="divider"></div>
        <div class="v2-margin-2-b">Please complete your name to continue.</div>
      </div>

      <mat-form-field class="a7-form-field"
                      [hideRequiredMarker]="true">
        <div matPrefix
             class="v2-h-5 v2-w-4 center-inner svg-primary-green-1">
          <img src="/assets/v2-icons/Profile.svg" />
        </div>

        <input matInput
               formControlName="first"
               name="first"
               placeholder="First name" />
        <!-- <mat-placeholder class="color-green-grey-3">First name</mat-placeholder> -->

        <mat-error *ngIf="form2.controls['first'].hasError('required')">
          Please enter your first name.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="a7-form-field"
                      [hideRequiredMarker]="true">
        <div matPrefix
             class="v2-h-5 v2-w-4 center-inner svg-primary-green-1">
          <img src="/assets/v2-icons/Profile.svg" />
        </div>
        <input matInput
               formControlName="last"
               name="last"
               placeholder="Last name" />

        <!-- <mat-placeholder class="color-green-grey-3">Last name</mat-placeholder> -->
        <mat-error *ngIf="form2.controls['last'].hasError('required')">
          Please enter your last name.
        </mat-error>
      </mat-form-field>

      <div class="v2-margin-4px-t v2-margin-8px-b">
        <a class="label-1"
           color="primary"
           (click)="login()">Do it later</a>
      </div>
      <button a7-auth-form-actions
              mat-flat-button
              [disabled]="signingUp"
              color="primary"
              (click)="login()">
        Login
      </button>
    </v2-auth-form-tab>
  </mat-tab>

  <!-- selectedIndex 3: already registered -->
  <mat-tab>
    <v2-auth-form-tab formTitle="ALREADY REGISTERED">
      Your email is already registered with us.

      <ng-container a7-auth-form-actions>
        <button mat-flat-button
                color="primary"
                [routerLink]="['/a/sign-in']">
          Login
        </button>

        <button mat-button
                color="primary"
                [routerLink]="['/a/forgot-password']">
          Reset password
        </button>
      </ng-container>
    </v2-auth-form-tab>
  </mat-tab>

  <!-- selectedIndex 4: internal server error -->
  <mat-tab>
    <a7-internal-server-error (back)="stage = 0"> </a7-internal-server-error>
  </mat-tab>
  <!-- selectedIndex 5: not available-->
  <mat-tab>
    <div class="h1 text-center">Ark7 may not be available in your area.</div>
    <div class="text-justify v2-margin-2-t">
      At this time Ark7 investments are only available to
      <a color="primary"
         href="https://ark7.com/faq?faqId=66573c16c98b64d91bc99051">U.S. citizens or
        residents above the age of 18.</a>
      We are actively working towards making it easier for everyone to invest
      through our platform in the near future so stay tuned for our updates and
      announcements.
    </div>
    If you have further questions, please contact us at
    <a href="mailto:support@ark7.com"
       color="primary">support&#64;ark7.com</a>.
  </mat-tab>
</mat-tab-group>
