import { A7LayoutService, LayoutSubscribeComponent } from '@ark7/layout';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'a7-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss'],
})
export class TosComponent extends LayoutSubscribeComponent implements OnInit {
  @Input() alwaysExpand: boolean = false;

  @Input() toggleDwolla: boolean = true;

  @HostBinding('class.a7-tos')
  _hostBindings = true;

  constructor(layout: A7LayoutService) {
    super(layout);
  }

  ngOnInit() {}
}
