import { A7Resource } from './resource';
import { A7ResourceAction } from './action';
import { IPaginationData, PaginationQuery } from './pagination';
import {
  IResourceMethodPromise,
  IResourceMethodPromiseStrict,
  ResourceRequestMethod,
} from './definitions';

export abstract class A7ResourceCRUD<
  TFull = any,
  TQuery = {},
  TModifier = {},
  TShort = TFull
> extends A7Resource {
  @A7ResourceAction({
    isArray: true,
    subSignalTopic: 'query',
  })
  query: IResourceMethodPromise<TQuery & TModifier, TShort[]>;

  @A7ResourceAction({
    pagination: true,
  })
  queryWithPagination: IResourceMethodPromise<
    TQuery & TModifier & PaginationQuery,
    IPaginationData<TShort>
  >;

  @A7ResourceAction({
    path: '/{!_id}',
    subSignalTopic: 'get',
  })
  get: IResourceMethodPromise<{ _id: string } & TModifier, TFull>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    subSignalTopic: 'create',
  })
  create: IResourceMethodPromiseStrict<Partial<TFull>, TModifier, void, TFull>;

  @A7ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!_id}',
    subSignalTopic: 'update',
  })
  update: IResourceMethodPromiseStrict<
    TFull,
    TModifier,
    { _id: string },
    TFull
  >;

  @A7ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!_id}',
    subSignalTopic: 'remove',
  })
  remove: IResourceMethodPromise<{ _id: string }, void | TFull>;
}
