<div class="v2-margin-2-t-n flex-row-start-center full-width flex-gap-4"
     [formGroup]="formGroup"
     a7SequentialInputGroup>
       <div class="input">+1</div>

       <mat-form-field 
                       [style.width.px]="30">
              <input matInput
                     placeholder=""
                     a7Restriction="\d\d\d"
                     a7SequentialInput
                     common-field-input
                     name="p1"
                     formControlName="p1">
       </mat-form-field>

       <div class="input">-</div>

       <mat-form-field 
                       [style.width.px]="30">
              <input matInput
                     placeholder=""
                     a7Restriction="\d\d\d"
                     a7SequentialInput
                     common-field-input
                     name="p2"
                     formControlName="p2">
       </mat-form-field>

       <div class="input">-</div>

       <mat-form-field 
                       [style.width.px]="40">
              <input matInput
                     placeholder=""
                     a7SequentialInput
                     a7Restriction="\d\d\d\d"
                     common-field-input
                     name="p3"
                     formControlName="p3">
       </mat-form-field>
</div>
<mat-error *ngIf="!isEmpty && !validate()">Please enter a valid phone number.</mat-error>
