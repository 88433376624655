import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { UserBehaviorType } from '@ark7/core-business-models';
import {
  ContentContainer,
  ContentContainerService,
  ExperimentalFlag,
  ExperimentService,
  GtagDefaultCategory,
  GtagEventName,
  GtagService,
  LayoutSectionService,
  MouseCaptureService,
  PageSection,
} from '@ark7/layout';
import {
  A7Router,
  BrowserProperties,
  Debug,
  getBrowserProperties,
} from '@ark7/utils';
import debug from 'debug';
import { fromEvent } from 'rxjs';
import _ from 'underscore';
import uuid from 'uuid/v4';

import { SocketService } from './socket.service';

const topic = 'user-behavior';

const d = debug('socket:UserBehaviorService');

export interface UserBehaviorPagePerformance {
  start?: number;
  offsetInMs?: number;
  videoStartInMs?: number;
  videoViewedDurationInMs?: number;
}

export interface PageStatus {
  container: ContentContainer;
  mouseX: number;
  mouseY: number;
  pageSections: PageSection[];
  flags: ExperimentalFlag[];
  currentPageSection: string;
}

export interface ExternalLink {
  link: string;
}

export interface UserBehaviorMessage {
  type: UserBehaviorType;
  location: {
    path: string;
    queryMap: any;
    hash: string;
  };
  requestId: string;
  lastPageDurationInMs: number;
  sessionId: string;
  sessionSeq: number;
  performance: UserBehaviorPagePerformance;
  browserProperties: BrowserProperties;
  pageStatus: PageStatus;
  externalLink?: ExternalLink;
}

@Injectable({
  providedIn: 'root',
})
export class UserBehaviorService {
  sessionSeq: number = 0;
  accessLog: UserBehaviorMessage;
  videoStartedMsg: UserBehaviorMessage;

  updatePageStatusUtil = Date.now();

  constructor(
    @Inject(DOCUMENT) document: any,
    @Inject(PLATFORM_ID) platformId: string,
    private socket: SocketService,
    private router: A7Router,
    private experiment: ExperimentService,
    private layoutSection: LayoutSectionService,
    private mouseCapture: MouseCaptureService,
    private contentContainer: ContentContainerService,
    private gtag: GtagService,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.initialize();
      setInterval(this.updatePageStatus.bind(this), 3000);
      fromEvent(document, 'mousemove').subscribe(this.markActive.bind(this));
    }
  }

  @Debug({ d })
  contentLoaded() {
    if (this.accessLog) {
      const message = this.cloneMessage(UserBehaviorType.CONTENT_LOADED);
      if (!message) {
        return;
      }
      this.socket.send({ topic, message });

      this.gtag.sendEvent(GtagEventName.TIMING_COMPLETE, {
        name: 'load',
        value: message.performance.offsetInMs,
        event_category: 'Content Load',
      });
    }
  }

  @Debug({ d })
  pageStatus() {
    const message = this.cloneMessage(UserBehaviorType.PAGE_STATUS);
    if (!message) {
      return;
    }
    message.location = urlToLocation(window?.location.href);
    this.socket.send({ topic, message });
  }

  @Debug({ d })
  openExternalLink(options: OpenExternalLinkOptions) {
    const message = this.cloneMessage(UserBehaviorType.OPEN_EXTERNAL_LINK);
    if (!message) {
      return;
    }
    message.externalLink = {
      link: options.link,
    };
    this.socket.send({ topic, message });
    this.gtag.sendEvent(GtagEventName.OPEN_EXTERNAL_LINK, {
      link: options.link,
      non_interaction: true,
    });
  }

  @Debug({ d })
  videoStart(options: VideoStartedOptions) {
    this.videoStartedMsg = this.cloneMessage(UserBehaviorType.VIDEO_START);
    if (!this.videoStartedMsg) {
      return;
    }
    this.videoStartedMsg.externalLink = {
      link: options.link,
    };
    this.socket.send({ topic, message: this.videoStartedMsg });

    this.gtag.sendEvent(GtagEventName.VIDEO_AUTO_PLAY_START, {
      event_category: GtagDefaultCategory.VIDEO_AUTO_PLAY,
      event_label: 'Introduction video',
      non_interaction: true,
    });
  }

  @Debug({ d })
  videoEnd(options: VideoStartedOptions) {
    if (this.videoStartedMsg == null) {
      return;
    }

    const message = this.cloneMessage(
      UserBehaviorType.VIDEO_END,
      this.videoStartedMsg,
    );
    if (!message) {
      return;
    }
    message.externalLink = {
      link: options.link,
    };
    message.performance.videoStartInMs = this.videoStartedMsg.performance.offsetInMs;
    message.performance.videoViewedDurationInMs =
      message.performance.offsetInMs - message.performance.videoStartInMs;

    this.socket.send({ topic, message });

    this.gtag.sendEvent(GtagEventName.VIDEO_AUTO_PLAY_END, {
      event_category: GtagDefaultCategory.VIDEO_AUTO_PLAY,
      event_label: 'Introduction video',
      non_interaction: true,
    });
  }

  @Debug({ d })
  signIn() {
    const message = this.cloneMessage(UserBehaviorType.SIGN_IN);
    if (!message) {
      return;
    }
    this.socket.send({ topic, message });
  }

  @Debug({ d })
  signOut() {
    const message = this.cloneMessage(UserBehaviorType.SIGN_OUT);
    if (!message) {
      return;
    }
    this.socket.send({ topic, message });
  }

  markActive() {
    this.updatePageStatusUtil = Date.now() + 6000;
  }

  get updatePageStatusActive(): boolean {
    return Date.now() < this.updatePageStatusUtil;
  }

  private updatePageStatus() {
    if (!this.updatePageStatusActive) {
      return;
    }

    this.pageStatus();
  }

  private initialize() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.accessLog = {
          type: UserBehaviorType.NAVIGATE,
          location: urlToLocation(event.url),
          requestId: uuid(),
          sessionId: this.socket.sessionId,
          sessionSeq: ++this.sessionSeq,
          lastPageDurationInMs: this.accessLog
            ? Date.now() - this.accessLog.performance.start
            : 0,
          performance: {
            start: Date.now(),
            offsetInMs: 0,
          },
          browserProperties: getBrowserProperties(),
          pageStatus: {
            container: this.contentContainer.size,
            mouseX: this.mouseCapture.mouseX,
            mouseY: this.mouseCapture.mouseY,
            pageSections: this.layoutSection.pageSections,
            flags: this.experiment.flags,
            currentPageSection: null,
          },
        };
      }

      if (event instanceof NavigationEnd || event instanceof NavigationError) {
        this.accessLog.location = urlToLocation(event.url);
        this.patchNormalFields(this.accessLog);

        this.socket.send({ topic, message: this.accessLog });
      }
    });
  }

  private patchNormalFields(message: UserBehaviorMessage): UserBehaviorMessage {
    message.browserProperties = getBrowserProperties();
    message.pageStatus = _.extend({}, message.pageStatus, {
      flags: this.experiment.flags,
      pageSections: this.layoutSection.pageSections,
      mouseX: this.mouseCapture.mouseX,
      mouseY: this.mouseCapture.mouseY,
      container: this.contentContainer.size,
    });

    if (message.pageStatus.container) {
      const target = message.pageStatus.container.scrollTop;
      const section = _.find(
        message.pageStatus.pageSections,
        (s) => s.offsetTop <= target && s.offsetTop + s.height > target,
      );
      message.pageStatus.currentPageSection =
        section == null ? null : section.name;
    } else {
      message.pageStatus.currentPageSection = null;
    }

    message.performance.offsetInMs = Date.now() - message.performance.start;
    return message;
  }

  private cloneMessage(type: UserBehaviorType, msg?: UserBehaviorMessage) {
    if (msg == null) {
      msg = this.accessLog;
      if (msg == null) {
        return null;
      }
    }

    const message: UserBehaviorMessage = _.defaults(
      {
        type,
        performance: _.clone(msg.performance),
        pageStatus: _.clone(msg.pageStatus),
      },
      msg,
    );

    return this.patchNormalFields(message);
  }
}

function urlToLocation(url: string) {
  const u = new URL(url, location.href);
  return {
    path: u.pathname,
    queryMap: u.searchParams,
    hash: u.hash,
  };
}

export interface OpenExternalLinkOptions {
  link: string;
}

export interface VideoStartedOptions {
  link: string;
}
