<div *ngIf="enabled"
     class="fixed z-index-highest v2-right-2 v2-bottom-16px flex-column-end-end">
  <div [@slideDown]="showDialog ? 'in' : 'void'"
       class="v2-w-35 v2-max-90vw v2-h-54 v2-radius-16px elevation-1dp v2-margin-16px-b bg-white flex-column-start-center">
    <div class="full-width bg-500 flex-row-start-center v2-padding-2-h v2-padding-20px-v">
      <img class="v2-h-20px"
           src="/assets/img_logo_white.webp" />
      <span class="text-200 h4"
            [ngStyle]="{ transform: 'translate(4px, 4px)' }">GPT</span>
    </div>
    <div class="flex-scale full-width v2-padding-2-h auto-scroll"
         #chats>
      <div class="full-width text-center v2-margin-1-v footer-2 color-green-grey-3">
        {{ startTime | date: 'short' }}
      </div>
      <div *ngFor="let c of supportConversation?.conversations">
        <div *ngIf="c.role === ConversationRole.MODEL"
             class="full-width flex-row-start-start v2-margin-1-b">
          <img class="v2-margin-4px-r"
               src="/assets/RoleGPT.svg" />
          <div class="flex-column-start-start">
            <div class="footer-2 color-green-grey-3">Ark7GPT (beta)</div>
            <div class="label-2 bg-blue-grey-2 v2-padding-16px-h v2-padding-8px-v v2-radius-8px"
                 [ngStyle]="{ 'max-width': '268px' }">
              {{ c.msg }}
            </div>
          </div>
        </div>
        <div *ngIf="c.role === ConversationRole.USER"
             class="full-width flex-row-end-start v2-margin-1-b">
          <div class="flex-column-start-end">
            <div class="footer-2 color-green-grey-3">Me</div>
            <div class="label-2 bg-500 text-white-high-emphasis v2-padding-16px-h v2-padding-8px-v v2-radius-8px"
                 [ngStyle]="{ 'max-width': '268px' }">
              {{ c.msg }}
            </div>
          </div>
          <img class="v2-margin-4px-l"
               src="/assets/role_me.webp" />
        </div>
      </div>
    </div>
    <div class="full-width v2-separator-h-1 v2-margin-1-b"></div>
    <div [ngClass]="[
        'full-width flex-row-end-center flex-wrap v2-padding-2-h',
        showAssistButtons ? 'v2-min-h-36px' : ''
      ]"
         [@slideDown]="showAssistButtons ? 'in' : 'void'">
      <button class="label-1 text-primary v2-radius-8px bg-white border-500 v2-border-1px v2-padding-4px-v v2-padding-1-h pointer overflow-show"
              (click)="requestFollowup()">
        Request follow-up
      </button>
    </div>

    <form (ngSubmit)="processInput()"
          class="full-width v2-padding-2-h v2-margin-2-b flex-row-center-center v2-margin-1-t">
      <a7-loading-content [progressor]="progressor"
                          class="full-width">
        <a7-loading-logo class="v2-w-3 v2-h-3"
                         a7-loading-indicator>
        </a7-loading-logo>
        <input class="v2-radius-8px v2-border-1px full-size v2-padding-1-v v2-padding-16px-h border-green-grey-4 color-green-grey-3 body-2"
               type="text"
               [disabled]="supportConversation.emailSent != null"
               [(ngModel)]="userInput"
               placeholder="Type a message..."
               [(ngModel)]="userInput"
               name="userInput"
               required />
      </a7-loading-content>
    </form>
  </div>
  <button (click)="showDialog = !showDialog"
          [ngClass]="[
      'h5 pointer flex-row-center-center smooth  text-white-high-emphasis bg-500 v2-border-0px v2-radius-50px v2-padding-8px-v elevation-lowest',
      showDialog || xs ? 'v2-padding-8px-h' : 'v2-padding-20px-h'
    ]">
    <ng-container *ngIf="!showDialog">
      <img [ngClass]="['v2-h-28px', xs ? '' : 'v2-margin-4px-r']"
           src="/assets/Ark7GPT.svg" />
      <span *ngIf="!xs">Support</span>
    </ng-container>
    <ng-container *ngIf="showDialog">
      <img class="v2-h-28px"
           src="/assets/a7gpt-close.webp" />
    </ng-container>
  </button>
</div>