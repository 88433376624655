import { Injectable } from '@angular/core';
import {
  NavigationBehaviorOptions,
  NavigationEnd,
  NavigationExtras,
  Router,
  UrlCreationOptions,
  UrlTree,
} from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class A7Router {
  public previousUrl: string;
  public currentUrl: string;
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    // Debug weird re-directions by throw  exceptions & check frames
    // console.debug(commands);
    // if (commands[0] === '/u/portfolio') throw commands;
    return this.router.navigate(commands, extras);
  }

  navigateByUrl(
    url: string | UrlTree,
    extras?: NavigationBehaviorOptions,
  ): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  createUrlTree(
    commands: any[],
    navigationExtras?: UrlCreationOptions,
  ): UrlTree {
    return this.router.createUrlTree(commands, navigationExtras);
  }

  serializeUrl(url: UrlTree): string {
    return this.router.serializeUrl(url);
  }

  get url() {
    return this.router.url;
  }

  get events() {
    return this.router.events;
  }
}
