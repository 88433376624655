import debug from 'debug';
import { A7Router, Debug, a7EmailValidator } from '@ark7/utils';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { GtagEventName, GtagService } from '@ark7/layout';
import { LocalStorage } from 'ngx-webstorage';
import {
  Logger,
  SignUpErrorCode,
  StatsResource,
  TrackingService,
  UserService,
} from '@ark7/resources-common';

import { UserRedirectComponent } from '../user-redirect-component';

const d = debug('a7-ui-auth:SignUpFormComponent');

enum SignUpFormStage {
  DEFAULT = 0,
  ALREADY_REGISTERED = 1,
  INTERNAL_SERVER_ERROR = 2,
  REGISTERED_SUCCESSFULLY = 3,
}

@Component({
  selector: 'a7-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
})
export class SignUpFormComponent
  extends UserRedirectComponent
  implements OnChanges, OnInit {
  @Input() formTitle: string = 'Begin Your Ark7 Journey';

  @Input() social: boolean = false;
  @Input() app: 'renters' | 'main_site' = 'main_site';

  @Input() partner: boolean = false;
  @Input() signInLink: boolean = true;
  @Input() getAppLink: boolean = false;
  @Input() redirectToWhenSucceed: string = '';
  @Input() cta: string = 'Agree & Sign Up';
  @Input() version: number = 1;
  @Input() customizedRegistrationResult: boolean = false;

  @Input() email: string;

  @LocalStorage() newUserName: { first: string; last: string };

  @HostBinding('class.a7-ui-auth-sign-up-form')
  @HostBinding('class.a7-ui-auth-form')
  _hostBinding = true;

  @HostBinding('class.a7-ui-auth-form__v2')
  get v2() {
    return this.version === 2;
  }

  form: UntypedFormGroup;
  signingUp: boolean = false;
  stage: SignUpFormStage = 0;
  private params: Params = null;

  constructor(
    private fb: UntypedFormBuilder,
    private router: A7Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private statsResource: StatsResource,
    private logger: Logger,
    private gtag: GtagService,
    private trackingService: TrackingService,
  ) {
    super(userService, router);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.email && changes.email.currentValue && this.form) {
      const email = changes.email.currentValue;
      this.form.controls.email.setValue(email);
      this.form.controls.email.disable();
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [
        '',
        [Validators.required, Validators.email, a7EmailValidator()],
        this.validateEmailNotTaken.bind(this),
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          // Validators.pattern(/[$^.*[\]{}()"!@#%&/,\\':;|_~`-]/),
          Validators.pattern(/[0-9]/),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[a-z]/),
        ],
      ],
      first: ['', Validators.required],
      last: ['', Validators.required],
    });

    this.safeSubscribe(this.route.queryParams, (params) => {
      if (params.email && !this.form.controls.email.value) {
        this.form.controls.email.setValue(params.email);
      }

      this.params = params;
    });
  }

  get emailControl() {
    return this.form.controls.email;
  }

  get passwordControl() {
    return this.form.controls.password;
  }

  @Debug({ d })
  async validateEmailNotTaken(control: AbstractControl): Promise<any> {
    try {
      // email regex taken from http://emailregex.com/
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegex.test(control.value)) {
        return null;
      }

      const result = await this.statsResource.checkEmail({
        email: control.value,
        app: this.app,
      });

      return result.ok ? null : { checkEmail: result };
    } catch (error) {
      // Unblocking the server failure.
      return null;
    }
  }

  @Debug({ d })
  async signup() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.signingUp = true;

    try {
      const email = this.form.controls.email.value.trim();
      const name = {
        first: this.form.controls.first.value.trim(),
        last: this.form.controls.last.value.trim(),
      };
      this.newUserName = name;
      const password = this.form.controls.password.value.trim();
      await this.userService.signUp({
        email,
        password,
        name,
        mainSiteTOS: this.app === 'main_site',
        rentersTOS: this.app === 'renters',
        referrer: this.trackingService.referrer,
        trackingCode: this.trackingService.trackingCode,
        leadCode: this.trackingService.leadCode,
      });

      // emit sign up converesion for google ads
      if (this.gtag.isAdsTrackingEnabled) {
        this.gtag.sendEvent(GtagEventName.SIGN_UP, {
          send_to: this.gtag.adsConversionSignUp,
        });
      }

      this.trackingService.clearReferral();

      // unset redirectTo to avoid login auto redirect
      this.redirectTo = null;
      try {
        await this.userService.signIn({
          email,
          password,
        });

        // renter emails are added by admin and don't need to verify manually
        if (this.app === 'main_site') {
          await this.userService.currentUser.sendEmailVerification();
        }
      } catch (ignore) {}

      // verification email sent
      if (this.redirectToWhenSucceed.length > 0) {
        this.router.navigate([this.redirectToWhenSucceed], {
          queryParams: this.params,
        });
      } else {
        this.stage = SignUpFormStage.REGISTERED_SUCCESSFULLY;
      }
    } catch (e) {
      console.error('sign up error: ', e);
      switch (e.code) {
        case SignUpErrorCode.USERNAME_EXISTS:
          this.stage = SignUpFormStage.ALREADY_REGISTERED;
          break;
        case SignUpErrorCode.INVALID_PASSWORD:
          const reason =
            {
              'Password did not conform with policy: Password not long enough':
                'Password not long enough',
              'Password did not conform with policy: Password must have symbol characters':
                'Password must have symbol characters',
            }[e.message] || 'Password is invalid';

          this.form.controls.password.setErrors({
            invalid: reason,
          });
          return;
        default:
          this.logger.error('User sign up failed', {
            error: e,
          });
          this.stage = SignUpFormStage.INTERNAL_SERVER_ERROR;
      }
    } finally {
      this.signingUp = false;
    }
  }

  @Debug({ d })
  reset() {
    // TODO: This needs to be called.
    this.form.patchValue({
      email: '',
      password: '',
      confirmPassword: '',
    });

    this.form.markAsUntouched();

    this.signingUp = false;
    // move to sign up tab
    this.stage = 0;
  }
}
