<div class="flex-row-center-center bg-blue-grey-2 v2-radius-4px v2-padding-4px v2-padding-8px-r">
  <div class="v2-w-2 v2-h-2">
    <img *ngIf="status === 'OFFERING'"
         class="full-size"
         src="/assets/v2-offering.svg" />
    <img *ngIf="status === 'TRADING'"
         class="full-size"
         src="/assets/v2-trading.svg" />
  </div>
  <span class="label-1 v2-margin-4px-l"
        [ngStyle]="{ transform: 'translate(0,2px)' }">{{ status | case: 'title' }}</span>
</div>