<app-page headerMode="none"
          headerLogoLink=""
          [footerShowNavi]="false">
  <div class="v2-max-w-120 margin-auto v2-min-h-50 body-2 color-green-grey-1 v2-padding-5-b"
       [ngClass]="sm ? 'v2-padding-2-h' : 'v2-padding-5-h'">
    <div class="h1 text-center v2-padding-2-t">Ark7 Investor Bonuses Promotion</div>
    <div class="h2 text-center v2-margin-4-b v2-margin-1-t">Terms and Conditions</div>

    <p>The New Investor Bonus, Investor Milestone Bonus, and Investor Dividend Day Bonus Promotion (hereinafter
      collectively referred to as the “Offer”) are offered by Ark7 Inc. ("Ark7").</p>

    <p>Ark7 serves as manager of Ark7 Properties LLC and Ark7 Properties Plus LLC, and such companies shall be
      collectively referred to herein as “Ark7 Properties”. This Offer is subject to the following Terms & Conditions.
      You acknowledge and agree to these Terms and Conditions to receive the Ark7 Investor Bonuses of Ark7
      Properties when qualifying based on these Terms and Conditions. Ark7 reserves the right to amend or
      terminate the Offer at any time, for any reason.</p>

    <p>Offer Period. January 1, 2024, 12:00 AM PDT – December 31, 2024, 11:59 PM PDT, unless otherwise specified in
      promotion detail.</p>

    <p>Eligibility. The New Investor Bonus is exclusively available to individuals who meet the following criteria: (a)
      they have not previously invested in Ark7 Properties; (b) they have opened a new Ark7 account and invested in Ark7
      properties for the first time through the promotional page (or advertisement); and (c) They have fulfilled the
      order transaction(s) on properties under Open Offerings within 30 days of registration. Orders from the Trading
      Market are not eligible to earn the New Investor Bonus. Ark7 Properties will issue bonus credits based on qualified
      transactions per investor, with no more than one Ark7 account enrolled per investor. The Investor Milestone Bonus
      and Investor Dividend Day Bonus are available to all investors upon meeting investment amount milestone(s) and
      fulfilling qualifying transactions during the promotion period. The Investor Cash Bonuses will be credited to the
      qualifying account within 5 business days after all conditions are satisfied. The Investor Dividend Boost Bonuses
      will be credited to the qualifying account on each monthly Dividend Day for the period specified in the promotion.
    </p>

    <p>Limitation. This Offer is not valid with any other Ark7 promotional offers, except for the Ark7 Referral offer,
      and is non-transferable and non-withdrawable. Ark7 will credit one bonus per fulfilled order, with the larger
      amount credited if more than one bonus qualifies for the order. The Offer is available to U.S. citizens or
      residents above the age of 18 only. Ark7 reserves the right to change these Terms and Conditions or terminate this
      Offer at any time without notice for any reason and to refuse or recover any Ark7 Bonuses if Ark7 determines that
      it was obtained under wrongful or fraudulent circumstances, that inaccurate or incomplete information was provided
      in opening the new account or connecting the bank account, that any rules or regulations would be violated, or that
      any terms of the Ark7 user agreement(s) have been violated. The Offer, when combined with the value you received
      from all other offers from Ark7 Properties in any given 12-month period, may not exceed $1,500. Other restrictions
      may apply.</p>

    <p>Taxes. Taxes related to this Offer are solely your responsibility. Consult with your tax advisor about the
      appropriate tax treatment for this offer and any tax implications associated with the receipt of a stock reward
      before enrolling.</p>

    <p>Release. BY PARTICIPATING IN THE OFFER, YOU RELEASE, DISCHARGE, AND AGREE TO INDEMNIFY AND HOLD HARMLESS ARK7 AND
      ARK7 PROPERTIES AND ITS OWNER(S), AFFILIATE(S), AND/OR SUBSIDIARIES, AND ALL OF THEIR RESPECTIVE DIRECTORS,
      OFFICERS, EMPLOYEES, MEMBERS, MANAGERS, AGENTS, LICENSEES, CONTRACTORS, REPRESENTATIVES, SUCCESSORS, AND ASSIGNS
      FROM AND AGAINST ANY LIABILITY FOR ANY DAMAGES, INJURY OR LOSSES, TO ANY PERSON OR PROPERTY, OF ANY KIND RESULTING
      IN WHOLE OR IN PART, DIRECTLY OR INDIRECTLY FROM ACCEPTANCE, POSSESSION, MISUSE OR USE OF ANY OFFER REWARD OR
      PARTICIPATION IN THE OFFER.</p>
  </div>
</app-page>
