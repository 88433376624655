<div [ngClass]="[
    'flex-column-center-center bg-blue-grey-2 overflow-hidden ',
    sm ? 'v2-padding-64px-v' : 'v2-padding-8-t v2-padding-7-b'
  ]">
  <div [ngClass]="[
      'h1 text-center color-green-grey-1 v2-w-80pct',
  
    ]">
    Hear from Our Investors

    <!-- TrustBox widget - Mini -->
    <div class="trustpilot-widget v2-margin-4-t"
         data-locale="en-US"
         data-template-id="53aa8807dec7e10d38f59f32"
         data-businessunit-id="609e64a5d83b380001945410"
         data-style-height="150px"
         data-style-width="100%"
         data-theme="light">
      <a href="https://www.trustpilot.com/review/ark7.com"
         target="_blank"
         rel="noopener">Trustpilot</a>
    </div>
    <!-- End TrustBox widget -->
  </div>
  <v2-carousel [controls]="sm ? 'none' : 'circle'"
               [focus]="sm">
    <ng-container *ngFor="let u of fus">
      <v2-featured-user *carouselItem
                        [profile]="u"></v2-featured-user>
    </ng-container>
  </v2-carousel>
</div>