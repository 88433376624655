import * as _ from 'underscore';
import debug from 'debug';
import urlJoin from 'url-join';
import { Document } from '@ark7/common-models';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Model } from '@ark7/model';
import { ResourceGlobalConfig } from '@ngx-resource/core';
import { catchError, last, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

const d = debug('a7-resources-common:FileService');

const DEFAULT_UPLOAD_FILE_OPTION: UploadDocumentOptions = {
  path: '/api/v2/documents',
};

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient) {}

  async uploadForm<T extends Document>(
    form: FormData,
    options: UploadDocumentOptions = {},
  ): Promise<T> {
    return await new Promise<T>(async (resolve, reject) => {
      const url = urlJoin(
        await (ResourceGlobalConfig.pathPrefix as any),
        options.path,
      );

      const req = new HttpRequest('POST', url, form, {
        withCredentials: true,
        responseType: 'json',
        reportProgress: true,
      });
      let lastProgress = _.now();

      this.http
        .request(req)
        .pipe(
          tap((event: HttpEvent<T>) => {
            d('Consume http event, %O', event);
            if (
              options.onUploadProgress &&
              event.type === HttpEventType.UploadProgress &&
              (_.now() > lastProgress + 500 || event.loaded === event.total)
            ) {
              options.onUploadProgress(event);
              lastProgress = _.now();
            }
          }),
          last(),
          catchError((err) => {
            reject(err);
            return throwError(err);
          }),
        )
        .subscribe((resp: HttpResponse<T>) => {
          resolve((options.model ?? Document).modelize(resp.body as any) as T);
        });
    });
  }

  async uploadBlob<T extends Document>(
    options: UploadBlobDocumentOptions,
  ): Promise<T> {
    _.defaults(options, DEFAULT_UPLOAD_FILE_OPTION);

    const form = new FormData();
    form.append('file', options.blob, options.fileName);

    return await this.uploadForm(form, options);
  }

  async upload<T extends Document>(
    uploadFile: NgxFileDropEntry,
    options: UploadDocumentOptions = {},
  ): Promise<T> {
    _.defaults(options, DEFAULT_UPLOAD_FILE_OPTION);

    const fileEntry = uploadFile.fileEntry as FileSystemFileEntry;

    const form = await new Promise<FormData>((resolve) => {
      fileEntry.file((file) => {
        const formData = new FormData();

        formData.append('file', file, uploadFile.relativePath);

        resolve(formData);
      });
    });

    return await this.uploadForm(form, options);
  }
}

export interface IUploadedFile {
  link: string;
}

export interface UploadDocumentOptions {
  path?: string;
  onUploadProgress?: (event: HttpProgressEvent) => any;
  model?: typeof Model;
}

export interface UploadBlobDocumentOptions extends UploadDocumentOptions {
  blob: Blob;
  fileName: string;
}
