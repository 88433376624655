<div [formGroup]="form"
     [ngClass]="[
    'text-center flex-row-wrap center-inner v2-row-gap-2 v2-col-gap-2',
    sm ? 'v2-max-w-25' : ''
  ]">
  <mat-form-field [hideRequiredMarker]="true"
                  appearance="outline"
                  class="bg-blue-grey-2 color-green-grey-1 v2-w-24 v2-padding-2-h v2-padding-1-v v2-radius-12px">
    <input class="h4 color-green-grey-1 v2-margin-0-v"
           matInput
           formControlName="email"
           placeholder="Email" />
  </mat-form-field>

  <button [ngClass]="['v2-radius-10px border-300 v2-border-1px text-primary bg-white pointer',
                    sm ? 'v2-w-24 h4 v2-padding-20px-h v2-padding-1-v' : 'h5 v2-padding-2-h v2-padding-16px-v']"
          (click)="subscribe()">
    Join Email List
  </button>
</div>
