import { A7Router, SubscribeComponent } from '@ark7/utils';
import { Component, Input } from '@angular/core';
import { UserService } from '@ark7/resources-common';

/**
 * For un-sign-in user only. If the user has signed in, then redirect to root
 * page.
 */
@Component({
  selector: 'a7-user-redirect-component',
  template: '<div></div>',
})
export class UserRedirectComponent extends SubscribeComponent {
  @Input() redirectTo: string = '/';

  constructor(userService: UserService, router: A7Router) {
    super();

    this.safeSubscribe(userService.userChange$, (user) => {
      if (user != null && this.redirectTo != null) {
        router.navigate([this.redirectTo]);
      }
    });
  }
}
