import { layoutService, PageSize } from './a7-layout.service';

export function A7PageSize(): PropertyDecorator {
  return (target: any, propertyKey: string | symbol) => {
    Object.defineProperty(target, propertyKey, {
      get: () => layoutService && layoutService._pageSize,
    });
  };
}

export function A7PageSizeMatcher(matcher: string): PropertyDecorator {
  const minus = '-';
  const above = '+';

  return (target: any, propertyKey: string | symbol) => {
    const matchGroup = [];
    if (matcher.endsWith(minus)) {
      const key = matcher.substring(0, matcher.length - 1);
      for (const candidate of Object.keys(PageSize)) {
        matchGroup.push(candidate);
        if (candidate === key) {
          break;
        }
      }
    } else if (matcher.endsWith(above)) {
      const key = matcher.substring(0, matcher.length - 1);
      for (const candidate of Object.keys(PageSize).reverse()) {
        matchGroup.push(candidate);
        if (candidate === key) {
          break;
        }
      }
    } else {
      matchGroup.push(matcher);
    }

    Object.defineProperty(target, propertyKey, {
      get: () => matchGroup.indexOf(layoutService?._pageSize) >= 0,
    });
  };
}
