import { Injectable } from '@angular/core';
import { SupportConversation } from '@ark7/core-business-models';
import {
  A7ResourceCRUD,
  A7ResourceParams,
  ResourceHandler,
} from '@ark7/resource2';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/support-conversation',
  withCredentials: true,
  model: SupportConversation,
})
export class ChatbotResource extends A7ResourceCRUD<SupportConversation, {}> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }
}
