import { Location } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Debug, SubscribeDirective } from '@ark7/utils';
import debug from 'debug';

import { debounceTime } from 'rxjs';
import { ContentContainerService } from '../services/content-container.service';
import { LayoutSectionService } from '../services/layout-section.service';

export interface PageSection {
  name: string;
  width: number;
  height: number;
  offsetTop: number;
  offsetLeft: number;
}

const d = debug('a7-layout:LayoutSectionDirective');

@Directive({
  selector: '[a7LayoutSection]',
})
export class LayoutSectionDirective
  extends SubscribeDirective
  implements AfterViewInit, OnInit, OnDestroy {
  @Input() a7LayoutSection: string;
  @Input() a7LayoutSectionBindId: boolean = true;
  @Input() a7LayoutSectionDelta: number = 0;
  @Input() a7LayoutSectionChangeHash: boolean = true;
  @Input() a7LayoutSectionAttachment: any;
  @Input() a7LayoutSectionHash: string;

  constructor(
    private layoutSectionService: LayoutSectionService,
    private host: ElementRef,
    private contentContainer: ContentContainerService,
    private location: Location,
  ) {
    super();
  }

  ngOnInit() {
    if (this.contentContainer.scroll) {
      this.safeSubscribe(
        this.contentContainer.scroll.pipe(debounceTime(50)),
        this.onContainerScroll.bind(this),
      );
    }
  }

  @Debug({ d })
  onContainerScroll() {
    const scrollTop =
      this.contentContainer.contentContainer.host.nativeElement.scrollTop +
      this.contentContainer.contentContainer
        .a7ContentContainerFocusSectionDelta +
      this.a7LayoutSectionDelta;
    if (
      scrollTop >= this.host.nativeElement.offsetTop &&
      scrollTop <=
        this.host.nativeElement.offsetTop + this.host.nativeElement.offsetHeight
    ) {
      this.contentContainer.setActiveLayoutSection(this);

      if (this.a7LayoutSectionChangeHash) {
        const hash =
          this.a7LayoutSectionHash == null
            ? this.a7LayoutSection
            : this.a7LayoutSectionHash;

        const currentPath = this.location.path(true);
        const newPath = this.location.path(false) + (hash ? `#${hash}` : '');

        d(
          'current path: %O, newPath: %O, equal: %O, fragmentLocked: %O',
          currentPath,
          newPath,
          newPath !== currentPath,
          this.contentContainer.fragmentLocked,
        );
        if (newPath !== currentPath && !this.contentContainer.fragmentLocked) {
          this.location.replaceState(newPath);
        }
      }
    } else {
      this.contentContainer.unsetActiveLayoutSection(this);
    }
  }

  @HostBinding('id')
  get id(): string {
    return this.a7LayoutSectionBindId ? this.a7LayoutSection : undefined;
  }

  ngAfterViewInit() {
    this.layoutSectionService.addSection(this);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.layoutSectionService.removeSection(this);
  }

  get pageSection(): PageSection {
    return {
      name: this.a7LayoutSection,
      width: this.host.nativeElement.offsetWidth,
      height: this.host.nativeElement.offsetHeight,
      offsetTop: this.host.nativeElement.offsetTop,
      offsetLeft: this.host.nativeElement.offsetLeft,
    };
  }
}
