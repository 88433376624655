import { A7PageSizeMatcher } from '@ark7/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dalmore-secondary-market-trading-agreement',
  templateUrl: './dalmore-secondary-market-trading-agreement.component.html',
  styleUrls: ['./dalmore-secondary-market-trading-agreement.component.scss'],
})
export class DalmoreSecondaryMarketTradingAgreementComponent {
  @A7PageSizeMatcher('SM-') sm;
}
