import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

import { A7Router } from './a7-router.service';
import { JsonLdData, JsonLdService } from './json-ld.service';

@Injectable({
  providedIn: 'root',
})
export class SeoRoutingHelperService {
  constructor(
    private readonly router: A7Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly jsonLdService: JsonLdService,
    private readonly meta: Meta,
    private readonly title: Title,
    @Inject(DOCUMENT) private dom,
  ) {
    this.setupRouting();
  }

  // use prod host for canonical link so that test environment pages won't be indexed
  _hostName = 'https://ark7.com';

  private updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  private removeCanonicalUrl() {
    const element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element != null) {
      element.remove();
    }
  }

  private setupRouting() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
      )
      .subscribe((route: ActivatedRoute) => {
        const jsonLd: JsonLdData = route.snapshot.data['jsonLd'];
        if (jsonLd) {
          this.jsonLdService.setData(jsonLd.type, jsonLd.data);
        }

        const title: string = route.snapshot.data['title'];

        if (title) {
          this.title.setTitle(title);
        }

        const description: string = route.snapshot.data['description'];

        if (description) {
          this.meta.updateTag({
            name: 'description',
            content: description,
          });
        }

        const canonical = route.snapshot.data['canonical'];
        if (canonical) {
          this.updateCanonicalUrl(this._hostName + canonical);
        } else {
          this.removeCanonicalUrl();
        }
      });
  }
}
