<div class="flex-column-center-center v2-row-gap-8 bg-blue-grey-1">
  <div [ngClass]="[
      'full-width',
      sm ? 'v2-max-w-33 v2-col-gap-4' : 'v2-max-w-120 v2-h-33 v2-col-gap-1',
      sm
        ? 'flex-column-center-center'
        : reverse
        ? 'flex-row-reverse-center-center'
        : 'flex-row-center-center',
      v2PaddingH
    ]">
    <div [ngClass]="[
      sm ? '' : 'flex-scale-basis-0',
      sm
        ? ''
        : reverse
        ? 'v2-margin-3-r-n'
        : 'v2-margin-7-r',
      'center-inner'
  ]">
      <div class="v2-w-95pct v2-max-w-33 center-inner">
        <ng-content></ng-content>
      </div>
    </div>

    <div [ngClass]="[
        sm ? '' : 'flex-scale-basis-0',
        'flex-column-center-start'
      ]">
      <div [ngClass]="[
          'v2-max-w-48',
          'v2-row-gap-1',
          'flex-column-center-start',
          sm
            ? 'v2-margin-1-t'
            : reverse
            ? 'v2-margin-8-l'
            : 'v2-margin-8-r'
        ]">
        <div *ngIf="title"
             class="h1 v2-margin-1-v color-green-grey-1 full-width"
             [ngClass]="sm ? 'text-center' : ''">{{ title }}</div>
        <div *ngFor="let f of features"
             class="flex-row-between-start">
          <div [ngClass]="[bullet ? 'cube-bullet' : '']"></div>
          <div class="body-1 color-green-grey-2">{{ f }}</div>
        </div>
        <div *ngIf="footNote != ''"
             class="label-2 color-green-grey-3">
          {{ footNote }}
        </div>
        <a *ngIf="learnMoreLink != ''"
           class="h4 color-green-grey-2 underline-thickness-1 v2-padding-44px-h"
           [href]="learnMoreLink"
           target="_blank">Learn more</a>
      </div>
    </div>
  </div>
</div>
