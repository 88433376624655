import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';

import { MatMenuTemplateDirective } from './mat-menu-template.directive';

@Component({
  selector: 'a7-mat-menu',
  templateUrl: './mat-menu.component.html',
  styleUrls: ['./mat-menu.component.scss'],
})
export class MatMenuComponent implements OnInit {
  @Input()
  mode: 'window' | 'bottom' | 'fullscreen' = 'window';
  @Input()
  backdropClass: string;
  @Output()
  menuOpened = new EventEmitter<void>();
  @Output()
  menuClosed = new EventEmitter<void>();

  @ContentChildren(MatMenuTemplateDirective)
  menuItems: QueryList<MatMenuTemplateDirective>;

  constructor() {}
  ngOnInit(): void {}
}
