import { AppConfigurationService } from '@ark7/resources-common';
import { Component, Input } from '@angular/core';
import { getUrlHost } from '@ark7/utils';

import { CarrierBaseComponent } from '../carrier-base/carrier-base.component';

@Component({
  selector: 'a7-carrier-abstract',
  templateUrl: './carrier-abstract.component.html',
  styleUrls: ['./carrier-abstract.component.scss'],
})
export class CarrierAbstractComponent extends CarrierBaseComponent {
  @Input() forceSm = false;
  @Input() showDistribution = true;

  constructor(private configurationService: AppConfigurationService) {
    super();
  }

  get shareUrl() {
    let baseUrl = getUrlHost();
    if (baseUrl.includes('4200')) {
      baseUrl = baseUrl.replace('4200', '4201');
    }
    return `${baseUrl}/share-investments?carrierId=${this.carrier?._id?.toString()}`;
  }

  get hasDividendBoost() {
    return (
      this.carrier.isIpoOffering && this.configurationService.hasDividendBoost
    );
  }

  get dividendBoostRate() {
    return this.configurationService.dividendBoostRate;
  }

  get dividendBoostEndDate() {
    return this.configurationService.dividendBoostEndDate;
  }

  introductionContent(field: string) {
    return (Array.isArray(this.carrier.introduction[field])
      ? this.carrier.introduction[field]
      : [this.carrier.introduction[field]]
    ).join(' · ');
  }
}
