import _ from 'underscore';
import debug from 'debug';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { toPartialMatchRegex } from './extend-regexp';

const d = debug('a7-input:A7SequentialInputGroupDirective');

@Directive({
  selector: '[a7SequentialInputGroup]',
  exportAs: 'sequentialGroup',
})
export class SequentialInputGroupDirective {
  @Output() done = new EventEmitter<string[]>();

  inputs: SequentialInputDirective[] = [];

  add(input: SequentialInputDirective): number {
    this.inputs.push(input);
    d('input added');
    return this.inputs.length - 1;
  }

  get isComplete() {
    return this.inputs.every((i) => i.isComplete);
  }

  get value() {
    return _.map(this.inputs, (i) => i.value).join();
  }

  set value(value: string) {
    d(`Group: value set`);
    const fills: string[] = [];
    for (let i = 0, input = 0, word = ''; i < value.length; i++) {
      if (input >= this.inputs.length) {
        break;
      }
      word += value.charAt(i);
      const check = this.inputs[input].check(word);
      switch (check) {
        case 'invalid':
          return;
        case 'partial':
          fills[input] = word;
          break;
        case 'complete':
          fills[input++] = word;
          word = '';
          break;
      }
    }
    d(`Group: fills to inputs`);
    for (let j = 0; j < fills.length; j++) {
      this.inputs[j].value = fills[j];
    }
  }

  clear() {
    d(`Group: clear`);
    this.inputs.forEach((i) => i.clear());
  }

  focus() {
    d(`Group: focus`);
    for (const input of this.inputs) {
      if (!input.isComplete) {
        input.focus();
        return;
      }
    }
    this.inputs[this.inputs.length - 1].focus();
  }

  focusBack(input: SequentialInputDirective) {
    d(`Group: focusBack`);
    const index = this.inputs.indexOf(input);
    if (index > 0) {
      this.inputs[index - 1].focus();
    }
  }

  focusNext(input: SequentialInputDirective) {
    d(`Group: focusNext`);
    const index = this.inputs.indexOf(input);
    if (index + 1 < this.inputs.length) {
      this.inputs[index + 1].focus();
    }
    if (index + 1 === this.inputs.length) {
      this.done.emit(_.map(this.inputs, (i) => i.value));
    }
  }

  paste(value: any) {
    d(`Group: paste`);
    if (value) {
      this.value = value;
      this.focus();
      this.done.emit(_.map(this.inputs, (i) => i.value));
    }
  }
}

@Directive({
  selector: '[a7SequentialInput]',
})
export class SequentialInputDirective implements OnInit {
  @Input() a7Restriction: string;

  private partialReg: RegExp;
  reg: RegExp;

  private seq = 0;

  constructor(
    private group: SequentialInputGroupDirective,
    public el: ElementRef,
  ) {
    this.seq = group.add(this);
  }

  ngOnInit() {
    d(`${this.seq}: onInit`);
    this.reg = new RegExp('^' + this.a7Restriction + '$');
    this.partialReg = toPartialMatchRegex(this.reg);
  }

  check(value: string): 'partial' | 'complete' | 'invalid' {
    let ret: 'partial' | 'complete' | 'invalid' = 'invalid';
    if (this.reg.test(value)) {
      ret = 'complete';
    } else if (this.partialReg.test(value)) {
      ret = 'partial';
    }
    d(`${this.seq}: check ${value}: ${ret}`);
    return ret;
  }

  get isComplete() {
    return this.reg.test(this.el.nativeElement.value);
  }

  get value() {
    return this.el.nativeElement.value;
  }

  set value(value: string) {
    d(`${this.seq}: set ${value}`);
    if (this.reg.test(value)) {
      this.el.nativeElement.value = value;
    }
  }
  clear() {
    this.el.nativeElement.value = '';
  }

  @HostListener('keyup.backspace', ['$event'])
  onKeydownBackspace(event: KeyboardEvent) {
    d(`${this.seq}: backspace`);

    if (this.el.nativeElement.value === '') {
      this.group.focusBack(this);
    } else {
      const start: number = (event.target as any).selectionStart;
      const end: number = (event.target as any).selectionEnd;
      if (start === 0 && end === 0) {
        this.group.focusBack(this);
      }
    }
  }

  @HostListener('keyup', ['$event'])
  onKeydown(event: KeyboardEvent) {
    d(
      `${this.seq}: keyup ${event.key} ${event.detail} ${
        event.code
      }, ${JSON.stringify(event)}`,
    );
    const value: string = this.el.nativeElement.value;

    if (this.reg.test(value)) {
      d(`focus next because ${this.reg} allows ${value}`);
      this.group.focusNext(this);
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    d(`${this.seq}: paste`);
    if (!event) {
      return;
    }
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text');
    if (pastedText) {
      this.group.paste(pastedText);
    }
  }

  @HostListener('focus', ['$event'])
  focus() {
    d(`${this.seq}: focus`);
    this.el.nativeElement.focus();

    this.el.nativeElement.select();
  }
}
