import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UserService } from '@ark7/resources-common';

@Component({
  selector: 'a7-social-sign-in',
  templateUrl: './social-sign-in.component.html',
  styleUrls: ['./social-sign-in.component.scss'],
})
export class SocialSignInComponent implements OnInit {
  @Input() mode: string = 'normal';
  @Input() action: string = 'Sign in';
  @Input() wordClasses = '';

  @Input()
  @HostBinding('class.blue-fb')
  blueFb = true;

  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  signInGoogle() {
    this.userService.signInGoogle();
  }

  signInFacebook() {
    this.userService.signInFacebook();
  }

  signInApple() {
    this.userService.signInApple();
  }
}
