import { Observable, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';

export function timeout(time: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export async function until<T>(promise: Promise<T>, time: number): Promise<T> {
  const result = await Promise.all([promise, timeout(time)]);
  return result[0];
}

export function observableToPromise<T>(o: Observable<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    let completed = false;
    o.pipe(
      catchError((err) => {
        if (!completed) {
          completed = true;
          reject(err);
        }
        return throwError(err);
      }),
    )
      .pipe(take(1))
      .subscribe((res) => {
        if (!completed) {
          completed = true;
          resolve(res);
        }
      });
  });
}

export function waitFor(conditionFunction, checkGap = 200): Promise<void> {
  const poll = (resolve) => {
    if (conditionFunction()) {
      resolve();
    } else {
      setTimeout(() => poll(resolve), checkGap);
    }
  };

  return new Promise(poll);
}
