import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[a7Nlink]',
})
export class NlinkDirective {
  @Input() a7Nlink: string;

  constructor(private el: ElementRef) {}

  @HostListener('click', ['$event'])
  onMouseDown(event: any) {
    if (event.metaKey || event.ctrlKey) {
      window?.open(this.a7Nlink || this.el.nativeElement.href);
    }
    event.preventDefault();
    event.stopPropagation();
  }
}
