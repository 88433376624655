import { InjectionToken } from '@angular/core';
import { AuthOptions } from '@aws-amplify/auth/lib/types';

export interface A7ResourcesCommonModuleConfig {
  authOptions: AuthOptions;
  logErrorsToServer?: boolean;
}

export const A7_RESOURCES_CONFIG = new InjectionToken<AuthOptions>('A7_RESOURCES_CONFIG');
export const A7_RESOURCES_USER = new InjectionToken('A7_RESOURCES_USER');

// Inactive time for auto sign-out dialog to appear.
export const A7_SIGN_OUT_DIALOG_TIME = new InjectionToken<number>('A7_SIGN_OUT_DIALOG_TIME');
// Time after auto sign-out dialog appear & before actually sign off the user, if the user did not respond.
export const A7_EXECUTE_SIGN_OUT_TIME = new InjectionToken<number>('A7_EXECUTE_SIGN_OUT_TIME');
