import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { Component, OnInit } from '@angular/core';
import { LeadResource, TrackingService } from '@ark7/resources-common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { a7EmailValidator, forms } from '@ark7/utils';

@Component({
  selector: 'v2-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent extends V2BaseComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    fb: UntypedFormBuilder,
    l: A7LayoutService,
    private leadResource: LeadResource,
    private trackingResource: TrackingService,
    // private subscriptionResource: SubscriptionResource,
    private snackBar: MatSnackBar,
  ) {
    super(l);
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email, a7EmailValidator()]],
    });
  }

  ngOnInit() {}

  async subscribe() {
    if (!this.form.valid) {
      forms.validateAllFormFields(this.form);
      return;
    }

    await this.leadResource.create({
      email: this.form.controls.email.value,
      referrer: this.trackingResource.referrer,
      tracking: this.trackingResource.trackingCode,
    });
    if (this.form.controls.email.value) {
      this.trackingResource.leadEmail = this.form.controls.email.value;
    }

    // try {
    //   await this.subscriptionResource.create({
    //     email: this.form.controls.email.value,
    //   });
    // } catch (error) {
    //   if (
    //     error.body == null &&
    //     error.body.message !== 'Record already exists'
    //   ) {
    //     this.snackBar.open('Subscribed failed, please try again later!', '', {
    //       duration: 5000,
    //     });
    //     return;
    //   }
    // }

    this.snackBar.open('Subscribed successfully!', '', {
      duration: 5000,
    });

    this.form.setValue({ email: '' });
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }
}
