<div [ngStyle]="{
    height: height + 'px',
    display: height? 'inline-block':'none',
    'vertical-align': 'bottom',
    'white-space': 'nowrap'
  }">
  <span *ngFor="let x of target; let i = index"
        [ngClass]="[dotPos <= i ? decimalPlaceClass : '']"
        [ngStyle]="{
      height: height + 'px',
      display: 'inline-block',
      overflow: 'hidden'
    }">
    <div *ngIf="isDigit(i)"
         [@slide]="{ value: target[i], params: { marginTop: target[i] } }">
      <div *ngFor="let d of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
           class="flex-row-center-center"
           [ngStyle]="{ height: height + 'px' }">
        {{ d }}
      </div>
    </div>

    <div *ngIf="!isDigit(i)"
         class="flex-row-center-center"
         [ngStyle]="{ height: height + 'px' }">
      {{ numStr.charAt(i) }}
    </div>
  </span>
</div>
