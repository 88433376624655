import { Component, OnInit } from '@angular/core';

import { TERMS } from './terms';

@Component({
  selector: 'app-referral-terms-and-conditions',
  templateUrl: './referral-terms-and-conditions.component.html',
  styleUrls: ['./referral-terms-and-conditions.component.scss'],
})
export class ReferralTermsAndConditionsComponent implements OnInit {
  terms = TERMS;

  constructor() {}

  ngOnInit() {}
}
