import {
  A7ResourceCRUDObservable,
  A7ResourceParams,
  ResourceHandler,
} from '@ark7/resource2';
import { AppConfiguration } from '@ark7/core-business-models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/app/configurations',
  withCredentials: true,
  model: AppConfiguration,
})
export class AppConfigurationResource extends A7ResourceCRUDObservable<
  AppConfiguration,
  { osType: string; appVersion: string; trackingCode?: string }
> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }
}
