<ng-container [ngTemplateOutlet]="mapComponent"> </ng-container>

<ng-template #popUpComponent>
  <div class="bg-white v2-90vw v2-80vh relative v2-radius-8px overflow-hidden"
       cdkFocusInitial>
    <h5 class="bg-blue-grey-1 full-width v2-5vh flex-row-between-center v2-padding-2-h">
      <span class="flex-scale center-inner">{{ carrier.nickname }}</span>
      <span (click)="popUpComponentRef.close(); this.selectedIndex = 0"
            class="pointer">╳</span>
    </h5>
    <div [ngClass]="[
        'v2-75vh',
        mobile
          ? 'flex-column-reverse-between-center'
          : 'flex-row-between-center'
      ]">
      <div class="flex-scale flex-column-start-start block full-height auto-scroll">
        <v2-inplace-dropdown-select class="full-wdith block v2-margin-1-v v2-margin-2-h"
                                    [choices]="labels"
                                    [(selected)]="selectedIndex">
        </v2-inplace-dropdown-select>
        <div *ngFor="let poi of selectedPois"
             class="flex-row-between-center v2-col-gap-1 v2-margin-1-v v2-margin-2-h">
          <div *ngIf="poi.type"
               [@bounce]="poi.bounce"
               (click)="selectPOI(poi)"
               [ngClass]="[
              'icon-1 v2-w-4 v2-h-4  v2-min-w-4 v2-radius-8px center-inner pointer',
              'icon-' + enumToType(poi.type) + '-background'
            ]">
            <img [src]="'/assets/v2_map_logo_' + enumToType(poi.type) + '.svg'" />
          </div>
          <div class="flex-column-between-start flex-scale">
            <div class="label-2">
              {{ poi.title }}
            </div>
            <div class="label-2 color-green-grey-3">
              {{poi.driveInMinutes?.toFixed(0)??'n/a'}} minutes' drive
            </div>
          </div>
          <div class="label-1 v2-w-6">
            {{poi.distanceInMiles?.toFixed(2)??'n/a'}} Miles
          </div>
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="mapComponent"> </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #mapComponent>
  <div [ngClass]="['map-outer full-height', mobile ? 'full-size' : '']">
    <google-map [width]="mapWidth"
                [height]="mapHeight"
                [center]="mapCenter"
                [zoom]="carrier.locations?.zoomLevel"
                [options]="mapOptions">
      <map-marker [position]="carrier.locations?.location.toLatLngCache()"
                  [options]="markerOption">
        <map-info-window a7-map-info
                         class="no-outer-window pointer"
                         [options]="homeInfoOption">
          <img [src]="'/assets/v2_map_logo_home_circle.svg'" />
        </map-info-window>
      </map-marker>

      <map-marker *ngFor="let poi of selectedPois"
                  [position]="poi.location.toLatLngCache()"
                  [options]="markerOption">
        <map-info-window a7-map-info
                         class="pointer no-outer-window"
                         [ngSwitch]="poi.type"
                         [options]="infoOption"
                         [@bounce]="poi.bounce"
                         (click)="popedUp ? selectPOI(poi) : popup()">
          <div *ngIf="poi.type"
               [ngClass]="[
              'icon-' + enumToType(poi.type) + '-background',
              'v2-w-3 v2-h-3 border-circle center-inner'
            ]">
            <img [src]="'/assets/v2_map_logo_' + enumToType(poi.type) + '.svg'" />
          </div>
        </map-info-window>
      </map-marker>
    </google-map>
  </div>

  <div class="absolute v2-top-2 v2-left-2 flex-row-wrap-center v2-row-gap-1 v2-col-gap-1"
       *ngIf="!popedUp">
    <ng-container *ngIf="!mobile">
      <ng-container *ngFor="let counter of counters">
        <button mat-flat-button
                *ngIf="counter.count > 0"
                (click)="popup()">
          <div *ngIf="counter.type"
               class="flex-row-between-center v2-col-gap-8px">
            <img class="svg-green-grey-1"
                 [src]="'/assets/v2_map_logo_' + enumToType(counter.type) + '.svg'" />
            <span> {{ counter.label }}</span>
            <span class="text-primary">{{ counter.count }}</span>
          </div>
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="mobile">
      <button mat-flat-button
              (click)="popup()">
        POIs
        <span class="text-primary">{{ carrier.locations?.pois.length }}</span>
      </button>
    </ng-container>
  </div>
</ng-template>