import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const SLIDE_DOWN = trigger('slideDown', [
  state(
    'void',
    style({
      overflow: 'hidden',
      padding: 0,
      margin: 0,
      height: 0,
    }),
  ),
  state(
    '*',
    style({
      overflow: 'hidden',
      height: '*',
    }),
  ),
  transition(':enter', [animate('200ms 0ms ease-in-out')]),
  transition(':leave', [animate('200ms 0ms ease-in-out')]),
]);

export const SLIDE_LEFT = trigger('slideLeft', [
  state(
    'void',
    style({
      transform: 'translate3d(0, 0, 0)',
    }),
  ),
  state(
    '*',
    style({
      transform: 'translate3d(-100%, 0, 0)',
    }),
  ),
  transition('* <=> *', [animate('200ms 0ms ease-in')]),
]);

export const SLIDE = trigger('slide', [
  state(
    'hide',
    style({
      display: 'none',
    }),
  ),
  state(
    'slideInLeft',
    style({
      opacity: 1,
    }),
  ),
  state(
    'slideInRight',
    style({
      opacity: 1,
    }),
  ),
  state(
    'slideOutLeft',
    style({
      opacity: 0,
    }),
  ),
  state(
    'slideOutRight',
    style({
      opacity: 0,
    }),
  ),
  state(
    'slideOutLeftAndHide',
    style({
      display: 'none',
    }),
  ),
  state(
    'slideOutRightAndHide',
    style({
      display: 'none',
    }),
  ),
  transition('* => slideOutLeft', [
    style({}),
    animate(
      '200ms 0ms ease-in',
      keyframes([
        style({ opacity: 1, transform: 'translate3d(0, 0, 0)', offset: 0 }),
        style({ opacity: 0, transform: 'translate3d(-100%, 0, 0)', offset: 1 }),
      ]),
    ),
  ]),
  transition('* => slideOutLeftAndHide', [
    style({
      display: 'initial',
    }),
    animate(
      '200ms 0ms ease-in',
      keyframes([
        style({ opacity: 1, transform: 'translate3d(0, 0, 0)', offset: 0 }),
        style({ opacity: 0, transform: 'translate3d(-100%, 0, 0)', offset: 1 }),
      ]),
    ),
  ]),
  transition('* => slideInLeft', [
    style({
      display: 'initial',
    }),
    animate(
      '200ms 0ms ease-in',
      keyframes([
        style({ opacity: 0, transform: 'translate3d(-100%, 0, 0)', offset: 0 }),
        style({ opacity: 1, transform: 'translate3d(0, 0, 0)', offset: 1 }),
      ]),
    ),
  ]),
  transition('* => slideOutRight', [
    style({
      display: 'initial',
    }),
    animate(
      '200ms 0ms ease-in',
      keyframes([
        style({ opacity: 1, transform: 'translate3d(0, 0, 0)', offset: 0 }),
        style({ opacity: 0, transform: 'translate3d(100%, 0, 0)', offset: 1 }),
      ]),
    ),
  ]),
  transition('* => slideOutRightAndHide', [
    style({
      display: 'initial',
    }),
    animate(
      '200ms 0ms ease-in',
      keyframes([
        style({ opacity: 1, transform: 'translate3d(0, 0, 0)', offset: 0 }),
        style({ opacity: 0, transform: 'translate3d(100%, 0, 0)', offset: 1 }),
      ]),
    ),
  ]),
  transition('* => slideInRight', [
    style({
      display: 'initial',
    }),
    animate(
      '200ms 0ms ease-in',
      keyframes([
        style({ opacity: 0, transform: 'translate3d(100%, 0, 0)', offset: 0 }),
        style({ opacity: 1, transform: 'translate3d(0, 0, 0)', offset: 1 }),
      ]),
    ),
  ]),
]);
