import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'a7-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss'],
})
export class InternalServerErrorComponent implements OnInit {
  @Output() back = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
