import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import _ from 'underscore';

import { LoadingContentComponent } from '../loading-content/loading-content.component';
import { LoadingState } from '../loading-progressor';

@Directive({
  selector: '[a7-loading-indicator]',
})
export class LoadingIndicatorDirective implements AfterViewInit, OnDestroy {
  @Input() position: 'top' | 'center' | 'bottom' = 'center';
  @Input() align: 'begin' | 'center' | 'end' = 'center';

  private internal: any;

  offsetHeight: number = 0;
  offsetWidth: number = 0;

  @HostBinding('style.opacity')
  opacity: number = 0;

  constructor(
    private el: ElementRef,
    private loadingContent: LoadingContentComponent,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.loadingContent.stateChanges.subscribe((_state: LoadingState) => {
      this.refresh();
    });
  }

  @HostBinding('class.a7-loading-indicator')
  _hostBinding = true;

  @HostBinding('class.a7-loading-indicator_position-top')
  get isPositionTop() {
    return this.position === 'top';
  }

  @HostBinding('class.a7-loading-indicator_position-center')
  get isPositionCenter() {
    return this.position === 'center';
  }

  @HostBinding('class.a7-loading-indicator_position-bottom')
  get isPositionBottom() {
    return this.position === 'bottom';
  }

  @HostBinding('class.a7-loading-indicator_align-begin')
  get isAlignBegin() {
    return this.align === 'begin';
  }

  @HostBinding('class.a7-loading-indicator_align-center')
  get isAlignCenter() {
    return this.align === 'center';
  }

  @HostBinding('class.a7-loading-indicator_align-end')
  get isAlignBottom() {
    return this.align === 'end';
  }

  @HostBinding('style.top')
  get styleTop() {
    if (this.position === 'center') {
      return `calc(50% - ${Math.floor(this.offsetHeight / 2)}px)`;
    }
  }

  @HostBinding('style.left')
  get styleLeft() {
    if (this.align === 'center') {
      return `calc(50% - ${Math.floor(this.offsetWidth / 2)}px)`;
    }
  }

  ngAfterViewInit() {
    this.refresh();
    if (isPlatformBrowser(this.platformId)) {
      /**
       * setInterval will cause prerender to hang, (not the element).
       */
      this.internal = setInterval(() => {
        this.offsetHeight = this.el.nativeElement.offsetHeight;
        this.offsetWidth = this.el.nativeElement.offsetWidth;
      }, 500);
    }
  }

  ngOnDestroy() {
    if (this.internal) {
      clearInterval(this.internal);
    }
  }

  private refresh() {
    this.opacity = 0;
    _.delay(() => {
      this.offsetHeight = this.el.nativeElement.offsetHeight;
      this.offsetWidth = this.el.nativeElement.offsetWidth;
      this.opacity = 1;
    }, 100);
  }
}
