<div [ngClass]="[
    'flex-column-between-center v2-row-gap-2  v2-max-80vh',
    xs ? 'v2-padding-1-h v2-padding-1-v' : 'v2-padding-7-h v2-padding-4-v'
  ]">
  <img [src]="data.pic" />
  <h1 class="v2-margin-3-t">{{ data.title }}</h1>
  <div class="body-1 color-green-grey-2"
       [innerHtml]="data.note"></div>
  <button mat-flat-button
          class="bg-500 text-white-high-emphasis h5 v2-border-0px v2-padding-1-v v2-w-12 v2-radius-8px pointer"
          (click)="dialogRef.close()">
    {{ data.cta }}
  </button>
</div>