import { Component, Input, OnInit } from '@angular/core';

import { LoadingContentComponent } from '../loading-content/loading-content.component';

@Component({
  selector: 'a7-spin-loading-indicator',
  templateUrl: './spin-loading-indicator.component.html',
  styleUrls: ['./spin-loading-indicator.component.scss'],
})
export class SpinLoadingIndicatorComponent implements OnInit {
  @Input() errorMessage: string;
  @Input() successMessage: string;
  @Input() detailMessage: string;
  @Input() barMode = false;

  constructor(public loadingContent: LoadingContentComponent) {}

  ngOnInit() {}
}
