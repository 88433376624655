import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { A7UtilsModule } from '@ark7/utils';

import { CodeInputComponent } from './code-input/code-input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { NumberInputDirective } from './restrictive-input/number-input.directive';
import { RestrictiveInputDirective } from './restrictive-input/restrictive-input.directive';
import {
  SequentialInputDirective,
  SequentialInputGroupDirective,
} from './restrictive-input/sequential-input-group.directive';
import { SlideInputComponent } from './slide-input/slide-input.component';

@NgModule({
  declarations: [
    NumberInputDirective,
    RestrictiveInputDirective,
    SequentialInputDirective,
    SequentialInputGroupDirective,
    SlideInputComponent,
    PhoneInputComponent,
    CodeInputComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSliderModule,
    TextFieldModule,
    A7UtilsModule,
  ],
  exports: [
    NumberInputDirective,
    RestrictiveInputDirective,
    SequentialInputDirective,
    SequentialInputGroupDirective,
    SlideInputComponent,
    PhoneInputComponent,
    CodeInputComponent,
  ],
})
export class A7InputModule {}
