<div [ngClass]="[
    'bg-white flex-column-between-center',
    sm ? 'v2-padding-64px-t v2-padding-4-b' : 'v2-padding-8-t v2-padding-11-b'
  ]">
  <div [ngClass]="[
      'flex-column-between-center v2-max-w-120 full-width',
      v2PaddingH
    ]">
    <div class="h1 color-green-grey-1 text-center">
      Buy Shares in Curated Properties
    </div>
    <div [ngClass]="[
        'h3 color-green-grey-2 v2-margin-8px-t',
        sm ? 'v2-margin-40px-b' : 'v2-margin-4-b'
      ]">
      Starting as low as $20.00 / share
    </div>
    <div class="full-width overflow-hidden">
      <div #container
           [ngClass]="[
          sm
            ? 'flex-row-center-center v2-col-gap-2 v2-margin-32px-b'
            : 'v2-min-w-110 flex-row-between-center v2-margin-4-b'
        ]">
        <div *ngFor="let p of fps; index as i">
          <img [alt]="p.name"
               [src]="p.picUrl"
               [ngClass]="[
              'smooth border-box pointer swipe-trap ',
              isSelected(p) ? 'v2-border-4px border-500' : 'v2-opacity-20',
              sm
                ? 'v2-w-9 v2-h-7 v2-radius-8px '
                : 'v2-w-20 v2-h-14 v2-radius-24px'
            ]"
               (click)="select(i)"
               (swipeleft)="next()"
               (swiperight)="prev()" />
        </div>
      </div>
    </div>
    <div *ngIf="selected != null"
         [ngClass]="[
        md ? 'v2-col-gap-4' : 'v2-col-gap-5',
        sm
          ? 'flex-column-between-center v2-max-w-27'
          : 'full-width flex-row-between-center'
      ]">
      <div class="flex-scale-basis-0">
        <img [alt]="selected.name"
             [src]="selected.picUrl"
             [ngClass]="[
            'smooth full-width v2-obj-cover swipe-trap pointer',
            sm ? 'v2-h-19' : md ? 'v2-h-44' : 'v2-h-49',
            sm ? 'v2-radius-18px' : 'v2-radius-32px'
          ]"
             (swipeleft)="next()"
             (swiperight)="prev()"
             (click)="jumpToSharePage()" />
      </div>
      <div [ngClass]="[
          'flex-column-between-start',
          sm
            ? 'full-width v2-padding-32px-t'
            : md
            ? 'v2-w-33 v2-h-44 v2-padding-3-v'
            : 'v2-w-39 v2-h-49 v2-padding-3-v'
        ]">
        <div class="h4 color-green-grey-3">
          {{ selected.location }} · {{ selected.type }}
        </div>
        <div class="h1 color-green-grey-1 flex-row-start-center"
             [ngClass]="['pointer', sm ? 'v2-padding-4px-v' : '']"
             (click)="jumpToSharePage()">
          <div>{{ selected.name }}</div>
          <img *ngIf="selected.requireAccredited"
               class="vertical-align-middle v2-margin-1-l"
               [ngClass]="[sm ? 'v2-h-24px' : 'v2-h-32px']"
               src="/assets/v2-7-plus.svg" />
        </div>
        <div [ngClass]="[
            'h2 color-green-grey-1',
            sm ? 'v2-margin-1-t' : 'v2-margin-2-t'
          ]">
          {{ selected.price | currency: 'USD':'$':'0.2-2' }} <span class="h3">/ share</span>
        </div>
        <div class="v2-margin-1-t full-width flex-row-between-center">
          <div class="h4 color-green-grey-2">
            {{
              selected.distribution != '' ? 'Cash Distribution' : 'Market Cap'
            }}
          </div>
          <div class="h2 color-green-grey-1">
            {{
              selected.distribution != ''
                ? selected.distribution
                : selected.marketCap
            }}
          </div>
        </div>
        <div class="v2-margin-4px-t full-width flex-row-between-center">
          <div class="h4 color-green-grey-2">
            {{
              selected.totalInvestors != ''
                ? 'Investors'
                : selected.requireAccredited
                ? 'Appreciation'
                : 'Monthly Rent'
            }}
          </div>
          <div class="h2 color-green-grey-1">
            {{
              selected.totalInvestors != ''
                ? selected.totalInvestors
                : selected.requireAccredited
                ? selected.appreciation
                : selected.monthlyRent
            }}
          </div>
        </div>
        <div [ngClass]="[sm ? 'v2-margin-1-t' : 'v2-margin-32px-t']">
          <div class="h4 color-green-grey-2 v2-margin-1-b color-green-grey-2 check-green flex-row-start-center"
               *ngFor="let h of selected.highlights">
            {{ h }}
          </div>
        </div>
        <a7-button [ngClass]="[sm ? 'v2-margin-1-t' : 'v2-margin-32px-t']"
                   (click)="jumpToSharePage()">
          <div class="flex-row-center-center">
            <img src="/assets/eye.svg">
            <div class="v2-margin-8px-l">View Details</div>
          </div>
        </a7-button>
      </div>
    </div>

    <div class="h3 text-center color-green-grey-1"
         [ngClass]="[sm ? 'v2-margin-32px-t' : 'v2-margin-4-t']">
      We are currently in 10 markets and are going national.
    </div>
    <div class="color-green-grey-2 body-1 v2-margin-4px-t">
      <a class="h4 underline-thickness-1"
         href="https://ark7.com/blog/about-us/how-does-ark7-select-properties/"
         target="_blank">Learn more</a>
      about how we curate the properties.
    </div>
  </div>
</div>
