import { formatCurrency } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import {
  A7LayoutService,
  A7PageSizeMatcher,
  LayoutSubscribeComponent,
} from '@ark7/layout';
import {
  AppConfigurationService,
  TrackingService,
} from '@ark7/resources-common';

@Component({
  selector: 'app-referral-invitation-result',
  templateUrl: './referral-invitation-result.component.html',
  styleUrls: ['./referral-invitation-result.component.scss'],
})
export class ReferralInvitationResultComponent
  extends LayoutSubscribeComponent
  implements OnInit {
  constructor(
    layout: A7LayoutService,
    private configurationService: AppConfigurationService,
    public trackingService: TrackingService,
  ) {
    super(layout);
  }

  @HostBinding('class.mobile')
  @A7PageSizeMatcher('XS')
  mobile: boolean;

  ngOnInit(): void {}

  get downloadQueryParams() {
    return {
      r: this.trackingService.referrer,
      tc: this.trackingService.trackingCode,
    };
  }

  get minInvestmentAmount() {
    const amount = this.configurationService.getConfig(
      'app.referral.minInvestmentAmount',
    );
    return amount == null
      ? ''
      : formatCurrency(amount, 'en-US', '$', 'USD', '.0');
  }

  get refereeBonusAmount() {
    const amount = this.configurationService.getConfig(
      'app.referral.refereeBonusAmount',
    );
    return amount == null
      ? ''
      : formatCurrency(amount, 'en-US', '$', 'USD', '.0');
  }

  get referralInvitationDesc() {
    return this.configurationService.referralInvitationResultDesc
      .replace(
        '<subheading2>#MIN_INVESTMENT_AMOUNT#</subheading2>',
        `<span>${this.minInvestmentAmount}</span>`,
      )
      .replace(
        '<subheading2>#REFEREE_BONUS_AMOUNT#</subheading2>',
        `<span class="h5 color-green-grey-1">${this.refereeBonusAmount}</span>`,
      );
  }
}
