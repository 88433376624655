import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'v2-abcd-block',
  templateUrl: './abcd-block.component.html',
  styleUrls: ['./abcd-block.component.scss'],
})
export class AbcdBlockComponent implements OnInit {
  @Input() layout = 'flex-column-between-start';
  /* a */
  @Input() percent: number = null;
  /* b */
  @Input() bText = null;
  @Input() bClass = null;

  /* b/c */
  @Input() slash = ' / ';

  /* c */
  @Input() cText = '';
  @Input() cClass = 'h5';
  @Input() decimalPlaceClass = 'h5';

  /* d */
  @Input() dText = '';
  @Input() dClass = 'label-2 color-green-grey-3';
  @Input() toolTip = '';

  constructor() {}

  ngOnInit(): void {}
}
