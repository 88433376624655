import { DialogRef } from '@angular/cdk/dialog';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'v2-dialog-bar',
  templateUrl: './v2-dialog-bar.component.html',
  styleUrls: ['./v2-dialog-bar.component.scss'],
})
export class V2DialogBarComponent {
  @Input() dialogRef: DialogRef<any, any>;
  @Input() title = '';
  @Input() titleClass = 'h5 color-green-grey-1';
  @Input() topBarClass = 'bg-blue-grey-1';
  @Input() svgClass = '';
}
