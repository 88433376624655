/// <reference types="@types/google.maps" />

import _ from 'underscore';
import { A7Model, Mixin, StrictModel } from '@ark7/model';
import { Location } from '@ark7/common-models';
import { formatNumber } from '@angular/common';

@A7Model({})
export class LocalLocation extends StrictModel {
  protected _latlng: google.maps.LatLng;

  static centerOf(locations: Location[]): Location {
    const center: Location = _.reduce(
      locations,
      (res: Location, l) =>
        Location.modelize({
          coordinates: [
            res.coordinates[0] + l.coordinates[0] / locations.length,
            res.coordinates[1] + l.coordinates[1] / locations.length,
          ],
          type: 'Point',
        } as any),
      Location.modelize({
        coordinates: [0, 0],
        type: 'Point',
      } as any),
    );

    return center;
  }

  static fromLatLng(
    latLng: google.maps.LatLngLiteral | google.maps.LatLng,
  ): Location {
    return Location.modelize({
      type: 'Point',
      coordinates:
        latLng instanceof google.maps.LatLng
          ? [latLng.lat(), latLng.lng()]
          : [latLng.lat, latLng.lng],
    } as any);
  }

  get latitude() {
    return this.coordinates[0];
  }

  get longitude() {
    return this.coordinates[1];
  }

  setFromLatLng(latLng: google.maps.LatLng) {
    this.coordinates[0] = latLng.lat();
    this.coordinates[1] = latLng.lng();
    this._latlng = null;
  }

  toLatLng(): google.maps.LatLng {
    return this.coordinates.length < 2
      ? null
      : new google.maps.LatLng(this.coordinates[0], this.coordinates[1]);
  }

  toLatLngCache(): google.maps.LatLng {
    if (this._latlng == null) {
      this._latlng = this.toLatLng();
    }

    return this._latlng;
  }

  clone(): Location {
    return Location.modelize({
      type: this.type,
      coordinates: [this.coordinates[0], this.coordinates[1]],
    } as any);
  }

  toString(): string {
    return `(${formatNumber(this.latitude, 'en-US', '.0-4')}, ${formatNumber(
      this.longitude,
      'en-US',
      '.0-4',
    )})`;
  }
}

export interface LocalLocation extends Location {}

Mixin(LocalLocation)(Location);

declare module '@ark7/common-models/commons/location' {
  interface ILocation {
    latitude?: number;
    longitude?: number;

    setFromLatLng?: (latLng: google.maps.LatLng) => void;
    toLatLng?: () => google.maps.LatLng;
    toLatLngCache?: () => google.maps.LatLng;
    clone?: () => Location;
  }
}
