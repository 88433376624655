<div [ngClass]="[
      'v2-max-w-94 full-width bg-white v2-radius-24px v2-padding-32px-v v2-row-gap-2',
      sm ? 'flex-column-between-center v2-padding-2-h' : 'flex-row-between-center v2-padding-4-h'
]">
  <div class="h3 color-green-grey-1">Create Your Free Account</div>
  <div [formGroup]="form"
       [ngClass]="[
      'text-center flex-row-wrap center-inner v2-row-gap-2 v2-col-gap-2',
      sm ? 'v2-max-w-25' : ''
    ]">
    <mat-form-field [hideRequiredMarker]="true"
                    appearance="outline"
                    class="bg-blue-grey-2 color-green-grey-1 v2-w-24 v2-padding-2-h v2-padding-1-v v2-radius-12px">
      <input class="h4 color-green-grey-1 v2-margin-0-v"
             matInput
             formControlName="email"
             placeholder="Email address" />
    </mat-form-field>

    <button [ngClass]="[
        'v2-padding-32px-h v2-padding-16px-v v2-radius-10px v2-border-0px text-white-high-emphasis h5 bg-500 pointer',
        sm ? 'v2-w-24' : ''
      ]"
            (click)="next()">
      Next
    </button>
  </div>
</div>
