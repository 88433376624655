import _ from 'underscore';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
} from '@angular/core';

import { A7SpjTemplateDirective } from './a7-spj-template.directive';

@Component({
  selector: 'a7-switch-panel-junior',
  templateUrl: './a7-switch-panel-junior.component.html',
  styleUrls: ['./a7-switch-panel-junior.component.scss'],
})
export class A7SwitchPanelJuniorComponent implements AfterViewInit {
  @ContentChildren(A7SpjTemplateDirective)
  panels!: QueryList<A7SpjTemplateDirective>;

  private _index = 0;
  @Input()
  set selected(v) {
    this._index = v;
  }

  get selected() {
    return this._index;
  }

  @Input()
  lazyRendering = true;

  @Input()
  instableContentWidth = false;

  initWidth = 0;

  smoothClass = ''; // do not enable until we have width
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    // We want this panel to init a bit late, when 100% width has settled.
    _.delay(
      () => {
        this.initWidth = this.el.nativeElement.offsetWidth;
        if (this.initWidth === 0) {
          setTimeout(() => this.ngAfterViewInit(), 500);
        } else {
          setTimeout(() => (this.smoothClass = 'smooth'), 300);
        }
      },
      this.instableContentWidth ? 1000 : 0,
    );
  }
}
