import { Component } from '@angular/core';
import { A7PageSizeMatcher } from '@ark7/layout';

@Component({
  selector: 'app-promo-terms',
  templateUrl: './promo-terms.component.html',
  styleUrls: ['./promo-terms.component.scss'],
})
export class PromoTermsComponent {
  @A7PageSizeMatcher('SM-') sm;
}
