import * as changeCase from 'change-case';
import _ from 'underscore';
import debug from 'debug';
import { A7Router, Debug } from '@ark7/utils';
import { Event, NavigationStart } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

const d = debug('a7-layout:ExperimentService');

const DEFAULT_EXPERIMENT_FLAG_OBSERVE_OPTIONS: ExperimentFlagObserveOptions = {
  priority: 'session',
  acceptLocalStorageCache: true,
  flagReadPolicy: 'at_least_one',
};

const DEFAULT_GET_EXPERIMENTAL_FLAG_OPTIONS: GetExperimentalFlagOptions = {
  priority: 'session',
  flagReadPolicy: 'at_least_one',
};

export let experiment: ExperimentService;

export interface ExperimentalFlag {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExperimentService {
  lastFlags: ExperimentalFlag[];
  flags: ExperimentalFlag[] = [];

  constructor(private storage: LocalStorageService, private router: A7Router) {
    if (experiment == null) {
      experiment = this;
    }

    this.initialize();
  }

  private initialize() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.lastFlags = this.flags;
        this.flags = [];
      }
    });
  }

  private addValue(flag: ExperimentalFlag) {
    const t = _.find(
      this.flags,
      (f) => f.name === flag.name && f.value === flag.value,
    );

    if (t == null) {
      this.flags.push(flag);
    }
  }

  @Debug({ d })
  getExperimentalFlag(
    flag: string,
    options: GetExperimentalFlagOptions = {},
  ): any {
    options = _.defaults({}, options, DEFAULT_GET_EXPERIMENTAL_FLAG_OPTIONS);
    const keys = this.getKeyNames(flag);

    const userValue = this.storage.retrieve(keys.userFlag);
    const sessionValue = this.storage.retrieve(keys.sessionFlag);

    let value: any;

    if (
      options.flagReadPolicy === 'at_least_one' ||
      (userValue != null && sessionValue != null)
    ) {
      if (options.priority === 'session' && sessionValue != null) {
        value = sessionValue;
      }

      if (value == null && options.priority === 'user' && userValue != null) {
        value = userValue;
      }

      if (value == null && sessionValue != null) {
        value = sessionValue;
      }

      if (value == null && userValue != null) {
        value = userValue;
      }
    }

    if (value == null) {
      value = options.default;
    }

    if (value != null) {
      switch (options.format) {
        case 'boolean':
          value = !(value === '0' || value === 'false' || value === '');
          break;
      }
    }

    this.addValue({ name: flag, value });

    return value;
  }

  @Debug({ d })
  observe(
    flag: string,
    options: ExperimentFlagObserveOptions = {},
  ): Observable<string> {
    options = _.defaults({}, options, DEFAULT_EXPERIMENT_FLAG_OBSERVE_OPTIONS);

    const keys = this.getKeyNames(flag);

    let obs = merge(
      this.storage.observe(keys.sessionFlag),
      this.storage.observe(keys.userFlag),
    ).pipe(
      map(() => this.getExperimentalFlag(flag, options)),
      filter((x) => x != null),
    );

    if (options.acceptLocalStorageCache) {
      const value = this.getExperimentalFlag(flag, options);
      if (value != null) {
        obs = obs.pipe(startWith(value));
      }
    }

    return obs.pipe(distinctUntilChanged());
  }

  @Debug({ d })
  private getKeyNames(flag: string) {
    const flagTitle = changeCase.pascal(flag);
    const sessionFlag = `flagSession${flagTitle}`;
    const userFlag = `userSession${flagTitle}`;
    return { sessionFlag, userFlag };
  }
}

export interface ExperimentFlagObserveOptions {
  priority?: 'session' | 'user';
  acceptLocalStorageCache?: boolean;
  flagReadPolicy?: 'at_least_one' | 'both';
}

export interface GetExperimentalFlagOptions {
  priority?: 'session' | 'user';
  flagReadPolicy?: 'at_least_one' | 'both';
  default?: string;
  format?: 'string' | 'boolean';
}
