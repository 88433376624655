import { InjectionToken } from '@angular/core';

export interface ApplicationMeta {
  serviceName: string;
  env: string;
  version: string;
  timestamp?: string;
  clientTimestamp?: string;
}

export const APPLICATION_META = new InjectionToken<string>('APPLICATION_META');
