import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'v2-featured-user',
  templateUrl: './featured-user.component.html',
  styleUrls: ['./featured-user.component.scss'],
})
export class FeaturedUserComponent extends V2BaseComponent implements OnInit {
  @Input()
  profile: FeaturedUser;

  ngOnInit(): void {}

  constructor(layoutService: A7LayoutService) {
    super(layoutService);
  }
}

export interface FeaturedUser {
  name: string;
  profileImgUrl: string;
  profiles: string[];
  comments: UseComment[];
}

export interface UseComment {
  text: string;
}
