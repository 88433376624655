import _ from 'underscore';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User, UserInterest } from '@ark7/core-business-models';
import { filter } from 'rxjs/operators';

import { GlobalNotificationComponent } from '../components/global-notification/global-notification.component';
import { IpInfoService } from './ip-info.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalNotificationService {
  constructor(
    userService: UserService,
    ipInfo: IpInfoService,
    dialog: MatDialog,
  ) {
    const sub = userService.userChange$
      .pipe(filter((u) => !_.isEmpty(u?._id?.toString())))
      .subscribe(async (user: User) => {
        sub.unsubscribe();

        if (
          user.status?.accreditationVerified &&
          user.tags?.hasTag?.(UserInterest.PLUS_ACTIVATION_CLICKED) &&
          !user.status?.plusGreeted
        ) {
          dialog.open(GlobalNotificationComponent, {
            data: {
              pic: '/assets/v2-all-set.svg',
              title: 'Your Ark7+ Activated',
              note:
                'Congratulations! Your Ark7+ access is now fully activated. Visit Marketplace today to find your next rental home investment.',
              cta: 'Okay',
            },

            width: '960px',
          });

          user.status.$update({
            plusGreeted: true,
          });
        } else if (user) {
          for (const test of this.registeredItems.keys()) {
            if (await test()) {
              this.registeredItems.get(test)(dialog);
              break;
            }
          }
        }
      });

    //     const subSen = ipInfo.isSensitiveCountry().subscribe((sensitive) => {
    //       subSen.unsubscribe();
    //       if (sensitive) {
    //         dialog.open(GlobalNotificationComponent, {
    //           data: {
    //             title: 'Important Notice about Ark7.com Operations:',
    //             note: `
    // <div>
    //   We want to bring to your attention that Ark7 currently provides services
    //   <span class="underline h3">exclusively to residents in the United States</span>.
    //   Our investor app, available for download at https://ark7.com/app, and our
    //   official website https://ark7.com/ are the exclusive platforms for investment
    //   with us. If you, or anyone else, are approached for fund transfers overseas in
    //   the name of Ark7, it is essential to be aware that this is not a legitimate
    //   request and is not aligned with our business practices.
    // </div>
    // <div>
    //   We strongly advise against engaging with any suspicious entity and recommend
    //   refraining from providing any personal information or participating in fund
    //   transfers. In the meantime, please consider reporting any suspicious activity
    //   or claims that contradict these guidelines to your local authorities promptly.
    // </div>
    // <div>
    //   Your safety and security are our top priorities. Thank you for your attention
    //   to this critical matter.
    // </div>
    //               `,
    //             cta: 'Okay',
    //           },

    //           width: '960px',
    //         });
    //       }
    //     });
  }

  private registeredItems: Map<
    () => Promise<boolean>,
    (dialog: MatDialog) => void
  > = new Map();

  registerPopup(
    testFunc: () => Promise<boolean>,
    popup: (dialog: MatDialog) => void,
  ) {
    this.registeredItems.set(testFunc, popup);
  }
}
