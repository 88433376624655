import * as hd_ from 'humanize-duration';
import * as mmt from 'moment-timezone';
import * as mr from 'moment-range';
import moment_ from 'moment';

// Issue: https://github.com/jvandemo/generator-angular2-library/issues/221
const hd = hd_;
export const moment = moment_;
export const momentRange = mr.extendMoment(moment);

export type Time = moment_.Moment | Date | string;

export function toMoment(t: Time): moment_.Moment {
  return moment.isMoment(t) ? t : moment(t);
}
export function toDuration(d: moment_.Duration | string): moment_.Duration {
  return moment.isDuration(d) ? d : moment.duration(d);
}

export function floor(t: Time, d: moment_.Duration | string): moment_.Moment {
  const result = toMoment(t).clone();
  const duration = toDuration(d);

  // TODO: generic the convert logic.
  if (duration.asMinutes() <= 60) {
    const diff = result.toDate().getTime() % duration.asMilliseconds();
    return result.add(moment.duration(-diff, 'milliseconds'));
  } else if (duration.asDays() === 1) {
    return result.startOf('day');
  } else if (duration.asWeeks() === 1) {
    return result.startOf('week');
  } else if (duration.asMonths() === 1) {
    return result.startOf('month');
  } else {
    throw new Error('unknown duration');
  }
}

export function rangeBy(
  start: moment_.Moment,
  end: moment_.Moment,
  d: moment_.Duration | string,
): moment_.Moment[] {
  const range = momentRange.range(start, end);
  const duration = toDuration(d);
  if (duration.asMinutes() < 60 * 24) {
    return Array.from(range.by('minute', { step: duration.asMinutes() }));
  } else if (duration.asWeeks() < 1) {
    return Array.from(range.by('day', { step: duration.asDays() }));
  } else if (duration.asMonths() < 1) {
    return Array.from(range.by('week', { step: duration.asWeeks() }));
  } else if (duration.asYears() < 1) {
    return Array.from(range.by('month', { step: duration.asMonths() }));
  } else {
    return Array.from(range.by('year', { step: duration.asYears() }));
  }
}

export function humanizeDuration(sec: number): string {
  return hd(sec, { largest: 1, maxDecimalPoints: 0 });
}

/*
 * Market Trading Hours: Monday - Friday, 10:30 AM to 5:00 PM Eastern Time (ET)
 */
export function isMarketOpen(time?: Date): boolean {
  if (time == null) {
    time = new Date();
  }

  const mTime = mmt.tz(time, 'America/New_York');

  const mmtMidnight = mTime.clone().startOf('day');

  const diffSeconds = mTime.diff(mmtMidnight, 'seconds');

  return (
    mTime.weekday() > 0 &&
    mTime.weekday() < 6 &&
    diffSeconds > 10.5 * 3600 &&
    diffSeconds < 17 * 3600
  );
}
