import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[cTip]',
})
export class CampaignTooltipDirective implements OnDestroy {
  @HostBinding('class')
  pointer = 'pointer';

  @Input() cTip = '';
  @Input() delay?: number = 0;
  @Input() removalDelay?: number = 0;
  vOffset = 10;

  private myPopup: HTMLElement | null = null;
  private enterTimer: any = null;
  private leaveTimer: any = null;

  constructor(private el: ElementRef) {}

  ngOnDestroy(): void {
    if (this.enterTimer) {
      clearTimeout(this.enterTimer);
    }
    if (this.leaveTimer) {
      clearTimeout(this.leaveTimer);
    }
    if (this.myPopup) {
      this.myPopup.remove();
    }
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.leaveTimer) {
      clearTimeout(this.leaveTimer);
    }
    if (!this.myPopup) {
      this.enterTimer = setTimeout(() => {
        const x =
          this.el.nativeElement.getBoundingClientRect().left +
          this.el.nativeElement.offsetWidth / 2;
        const y =
          this.el.nativeElement.getBoundingClientRect().top +
          this.el.nativeElement.offsetHeight / 2;
        this.createTooltipPopup(x, y);
      }, this.delay);
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.enterTimer) {
      clearTimeout(this.enterTimer);
    }
    this.initiateTooltipRemoval();
  }

  private initiateTooltipRemoval() {
    this.leaveTimer = setTimeout(() => {
      if (this.myPopup) {
        this.myPopup.remove();
        this.myPopup = null;
      }
    }, this.removalDelay);
  }

  private createTooltipPopup(x: number, y: number) {
    const body = document.body;
    const bodyWidth = body.offsetWidth;
    const bodyHeight = body.offsetHeight;

    const popup = document.createElement('div');
    popup.innerHTML = this.cTip;
    popup.setAttribute('class', 'tooltip-container');

    if (bodyWidth / 2 > x) {
      popup.style.left = `${x}px`;
    } else {
      popup.style.right = `${bodyWidth - x}px`;
    }
    if (bodyHeight / 2 > y) {
      popup.style.top = `${y + this.vOffset}px`;
    } else {
      popup.style.bottom = `${bodyHeight - y + this.vOffset}px`;
    }

    body.appendChild(popup);
    this.myPopup = popup;

    popup.addEventListener('mouseenter', () => {
      clearTimeout(this.leaveTimer);
    });
    popup.addEventListener('mouseleave', () => {
      this.initiateTooltipRemoval();
    });
  }
}
