import _ from 'underscore';
import debug from 'debug';
import { A7LayoutService } from '@ark7/layout';
import {
  A7Router,
  Debug,
  SubscribeComponent,
  a7EmailValidator,
} from '@ark7/utils';
import { ActivatedRoute } from '@angular/router';
import {
  BasicUserResource,
  Logger,
  SignInErrorCode,
  USER_ID_KEY,
  UserService,
} from '@ark7/resources-common';
import { CodeInputComponent, SequentialInputGroupDirective } from '@ark7/input';
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, debounceTime } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TwoFAVerificationMethod } from '@ark7/core-business-models';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserBehaviorService } from '@ark7/socket';
import { isPlatformBrowser } from '@angular/common';

const d = debug('a7-ui-auth:SignInFormComponent2');

enum SignInFormStage {
  DEFAULT = 0,
  REGISTERED_SUCCESSFULLY = 1,
  TWO_FA_SEND = 2,
  TWO_FA_VERIFY = 3,
  INTERNAL_SERVER_ERROR = 4,
}

// If set, this sign-in form is for oauth, and has oauth specific logics.
enum OAuthConnectClient {
  NONE = 'NONE',
  MONEYMADE = 'MONEYMADE',
}

@Component({
  selector: 'v2-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss'],
})
export class SignInFormComponent2 extends SubscribeComponent implements OnInit {
  @Input() formTitle: string;
  @Input() allowedRedirectHosts: string[];
  @Input() host: string;
  @Input() logo: string;
  @Input() social: boolean = false;
  @Input() oauthClient: string = OAuthConnectClient.NONE;
  @Output() signInEvent = new EventEmitter<boolean>();
  @ViewChild(SequentialInputGroupDirective)
  verificationCodeInput: SequentialInputGroupDirective;

  @HostBinding('class.a7-ui-auth-sign-in-form')
  @HostBinding('class.a7-ui-auth-form')
  _hostBinding = true;

  form: UntypedFormGroup;
  loggingIn: boolean = false;
  stage: SignInFormStage = 0;

  private redirectTo: string = '/';
  private pageTitle: string;

  private focusToRefresh: boolean = false;
  autoTriggerVerify = new Subject<void>();
  constructor(
    fb: UntypedFormBuilder,
    route: ActivatedRoute,
    layout: A7LayoutService,
    @Inject(PLATFORM_ID) platformId: string,
    private router: A7Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private userResource: BasicUserResource,
    private logger: Logger,
    private userBehavior: UserBehaviorService,
    private localStorage: LocalStorageService,
    private title: Title,
  ) {
    super();

    this.form = fb.group({
      username: [
        '',
        [Validators.required, Validators.email, a7EmailValidator()],
      ],
      password: ['', [Validators.required]],
    });

    if (this.TwoFAVerificationMethod.length > 0) {
      this.verificationMethod = this.TwoFAVerificationMethod[0];
    }

    route.queryParams.subscribe((params) => {
      if (params.email) {
        this.form.controls.username.setValue(params.email);
      }
      // only allow to override stage=2 (TWO_FA_SEND)
      if (params.stage === '2') {
        this.stage = SignInFormStage.TWO_FA_SEND;
      }
      if (params.redirectTo && isPlatformBrowser(platformId)) {
        d('params.redirectTo', params.redirectTo);

        this.redirectTo = new URL(
          params.redirectTo,
          window?.location.href,
        ).toString();

        this.pageTitle = params.pageTitle;
      }
    });
  }

  get formTitleWithDefault() {
    return 'Welcome back to Ark7';
  }

  ngOnInit() {
    this.safeSubscribe(this.autoTriggerVerify.pipe(debounceTime(20)), () =>
      this.verify2FACode(),
    );
    this.safeSubscribe(this.userService.userChange$, (user) => {
      d('User Update!', user);
      // Defer the redirect to avoid circular redirection.
      _.defer(() => {
        const redirectTo = new URL(this.redirectTo, window?.location.href);
        d('redirectTo', redirectTo);
        d(`allowedRedirectHosts`, this.allowedRedirectHosts);
        d(`redirectTo.host`, redirectTo.host);
        d(`this.host`, this.host);
        if (
          user != null &&
          // do not use endsWith cause of query params
          (this.router.url.startsWith('/a/sign-in') ||
            this.router.url.startsWith('/v2/sign-in'))
        ) {
          if (this.allowedRedirectHosts.indexOf(redirectTo.host) >= 0) {
            d('host in allowedRedirectHosts, hard redirect');
            window.location.href = redirectTo.toString();
          } else if (redirectTo.host === this.host) {
            d('hosts are the same, try router navigate');
            const url = redirectTo
              .toString()
              .substring(redirectTo.origin.length);
            this.router.navigateByUrl(url);
          } else {
            d('router navigate to root');
            this.router.navigate(['/']);
          }
        }
      });
    });

    this.safeSubscribe(
      this.localStorage.observe(USER_ID_KEY),
      (userID: string) => {
        if (userID != null && !this.loggingIn) {
          this.focusToRefresh = true;

          this.title.setTitle(this.pageTitle || 'Login - Welcome back to Ark7');
        } else {
          this.focusToRefresh = false;
        }
      },
    );
  }

  @HostListener('window:focus')
  onWindowFocus() {
    if (this.focusToRefresh) {
      location.reload();
    }
  }

  emitSignInEvent() {
    try {
      this.userResource.emitSignInEvent({});
      this.userBehavior.signIn();

      if (this.oauthClient !== OAuthConnectClient.NONE) {
        this.signInEvent.emit(true);
      }
    } catch (e) {}
  }

  @Debug({ d })
  async login() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.form.disable();

    const username = this.form.controls.username.value.trim();
    const password = this.form.controls.password.value.trim();

    try {
      this.loggingIn = true;
      this.focusToRefresh = false;
      await this.userService.signIn({
        email: username,
        password,
      });
      this.emitSignInEvent();
      // sign in successfully, will trigger deferred redirection.
      d('Will redirect');
    } catch (e) {
      d('Sign In Error: %O', e);

      this.form.enable();

      if (e.status === 401 && e.body?.meta?.tos === true) {
        this.router.navigate(['/a/accept-tos']);
        return;
      } else if (
        e.status === 401 &&
        e.body?.message === 'two_factor_verification_required'
      ) {
        this.twoFAMethods = e.body?.meta?.availableMethods;

        this.stage = SignInFormStage.TWO_FA_SEND;
      } else if (
        e.status === 400 &&
        e.body &&
        e.body.message === 'User does not exist.'
      ) {
        this.form.controls.username.setErrors({
          userNotExist: true,
        });
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (
        e.status === 400 &&
        e.body &&
        e.body.message === 'Incorrect username or password.'
      ) {
        this.form.controls.password.setValue('');
        this.form.controls.password.setErrors({
          unauthorized: true,
        });
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (
        e.status === 400 &&
        e.body &&
        e.body.message === 'User is not confirmed.'
      ) {
        this.stage = SignInFormStage.REGISTERED_SUCCESSFULLY;
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (
        e.code === SignInErrorCode.NOT_AUTHORIZED ||
        e.code === SignInErrorCode.USER_NOT_FOUND
      ) {
        this.form.controls.password.setValue('');
        this.form.controls.password.setErrors({
          unauthorized: true,
        });
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (e.code === SignInErrorCode.USER_NOT_CONFIRMED_EXCEPTION) {
        this.stage = SignInFormStage.REGISTERED_SUCCESSFULLY;
        this.logger.info('User authorized failed', {
          error: e,
        });
      } else if (e.status !== 0) {
        this.logger.error('User sign in failed', {
          error: e,
        });

        this.snackBar.open('Server error, please try again later', '', {
          duration: 3000,
        });
      }
      this.loggingIn = false;
    }
  }

  get email() {
    return this.form.controls.username.value;
  }

  @LocalStorage() twoFAMethods: string[];

  get TwoFAVerificationMethod(): TwoFAVerificationMethod[] {
    return _.isEmpty(this.twoFAMethods)
      ? [TwoFAVerificationMethod.EMAIL, TwoFAVerificationMethod.TEXT_MESSAGE]
      : (this.twoFAMethods as any);
  }

  verificationMethod: TwoFAVerificationMethod;

  sendingTwoFACode = false;
  verify2FAFailed = false;

  count: number;
  resendEnable: boolean = false;

  @ViewChild('codeInput') codeInput: CodeInputComponent;

  async send2FACode() {
    this.sendingTwoFACode = true;
    try {
      await this.userService.sendTwoFACode(this.verificationMethod);

      this.startCountdown();
      this.stage = SignInFormStage.TWO_FA_VERIFY;
      _.delay(() => {
        if (this.verificationCodeInput) {
          this.verificationCodeInput.clear();
          this.verificationCodeInput.focus();
        }
      }, 500);
    } catch (err) {
      this.logger.warn('send 2fa code error', err);

      if (err.status === 401) {
        this.stage = SignInFormStage.DEFAULT;
      } else if (
        err.status == 422 &&
        err.body?.message === 'Phone number is not verified.'
      ) {
        this.snackBar.open(
          'Your phone number has not been verified, please send code via email.',
          '',
          {
            duration: 3000,
          },
        );
        this.stage = SignInFormStage.TWO_FA_SEND;
      } else if (
        err.status == 422 &&
        err.body?.message === 'Email is not verified.'
      ) {
        this.snackBar.open(
          'Your email has not been verified, please send code via text message.',
          '',
          {
            duration: 3000,
          },
        );
        this.stage = SignInFormStage.TWO_FA_SEND;
      }
    } finally {
      this.sendingTwoFACode = false;
    }
  }

  countdownHandler: any;

  startCountdown() {
    this.count = 60;
    this.resendEnable = false;
    if (this.countdownHandler) {
      clearInterval(this.countdownHandler);
    }
    this.countdownHandler = setInterval(() => {
      this.count -= 1;
      if (this.count <= 0) {
        this.resendEnable = true;
        clearInterval(this.countdownHandler);
        this.countdownHandler = null;
      }
    }, 1000);
  }

  ngOnDestroy() {
    if (this.countdownHandler) {
      clearInterval(this.countdownHandler);
    }
  }

  async verify2FACode() {
    this.codeInput.form.disable();
    this.verify2FAFailed = false;
    try {
      await this.userService.verifyTwoFACode(
        this.codeInput.code,
        this.verificationMethod,
      );
      await this.userService.fetch();
      this.emitSignInEvent();
    } catch (err) {
      this.logger.warn('verify 2fa code error', err);

      if (err.status === 401) {
        this.stage = SignInFormStage.DEFAULT;
      } else if (err.status === 400) {
        this.verify2FAFailed = true;
      }
    } finally {
      this.codeInput.form.enable();
    }
  }
}
