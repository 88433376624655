import { A7UtilsModule } from '@ark7/utils';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { InfoBlockComponent } from './info-block/info-block.component';
import { InfoBlocksComponent } from './info-blocks/info-blocks.component';
import { RangeComponent } from './range/range.component';

@NgModule({
  declarations: [RangeComponent, InfoBlockComponent, InfoBlocksComponent],
  imports: [CommonModule, A7UtilsModule, MatMenuModule, RouterModule],
  exports: [RangeComponent, InfoBlockComponent, InfoBlocksComponent],
})
export class A7InfoModule {}
