import {
  A7ResourceCRUD,
  A7ResourceParams,
  ResourceHandler,
} from '@ark7/resource2';
import { Injectable } from '@angular/core';
import { Subscription } from '@ark7/core-business-models';

@Injectable({
  providedIn: 'root',
})
@A7ResourceParams({
  pathPrefix: '/api/v2/subscriptions',
  withCredentials: true,
  model: Subscription,
})
export class SubscriptionResource extends A7ResourceCRUD<Subscription, {}> {
  constructor(handler: ResourceHandler) {
    super(handler);
  }
}
