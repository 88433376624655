import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpRequest,
  HttpUploadProgressEvent,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { observableToPromise } from '../utils/promises';

export const API_HOST = new InjectionToken<string>('API_HOST');

export interface UploadImageOptions {
  onProgress?: (event: HttpUploadProgressEvent) => void;
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private http: HttpClient,
    @Inject(API_HOST) private apiHost: string,
  ) {}

  async uploadImage(
    uploadFile: NgxFileDropEntry,
    options: UploadImageOptions = {},
  ): Promise<IUploadedFile> {
    const fileEntry = uploadFile.fileEntry as FileSystemFileEntry;

    const form = await new Promise((resolve) => {
      fileEntry.file((file) => {
        const formData = new FormData();

        formData.append('file', file, uploadFile.relativePath);
        formData.append(
          'type',
          uploadFile.relativePath.endsWith('.png')
            ? 'png'
            : uploadFile.relativePath.endsWith('.gif')
            ? 'gif'
            : uploadFile.relativePath.endsWith('.json')
            ? 'json'
            : 'jpg',
        );

        resolve(formData);
      });
    });

    return await new Promise((resolve, reject) => {
      this.http
        .request(
          new HttpRequest(
            'POST',
            this.apiHost + '/api/v2/admin/upload-image',
            form,
            {
              withCredentials: true,
              responseType: 'json',
              reportProgress: true,
            },
          ),
        )
        .pipe(
          map((event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                if (options.onProgress) {
                  options.onProgress(event as HttpUploadProgressEvent);
                }
                break;
              case HttpEventType.Response:
                resolve(event.body);
            }
          }),
          catchError((error: HttpErrorResponse) => {
            reject(error);
            return throwError(error);
          }),
        )
        .subscribe(() => {});
    });
  }

  async uploadFile(uploadFile: NgxFileDropEntry): Promise<IUploadedFile> {
    const fileEntry = uploadFile.fileEntry as FileSystemFileEntry;

    const form = await new Promise((resolve) => {
      fileEntry.file((file) => {
        const formData = new FormData();

        formData.append('file', file, uploadFile.relativePath);

        resolve(formData);
      });
    });

    const result = (await observableToPromise(
      this.http.post(this.apiHost + '/api/v2/admin/upload-file', form, {
        withCredentials: true,
        responseType: 'json',
      }),
    )) as any;

    return result;
  }
}

export interface IUploadedFile {
  link: string;
}
